/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getHrefParams} from '../lib/api';

export default {
  getPolicyVersionFromHref(href) {
    return getHrefParams(href).pversion;
  },

  compareActiveHref(activeHref, versionHref) {
    return (
      versionHref.replace(/\/sec_policy\/\d+/, '/sec_policy/active') ===
      activeHref.replace(/\/sec_policy\/\d+/, '/sec_policy/active')
    );
  },

  findObjectByHref(href, objects, ignoreVersion) {
    if (!href || !objects || _.isEmpty(objects)) {
      return;
    }

    const {pversion} = getHrefParams(href);
    // handle unversioned objects too
    const source = !ignoreVersion && !_.isUndefined(pversion) ? objects[pversion] : objects;

    return _.find(source, o => (pversion === 'active' ? this.compareActiveHref(href, o.href) : o.href === href));
  },

  findObjectByQuery(query, objects) {
    return _.find(objects, o => o.query === query);
  },

  setObject(data, objects, filter) {
    const index = _.findIndex(objects, o => o && o.href === data.href);

    if (_.isFunction(filter)) {
      data = filter(data);
    }

    if (index === -1) {
      objects.push(data);
    } else {
      objects[index] = data;
    }
  },

  setObjectByQuery(data, query, objects) {
    const index = _.findIndex(objects, o => o && o.query === query);

    if (index === -1) {
      data.query = query;
      objects.push(data);
    } else {
      data.query = query;
      objects[index] = data;
    }
  },

  setObjectByVersion(data, version, source, filter) {
    source[version] ||= [];

    let index;

    if (version === 'active') {
      index = _.findIndex(source[version], o => o && this.compareActiveHref(data.href, o.href));
    } else {
      index = _.findIndex(source[version], o => o && o.href === data.href);
    }

    if (_.isFunction(filter)) {
      data = filter(data);
    }

    if (index > -1) {
      source[version][index] = data;
    } else {
      source[version].push(data);
    }
  },

  setObjects(source, loaded, data, version = 'draft') {
    if (version === 'active' && data.length) {
      const pversion = this.getPolicyVersionFromHref(data[0].href);

      source[pversion] = data;
      loaded[pversion] = true;
    }

    source[version] = data;
    loaded[version] = true;
  },
};
