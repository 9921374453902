/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {Link, State} from 'react-router';
import {ObjectSelector, Banner, Button, Label, Notification} from '../../components';
import {Select} from '../../components/FormComponents';
import Constants from '../../constants';
import RouterMixin from '../../mixins/RouterMixin';
import StoreMixin from '../../mixins/StoreMixin';
import {LinkTable, VizPanel, NavPathPanel, UnmanagedIps, DomainTable} from '../../components/Explorer';
import ExplorerActions from '../../actions/ExplorerActions';
import TimeFilter, {
  getDefaultTimeOptions,
  getSAASTimeOptions,
  timeOptionExistsInOptions,
} from '../../components/Explorer/TimeFilter.jsx';
import {ExplorerUtils, ExplorerBoundaryUtils} from '../../utils/Explorer';
import {
  GeneralStore,
  TrafficStore,
  WorkloadStore,
  SessionStore,
  ServiceStore,
  ExplorerFilterStore,
  ExplorerStore,
  SettingsOrgStore,
  MapPageStore,
} from '../../stores';
import {
  GraphDataUtils,
  RenderUtils,
  RestApiUtils,
  GroupDataUtils,
  IpUtils,
  LabelUtils,
  ServiceUtils,
  EnforcementBoundariesUtils,
} from '../../utils';

import actionCreators from '../../actions/actionCreators';
import {webStorageUtils} from '@illumio-shared/utils';

const BOUNDARIES = 'boundaries';
const APPGROUP = 'appgroup';
const basicFormatTypes = () => [
  {key: 'parallelCoordinates', value: intl('Explorer.ParallelCoordinates')},
  {key: 'table', value: intl('Explorer.Table')},
];

const allFormatTypes = () => [
  ...basicFormatTypes(),
  {key: 'unmanagedIps', value: intl('Common.UnmanagedIPAddresses')},
  {key: 'domaintable', value: intl('Common.DomainTable')},
];

const allPolicyOptions = () => ({
  [intl('Common.ReportedPolicy')]: 'reported',
  [intl('Common.Allowed')]: 'allowed',
  [intl('Common.PotentiallyBlocked')]: 'potentiallyBlocked',
  [intl('Common.PotentiallyBlockByDenyRules')]: 'potentially_blocked_by_boundary',
  [intl('Common.Blocked')]: 'blocked',
  [intl('Common.BlockedByDenyRules')]: 'blocked_by_boundary',
  [intl('Common.Unknown')]: 'unknown',
});

const blockedPolicyOptions = () => ({
  [intl('Common.ReportedPolicy')]: 'reported',
  [intl('Common.PotentiallyBlocked')]: 'potentiallyBlocked',
  [intl('Common.PotentiallyBlockByDenyRules')]: 'potentially_blocked_by_boundary',
  [intl('Common.Blocked')]: 'blocked',
  [intl('Common.BlockedByDenyRules')]: 'blocked_by_boundary',
  [intl('Common.Unknown')]: 'unknown',
});

const directionOptions = () => ({
  [intl('Common.Destination')]: 'inbound',
  [intl('Common.Sourced')]: 'outbound',
});

const typeOptions = () => ({
  [intl('ApplicationsCoverage.IntraScope')]: 'intraScope',
  [intl('ApplicationsCoverage.ExtraScope')]: 'extraScope',
  [intl('Common.IPList')]: 'iplist',
  [intl('Common.RFC1918')]: 'rfc1918',
  [intl('Common.Internet')]: 'internet',
  [intl('PCE.FQDN')]: 'fqdn',
});

const boundaryTypeOptions = () => ({
  [intl('Common.IPList')]: 'iplist',
  [intl('Common.RFC1918')]: 'rfc1918',
  [intl('Common.Internet')]: 'internet',
});

const transmissionOptions = () => ({
  [intl('Map.Traffic.Unicast')]: 'unicast',
  [intl('Map.Traffic.Multicast')]: 'multicast',
  [intl('Map.Traffic.Broadcast')]: 'broadcast',
});

const options = (selectedOptions, showBoundaries) => ({
  ...ExplorerUtils.filterPolicyFilterOptions(allPolicyOptions(), selectedOptions, showBoundaries),
  ...directionOptions(),
  ...typeOptions(),
  ...transmissionOptions(),
});
const boundaryOptions = (selectedOptions, showBoundaries) => ({
  ...ExplorerUtils.filterPolicyFilterOptions(blockedPolicyOptions(), selectedOptions, showBoundaries),
  ...boundaryTypeOptions(),
  ...transmissionOptions(),
});
const unmanagedOptions = () => ({...directionOptions(), ...transmissionOptions()});
const domainOptions = () => ({...transmissionOptions()});

const facetMap = () => ({
  [intl('Common.ServiceMore')]: 'services',
  [intl('Common.ServiceIsNotMore')]: 'serviceIsNot',
  [intl('Common.EntitiesMore')]: 'entities',
  [intl('Common.EntitiesIsNotMore')]: 'entityIsNot',
  [intl('Common.ApplicationsMore')]: 'appGroups',
  [intl('Common.IPAddressesMore')]: 'ipAddress',
});

const unmanagedFacetMap = () => ({
  [intl('Common.ServiceMore')]: 'services',
  [intl('Common.ServiceIsNotMore')]: 'serviceIsNot',
  [intl('Common.IPAddressesMore')]: 'ipAddress',
});

const allUnmanagedOptions = () => ({...unmanagedOptions(), ...unmanagedFacetMap()});
const allDomainOptions = () => ({...domainOptions(), ...unmanagedFacetMap()});

const reverseFacetMap = () => ({
  services: [intl('Common.ServiceMore')],
  serviceIsNot: [intl('Common.ServiceIsNotMore')],
  entities: [intl('Common.EntitiesMore')],
  entityIsNot: [intl('Common.EntitiesIsNotMore')],
  labels: [intl('Common.ApplicationsMore')],
  ipAddress: [intl('Common.IPAddressesMore')],
});

const labelMap = () => ({
  [intl('Common.ServiceMore')]: intl('Common.Service'),
  [intl('Common.ServiceIsNotMore')]: intl('Common.ServiceIsNot'),
  [intl('Common.EntitiesMore')]: intl('Common.Workload'),
  [intl('Common.EntitiesIsNotMore')]: intl('Common.EntitiesIsNot'),
  [intl('Common.ApplicationsMore')]: intl('Common.Applications'),
  [intl('Common.IPAddressesMore')]: intl('IPLists.IPAddresses'),
});

function getStateFromStores() {
  const groupType = GroupDataUtils.getType(this.getPathname());

  let href = '';

  if (this.state) {
    const {groupHref} = this.state;

    href = groupHref;

    if (groupType.toLowerCase() !== 'appgroups') {
      href = `${groupType}${this.getParams().id}`;
    }
  }

  const isSAAS = SessionStore.isPCESaaS();

  // EYE-76997 If it's SAAS, we are limiting the query to a org set time span
  const limitToMaxDays = isSAAS ? SettingsOrgStore.getExplorerMaxQueryTimespan() : null;

  // EYE-76997 If it's SAAS, we are limiting the time ranges to Last hour, day, week and Custom Range
  const timeOptions = () => (isSAAS ? getSAASTimeOptions(limitToMaxDays) : getDefaultTimeOptions());
  const aggregationLevel = this.state?.aggregationLevel || 'labels';

  return {
    ...ExplorerStore.getSelected(),
    tableLinks:
      aggregationLevel === 'labels'
        ? ExplorerFilterStore.getAggregatedTableLinks(href)
        : ExplorerFilterStore.getTableLinks(href),
    vizLinks: ExplorerFilterStore.getVizLinks(href),
    ipLinks: ExplorerFilterStore.getIpLinks(href),
    domainLinks: ExplorerFilterStore.getDomainLinks(href),
    services: ExplorerFilterStore.getServices(href),
    excludeServices: ExplorerFilterStore.getExcludeServices(href),
    entities: ExplorerFilterStore.getEntities(href),
    excludeEntities: ExplorerFilterStore.getExcludeEntities(href),
    unmanagedServices: ExplorerFilterStore.getUnmanagedServices(href),
    unmanagedExcludeServices: ExplorerFilterStore.getUnmanagedExcludeServices(href),
    domainServices: ExplorerFilterStore.getDomainServices(href),
    domainExcludeServices: ExplorerFilterStore.getDomainExcludeServices(href),
    groups: ExplorerFilterStore.getGroups(href),
    addresses: ExplorerFilterStore.getAddresses(href),
    unmanagedAddresses: ExplorerFilterStore.getUnmanagedAddresses(href),
    domainAddresses: ExplorerFilterStore.getDomainAddresses(href),
    items: ExplorerFilterStore.getFilters(href),
    asyncStatus: ExplorerStore.getNewQueryStatus(),
    explorerType: ExplorerStore.getExplorerType(),
    asyncEnabled: SessionStore.isAsyncExplorerEnabled(),
    loadedQuery: ExplorerStore.getLoadedQuery(),
    name: ExplorerFilterStore.getBoundaryName(href),
    showBoundaries: MapPageStore.getTotalEnforcementBoundaries() > 0,
    timeOptions,
    limitToMaxDays,
    isSAAS,
  };
}

export default React.createClass({
  mixins: [
    State,
    RouterMixin,
    StoreMixin([WorkloadStore, ExplorerStore, ExplorerFilterStore, ServiceStore], getStateFromStores),
  ],

  getInitialState() {
    const type = this.getPathname().split('/')[1];
    const appGroupType = type.toLowerCase() === 'appgroups';
    let groupHref;
    let groupLabelHrefs;

    if (appGroupType) {
      ({groupHref, groupLabelHrefs} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id));
    } else {
      groupHref = `${type}${this.getParams().id}`;
    }

    const aggregationLevel = (appGroupType && localStorage.getItem('aggregation_level_appgroup')) || 'labels';
    const sorting = GeneralStore.getSorting('linkTable');
    const format = allFormatTypes().find(formatType => formatType.value === localStorage.getItem('explorerFormat'));
    const time = this.getGroupTime(groupHref);

    return {
      type,
      time,
      groupLabelHrefs,
      aggregationLevel,
      groupHref: groupHref || `${type}${this.getParams().id}`,
      groupType: GroupDataUtils.getType(this.getPathname()),
      sorting: sorting || [{key: 'src_ip', direction: false}],
      currentPage: 1,
      groupExists: true,
      dropdownValues: {},
      status: [Constants.STATUS_BUSY],
      format:
        ExplorerStore.getExplorerType() === APPGROUP
          ? (format && format.value) || intl('Explorer.Table')
          : intl('Explorer.Table'),
    };
  },

  componentWillReceiveProps() {
    const groupType = GroupDataUtils.getType(this.getPathname());
    const groupHref =
      groupType.toLowerCase() === 'appgroups' ? this.getParams().id : `${groupType}${this.getParams().id}`;
    const sorting = GeneralStore.getSorting('groupRules');
    const time = this.getGroupTime(groupHref);

    if (groupHref !== this.state.groupHref || groupType !== this.state.groupType) {
      if (groupType === BOUNDARIES) {
        this.getInitialBoundaryTraffic(time);

        this.setState({
          groupType,
          groupHref,
          sorting: sorting || [],
          time,
        });
      } else {
        const {groupHref, groupLabelHrefs} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

        _.defer(() => {
          this.getGroup(TrafficStore.getNode(groupHref), groupHref);
        });

        this.setState({
          groupType,
          groupHref,
          groupLabelHrefs,
          sorting: sorting || [],
          groupExists: true,
          time,
        });
      }
    }
  },

  async componentDidMount() {
    const {groupType, groupHref, group} = this.state;

    if (!localStorage.getItem('disable_explorer_services')) {
      RestApiUtils.services.getCollection({max_results: 100_000});
    }

    await this.handleSelectiveEnforcement();

    if (this.state.isSAAS) {
      // Request the org settings only if env is SAAS
      await RestApiUtils.orgSettings.get();
    }

    if (groupType === BOUNDARIES) {
      this.getInitialBoundaryTraffic();

      return;
    }

    actionCreators.setExplorerType(APPGROUP);

    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    RestApiUtils.services.getCollection({max_results: 100_000});

    if (SessionStore.canUserViewEnforcementBoundaries()) {
      RestApiUtils.enforcementBoundaries.getCollection('draft', true, {max_results: 1});
    }

    this.getGroup(group, groupHref);

    const response = await RestApiUtils.workloads.getCollection({max_results: 1}, true);
    const totalWorkloads = Number(response.headers.get('x-total-count'));

    this.mapLevel = totalWorkloads > (localStorage.getItem('location_view') || 50) ? 'loc' : 'full';
  },

  componentWillUnmount() {
    if (this.queryPollingId) {
      clearTimeout(this.queryPollingId);
    }
  },

  getSavedTime(groupHref) {
    if (groupHref.includes(BOUNDARIES)) {
      return {[groupHref]: localStorage.getItem('boundary_time') || 'Last 24 Hours'};
    }

    const timeFilters = localStorage.getItem('app_group_time_filters');

    try {
      return JSON.parse(timeFilters);
    } catch {
      return {};
    }
  },

  getGroupTime(groupHref) {
    const defaultTime = intl('Explorer.LastDays', {count: 1});
    const savedTime = this.getSavedTime(groupHref)?.[groupHref];

    return !savedTime || savedTime === intl('Explorer.CustomRange') ? defaultTime : savedTime;
  },

  async getInitialBoundaryTraffic(newTime) {
    // If this boundary is already loaded, don't reload it
    if (ExplorerFilterStore.getGroup(this.state.groupHref)) {
      this.setState({status: [Constants.STATUS_IDLE]});

      return;
    }

    const {id, pversion} = this.getParams();
    const {time} = this.state;

    this.setState({status: [Constants.STATUS_BUSY]});

    await RestApiUtils.enforcementBoundaries.get(id, pversion);

    const cache = await ExplorerBoundaryUtils.loadCachedBoundaryTraffic(id, newTime || time);

    // If cache doesn't exist - load traffic from scratch
    if (cache === 'empty') {
      const boundaryData = await ExplorerBoundaryUtils.getBoundaryData(id, pversion, time, this.setQueryPollId);

      this.setState({status: [Constants.STATUS_IDLE], ...boundaryData});
    } else {
      this.setState({status: [Constants.STATUS_IDLE]});
    }
  },

  async getGroup(currentGroup, groupHref) {
    let group = currentGroup;

    this.setState({status: [Constants.STATUS_BUSY]});

    if (!group) {
      if (!GraphDataUtils.isSummaryDataLoaded('requested')) {
        await Promise.all([
          GraphDataUtils.getTraffic('location', {route: 'groups'}),
          GraphDataUtils.getTraffic('appGroup', {route: 'groups'}),
        ]);
      }

      group = TrafficStore.getNode(groupHref);

      this.setState({status: [Constants.STATUS_IDLE], groupExists: !_.isEmpty(group), group});
    }

    if (group) {
      const caps = group.caps;

      this.setState({caps});
      this.getGroupTraffic(group);
    }
  },

  async getBoundaryTraffic() {
    this.setState({status: [Constants.STATUS_BUSY]});

    const {id, pversion} = this.getParams();
    const {time, consumers, providers, boundaryServices} = this.state;

    // Remove any old cached queries
    await ExplorerBoundaryUtils.removeCachedBoundaryTraffic(id, time);

    if (!consumers || !providers || !boundaryServices) {
      // If we don't have the expanded info - load everything
      const boundaryData = await ExplorerBoundaryUtils.getBoundaryData(id, pversion, time, this.setPollingId);

      this.setState(boundaryData);
    } else {
      // If we know the expanded info just load the traffic
      ExplorerBoundaryUtils.loadBoundaryTraffic(id, time, consumers, providers, boundaryServices, this.setPollingId);
    }

    this.setState({status: [Constants.STATUS_IDLE]});
  },

  async getGroupTraffic(group = this.state.group, force) {
    if (ExplorerFilterStore.getGroup(group.href) && !force) {
      this.setState({status: [Constants.STATUS_IDLE]});

      return;
    }

    this.setState({status: [Constants.STATUS_BUSY]});

    const {id} = this.getParams();
    const {asyncEnabled, time} = this.state;
    const labels = group.labels;
    const labelMap = [labels.map(label => ({label: {href: label?.href || label.label?.href}}))];
    const labelHrefs = labels.map(label => label.href);
    const data = ExplorerUtils.getQueryData(labelMap, labelMap, [], time);

    if (asyncEnabled) {
      data.query_name = intl('Common.Application');

      const response = await RestApiUtils.trafficFlows.async.create(data);
      const queryHref = response?.body?.href;

      if (queryHref) {
        ExplorerUtils.pollQuery(queryHref, this.setPollingId, [
          {
            force: true,
            type: 'appGroup',
            href: group?.href,
            labels: labelHrefs,
          },
        ]);
      }
    } else {
      RestApiUtils.trafficFlows.get(data, true, 'appGroup', group?.href, labelHrefs);
    }

    try {
      // if the current App Group has no members, recalculate the map
      if (!TrafficStore.getAppGroupNode(id)) {
        sessionStorage.setItem('rebuild_map', true);
      }
    } catch (error) {
      if (error.status === 404) {
        this.replaceWith('resourceNotFound');
      }
    }

    this.setState({status: [Constants.STATUS_IDLE]});
  },

  setPollingId(pollingId) {
    this.setState({pollingId});
  },

  async handleSelectiveEnforcement() {
    const isSelectiveRoute = this.getPath().includes('boundaries');

    // Set the proper label scopes
    if (isSelectiveRoute) {
      const {pversion, id} = this.getParams();

      let details;

      // Retrieve session from Tesse React
      let enforcements = webStorageUtils.getSessionItem('selectiveInstanceLabels') || {};

      // This block of code is used when logging out of Selective Enforcement Rule tab then re-login.
      // Need to retrieve selective enforcement rules. During a logout, all webStorageUtils is cleared.
      if (_.isEmpty(enforcements)) {
        try {
          details = await EnforcementBoundariesUtils.handleEnforcementBoundariesLists(pversion, id);

          const selectivePolicyVersions = EnforcementBoundariesUtils.getSelectivePolicyVersions(details, pversion);

          enforcements = EnforcementBoundariesUtils.getSegmentationLabels(selectivePolicyVersions.versions, id);
        } catch {
          this.transitionTo('boundaries.list');
        }
      }

      const enforcementLabels = enforcements?.scopeLabels[pversion] ?? {};

      this.setState({
        boundariesInfo: {enforcementLabels},
      });
    }
  },

  /**
   * Used to save the polling timer id
   */
  setQueryPollId(id) {
    this.queryPollingId = id;
  },

  handleFormatChange(value) {
    this.setState({format: value}, () => {
      localStorage.setItem('explorerFormat', value);
    });
  },

  applyFilter(newServices, item) {
    let newService = {};

    if (item.key === 'portProtocol') {
      if (item.value.includes(' ')) {
        const portAndProtocol = item.value.split(' ');

        newService.port = Number(portAndProtocol[0]);
        newService.proto = ServiceUtils.reverseLookupProtocol(portAndProtocol[1]);
      } else if (isNaN(Number(item.value))) {
        newService.proto = ServiceUtils.reverseLookupProtocol(item.value);
      } else {
        newService.port = Number(item.value);
      }
    } else if (item.key === 'portRange') {
      const portRange = item.value.split(' ');
      const portsInRange = portRange[0].split('-');

      if (item.value.includes(' ')) {
        newService.port = Number(portsInRange[0]);
        newService.to_port = Number(portsInRange[1]);
        newService.proto = ServiceUtils.reverseLookupProtocol(portRange[1]);
      } else {
        newService.port = Number(portsInRange[0]);
        newService.to_port = Number(portsInRange[1]);
      }
    } else if (item.key === 'policyService' && item && item.value && Array.isArray(item.value)) {
      newService = item.value.map(service => {
        const result = {};

        if (service.hasOwnProperty('protocol') && service.protocol >= 0) {
          result.proto = service.protocol;
        }

        if (service.hasOwnProperty('port') && service.port >= 0) {
          result.port = service.port;
        }

        if (service.hasOwnProperty('to_port') && service.to_port >= 0) {
          result.to_port = service.to_port;
        }

        if (service.hasOwnProperty('process_name') && service.process_name !== null) {
          result.process_name = service.process_name.split('\\').pop();
        }

        if (service.hasOwnProperty('service_name') && service.service_name !== null) {
          result.windows_service_name = service.service_name;
        }

        return result;
      });

      return newServices.concat(newService);
    } else if (item.key === 'processName') {
      newService.process_name = item.value.split('\\').pop();
    } else if (item.key === 'windowsService') {
      newService.windows_service_name = item.value;
    }

    newServices.push(newService);

    return newServices;
  },

  handleTimeChange(time) {
    let timeFilters = localStorage.getItem('app_group_time_filters');

    timeFilters = this.getSavedTime(this.state.groupHref);

    if (this.state.groupHref.includes(BOUNDARIES)) {
      localStorage.setItem('boundary_time', time);
    }

    localStorage.setItem(
      'app_group_time_filters',
      JSON.stringify({...timeFilters, [this.state.groupHref || 'boundary']: time}),
    );

    this.setState({time});
  },

  onSelectOption(filter, value) {
    if (!value || !value.footer) {
      ExplorerActions.addAppGroupFilter({filter, value, href: this.state.groupHref});

      this.setState({dropdownValues: []});
    }
  },

  onRemoveOption(filter) {
    ExplorerActions.removeAppGroupFilter({filter, href: this.state.groupHref});
    this.setState({dropdownValues: []});
  },

  onRemoveMultiOption(filter, value) {
    ExplorerActions.removeMultiAppGroupFilter({filter, value, href: this.state.groupHref});
    this.setState({dropdownValues: []});
  },

  getFacetValues(key, query = '') {
    if (!key) {
      return;
    }

    const {items, format} = this.state;
    const dropdownValues = {};

    if (key === 'services' || key === 'serviceIsNot') {
      const {
        services,
        excludeServices,
        unmanagedServices,
        unmanagedExcludeServices,
        domainServices,
        domainExcludeServices,
      } = this.state;

      let serviceList = key === 'services' ? services : excludeServices;

      if (format === intl('Common.UnmanagedIPAddresses')) {
        serviceList = key === 'services' ? unmanagedServices : unmanagedExcludeServices;
      }

      if (format === intl('Common.DomainTable')) {
        serviceList = key === 'services' ? domainServices : domainExcludeServices;
      }

      const filterValues = service =>
        service.value.toLowerCase().includes(query.toLowerCase()) &&
        (!items[reverseFacetMap()[key]] ||
          !items[reverseFacetMap()[key]].some(filter => service.value.split(',').includes(filter.value)));

      let filteredServices = serviceList.services.filter(service => filterValues(service));
      let filteredPorts = serviceList.ports.filter(service => filterValues(service));
      let filteredProcesses = serviceList.processes.filter(service => filterValues(service));

      const matches = filteredServices.length + filteredPorts.length + filteredProcesses.length;

      if (matches > 15) {
        filteredServices = filteredServices.slice(0, 5);
        filteredPorts = filteredPorts.slice(0, 5);
        filteredProcesses = filteredProcesses.slice(0, 5);
      }

      dropdownValues[`${key}-${query}`] = {
        matches: [...filteredServices, ...filteredPorts, ...filteredProcesses].map(service => ({
          ...service,
          key,
        })),
        num_matches: matches,
      };
    }

    if (key === 'entities' || key === 'entityIsNot') {
      const {entities, excludeEntities} = this.state;

      const entityList = key === 'entities' ? entities : excludeEntities;

      const filtered = entityList.filter(
        entity =>
          entity.name.toLowerCase().includes(query.toLowerCase()) &&
          (!items[reverseFacetMap()[key]] || !items[reverseFacetMap()[key]].some(item => item.name === entity.name)),
      );

      dropdownValues[`${key}-${query}`] = {
        matches: filtered,
        num_matches: filtered.length,
      };
    }

    if (key === 'appGroups') {
      const {groups} = this.state;

      const filtered = groups.filter(
        group =>
          group.href.toLowerCase().includes(query.toLowerCase()) &&
          (!items[reverseFacetMap()[key]] || !items[reverseFacetMap()[key]].some(item => item.href === group.href)),
      );

      dropdownValues[`${key}-${query}`] = {
        matches: filtered,
        num_matches: filtered.length,
      };
    }

    if (key === 'ipAddress') {
      const {addresses, unmanagedAddresses, domainAddresses} = this.state;
      let count = 0;
      let addressList = addresses;

      if (format === intl('Common.UnmanagedIPAddresses')) {
        addressList = unmanagedAddresses;
      }

      if (format === intl('Common.DomainTable')) {
        addressList = domainAddresses;
      }

      const filtered = addressList.filter(
        address =>
          address.href.toLowerCase().includes(query.toLowerCase()) &&
          (!items[reverseFacetMap()[key]] || !items[reverseFacetMap()[key]].some(item => item.href === address.href)),
      );

      count = filtered.length;

      if (IpUtils.validateCidrBlock(query)) {
        filtered.push({
          key,
          name: query,
          href: query,
        });

        count += 1;
      } else {
        filtered.unshift({
          key,
          name: intl('Explorer.AddressHelp'),
          href: 'footer',
          footer: true,
        });
      }

      dropdownValues[`${key}-${query}`] = {
        matches: filtered,
        num_matches: count,
      };
    }

    this.setState({dropdownValues});
  },

  getCustomClass(value, key) {
    let additionalClass = '';

    if (key === intl('Common.ServiceIsNotMore') || key === intl('Common.EntitiesIsNotMore')) {
      additionalClass = 'ObjectSelector-item--strikethrough';
    }

    return additionalClass;
  },

  getReturnValue(item) {
    if (!item || typeof item === 'string') {
      return item;
    }

    if (item.type === 'appGroups') {
      return RenderUtils.truncateAppGroupName(item.href, 40, [20, 15, 15]);
    }

    return item.name;
  },

  customListItem({text, item, props}) {
    if (item && item.footer) {
      return (
        <li {...props} key={item.href} className={`${props.className} ObjectSelector-DropDownList-Footer`}>
          {text}
        </li>
      );
    }

    if (item && (item.type === 'entities' || item.type === 'entityIsNot')) {
      return (
        <li {...props}>
          <Label text={item.name} type={item.key} icon={LabelUtils.iconNames[item.key]} />
        </li>
      );
    }

    if (item && (item.key === 'services' || item.key === 'ipAddress')) {
      return (
        <li {...props} key={item.href ? item.href : item.name}>
          {item.name}
        </li>
      );
    }

    if (item && item.type === 'appGroups') {
      return (
        <li {...props} key={item.href} className={`${props.className} ObjectSelector-DropDownList-Truncate`}>
          {RenderUtils.truncateAppGroupName(item.href, 40, [40, 12, 12])}
        </li>
      );
    }

    const {groupType, items} = this.state;
    const currentTypeOptions = groupType === BOUNDARIES ? boundaryTypeOptions() : typeOptions();
    const policyOptions = ExplorerUtils.filterPolicyFilterOptions(
      groupType === BOUNDARIES ? blockedPolicyOptions() : allPolicyOptions(),
      items,
    );

    const policyEnd = Object.keys(policyOptions)
      .reverse()
      .find(option => !this.state.items[option]);
    const directionEnd = Object.keys(directionOptions())
      .reverse()
      .find(option => !this.state.items[option]);
    const typeEnd = Object.keys(currentTypeOptions)
      .reverse()
      .find(option => !this.state.items[option]);
    const transmissionEnd = Object.keys(transmissionOptions())
      .reverse()
      .find(option => !this.state.items[option]);
    const end =
      text === intl('Common.ServiceIsNotMore') ||
      text === intl('Common.IPAddressesMore') ||
      text === policyEnd ||
      text === directionEnd ||
      text === typeEnd ||
      text === transmissionEnd;

    const policyStart = Object.keys(policyOptions).find(option => !items[option]);
    const directionStart = Object.keys(directionOptions()).find(option => !items[option]);
    const typeStart = Object.keys(currentTypeOptions).find(option => !items[option]);
    const transmissionStart = Object.keys(transmissionOptions()).find(option => !items[option]);
    const start =
      text === intl('Common.EntitiesMore') ||
      text === intl('Common.ServiceMore') ||
      text === policyStart ||
      text === directionStart ||
      text === typeStart ||
      text === transmissionStart;
    const reported = item === intl('Common.ReportedPolicy');
    const policy = Object.keys(policyOptions).includes(item);

    if (policyEnd === policyStart && policyEnd === text) {
      return null;
    }

    if (start || end || policy) {
      const classes = cx(props.className, {
        'ObjectSelector-DropDownList-Section-Start': start,
        'ObjectSelector-DropDownList-Section-End': end,
        'ObjectSelector-DropDownList-Header': reported,
        'ObjectSelector-DropDownList-Policy': policy && !reported,
      });

      return (
        <li {...props} key={item.name} className={classes}>
          {text}
        </li>
      );
    }

    return <li {...props}>{text}</li>;
  },

  handleEdit() {
    const {group} = this.state;

    if (group) {
      setTimeout(() => this.getGroupTraffic(group, true), 1000);
    }
  },

  handleAggregationChange(aggregationLevel) {
    const {groupHref, groupType} = this.state;

    let href = groupHref;

    if (groupType.toLowerCase() !== 'appgroups') {
      href = `${groupType}${this.getParams().id}`;
    }

    localStorage.setItem('aggregation_level_appgroup', aggregationLevel);

    this.setState({
      tableLinks:
        aggregationLevel === 'labels'
          ? ExplorerFilterStore.getAggregatedTableLinks(href)
          : ExplorerFilterStore.getTableLinks(href),
      aggregationLevel,
    });
  },

  handleCreateRules(selected) {
    const params = this.getParams();
    const {tableLinks, groupType} = this.state;
    const uuid = ExplorerStore.getLoadedUuid();

    const selectedRows = tableLinks.reduce((result, link) => {
      if (
        link.rules &&
        !link.rules.length &&
        link.ruleWritingAvailable &&
        (selected === 'all' || selected.includes(link.index))
      ) {
        result.push(link.linkKey);
      }

      return result;
    }, []);

    localStorage.setItem('selectedLinks', JSON.stringify(selectedRows));

    if (groupType === BOUNDARIES) {
      this.transitionTo('boundariesEnforcementProposedRules', params);
    } else {
      this.transitionTo('appGroupExplorerProposedRules', {...params, uuid});
    }
  },

  handlePolicyChange(policy) {
    this.setState({policy});
  },

  render() {
    const {
      items,
      dropdownValues,
      format,
      vizLinks,
      tableLinks,
      ipLinks,
      domainLinks,
      isDataReady,
      collapse,
      selectedSource,
      selectedTarget,
      clickedTick,
      clickedTickPath,
      group,
      groupHref,
      groupType,
      explorerType,
      loadedQuery,
      consumers,
      providers,
      truncated,
      status,
      asyncStatus,
      timeOptions,
      limitToMaxDays,
      policy,
      aggregationLevel,
      pollingId,
      showBoundaries,
    } = this.state;

    const readonly = SessionStore.isUserReadOnly() || SessionStore.isSuperclusterMember();
    const dataTruncated = consumers?.truncated || providers?.truncated || truncated;
    const appGroupType = groupType.toLowerCase() === 'appgroups';
    const id = this.getParams().previd || this.getParams().id;
    const appGroup = TrafficStore.getAppGroupNode(id);
    const draftView = !policy || !policy.includes('Reported');

    let initialValues = Object.keys(facetMap());
    let filterItems = items;
    let singleValues = appGroupType
      ? options(filterItems, showBoundaries)
      : boundaryOptions(filterItems, showBoundaries);
    let map = facetMap();

    let table = null;
    let vizPanel = null;
    let ipTable = null;
    let domainTable = null;
    let navPath = null;
    let banner = null;
    let boundaryBlocks = null;

    const loading = status.includes(Constants.STATUS_BUSY) || asyncStatus === Constants.STATUS_BUSY;

    if (groupType === BOUNDARIES && tableLinks.length && tableLinks.every(link => link.rules)) {
      boundaryBlocks = tableLinks.reduce((result, link) => {
        if (!link.rules.length) {
          result += 1;
        }

        return result;
      }, 0);
    }

    const loadingLinks = (!tableLinks.length && loading) || pollingId;
    const noAppGroup = groupType !== BOUNDARIES && !loading && !appGroup;
    const loadingRules =
      groupType === BOUNDARIES && !loading && draftView && !boundaryBlocks && tableLinks.some(link => !link.rules);
    const noBlockedConnections =
      groupType === BOUNDARIES && !loading && ((draftView && !boundaryBlocks) || (!draftView && !tableLinks.length));
    const noResults =
      groupType !== BOUNDARIES && !loading && !tableLinks.length && ((appGroupType && appGroup) || !appGroupType);

    if (noAppGroup) {
      banner = (
        <Banner
          type="info"
          header={intl('Applications.ApplicationNoMember')}
          content={
            <Link to="appGroups" className="GroupExplorer-link">
              {intl('Applications.SelectApplications')}
            </Link>
          }
        />
      );
    } else if (loadingLinks) {
      banner = (
        <Banner
          type="progresscenterednooverlay"
          header={intl('PolicyGenerator.CalculationInProgress')}
          message={intl('PolicyGenerator.Spinner.ExploringData')}
        />
      );
    } else if (loadingRules) {
      banner = (
        <Banner
          type="progresscenterednooverlay"
          header={intl('PolicyGenerator.CalculationInProgress')}
          message={intl('PolicyGenerator.Spinner.CalculatingRuleCoverage')}
        />
      );
    } else if (noBlockedConnections) {
      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={intl('Explorer.NoBlockedConnections')} />
        </div>
      );
    } else if (noResults) {
      banner = (
        <div className="Graph-no-data">
          <Banner type="notice" header={intl('Map.Traffic.Filter.NoResult')} />
        </div>
      );
    }

    const formatPicker = (
      <div className="Explorer-format">
        <span className="Explorer-format-label">{intl('Explorer.Format')}</span>
        <Select
          options={appGroupType ? allFormatTypes() : basicFormatTypes()}
          value={format}
          onChange={this.handleFormatChange}
          tid="formattype"
        />
      </div>
    );

    if (format === intl('Explorer.Table')) {
      table = (
        <LinkTable
          disableHover
          links={tableLinks}
          href={groupHref}
          type={appGroupType ? APPGROUP : BOUNDARIES}
          onEdit={appGroupType ? this.handleEdit : null}
          fqdn={appGroupType}
          loadedQuery={loadedQuery}
          withFilter
          banner={banner}
          hideDraftSpinner={loadingRules}
          blockedDraft={!appGroupType}
          blockedConnections={boundaryBlocks}
          onCreateRules={this.handleCreateRules}
          blockedPolicy={!appGroupType && policy}
          onPolicyChange={this.handlePolicyChange}
          onAggregationChange={this.handleAggregationChange}
          aggregationLevel={aggregationLevel}
        />
      );
    } else if (format === intl('Explorer.ParallelCoordinates')) {
      vizPanel =
        vizLinks.length === 0 ? (
          banner
        ) : (
          <VizPanel
            links={vizLinks}
            selectedSource={selectedSource}
            selectedTarget={selectedTarget}
            clickedTick={clickedTick}
            clickedTickPath={clickedTickPath}
            isDataReady={isDataReady}
            collapse={collapse}
            narrow
          />
        );

      navPath = (
        <div className="Explorer-navPathPanel">
          {format === intl('Explorer.ParallelCoordinates') && (
            <div className="Explorer-navigation">
              <NavPathPanel
                selectedSource={selectedSource}
                selectedTarget={selectedTarget}
                clickedTick={clickedTick}
                clickedTickPath={clickedTickPath}
                isDataReady={isDataReady}
              />
            </div>
          )}
        </div>
      );
    } else if (format === intl('Common.UnmanagedIPAddresses')) {
      map = unmanagedFacetMap();
      initialValues = Object.keys(unmanagedFacetMap());
      singleValues = unmanagedOptions();
      filterItems = Object.keys(items).reduce((result, key) => {
        if (allUnmanagedOptions()[key]) {
          result[key] = items[key];
        }

        return result;
      }, {});

      ipTable =
        ipLinks.length === 0 ? (
          banner
        ) : (
          <UnmanagedIps disableHover ipTraffic={ipLinks} onSave={this.handleEdit} type={explorerType} withFilter />
        );
    } else if (format === intl('Common.DomainTable')) {
      map = unmanagedFacetMap();
      initialValues = Object.keys(unmanagedFacetMap());
      singleValues = domainOptions();
      filterItems = Object.keys(items).reduce((result, key) => {
        if (allDomainOptions()[key]) {
          result[key] = items[key];
        }

        return result;
      }, {});

      domainTable =
        domainLinks.length === 0 ? (
          banner
        ) : (
          <DomainTable disableHover domainTraffic={domainLinks} onSave={this.handleEdit} withFilter />
        );
    }

    const timestamp = loadedQuery?.updated_at ? (
      <div className="Explorer-appGroup-table-timestamp">
        <span className="Explorer-format-label">{intl('Common.Timestamp')}:</span>
        {intl.date(loadedQuery.created_at, 'L_HH_mm_ss')}
      </div>
    ) : null;

    const filtered = Object.keys(filterItems).length;
    const filters = (
      <div>
        {dataTruncated ? <Notification type="warning" closeable message={intl('Explorer.LabelGroupWarning')} /> : null}
        {!readonly &&
        boundaryBlocks !== null &&
        format === intl('Explorer.Table') &&
        (!policy || !policy.includes('Reported')) &&
        aggregationLevel === 'labels' ? (
          <Notification
            type="instruction"
            message={[
              filtered
                ? intl(
                    'Explorer.AllowFilteredConnectionsMessage',
                    {count: boundaryBlocks, className: 'Explorer-format-bold'},
                    {html: true},
                  )
                : intl(
                    'Explorer.AllowAllConnectionsMessage',
                    {count: boundaryBlocks, className: 'Explorer-format-bold'},
                    {html: true},
                  ),
              boundaryBlocks ? (
                <Button
                  text={filtered ? intl('Explorer.AllowFilteredConnections') : intl('Explorer.AllowAllConnections')}
                  onClick={_.partial(this.handleCreateRules, 'all')}
                />
              ) : null,
            ]}
          />
        ) : null}
        <div className="ExplorerTimeFilter-Bar">
          <div className="ExplorerFilter-Bar-Row">
            <div className="ExplorerFilter-Bar-TimeTitle" key="time-title">
              {intl('Explorer.Time')}
            </div>
            <div className="ExplorerFilter-TimeBar" key="time">
              <TimeFilter
                onChange={this.handleTimeChange}
                time={this.state.time}
                options={timeOptions}
                limitCustomRangeToMaxDays={limitToMaxDays}
              />
            </div>
            <Button
              text={intl('Common.Run')}
              tid="go"
              disabled={
                this.state.time === intl('Explorer.CustomRange') ||
                !timeOptionExistsInOptions(this.state.time, timeOptions())
              }
              onClick={appGroupType ? _.partial(this.getGroupTraffic, group, true) : this.getBoundaryTraffic}
            />
          </div>
        </div>
        <div className="ExplorerTimeFilter-Picker">
          <ObjectSelector
            customListItem={this.customListItem}
            getCustomClass={this.getCustomClass}
            showFacetItems={[]}
            addItem={this.onSelectOption}
            removeItem={this.onRemoveOption}
            removeMulti={this.onRemoveMultiOption}
            dropdownValues={dropdownValues}
            mapLabel={labelMap()}
            multiItems={initialValues}
            customClassItems={initialValues}
            facetMap={map}
            getFacetValues={this.getFacetValues}
            refetchFacetValues
            initialValues={initialValues}
            items={filterItems}
            hideLabel={Object.keys(unmanagedFacetMap())}
            placeholder={intl('Common.FilterView')}
            returnValue={this.getReturnValue}
            singleValues={singleValues}
            maxResults={1000}
            tid="app-group-traffic-type"
          />
          {timestamp}
          {formatPicker}
        </div>
      </div>
    );

    const appGroupClass = Object.keys(dropdownValues).some(key => key.includes('appGroups-')) ? ' GroupExplorer' : '';
    const ipAddressClass = Object.keys(dropdownValues).some(key => key.includes('ipAddress-'))
      ? ' GroupExplorerIpAddress'
      : '';
    const entityClass = Object.keys(dropdownValues).some(key => key.includes('entit')) ? ' GroupExplorerEntity' : '';
    const tabs = null;

    return (
      <div
        className={`Explorer ExplorerDisplay ExplorerAppGroup${appGroupClass}${entityClass}${ipAddressClass}`}
        data-tid="page-group-explorer"
      >
        {tabs}
        {filters}
        {navPath}
        {vizPanel}
        {ipTable}
        {domainTable}
        {table}
      </div>
    );
  },
});
