/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import {Button, Icon} from '..';

export default React.createClass({
  propTypes: {
    hideColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    columnKeyLabels: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {showTableOptions: false};
  },

  componentDidMount() {
    document.addEventListener('click', this.handleCompClick);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCompClick);
  },

  getTableOptions() {
    const {hideColumns, columnKeyLabels} = this.props;

    return (
      <ul className="RuleSearchGridColumnsSelect-TableOptions-List">
        {Object.keys(columnKeyLabels).map(key => (
          <li
            className="RuleSearchGridColumnsSelect-TableOptions-ListItem"
            onClick={() => this.toggleColumn(key)}
            key={key}
          >
            <Icon name="check" size="medium" styleClass={hideColumns.includes(key) ? 'hide' : ''} />
            <div className="RuleSearchGridColumnsSelect-TableOptions-ListItem-Label">{columnKeyLabels[key]}</div>
          </li>
        ))}
      </ul>
    );
  },

  handleClick() {
    this.setState({showTableOptions: !this.state.showTableOptions});
  },

  handleCompClick(evt) {
    if (evt.target === this.button || findDOMNode(this.button).contains(evt.target)) {
      return;
    }

    if (this.state.showTableOptions === true && !this.tableOptions.contains(evt.target)) {
      this.handleClick();
    }
  },

  toggleColumn(key) {
    const hideColumns = [...this.props.hideColumns];
    const keyIndex = hideColumns.indexOf(key);

    if (keyIndex === -1) {
      hideColumns.push(key);
    } else {
      hideColumns.splice(keyIndex, 1);
    }

    this.props.onChange(hideColumns);
  },

  render() {
    const {showTableOptions} = this.state;

    return (
      <div className="RuleSearchGridColumnsSelect">
        <Button
          text={intl('RuleSearch.Columns')}
          onClick={this.handleClick}
          iconAfter={showTableOptions ? 'caret-up' : 'caret-down'}
          ref={node => (this.button = node)}
        />
        {showTableOptions ? (
          <div className="RuleSearchGridColumnsSelect-TableOptions" ref={node => (this.tableOptions = node)}>
            {this.getTableOptions()}
          </div>
        ) : null}
      </div>
    );
  },
});
