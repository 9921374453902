/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import Dialog from '../components/Dialog.jsx';
import ToggleDropdown from '../components/ToggleDropdown.jsx';
import Checkbox from '../components/Checkbox.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  getInitialState() {
    const modalData = this.props.data;

    return {
      title: modalData.title,
      messageTitle: modalData.messageTitle,
      messageSubtitle: modalData.messageSubtitle,
      serviceValues: modalData.serviceValues,
      checkboxValues: modalData.checkboxValues,
      portMap: modalData.portMap,
      confirm: modalData.confirm,
      actions: modalData.actions,
      selectAllCheckbox: _.every(modalData.checkboxValues, checkbox => checkbox.checked),
    };
  },

  areDefaultServices(portMap) {
    const coreServicesPortProto = [
      '53 TCP',
      '53 UDP',
      '5353 TCP',
      '5353 UDP',
      '67 UDP',
      '68 UDP',
      '500 UDP',
      '4500 UDP',
    ];
    const portKeys = Object.keys(portMap);

    return portKeys.every(port => (coreServicesPortProto.includes(port) ? portMap[port] : !portMap[port]));
  },

  getPortCheckboxes(selectAllKey, ports) {
    const checkboxes = [];

    _.each(ports, port => {
      const checkboxValue = this.state.checkboxValues[port];

      checkboxes.push(
        <div>
          <Checkbox
            className="CheckboxModalCheckboxLabel"
            label={port}
            checked={checkboxValue.checked}
            onChange={_.partial(this.handleChange, checkboxValue, port)}
          />
        </div>,
      );
    });

    return checkboxes;
  },

  handleChange(checkboxValue) {
    const updatedCheckboxes = {...this.state.checkboxValues};
    const updatedPortMap = {...this.state.portMap};
    const newValue = !checkboxValue.checked;
    let {selectAllCheckbox} = this.state;

    // Walk through all the ports in the checkboxValue, and update the port map to the new value
    checkboxValue.ports.forEach(port => {
      if (port in updatedPortMap) {
        updatedPortMap[port] = newValue;
      }
    });
    // Go through all checkboxes and check if all ports are selected, and update check boxes accordingly.
    Object.keys(updatedCheckboxes).forEach(key => {
      updatedCheckboxes[key].checked = updatedCheckboxes[key].ports.every(port => updatedPortMap[port]);
    });
    // Determine if everything is selected
    selectAllCheckbox = newValue && Object.values(updatedCheckboxes).every(checkbox => checkbox.checked);
    this.setState({
      checkboxValues: updatedCheckboxes,
      portMap: updatedPortMap,
      selectAllCheckbox,
    });
  },

  handleClose() {
    actionCreators.setTrafficFilterHidden(false);
    actionCreators.closeDialog();
  },

  handleSelectAllChange() {
    const updatedCheckboxes = _.cloneDeep(this.state.checkboxValues);
    const newPortMap = _.cloneDeep(this.state.portMap);
    const newValue = !this.state.selectAllCheckbox;

    _.each(updatedCheckboxes, checkbox => {
      checkbox.checked = newValue;
      _.each(checkbox.ports, port => {
        if (port in this.state.portMap) {
          newPortMap[port] = newValue;
        }
      });
    });

    this.setState({
      checkboxValues: updatedCheckboxes,
      portMap: newPortMap,
      selectAllCheckbox: newValue,
    });
  },

  handleResetServicesToDefault() {
    const {portMap, checkboxValues} = this.state;
    const defaultPortMap = {};
    const defaultcheckboxValues = {};
    const coreServices = ['DNS', 'mDNS', 'DHCP', 'IPSec'];
    const coreServicesPortProto = [
      '53 TCP',
      '53 UDP',
      '5353 TCP',
      '5353 UDP',
      '67 UDP',
      '68 UDP',
      '500 UDP',
      '4500 UDP',
    ];

    // Default PortMap Values
    const portMapKeys = Object.keys(portMap);

    portMapKeys.forEach(port => {
      if (coreServicesPortProto.includes(port)) {
        defaultPortMap[port] = true;
      } else {
        defaultPortMap[port] = false;
      }
    });

    // Default Checkbox Values
    const checkboxKeys = Object.keys(checkboxValues);

    checkboxKeys.forEach(service => {
      defaultcheckboxValues[service] = {};
      defaultcheckboxValues[service].ports = checkboxValues[service].ports;

      if (coreServices.includes(service)) {
        defaultcheckboxValues[service].checked = true;
      } else {
        defaultcheckboxValues[service].checked = false;
      }
    });

    this.setState({portMap: defaultPortMap, checkboxValues: defaultcheckboxValues, selectAllCheckbox: false});
  },

  render() {
    const {portMap} = this.state;
    const coreServices = ['DNS', 'mDNS', 'DHCP', 'IPSec'];
    const coreServicesPortProto = [
      '53 TCP',
      '53 UDP',
      '5353 TCP',
      '5353 UDP',
      '67 UDP',
      '68 UDP',
      '500 UDP',
      '4500 UDP',
    ];

    const coreServicesLength = coreServices.length;
    const services = this.state.serviceValues.filter(service => !coreServicesPortProto.includes(service.href));

    const checkboxes = services
      .sort((a, b) => {
        // Core Services Should be on Top
        const isACoreService = coreServices.indexOf(a);
        const isBCoreService = coreServices.indexOf(b);

        if (isACoreService !== -1 && isBCoreService !== -1) {
          return isACoreService < isBCoreService;
        }

        if (isACoreService) {
          return 0;
        }

        if (isBCoreService) {
          return 1;
        }

        // services with portNames always on top of the services without portNames.
        // services with portNames is sorted by name.
        // services without portNames is sorted by port number.
        if ((a.portNames && b.portNames) || (!a.portNames && !b.portNames)) {
          const aValue = a.portNames ? a.name : a.name.split(' ')[0];
          const bValue = b.portNames ? b.name : b.name.split(' ')[0];

          return aValue.localeCompare(bValue, intl.locale, {sensitivity: 'base', numeric: true});
        }

        return a.portNames ? -1 : 1;
      })
      .map((serviceValue, i) => {
        const key = serviceValue.href;

        if (serviceValue.range) {
          // use toggle dropdown if it has a range
          const data = {title: serviceValue.name, content: this.getPortCheckboxes(key, serviceValue.ports)};
          const allCheckbox = this.state.checkboxValues[key];
          const areAnyPortsSelected = serviceValue.ports.some(port => portMap[port]);

          return (
            <tr>
              <td className="CheckboxModalToggleDropdown">
                <Checkbox checked={allCheckbox.checked} onChange={_.partial(this.handleChange, allCheckbox, key)} />
              </td>
              <td className="CheckboxModalToggleDropdown">
                <ToggleDropdown caretHighlight={areAnyPortsSelected} data={data} />
              </td>
              <td className="CheckboxModalPortNames" title={serviceValue.portNames}>
                {serviceValue.portNames}
              </td>
            </tr>
          );
        }

        const checkboxValue = this.state.checkboxValues[key];
        const isLastCS = i === coreServicesLength - 1;
        const isTopPadding = i === 0 || i === coreServicesLength;

        return (
          <tr>
            <td
              className={
                isTopPadding
                  ? 'CheckboxModalCheckbox CheckboxModalPadding'
                  : isLastCS
                    ? 'CheckboxModalCheckbox CheckboxModalDivider'
                    : 'CheckboxModalCheckbox'
              }
            >
              <Checkbox checked={checkboxValue.checked} onChange={_.partial(this.handleChange, checkboxValue, key)} />
            </td>
            <td
              className={
                isTopPadding
                  ? 'CheckboxModalServiceName CheckboxModalPadding'
                  : isLastCS
                    ? 'CheckboxModalServiceName CheckboxModalDivider'
                    : 'CheckboxModalServiceName'
              }
            >
              {serviceValue.name}
            </td>
            <td
              className={
                isTopPadding
                  ? 'CheckboxModalPortNames CheckboxModalPadding'
                  : isLastCS
                    ? 'CheckboxModalPortNames CheckboxModalDivider'
                    : 'CheckboxModalPortNames'
              }
              title={serviceValue.portNames}
            >
              {serviceValue.portNames}
            </td>
          </tr>
        );
      });

    // temporary solution
    // todo: Rewrite dialog component
    const actions = [
      {
        text: intl('Common.ResetToDefault'),
        tid: 'reset',
        type: 'secondary',
        disabled: this.areDefaultServices(this.state.portMap),
        onClick: () => {
          this.handleResetServicesToDefault();
        },
      },
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          this.handleClose();
        },
      },
      {
        text: intl('Common.OK'),
        type: 'primary',
        tid: 'ok',
        autoFocus: true,
        onClick: () => {
          this.state.confirm(this.state.portMap);
          this.handleClose();
        },
      },
    ];

    const messageTitle =
      _.isEmpty(checkboxes) && !_.isEmpty(this.state.messageTitle) ? (
        <div className="CheckboxModalMessageTitle">{this.state.messageTitle}</div>
      ) : null;
    const messageSubtitle =
      _.isEmpty(checkboxes) && !_.isEmpty(this.state.messageSubtitle) ? (
        <div className="CheckboxModalMessageSubtitle">{this.state.messageSubtitle}</div>
      ) : null;
    const selectAllCheckbox = _.isEmpty(checkboxes) ? null : (
      <tr>
        <td className="CheckboxModalCheckbox CheckboxModalDivider">
          <Checkbox checked={this.state.selectAllCheckbox} onChange={this.handleSelectAllChange} />
        </td>
        <td className="CheckboxModalServiceName CheckboxModalDivider">Select All</td>
        <td className="CheckboxModalPortNames CheckboxModalDivider" />
      </tr>
    );

    return (
      <Dialog title={this.state.title} className="CheckboxModal" scroll={true} actions={actions} hasBorder={true}>
        <div className="CheckboxModalContent">
          {messageTitle}
          {messageSubtitle}
          <table className="CheckboxModalTable">
            <tbody>
              {selectAllCheckbox}
              {checkboxes}
            </tbody>
          </table>
        </div>
      </Dialog>
    );
  },
});
