/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from './intl';
import JSONBig from 'json-bigint-keep-object-prototype-methods';
import type {IterableElement} from 'type-fest';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

/** Nominal Types */
export type ContainerCluster = string & {_brand: 'container_clusters'};
export type ServiceAccount = string & {_brand: 'service_account'};
export type UserHref = string & {_brand: 'users'};
export type ActiveSecPolicyHref = string & {_brand: 'active_sec_policy'};

/** Use _brand for Nominal type checking */
/** example: /orgs/2/workloads/28201543-feb7-41e6-898e-36f58a147c3a
 *           /users/173
 * */
export type Href = string & {_brand: 'href'};

/** example: 28201543-feb7-41e6-898e-36f58a147c3a */
export type HrefId = string & {_brand: 'href_id'};

/** example: 173 **/
export type HrefIdNumber = {_brand: 'href_id_number'} & (bigint | number);

export const isProperHref = (href?: string): href is Href =>
  href !== undefined && href.length > 1 && href.startsWith('/');

const orgIdRegex = /\/orgs\/(\d+)/;
export const getOrgIdFromHref = (href: string): string | undefined => orgIdRegex.exec(href)?.[1];

/**
 * Takes id from last segment of href
 * If string is empty or last segment is empty, return undefined so default parameter usage on caller side is possible
 * https://jsperf.com/last-string-segment
 *
 * e.g. Usage
 *
 *  https://stackoverflow.com/questions/40081332/what-does-the-is-keyword-do-in-typescript
 *
 * function fetchId = (id: HrefId): void => {
 *   console.log('id', id);
 * };
 *
 * const id = getId('/users/173');
 * if (id) {  // A type guard check to ensure id exist then make a call to fetchId(id)
 *   fetchId(id);
 * }
 */
export function getId(href: Href): HrefId;
export function getId(href?: string): HrefId | undefined;
export function getId(href?: Href | string): HrefId | undefined {
  if (isProperHref(href)) {
    return href.substring(href.lastIndexOf('/') + 1) as HrefId;
  }

  return href as HrefId;
}

//Regex to test if value is number and not string
const regexId = /^\d+$/;

export const getIdNumber = (href?: Href | string, parseBigInt = true): HrefIdNumber | undefined => {
  const id = getId(href);

  // return number of bigInt. (detect string if it is number of BigInt)
  // if Number return number, if BigInt return bigInt
  if (id && regexId.test(id)) {
    return (parseBigInt ? JSONBigIntNative.parse(id) : id) as HrefIdNumber;
  }
};

export type PVersion = IterableElement<typeof pversions>;

export const pversions = ['draft', 'active'] as const;
const pversionsRegex = new RegExp(pversions.join('|'));

export function getPVersion(href?: Href | string): PVersion | undefined {
  if (isProperHref(href)) {
    return (href.match(pversionsRegex)?.[0] as PVersion) ?? undefined;
  }
}

/* Used for sorting hrefs, which may contain number id,
   but we sort as strings to avoid converting ids bigger that MAX_SAFE_INTEGER to numbers*/
// Enable language sensitive string comparison. Using Intl.Collator compare method to ensure
// the strings are sorted according to the sort order of the specified locale.
export const collator = new Intl.Collator(intl.locale, {
  usage: 'sort',
  sensitivity: 'base',
  numeric: true,
  ignorePunctuation: false,
});

/** Move this method to Security Policy */
export const compareActiveHref = (activeHref: string, versionHref: string): activeHref is ActiveSecPolicyHref =>
  versionHref.replace(/\/sec_policy\/\d+/, '/sec_policy/active') ===
  activeHref.replace(/\/sec_policy\/\d+/, '/sec_policy/active');

/**
 * Whether the href is a container cluster
 * @param href A href string
 * @returns A boolean value
 */
export const isContainerClusterHref = (href?: string): href is ContainerCluster =>
  typeof href === 'string' && href.includes('/container_clusters/');

/**
 * Whether the href is a service account
 * @param href
 * @returns {boolean}
 */
export const isServiceAccountHref = (href?: string): href is ServiceAccount =>
  typeof href === 'string' && href.includes('/service_accounts/');

/**
 * Whether the href is a user
 * @param href
 * @returns {boolean}
 */
export const isUserHref = (href?: string): href is UserHref => typeof href === 'string' && href.includes('/users/');

export const getChangePassword = (): string => '/users/password/change';

/**
 * Returns stringified routeParams
 * Manually remove null params, needed for omitting existing params on merge by setting them to null
 */
export function processParams(params?: Record<string, unknown>): Record<string, unknown> {
  const routeParams: Record<string, unknown> = {};

  if (!params) {
    return routeParams;
  }

  for (const paramName in params) {
    if (params.hasOwnProperty(paramName)) {
      const paramValue = params[paramName];
      const paramType = typeof paramValue;

      if (paramValue !== null) {
        routeParams[paramName] = paramValue;

        if (paramType === 'number' || paramType === 'bigint') {
          routeParams[paramName] = String(paramValue);
        } else if (paramType === 'object' && !Array.isArray(paramValue)) {
          routeParams[paramName] = JSON.stringify(paramValue);
        }
      }
    }
  }

  return routeParams;
}
