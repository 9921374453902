/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import ErrorsAPI from './errorsAPI';

export default {
  /* Common section should always go first */
  Common: {
    About: 'About',
    AboutThisProduct: 'About This Product',
    Accept: 'Accept',
    AccessConfiguration: 'Access Configuration',
    AccessRestrictions: 'Access Restrictions',
    AccessStatus: 'Access Status',
    AccessType: 'Access Type',
    Account: 'Account',
    AccountId: 'Account ID',
    AccountSingularOrPlural: '{count, plural, =1 {Account} other {Accounts}}',
    Action: 'Action',
    Actions: 'Actions',
    Active: 'Active',
    ActiveDirectory: 'Active Directory',
    ActiveDirectoryCertificate: 'Active Directory Certificate Services',
    ActiveDirectoryLightweightDirectory: 'Active Directory Lightweight Directory Services',
    ActiveDirectoryRightsManagement: 'Active Directory Rights Management Services',
    Add: 'Add',
    Additional: 'Additional',
    AddressLine1: 'Address Line 1',
    AddressLine2: 'Address Line 2',
    AddToPolicy: 'Add to Policy',
    AddValidAddress: 'Please enter a valid Address.',
    AddValidDescription: 'Please enter a valid Description',
    AddValidIPAddressOrFQDN: 'Please enter a valid IP Address or FQDN.',
    AddValidName: 'Please enter a valid Name.',
    Alert: 'Alert',
    All: 'All',
    ALL: 'ALL',
    AllAllowed: 'All Allowed',
    AllApplicationLabels: 'All Application Labels',
    AllApplications: 'All Applications',
    AllBlocked: 'All Blocked',
    AllCloud: 'All Cloud',
    AllEnvironmentLabels: 'All Environment Labels',
    AllEnvironments: 'All Environments',
    AllLocationLabels: 'All Location Labels',
    AllLocations: 'All Locations',
    Allow: 'Allow',
    Allowed: 'Allowed',
    AllowedAcrossBoundary: 'Allowed across Boundary',
    AllowItemsRemove: 'These items are available to delete',
    AllPorts: 'All Ports',
    AllPortsProtocols: 'All Ports/Protocols',
    AllRegions: 'All Regions',
    AllResources: 'All Resources',
    AllRoleLabels: 'All Role Labels',
    AllRoles: 'All Roles',
    AllServices: 'All Services',
    And: 'and',
    Anything: 'Anything',
    API: 'API',
    App: 'App',
    Appearance: 'Appearance',
    ApplicableScopedPolicies: 'Applicable Scoped Policies',
    Application: 'Application',
    ApplicationDescription: 'The name of the application that the Workload supports. E.g., eCommerce or ERP.',
    ApplicationDetails: 'Application Details',
    ApplicationMap: 'Applications Map',
    Applications: 'Applications',
    ApplicationsList: 'Applications List',
    ApplicationsMore: 'Applications...',
    ApplicationsSettings: 'Applications Configuration',
    Apply: 'Apply',
    ApplyChanges: 'Apply Changes',
    Approve: 'Approve',
    Approved: 'Approved',
    Apps: 'Apps',
    AreYouSure: 'Are you sure?',
    AreYouSureYouWantToContinue: 'Are you sure you want to continue?',
    Arrow: 'Arrow',
    ArrowDown: 'Arrow down',
    ArrowLeft: 'Arrow left',
    ArrowLeftLong: 'Arrow left long',
    ArrowRight: 'Arrow right',
    ArrowRightLong: 'Arrow right long',
    ArrowUp: 'Arrow up',
    AsCSV: 'as CSV',
    AsJSON: 'as JSON',
    Assign: 'Assign',
    AssignRole: 'Assign Role',
    AssignRoles: 'Assign Roles',
    At: 'At',
    AtEndpoint: 'at {endpoint}',
    Attached: 'Attached',
    AttachedResources: 'Attached Resources',
    Attributes: 'Attributes',
    AttributeType: 'Attribute Type',
    Audit: 'Audit',
    AUSUsers: 'Adaptive User Segmentation (AUS) users',
    Authentication: 'Authentication',
    AuthenticationSettings: 'Authentication Settings',
    Auto: 'Auto',
    Automation: 'Automation',
    AWS: 'AWS',
    AwsLogo: 'AWS logo',
    AwsResource: 'AWS Resource',
    AwsResources: 'AWS Resources',
    Azure: 'Azure',
    AzureResource: 'Azure Resource',
    Back: 'Back',
    BackToList: 'Back to list',
    Beta: 'Beta',
    BlockAction: 'Reject Connections',
    Blocked: 'Blocked',
    BlockedByDenyRules: 'Blocked by Deny',
    BlockedByOverrideDenyRules: 'Denied by Override Deny',
    Boundary: 'Boundary',
    BrowserGrayWarning: 'Your browser is not formally supported',
    Build: 'Build',
    Busy: 'Busy',
    By: 'by',
    ByteCount: 'Byte Count',
    Bytes: 'Bytes',
    BytesIn: 'Bytes In',
    BytesOut: 'Bytes Out',
    CABundle: 'Trusted CA Bundle',
    Calculate: 'Calculate',
    Cancel: 'Cancel',
    Canceled: 'Canceled',
    CancelRequested: 'Cancel Requested',
    CannotRemoveItemInUse: 'This item cannot be removed because it is in use.',
    CannotRemoveItemsInUse: 'The selected items cannot be removed because they are in use.',
    Capslock: 'Capslock',
    Categories: 'Categories',
    Category: 'Category',
    Certificate: 'Certificate',
    Chain: 'Chain',
    Change: 'Change',
    ChangesHighlighted: 'Changes are highlighted in Green (Addition) and Red (Deletion).',
    ChooseApiExplorer: 'Choose an item from the menu on the left side',
    City: 'City',
    ClassicAppGroupMap: 'Classic App Group Map',
    ClassicAppGroupMapDeprecated:
      'The classic app group map has been deprecated and is slated for discontinuation in a forthcoming version of the Illumio PCE. We recommend using the new app group explorer.',
    ClassicIllumination: 'Classic Illumination',
    ClassicIlluminationDeprecated:
      'The Illumination Classic feature has been deprecated and is slated for discontinuation in a forthcoming version of the Illumio PCE. For improved network visibility, we strongly recommend using the newly-introduced Map page.',
    ClassicUI: 'Classic UI',
    ClickHere: 'Click here for more information',
    Close: 'Close',
    CloseAllNotifications: 'Close all notifications',
    Cloud: 'Cloud',
    CloudIntegration: 'Cloud Integration',
    Code: 'Code',
    Coexistence: 'Coexistence',
    Collapse: 'Collapse',
    CollapseAll: 'Collapse All',
    CollapseGroups: 'Collapse all Groups',
    ColumnsHidden: '({count, plural, =1 {# column is} other {# columns are}} hidden)',
    Command: 'Command',
    Comment: 'Comment',
    CompanyURL: 'Company URL',
    CompatibilityReport: 'Compatibility Report',
    Completed: 'Completed',
    Configuration: 'Configuration',
    ConfigurationWizard: 'Configuration Wizard',
    Configure: 'Configure',
    Confirm: 'Confirm',
    ConfirmChange: 'Confirm Change',
    ConfirmPassword: 'Confirm Password',
    ConfirmRemoval: 'Confirm Removal',
    Connect: 'Connect',
    Connected: 'Connected',
    ConnectionFailed: 'Failed to establish connection to the PCE Server',
    Connections: 'Connections',
    // ConnectionsFlows: 'Connections/Flows',
    ConnectionState: 'Connection State',
    Connectivity: 'Connectivity',
    Console: 'Console',
    ConsumersGlobal: 'Global Sources',
    ConsumerWorkload: 'Source is a Workload',
    ContactSupport: 'Contact Support',
    Container: 'Container',
    ContainerHost: 'Container Host',
    Containers: 'Containers',
    ContainerWorkload: 'Container Workload',
    ContainerWorkloads: 'Container Workloads',
    Continue: 'Continue',
    ContinueWaiting: 'Continue waiting',
    Contract: 'Contract',
    Contrast: 'Contrast',
    Copied: 'Copied',
    Copy: 'Copy',
    CopyIP: 'Copy IP',
    CopyToClipboard: 'Copy to clipboard',
    Core: 'Core',
    Corporate: 'Corporate',
    Corrupt: 'Corrupt',
    CountMore: '+{count, number} more',
    CountMoreLabels: '+{count, plural, =1 {# more label} other {# more labels}}',
    CountMoreLabelsAndScopes:
      '+{bothLeft, select, true { {labelsLeft, plural, =0 {} =1 {# label} other {# labels}} and {scopesLeft, plural, =0 {} =1 {# more scope} other {# more scopes}}} false {{labelsLeft, plural, =0 {} =1 {# label} other {# labels}} {scopesLeft, plural, =0 {} =1 {# more scope} other {# more scopes}}}}',
    Country: 'Country',
    Create: 'Create',
    Created: 'Created',
    CreatedBy: 'Created By',
    CreatedOn: 'Created On',
    CreatedWith: 'Created With',
    Critical: 'Critical',
    Csp: 'CSP',
    CspId: 'CSP Id',
    CSV: 'CSV',
    Custom: 'Custom',
    CustomColumns: 'Customize columns',
    CustomServices: 'Custom Services',
    Dark: 'Dark',
    Dashboard: 'Dashboard',
    DataCenter: 'Data Center',
    DateAtTime: '{when, date, L} at {when, date, HH_mm_ss}',
    DateAtTimeBy: '{when, date, L} at {when, date, HH_mm_ss} by {name}',
    Day: 'Day',
    Days: 'Days',
    DaysNumber: '{count, plural, =1 {# Day} other {# Days}}',
    Default: 'Default',
    Delete: 'Delete',
    Deleted: 'Deleted',
    DeletedWorkload: 'Deleted Workload',
    DeletedWorkloadCount: '{count, plural, =1 {# Deleted Workload IP} other {# Deleted Workload IPs}}',
    DeletedWorkloadIps: '{count} Deleted Workload IPs',
    DeletedWorkloads: 'Deleted {count, plural, =1 {Workload} other {Workload}}',
    Denied: 'Denied',
    DeniedTraffic: 'Denied Traffic',
    Deny: 'Deny',
    Description: 'Description',
    DescriptionIsTooLong: 'Description must not be more than {number} characters',
    Destination: 'Destination',
    DestinationCategory: 'Destination Category',
    DestinationCSPId: 'Destination CSP ID',
    DestinationDetailType: 'Destination Detail Type',
    DestinationFqdn: 'Destination FQDN',
    DestinationIP: 'Destination IP',
    DestinationObjectType: 'Destination Object Type',
    DestinationPort: 'Destination Port',
    DestinationPortProtocol: 'Destination Port/Protocol',
    DestinationProcess: 'Destination Process',
    DestinationRegion: 'Destination Region',
    Destinations: 'Destinations',
    DestinationsAndSources: 'Destinations And Sources',
    DestinationsOrSources: 'Destinations Or Sources',
    DestinationSubCategory: 'Destination Sub Category',
    DestinationTenantId: 'Destination Tenant ID',
    DestinationType: 'Destination Type',
    Details: 'Details',
    DeviceType: 'Device Type',
    Disable: 'Disable',
    Disabled: 'Disabled',
    DisabledLow: 'disabled',
    Discard: 'Discard',
    DiscardUnsavedChanges: 'Discard Unsaved Changes',
    DiscardUnsavedChangesMessage: 'Are you sure you want to discard unsaved changes?',
    DiscardUnsavedChangesToObjectMessage:
      'Are you sure you want to discard unsaved changes to the {object, select, service {Service} iprange {IP Range} label {Label} label_group {Label Group} rule {Rule}}?',
    Dismiss: 'Dismiss',
    DisplayingPartialResults: 'Displaying Partial Results',
    DisplayingPartialResultsMessage:
      'The number of matching connections found in the database exceeds the configured maximum. The displayed connection count is partial and could be misleading.',
    DistinguishedName: 'Distinguished Name',
    Domain: 'Domain',
    Domains: 'Domains',
    DomainTable: 'Unmanaged FQDNs',
    Done: 'Done',
    DoNotAllow: 'Do Not Allow',
    DoNotShowMessage: "Don't show this message again.",
    DoNotShowThisAgain: "Don't show this again",
    DoubleClickToExpand: 'Double-click to expand',
    Down: 'Down',
    Download: 'Download',
    Draft: 'Draft',
    DraftAll: 'All Draft',
    DraftEnforcementBoundary: 'Draft Deny Rule',
    DraftPolicy: 'Draft Policy Decision',
    Duplicate: 'Duplicate',
    Edge: 'Edge',
    Edit: 'Edit',
    Edited: 'Edited',
    Email: 'Email',
    EmailProvideValid: 'Please provide a valid email',
    EmptyProvisioningFailures: 'No Recent Provisioning Errors.',
    EmptyRecentFilters: 'No Recent Filter.',
    EmptySavedFilters: 'No Saved Filter. To save a filter set, click the Save icon',
    Enable: 'Enable',
    Enabled: 'Enabled',
    EnabledClassicUIPages: 'Enabled Classic UI pages',
    EnabledLow: 'enabled',
    EndOfData: 'End of Data Set',
    Endpoint: 'Endpoint',
    Enforce: 'Enforce',
    Enforced: 'Enforced',
    EnforcedHighDetail: 'Enforced: High Detail',
    EnforcedLowDetail: 'Enforced: Low Detail',
    EnforcedNoDetail: 'Enforced: No Detail',
    Enforcement: 'Enforcement',
    EnforcementStop: 'Stop Enforcement',
    EntitiesIsNot: 'Workload is not',
    EntitiesIsNotMore: 'Workload is not...',
    EntitiesMore: 'Workload...',
    EntityState: 'Entity State',
    EntriesCount: '{current, number} of {all, number} Entries',
    Environment: 'Environment',
    EnvironmentDescription: 'Stage of application development. E.g., QA, Staging, Production.',
    Environments: 'Environments',
    Error: 'Error',
    ErrorCount: '{count, plural, =1 {# Error} other {# Errors}}',
    ErrorID: 'Error ID',
    Errors: 'Errors',
    Event: 'Event',
    Events: 'Events',
    EventSettings: 'Event Settings',
    Examples: 'Examples',
    Excellent: 'Excellent',
    Exclude: 'Exclude',
    Exclusive: 'Exclusive',
    ExistingLabels: 'Existing Labels',
    Expand: 'Expand',
    Expired: 'Expired',
    ExpiresAt: 'Expires At',
    ExpiresIn: 'Expires In',
    Explore: 'Explore',
    Explorer: 'Explorer',
    ExplorerDeprecated:
      'The Explorer feature is now deprecated and is scheduled for removal in an upcoming version of Illumio PCE. We recommend transitioning to the Traffic page, which offers enhanced functionality for network flow inspection.',
    Export: 'Export',
    ExportAll: 'Export All',
    ExportFiltered: 'Export Filtered',
    ExportFormat: 'Export Format',
    ExportJSON: 'Export to JSON',
    ExportToCsv: 'Export to CSV',
    ExposureScore: 'Exposure Score',
    External: 'External',
    ExternalConnections: 'External Connections',
    ExternalNonCorporate: 'External (Non-Corporate)',
    ExternalReference: 'External Data Reference',
    ExternalSet: 'External Data Set',
    Failed: 'Failed',
    Failure: 'Failure',
    False: 'False',
    FileType: 'File Type',
    Filter: 'Filter',
    FilterBy: 'Filter by',
    FilterByCSP: 'Filter by CSP',
    FilterByField: 'Filter by {fieldName}',
    FilterByTime: 'Filter by Time',
    Filters: 'Filters',
    FiltersApplied: '{count, plural, =1 {# Filter} other {# Filters}} Applied',
    FilterView: 'Select properties to filter view',
    Finish: 'Finish',
    First: 'first',
    FlipHorizontal: 'Flip Horizontal',
    FlowCollectionFilters: 'Flow Collection',
    // FlowLatency: 'Flow Latency',
    Flows: 'Flows',
    Folder: 'Folder',
    Formats: 'Formats',
    FormError: 'Correct the errors in red before saving',
    Fqdns: 'FQDNs',
    FullmapSelectModeAlert:
      'Dragging the mouse is used only to select traffic links in full map. To reposition a group, use the Hand Tool',
    General: 'General',
    Generate: 'Generate',
    Generated: 'Generated',
    GeneratedAt: 'Generated At',
    GeneratedAtTime: 'Generated at {time}',
    GeneratedBy: 'Generated By',
    GeneratedByAgent: 'Generated By Agent',
    GeneratedByUser: 'Generated By User',
    GenerateKey: 'Generate Key',
    GenerateSupportReport: 'Generate Support Bundle',
    Generating: 'Generating...',
    GetStarted: 'Get Started',
    Global: 'Global',
    GlobalMap: 'Global Map',
    GlobalPolicy: 'Global Policy',
    GoBack: 'Go Back',
    Good: 'Good',
    Grab: 'Grab',
    Granted: 'Granted',
    Group: 'Group',
    GroupDashboard: 'Group Dashboard',
    GroupHelpTextSubtitle:
      'Displays a list of groups, admin groups (identifiable with a blue badge), provisioning status, and the number of endpoints in a group.',
    Groups: 'Groups',
    GroupsByCount: '{count, plural, =1 {Group} other {Groups}}',
    GroupsCount: '{count, plural, =1 {One Group} other {# Groups}}',
    GroupWizard: 'Group Wizard',
    GroupWizardHelpTextSubtitle:
      'Helps create groups, designate admin groups, indicate which admin group can communicate with a target group, and provides an option to encrypt traffic.',
    Healthy: 'Healthy',
    Help: 'Help',
    Hidden: 'Hidden',
    Hide: 'Hide',
    HideAllMembers: 'Hide All Members',
    High: 'High',
    Home: 'Home',
    HostAttributes: 'Host Attributes',
    Hostname: 'Hostname',
    Hours: 'Hours',
    HoursNumber: '{count, plural, =1 {# Hour} other {# Hours}}',
    Href: 'Href',
    ID: 'ID',
    Idle: 'Idle',
    IdleControl: 'VEN does not take control of the host firewall',
    Illuminate: 'Illuminate',
    Illuminated: 'Illuminated',
    Illumination: 'Illumination',
    IlluminationMap: 'Illumination Map',
    IlluminationPlus: 'Illumination Plus',
    IllumioRegion: 'Illumio Region',
    Immediately: 'Immediately',
    Import: 'Import',
    Inbound: 'Inbound',
    Include: 'Include',
    Increased: 'Increased',
    IncreaseTrafficUpdateRate: 'Increase Traffic Update Rate',
    IncreaseTrafficUpdateRateMessage:
      'Increase traffic flow frequency to {minutes, plural, =1 {one minute} other {# minutes}}. After {minutes, plural, =1 {one minute} other {# minutes}}, traffic flow information is sent to the PCE at regular intervals.',
    IncreaseTrafficUpdateRateTooltip:
      'Increase the rate of traffic flow updates from Workloads for the next {minutes, plural, =1 {one minute} other {# minutes}}',
    Info: 'Info',
    Information: 'Information',
    IngestedResourcesSummary: 'Ingested Resources Summary',
    InProgress: 'In Progress',
    Insights: 'Insights',
    Installed: 'Installed',
    InSync: 'In Sync',
    Internet: 'Internet',
    InUse: 'In use',
    InUseBy: 'In use by',
    InUseByBlockAction: 'In Use By Reject Connections',
    InUseByContainerInheritHostPolicy: 'In Use By Container Inherit Host Policy',
    InUseByContainerWorkloads: 'In Use By Container Workloads',
    InUseByContainerWorkloadsProfiles: 'In Use By Container Workloads Profiles',
    InUseByContent: 'In Use by {content}',
    InUseByFirewallCoexistence: 'In Use By Firewall Coexistence',
    InUseByIPForwarding: 'In Use By IP Forwarding',
    InUseByLabelGroups: 'In Use By Label Groups',
    InUseByLoopbackInterfaces: 'In Use By Loopback Interfaces',
    InUseByPairingProfiles: 'In Use By Pairing Profiles',
    InUseByRBAC: 'In Use By RBAC',
    InUseByRulesets: 'In Use By Policies',
    InUseByStaticPolicy: 'In Use By Static Policy',
    InUseByVirtualServer: 'In Use By Virtual Servers',
    InUseByVirtualServices: 'In Use By Virtual Services',
    InUseByWorkloads: 'In Use By Workloads',
    InvalidDay: 'Invalid day',
    InvalidPortRangeMessage: 'Invalid Port Range \n E.g. 71-74, 80-90 TCP',
    InvalidProcessNameMessage: 'Invalid Process Name. E.g. c:\\windows\\myprocess.exe',
    InvalidProtocolMessage: 'Invalid Port and/or Protocol \n E.g. 22, 514 UDP, ICMP',
    InvalidWinServiceMessage: 'Invalid Windows Service Name',
    Inventory: 'Inventory',
    Invite: 'Invite',
    Invited: 'Invited',
    IP: 'IP',
    IPAddress: 'IP Address',
    IPAddressesMore: 'IP Addresses...',
    IPAddressHostname: 'IP Address or Hostname',
    IPAddressInvalid: 'Invalid IP Address',
    IPAddressOptions: 'IPs, IP Ranges, FQDNs, CIDR Blocks, Any IP (0.0.0.0/0 and ::/0)',
    IPAddressOrCIDRBlock: 'IP Address/CIDR Block',
    IPAddressPublic: 'Public IP Address',
    IPList: 'IP List',
    IPLists: 'IP Lists',
    IPListsCount: '{count, plural, =1 {# IP List} other {# IP Lists}}',
    IPListSingularOrPlural: '{singular, select, true {IP List} false {IP Lists}}',
    IPOverrides: 'IP Overrides',
    IPRange: 'IP Range',
    IpTables: 'iptables',
    IsCommaSeparated: '{label} must be separated by comma(,)',
    IsNot: 'is not',
    IsRequired: '{label} is required',
    IsUUID: '{label} must be a valid UUID',
    Items: 'Items',
    ItemsCannotRemove: 'These items cannot be removed:',
    JSON: 'JSON',
    JSONResponse: 'JSON Response for: {selected}',
    KnownNetworks: 'Known Networks',
    Kubelink: 'Kubelink',
    Kubernetes: 'Kubernetes',
    KubernetesAttributes: 'Kubernetes Attributes',
    KubernetesLabels: 'Kubernetes Labels',
    KubernetesWorkload: 'Kubernetes Workload',
    KubernetesWorkloads: 'Kubernetes Workloads',
    Label: 'Label',
    Labels: 'Labels',
    LabelSettings: 'Label Settings',
    LabelType: 'Label Type',
    LabelValue: 'Label Value',
    Last: 'last',
    LastApproved: 'Last Approved',
    LastModified: 'Last Modified',
    LastModifiedAt: 'Last Modified At',
    LastModifiedBy: 'Last Modified By',
    LastModifiedOn: 'Last Modified On',
    LastPairingKeyGenerated: 'Last Pairing Key Generated',
    LastReportUpdate: 'Last Report Update',
    LastSynced: 'Last Synced:',
    LastUsed: 'Last Used',
    LastVENPaired: 'Last VEN Paired',
    Layout: 'Select Layout',
    LDAP: 'LDAP',
    LDAPS: 'LDAPS',
    LearnMore: 'Learn More',
    Leave: 'Leave',
    Light: 'Light',
    Limited: 'Limited',
    Link: 'Link',
    Linked: 'Linked',
    Loading: 'Loading',
    Local: 'Local',
    Location: 'Location',
    LocationDescription: 'Physical or geographic location of the Workload',
    Locations: 'Locations',
    Lock: 'Lock',
    Locked: 'Locked',
    LogIn: 'Log In',
    Low: 'Low',
    MachineAuthentication: 'Machine Authentication',
    MachineId: 'Machine ID',
    MainDropdownMenu: 'Main Dropdown Menu',
    Managed: 'Managed',
    Management: 'Management',
    ManageScope: 'Manage Scope',
    Map: 'Map',
    MatchingServices: '{count, plural, =1 {# Matching Service} other {# Matching Services}}',
    MatchingServicesOnly: 'Matching Services',
    MaxlengthError:
      '{label, select, false {Value} other {{label}}} must be less than or equal to {maxlength, plural, =1 {# character} other {# characters}}',
    Medium: 'Medium',
    Members: 'Members',
    Minutes: 'Minutes',
    MinutesNumber: '{count, plural, =1 {# Minute} other {# Minutes}}',
    Missing: 'Missing',
    MixedTraffic: 'Mixed Traffic',
    Mode: 'Mode',
    ModificationNotAllowed: 'Modification Not Allowed',
    ModifiedBy: 'Modified By',
    Modify: 'Modify',
    MonthsNumber: '{count, plural, =1 {# Month} other {# Months}}',
    More: 'more',
    Move: 'Move',
    MoveModeAlert:
      'The Hand Tool is used only to pan the map. To select or reposition an object, use the Selection Tool.',
    MoveTool: 'Hand Tool',
    MoveToolDescription: 'Drag to pan the map view. Shortcut: Press space bar to enable.',
    MustBeAnInteger: '{name} must be an integer',
    MustClearDeletedUserGroups: 'Deleted User Groups must be cleared to enable save.',
    NA: 'N/A',
    Name: 'Name',
    NameExist: 'Name {value} already exists',
    NameID: 'Name and ID',
    NameIsNotAllowed: 'That name is not allowed, please use another name',
    NameIsTooLong: 'Name must not be more than 255 characters',
    NameIsTooShort: 'Name must be atleast 2 characters',
    Namespace: 'Namespace',
    NamespaceIsTooLong: '{namespace} must not be more than 255 characters',
    NamespaceOrProject: 'Namespace/Project',
    NavigateUp: 'Navigate Up',
    NetworkInterfaces: 'Network Interfaces',
    NetworkNameInvalid: 'Invalid Network Name',
    NetworkProfile: 'Network Profile',
    Never: 'Never',
    NeverExpires: 'Never expires',
    New: 'New',
    Next: 'Next',
    No: 'No',
    NoApplication: 'No Application',
    NoApplicationLabel: 'No Application Label',
    NoData: 'No data to display',
    NoDataContainerCluster: 'No Container Clusters to display',
    NoDataContainerWorkloadProfiles: 'No Container Workload Profiles to display',
    NoDataContainerWorkloads: 'No Container Workloads to display',
    NoDataKubernetesWorkloads: 'No Kubernetes Workloads to display',
    NoDataLoadBalancer: 'No Load Balancers to display',
    NoDataNetwork: 'No Networks to display',
    NoDataServiceBackends: 'No Service Backends to display',
    NoDataWorkload: 'No Workloads to display',
    Node: 'Node',
    NoEnvironment: 'No Environment',
    NoEnvironmentLabel: 'No Environment Label',
    NoInternetConnectionAvailable: 'No internet connection available',
    NoLabel: 'No Label',
    NoLabels: 'No {name} Labels',
    NoLocation: 'No Location',
    NoLocationLabel: 'No Location Label',
    NoMatchDataContainerClusters: 'No matching Container Workload Profiles to display',
    NoMatchDataContainerWorkloads: 'No matching Container Workloads to display',
    NoMatchDataKubernetesWorkloads: 'No matching Kubernetes Workloads to display',
    NoMatchRules: 'No matching Rules',
    NoMatchServices: 'No matching Services to display',
    NoMembersToDisplay: 'No Members to display',
    None: 'None',
    NoneSelected: 'None selected',
    NoNetworkConnectionPleaseTryAgainLater: 'No network connection. Please try again later',
    NoObjectSelected: 'No objects were selected',
    NoPeers: 'No Peers',
    NoResultsFound: 'No results found',
    NoRole: 'No Role',
    NoRoleLabel: 'No Role Label',
    NoRules: 'No rules to display',
    NoSavedFilters: '<span class="{className}"> None Saved<br/>To save a filter set, click the Save icon.</span>',
    NoServicesData: 'No Services to display',
    NotApplicable: 'Not Applicable',
    NotAvailable: 'Not available',
    NotConnected: 'Not Connected',
    NotConsumed: 'Not Consumed',
    Note: 'Note',
    NotEnabled: 'Not Enabled',
    NotEnforced: 'Not Enforced',
    NotGranted: 'Not Granted',
    Notification: 'Notification',
    Notifications: 'Notifications',
    NotInUse: 'Not in use',
    NotProvided: 'Destination not in Group',
    NotRecommended: 'Not Recommended',
    NotSecure: 'Not Secure',
    NotStarted: 'Not Started',
    NotYetConnected: 'Not yet connected',
    NumOfNum: '{low, number} of {high, number}',
    Object: 'Object',
    OCI: 'OCI',
    Off: 'Off',
    OIDC: 'OIDC',
    OK: 'OK',
    OkToRemoveRemovable: 'If you click OK, only the removable items will be removed.',
    On: 'On',
    Onboard: 'Onboard',
    Onboarding: 'Onboarding',
    Online: 'Online',
    Open: 'Open',
    OpenLDAP: 'OpenLDAP',
    OpenShift: 'OpenShift',
    Operator: 'Operator',
    Optional: 'Optional',
    OptionalDescription: 'Optional Description',
    Options: 'Options',
    Organization: 'Organization',
    OrganizationEvent: 'Organization Event',
    OrganizationEvents: 'Organization Events',
    OrganizationSingularOrPlural: '{count, plural, =1 {Organization} other {Organizations}}',
    OS: 'OS',
    OSFamily: 'OS Family',
    Other: 'Other',
    Outbound: 'Outbound',
    OutOfSync: 'Out of Sync (Last Connection Time: {when, date, L_HH_mm_ss})',
    Override: 'Override',
    Packages: 'Packages',
    // PacketCount: 'Packet Count',
    PageFirst: 'First page',
    PageLast: 'Last page',
    Pagination:
      '<strong>{notZero, select, true {{min, number} – } false {}}{max, number}</strong> of <strong>{total, number}{plus, select, true {+} false {}}</strong> {type, select, label {Label Set Connections} connections {Connections} matched {Matched} total {Total}}',
    PaginationNumberString:
      '<strong>{notZero, select, true {{min, number} – } false {}}{max, number}</strong> of <strong>{total, number}{plus, select, true {+} false {}}</strong>',
    PaginationTypeString:
      '{type, select, label {Label Set Connections} connections {Connections} matched {Matched} total {Total}}',
    Paired: 'Paired',
    PairingKey: 'Pairing Key',
    Password: 'Password',
    PasswordChange: 'Change Password',
    PasswordResetLinkSent: 'A password reset link has been sent to your email.',
    Patents: 'Patents',
    Pause: 'Pause',
    PCE: 'PCE',
    PCEMaxDisplay:
      'The PCE web console only displays a maximum of {count, number} entries in a list. To see more results, apply a filter to the list.',
    PCEUnavailable: 'PCE is currently unavailable. Wait or try again after some time.',
    PCEUpgradeRulesetName: 'Illumio PCE Upgrade - Non-Corporate Endpoint Policies',
    Pending: 'Pending',
    PerPageCount: '{count, number} per page',
    Play: 'Play',
    Policies: 'Policies',
    PolicyData: 'Policy Data',
    PolicyDecision: 'Policy Decision',
    PolicyDetails: 'Policy Details',
    PolicyOptions: 'Policy Options',
    PolicySingularOrPlural: '{singular, select, true {Policy} false {Policies}}',
    PolicyStatus: 'Policy Status',
    PolicyType: 'Policy Type',
    PoolTarget: 'Pool Target',
    Poor: 'Poor',
    PortAndOrProtocol: 'Port and/or Protocol',
    Ports: 'Port(s)',
    PostalCode: 'Postal Code',
    PotentiallyBlockByDenyRules: 'Potentially Blocked by Deny Rules',
    PotentiallyBlocked: 'Potentially Blocked',
    Prefix: 'Prefix',
    Presets: 'Presets',
    Prev: 'Prev',
    Preview: 'Preview',
    PreviewChanges: 'Preview Changes',
    Previous: 'Previous',
    Priority: 'Priority',
    PrivacyPolicy: 'Privacy Policy',
    PrivateAddress: 'Private Address',
    PrivateAddresses: 'Private Addresses',
    PrivateDataCenters: 'Private Data Centers',
    Process: 'Process',
    Processes: 'Processes',
    Product: 'Product',
    ProductDemo: 'Watch Product Demo',
    Project: 'Project',
    Protected: 'Protected',
    ProtectionReady: 'Protection Ready',
    Protocol: 'Protocol',
    ProtocolInvalid: 'Invalid protocol',
    ProtocolMissing: 'Missing protocol',
    ProvideAName: 'Provide a name',
    ProvideName: 'Please provide your name',
    ProviderWorkload: 'Destination is a Workload',
    Provision: 'Provision',
    ProvisioningErrors: 'Provisioning errors',
    ProvisionStatus: 'Provision Status',
    PublicAddress: 'Public Address',
    PublicAddresses: 'Public Addresses',
    Radios: 'Radios',
    RBAC: 'RBAC',
    ReadOnly: 'Read Only',
    ReadWrite: 'Read and Write',
    Recalculate: 'Recalculate',
    RecentlyUsed: 'Recently Used {name}',
    Recommendation: 'Recommendation',
    Recommended: 'Recommended',
    Redo: 'Redo',
    Refresh: 'Refresh',
    Regenerate: 'Regenerate',
    Region: 'Region',
    Reject: 'Reject',
    Reload: 'Reload',
    Remove: 'Remove',
    Removed: 'Removed',
    RemoveGroup: 'Remove Group',
    Report: 'Report',
    Reported: 'Reported',
    ReportedEnforcementBoundary: 'Reported Deny Rule',
    ReportedPolicy: 'Reported Policy Decision',
    ReportPolicy: 'Report Policy',
    Reports: 'Reports',
    Required: 'Required',
    Reset: 'Reset',
    ResetColumnSelection: 'Reset to default columns',
    ResetLayout: 'Reset Layout',
    ResetSelsectionAndSortingBy: 'Reset to default columns and default sorting (by {name})',
    ResetSortingBy: 'Reset to default sorting{name, select, undefined {} other {(by {name})}}',
    ResetToDefault: 'Reset to Default',
    ResolveDomainsAlert:
      'FQDNs are resolved using reverse DNS from the PCE. Accuracy of results may vary depending on the relative locations of the PCE and the target device.',
    Resource: 'Resource',
    ResourceCount: '{count, plural, =1 {# Resource} other {# Resources}}',
    ResourceName: 'Resource Name',
    ResourceNameID: 'Resource Name and ID',
    Resources: 'Resources',
    ResourceState: 'Resource State',
    ResourceType: 'Resource Type',
    Restore: 'Restore',
    Retry: 'Retry',
    Reverse: 'Reverse',
    Revert: 'Revert',
    RFC1918: 'RFC 1918',
    RightClickForOptions: 'Right-click for options',
    Risk: 'Risk',
    Role: 'Role',
    RoleDescription: 'The function the Workload plays in the application. E.g., Database.',
    Roles: 'Roles',
    RoleUnassigned: 'Role not assigned',
    Row: 'Row',
    Rules: 'Rules',
    RulesCount: '{count, plural, =1 {# Rule} other {# Rules}}',
    RuleSearch: 'Rule Search',
    RulesetsCount: '{count, plural, =1 {# Policy} other {# Policies}}',
    Run: 'Run',
    Running: 'Running',
    SAML: 'SAML',
    Save: 'Save',
    SaveAndProvision: 'Save and Provision',
    SaveAs: 'Save As',
    SaveChangesConfirmation: 'Are you sure you want to save the changes?',
    SaveChangesTitle: 'Save Changes',
    SaveForLater: 'Save for Later',
    Saving: 'Saving',
    Scope: 'Scope',
    Scoped: 'Scoped',
    Scopes: 'Scopes',
    Score: 'Score',
    Script: 'Script',
    Search: 'Search',
    Seconds: 'Seconds',
    SecondsNumber: '{count, plural, =1 {# Second} other {# Seconds}}',
    Secret: 'Secret',
    Secrets: 'Secrets',
    Secure: 'Secure',
    SecureConnect: 'SecureConnect',
    SecureConnectGateway: 'SecureConnect Gateway',
    SecureConnectGateways: 'Secure Connect Gateways',
    SecureConnectGatewaysCount: '{count, plural, =1 {# Secure Connect Gateway} other {# Secure Connect Gateways}}',
    Security: 'Security',
    SecuritySettings: 'Security Settings',
    SeeMore: 'See More',
    Select: 'Select',
    SelectAll: 'Select All',
    SelectConsumers: 'Select Sources',
    Selected: 'Selected',
    SelectedCount: '{count, plural, other {<span class="{className}">#</span>&nbsp;Selected}}',
    SelectedNumber: '<span class="{className}">{count, number}</span>',
    SelectionTool: 'Selection Tool',
    SelectionToolDescription: 'Click to select objects. Drag to move objects.',
    SelectLabels: 'Select Labels',
    SelectLabelsLabelGroups: 'Select Labels And Label Groups',
    SelectModeAlert: 'Dragging the mouse is used only to select traffic links. To pan the map, use the Hand Tool.',
    SelectModeAlertShortcut:
      '<span class="{className}">Shortcut: </span>Press and hold the space bar to enable the Hand Tool.',
    SelectOrTypeConsumers: 'Select or type for more sources',
    SelectOrTypeProviders: 'Select or type for more destinations',
    SelectPolicy: 'Select a Policy',
    SelectProtocol: 'Select a Protocol',
    SelectProviders: 'Select Destinations',
    SelectScope: 'Select Scope',
    Server: 'Server',
    ServersAndEndpoints: 'Servers and Endpoints',
    ServersAndEndpointsOnlyFeature: 'This feature is limited to Servers and Endpoints only',
    Service: 'Service',
    ServiceAccounts: 'Service Accounts',
    ServiceBackends: 'Service Backends',
    ServiceBindings: 'Service Bindings',
    ServiceIs: 'Service is',
    ServiceIsNot: 'Service is not',
    ServiceIsNotMore: 'Service is not...',
    ServiceMore: 'Service...',
    ServiceNotRunning: 'Service not running',
    ServicePort: 'Service/Port',
    ServiceRunning: 'Service running',
    Services: 'Services',
    ServicesCount: '{count, plural, =1 {# Service} other {# Services}}',
    ServiceSelection: {
      AddNewService: 'Add New Service',
    },
    ServiceSingularOrPlural: '{singular, select, true {Service} false {Services}}',
    Setting: 'Setting',
    Settings: 'Settings',
    SettingsCount: '{count, plural, =1 {# Setting} other {# Settings}}',
    Severity: 'Severity',
    Show: 'Show',
    ShowAllMembers: 'Show All Members',
    ShowImpact: 'Show Impact',
    ShowLess: 'Show less',
    ShowTrafficMembers: 'Show Members with Traffic',
    SideBySideDiff: 'Side-by-side diff',
    Sort: 'Sort',
    SortedBy: 'Sorted by {column}',
    SortOn: 'Sort on',
    Source: 'Source',
    SourceCategory: 'Source Category',
    SourceCSPId: 'Source CSP ID',
    Sourced: 'Sourced',
    SourceDetailType: 'Source Detail Type',
    SourceFqdn: 'Source FQDN',
    SourceObjectType: 'Source Object Type',
    SourceProcess: 'Source Process',
    Sources: 'Sources',
    SourcesAndDestinations: 'Sources And Destinations',
    SourcesOrDestinations: 'Sources Or Destinations',
    SourceSubCategory: 'Source Sub Category',
    SourceTenantId: 'Source Tenant ID',
    SourceToTarget: '{source}-To-{target}',
    SourceType: 'Source Type',
    Staged: 'Staged',
    StaleSecurityControls: 'Stale Security Controls',
    Standard: 'Standard',
    StandardServices: 'Standard Services',
    STARTTLS: 'STARTTLS',
    State: 'State',
    Stateless: 'Stateless',
    StateProvinceTerritory: 'State/Province/Territory',
    Static: 'Static',
    StaticPolicy: 'Static Policy',
    Status: 'Status',
    Step: 'Step {step}',
    Steps: {
      SelectServices: 'Select Services',
    },
    Stop: 'Stop',
    Stopped: 'Stopped',
    Style: 'Style',
    SubDropdownMenu: 'Sub Dropdown Menu',
    SubnetId: 'Subnet ID',
    Subscription: 'Subscription',
    SubscriptionSingularOrPlural: '{count, plural, =1 {Subscription} other {Subscriptions}}',
    Success: 'Success',
    Successful: 'Successful',
    Summary: 'Summary',
    SuperClusterPartialDataWarning: 'Partial Map based on data for this PCE only',
    Support: 'Support',
    SupportPortal: 'Support Portal',
    System: 'System',
    SystemEvents: 'System Events',
    SystemInitializing: 'System initializing',
    Tags: 'Tags',
    Tenant: 'Tenant',
    TenantSingularOrPlural: '{count, plural, =1 {Tenant} other {Tenants}}',
    Terms: 'Terms',
    Test: 'Test',
    Theming: 'Theming',
    ThirdParty: 'Third Party',
    Timestamp: 'Timestamp',
    Timezone: 'Time Zone',
    ToBeDeprecated: 'To be deprecated',
    ToClose: 'to close',
    Today: 'Today',
    TogglePresetTableColumns: '{action} {presetToggle} table columns',
    Token: 'Token',
    ToNavigate: 'to navigate',
    ToSelect: 'to select',
    Total: 'Total',
    TotalEndpoints: 'Total Endpoints',
    TotalFlows: 'Total Flows',
    TotalNumber: '<span class="{className}">{count, number}</span> Total',
    TotalPCEs: 'Total PCEs',
    TotalResources: 'Total Resources',
    TotalServers: 'Total Servers',
    TotalVENs: 'Total VENs',
    Traffic: 'Traffic',
    TrafficView: 'Traffic View',
    TransmissionMode: 'Transmission Mode',
    Troubleshoot: 'Troubleshoot',
    Troubleshooting: 'Troubleshooting',
    True: 'True',
    TurnOff: 'Turn Off',
    TurnOn: 'Turn On',
    Type: 'Type',
    TypeDescription: 'Type a description',
    Types: 'Types',
    Unavailable: 'Unavailable',
    Undo: 'Undo',
    Unidentified: 'Unidentified',
    UnifiedConsole: 'Unified Console',
    UnifiedDiff: 'Unified diff',
    Uninstalled: 'Uninstalled',
    Unknown: 'Unknown',
    UnknownTraffic: 'Unknown Traffic',
    Unlimited: 'Unlimited',
    Unlink: 'Unlink',
    Unlock: 'Unlock',
    Unlocked: 'Unlocked',
    Unmanage: 'Unmanage',
    Unmanaged: 'Unmanaged',
    UnmanagedIPAddresses: 'Unmanaged IP Addresses',
    UnmanagedIPs: 'Unmanaged IPs',
    UnmanagedWorkload: 'Unmanaged Workload',
    Unpair: 'Unpair',
    UnsavedChanges: 'You have unsaved changes.',
    UnsavedPendingWarning: 'Leaving this page will discard pending changes.',
    Up: 'Up',
    Updated: 'Updated',
    UpdatedAt: 'Updated at',
    UpdatedBy: 'Updated By',
    Upgrade: 'Upgrade',
    Upload: 'Upload',
    Usage: 'Usage',
    UserEmail: 'Email Address/Username',
    UserGroup: 'User Group',
    UserGroups: 'User Groups',
    Username: 'Username',
    UserProfile: 'User Profile',
    UsesVirtualServices: 'Uses Virtual Services only',
    UsesVirtualServicesWorkloads: 'Uses Virtual Services and Workloads',
    UseVirtualService: 'Use port/protocol in the Virtual Service',
    UUID: 'UUID',
    ValidationError: 'Validation Error',
    Value: 'Value',
    VEN: 'VEN',
    VenDashboard: 'VEN Dashboard',
    VENS: 'VENs',
    Verify: 'Verify',
    VerifyTLS: 'Verify TLS',
    Version: 'Version',
    Video: 'Video',
    View: 'View',
    ViewDraft: 'View Draft',
    ViewMore: 'View more',
    ViewScope: 'View Scope',
    VIPPort: 'VIP & Port',
    VirtualServer: 'Virtual Server',
    VirtualServers: 'Virtual Servers',
    VirtualServersCount: '{count, plural, =1 {# Virtual Server} other {# Virtual Servers}}',
    VirtualService: 'Virtual Service',
    VirtualServices: 'Virtual Services',
    VirtualServicesCount: '{count, plural, =1 {# Virtual Service} other {# Virtual Services}}',
    Visibility: 'Visibility',
    VisibilityOnly: 'Visibility Only',
    Visible: 'Visible',
    VpcOrVnetId: 'VPC/VNET ID',
    Vulnerabilities: 'Vulnerabilities',
    Vulnerability: 'Vulnerability',
    VulnerabilityData: 'Vulnerability Data',
    Warning: 'Warning',
    WarningCount: '{count, plural, =1 {# Warning} other {# Warnings}}',
    WinService: 'Windows Services',
    WinServiceMessage: 'E.g. myprocess',
    Workload: 'Workload',
    WorkloadManagement: 'Workloads and VENs',
    Workloads: 'Workloads',
    Yes: 'Yes',
    ZIPCode: 'ZIP Code',
    ZoomIn: 'Zoom in',
    ZoomOut: 'Zoom out',
    ZoomToFit: 'Zoom to fit',
  },
  Copilot: {
    Chat: 'Chat',
    Description:
      'Ask me anything, or pick a suggestion. I have limitations and won’t always get it right, but your feedback will help me improve.',
    Disclaimer: 'IVA can make mistakes. Check important info.',
    Error: {
      FailToAnswer: 'fail to get the answer',
      FailToPerformAction: 'There was an error in performing action. Please try again.',
      UserAbort: 'User aborted the request',
    },
    Greeting: "Hello I'm Illumio Virtual Advisor, IVA",
    IVA: 'Illumio Virtual Advisor',
    StopGenerating: 'Stop Generating',
    TakeAction: 'Take Action',
  },
  /* eslint-disable-next-line sort-keys -- Common section above should always go first */
  AccessRestriction: {
    AccessRestrictionDelete: 'Remove {count, plural, =1 {Access Restriction} other {Access Restrictions}}',
    AccessRestrictionDeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this Access Restriction} other {# selected Access Restrictions}}?',
    AccessRestrictionPlaceholder: 'Select Access Restriction',
    AccessRestrictionTitle: 'Access Restriction',
    AddNew: 'Add a new Access Restriction',
    AddValidRestriction: 'Add a valid Access Restriction',
    APIKey: 'API Key',
    Errors: {
      APIKey: 'Unable to Edit API Key',
      Create: 'Unable to Create Access Restriction',
      Edit: 'Unable to Edit Access Restriction',
      SuperclusterMember:
        'API keys cannot be edited from a Super Cluster Member. Please log out and log into the Super Cluster Leader',
    },
    List: {
      Message: {
        RemoveSuccess:
          '{count, plural, =1 {Access Restriction has} other {# Access Restrictions have}} been successfully removed',
      },
    },
    MaxIPAddresses: 'Maximum 8 IPv4 Addresses or CIDR Blocks',
    NoData: 'No Data to display',
    RestrictionAppliedTo: 'Restriction Applied To',
    TypeRestrictionDescription: 'Type Access Restriction Description',
    TypeRestrictionName: 'Type Access Restriction Name',
    UserSession: 'User Session',
    UserSessionAndAPIKey: 'User Session and API Key',
    Warning: {
      FirstMessage: 'Assigning misconfigured access restrictions can prevent users from accessing the PCE',
      SecondMessage:
        'Assigning misconfigured access restrictions can prevent users from accessing the PCE. Are you sure you want to continue?',
    },
  },
  AILabeling: {
    AILabelingTab: 'AI Labeling',
    SuggestedActions: {
      CloudMap: {
        AllAzureTraffic: 'Show me all Azure traffic',
        AwsUsEastTraffic: 'Show me all traffic from AWS US East',
        USEast1Resources: 'Show me resources in us-east-1',
      },
      CloudTraffic: {
        AWSTrafficFromPastWeek: 'Show me AWS traffic from the past week',
        AzurePrivateEndpoint: 'Show me Azure private endpoints',
        AzureTrafficFromPastWeek: 'Show me Azure traffic from the past week',
      },
      CoreMap: {
        AdminPortsTraffic: 'Show me traffic on admin ports 22 and 3389',
        PciToNonPciWorkloads: 'Are any of my PCI compliant workloads talking to non-PCI compliant workloads?',
        PciWorkloadsDbPorts: 'Show me my PCI workloads talking on database ports',
        RiskyPortsTraffic: 'Show me traffic on risky ports',
      },
      Traffic: {
        AllProductionTraffic: 'Show me all Production traffic',
        TrafficFromNewYork: 'Show me traffic from New York',
        WebserverDbTraffic: 'Show me the Webservers talking to DB for the last week',
        WebTrafficPastWeek: 'Show me web traffic for the past week',
      },
    },
    SuggestedQuestions: {
      OnboardingCftPurpose: 'What is the onboarding CFT doing within my AWS account?',
      PortsPathwaysExample: 'Can you give me an example of ports and pathways that I would need to block?',
      PreventRansomwareSpread: 'How can I prevent the spread of Ransomware within my Organization?',
      RansomwareDashboard: 'What is ransomware dashboard?',
      RdpRansomwareVulnerability: 'Is RDP a ransomware vulnerable port? Can you clarify?',
      WhatIsCloudSecure: 'What is CloudSecure?',
      WriteOutboundAnyRule: 'How can I write an outbound any rule?',
    },
  },
  APIKeySettings: {
    APIKeySettings: 'API Key Settings',
    DefaultAPIKeyExpiresIn: 'By default, API Key for Service Accounts expires in',
    ExpirationSettingText: 'Changing this setting will not change the expiration of existing API Keys',
    ExpirationSettingViewText: 'Time rounded to days. Click Edit to see exact time in hours.',
    KeepExpiredAPIKeysFor: 'Keep Expired API Keys for',
  },
  Applications: {
    AllDeployments: 'All Deployments',
    AppEnv: 'Application | Environment',
    AppEnvDesc: 'Two Workload labels required to match. (Location label is ignored.)',
    AppEnvLoc: 'Application | Environment | Location',
    AppEnvLocDesc: 'Three Workload labels required to match',
    ApplicationNoMember: 'The current Application has no members, please select a different Application.',
    ApplicationType: 'Application Type',
    ApplicationTypeDescription:
      'Applications are created automatically based on Workload labels and the Application Type setting. Applications can be configured to require two or three matching labels.',
    AutoApproveDiscoveryRuleMessage:
      "Toggle 'Auto Approve' ON to automatically accept all discovered application definitions and any updates made to their deployments or resources.",
    AutoApproveMessage: `Toggle 'Auto Approve' ON to automatically accept all discovered deployments and resources.`,
    AutoApproveModalMessageOff:
      'Deployments and resources that match your cloud metadata will need to be approved manually.',
    AutoApproveModalMessageOn:
      'Deployments and resources that match your cloud metadata will be approved automatically.',
    AutoApproveOff: 'Auto Approve: OFF',
    AutoApproveOffTooltip:
      'Auto-Approve OFF: Deployments and resources must be approved manually. Modify this setting on the Edit page.',
    AutoApproveOn: 'Auto Approve: ON',
    AutoApproveOnTooltip:
      'Your deployments and resources have been automatically approved as you have enabled the Auto-Approve feature. To modify this setting, go to the Edit page.',
    AutoApproveSaveModalTitleOff: 'Save with Auto Approve OFF?',
    AutoApproveSaveModalTitleOn: 'Save with Auto Approve ON?',
    AutoApproveSetting: 'Auto Approve Setting',
    ChangingApplicationsType: 'Re-set Application Type',
    ChangingApplicationsWarning:
      'The Application Map must recalculate traffic before re-displaying the map. Recalculating traffic takes longer for large Organizations.',
    ContainerWorkloads: 'Application Container Workloads',
    EditApplicationDefinition: 'Edit Application Definition',
    EditApplicationsType: 'Edit Application Type',
    Explorer: 'Application Explorer',
    NoDeployment: 'No Deployment',
    OpenApplicationsMap: 'Open Application Map',
    PairingProfiles: 'Application Pairing Profiles',
    PolicyGenerator: 'Application Policy Generator',
    PolicyTooltip: 'Policies will be available soon. Try to refresh the page or come back later.',
    Rules: 'Application Rules',
    SelectApplications: 'Select a different Application',
    SetApplicationType: 'Set Application Type',
    VirtualServers: 'Application Virtual Servers',
    VirtualServices: 'Application Virtual Services',
    Vulnerabilities: 'Application Vulnerabilities',
    Workloads: 'Application Workloads',
  },
  ApplicationsCoverage: {
    AddToRuleset: 'Adding to Policy:',
    AllowRuleExtraScopeDesc: 'Extra-scope rules allow traffic from sources that are outside the scope of the policy',
    AllowRuleIntraScopeDesc: 'Intra-scope rules allow traffic from sources that are inside the scope of the policy',
    AppendExtraRules: 'Append Extra-Scope Rules',
    AppendIntraRules: 'Append Intra-Scope Rules',
    AppendIPListRules: 'Append IP Lists Rules',
    BuildNewRuleset: 'Building New Policy:',
    ConnectionWithoutRules: '{count, plural, =1 {Connection} other {Connections}} without Rules',
    ConnectionWithRules: '{count, plural, =1 {Connection} other {Connections}} with Rules',
    CoverageNotCalculated: 'Rule Coverage not calculated',
    DeletingRulesInRuleset: 'Deleting Rules from Policy',
    ExistObjectInRuleset: 'Existing Objects in Policy',
    ExtraScope: 'Extra-Scope',
    ExtraScopeRuleCoverage: '<strong>Extra-Scope - {val, number, percent}</strong> Rule Coverage',
    GoToApplicationRules: 'View Rules',
    IntraScope: 'Intra-Scope',
    IntraScopeRuleCoverage: '<strong>Intra-Scope - {val, number, percent}</strong> Rule Coverage',
    IpListRuleCoverage: '<strong>IP List - {val, number, percent}</strong> Rule Coverage',
    LastCalculated: 'Last Calculated: {when, date, L_HH_mm_ss}',
    MoreRecentRulesMessage: 'Your rules or traffic have been updated more recently than our calculations',
    NeverCalculatedMessage: 'The rule coverage for this Application have never been calculated',
    NewExtraRuleCoverageStrong: '<strong>{val, number, percent}</strong> New Extra-Scope Rule Coverage',
    NewIntraRuleCoverageStrong: '<strong>{val, number, percent}</strong> New Intra-Scope Rule Coverage',
    NewIpListRuleCoverageStrong: '<strong>{val, number, percent}</strong> New IP List Rule Coverage',
    NewObjectInRuleset: 'Adding New Objects to Policy',
    NoConnections: 'No Connections',
    NoConnectionsFound: 'No Connections Found',
    NoRuleset: 'Rule coverage is provided by policies that were not created by Policy Generator',
    Recalculate: 'Recalculate?',
    RecalculateConfirm: 'Recalculating the connections might take a few minutes.',
    ReplaceExtraRules: 'Replace Extra-Scope Rules',
    ReplaceIntraRules: 'Replace Intra-Scope Rules',
    ReplaceIPListRules: 'Replace IP Lists Rules',
    RuleCoverage: '{val, number, percent} Rule Coverage',
    RuleCoverageStrong: '<strong>{val, number, percent}</strong> Rule Coverage',
    RulesetAvailableForPolicyGenerator: 'is available to be used for the Policy Generator.',
    RulesetDisabled: 'The default policy generator policy is {disabled}. You must make it active to add rules.',
    StartWithExtra: 'Start with Extra-Scope',
    StartWithIntra: 'Start with Intra-Scope',
    StartWithIpList: 'Start with IP Lists',
    TheRuleset: 'The Policy',
    TooManyWorkloadsInApplication: 'Too Many Workloads in Application',
    UseThisRuleset: 'Use this Policy',
  },
  Authentication: {
    SignIn: {
      CreateAccount: 'Create an account?',
      EmailPlaceholder: 'Email: user@email.com',
      EnterPassword: 'Enter Password',
      ForgotPassword: 'Forgot Password?',
      IdPAuthenticationError: 'An issue occurred during Identity Provider authentication.',
      IncorrectPassword: 'Incorrect email or password',
      InvalidEmail: 'The email address you entered is not valid.',
      NonExistentEmail: 'Email not found',
      SignIn: 'Sign In',
      SignInIllumioConsole: 'Signing you in',
      SignInLoadingData: 'Loading your account information',
      StateMismatch: 'State does not match',
    },
    SignUp: {
      AgreeTo: 'Agree to',
      Company: 'Company',
      CompanyEmail: 'Company Email',
      CountryPlaceholder: 'Select your country',
      DisabledMessage: 'Signup is temporarily disabled due to ongoing maintenance',
      FirstName: 'First Name',
      FirstNamePlaceholder: 'Johnny',
      HaveAccount: 'Already have an account? <a>Log in</a>',
      InvalidEmail:
        'Invalid email. Please enter a valid company email address and try again.\nIf you need assistance, please contact our support team.',
      LastName: 'Last Name',
      LastNamePlaceholder: 'Appleseed',
      NoAccount: "Don't have an account? <a>Sign up for free trial</a>",
      ReceiveEmails: "I'd like to receive emails about news & updates from Illumio",
      RegionPlaceholder: 'Select your SaaS region',
      SaasRegion: 'Host Region',
      SignUp: 'Sign Up',
      TermsConditions: 'Terms and Conditions',
      Trial: 'Sign Up for Free Trial',
    },
    SignUpComplete: {
      Content:
        '<p>Your free trial of Illumio Console has begun.</p><p>An email has been sent to the address you entered. Click the link in the email to begin using Illumio Console.</p><p>If you have any questions or need assistance, our dedicated support team is here to help. Please don’t hesitate to reach out to us at <a>{email}</a></p>',
      Header: 'Thank you for signing up for a free trial',
    },
  },
  AuthenticationSettings: {
    AddServer: 'Create Server',
    AnonymousBind: 'Anonymous Bind',
    AuthenticationMethods: 'Authentication Methods',
    AuthenticationSettingsIntro:
      'Choose your <b>Authentication Method</b> to authenticate users for accessing the <b>PCE</b>',
    AuthMethodEnabled: '{method} authentication is enabled as the default authentication method.',
    AuthMethodNotEnabled: '{method} authentication is not active. Enable to set as the default authentication method.',
    Banner: 'Authentication Settings banner image',
    BindDN: 'Bind DN',
    BindDNExample: 'Example: cn=Manager,dc=domain,dc=com',
    BindDNHint: 'This is the distinguished name used to bind to the LDAP server',
    BindPassword: 'Bind Password',
    CheckYourConfigurationAndRetry: 'Check your configuration and retry.',
    ConfigureAuthenticationMessage:
      'Configure authentication methods to grant access to Illumio Console. Choose from protocols like OIDC to tailor authentication to your security needs and preferences.',
    ConfirmBindPassword: 'Confirm Bind Password',
    ConfirmDeleteLDAPConfig: 'Delete this LDAP server configuration and remove it from the list?',
    ConfirmDisableAuthMethod: 'Are you sure you want to disable {method} as the default authentication setting?',
    ConfirmDisableAuthMethodWarning:
      'Disabling this authentication method may prevent current users who use it from logging in.',
    ConfirmDisableSAML: 'Are you sure you want to disable SAML as the default authentication setting?',
    ConfirmEnableAuthMethod: 'Do you want to enable {method} as the default authentication setting?',
    ConfirmEnableLDAP: 'Confirm LDAP as the default authentication setting?',
    ConfirmEnableLDAPWithoutConfigs:
      'LDAP configuration has been enabled, but no servers have been added. Please add LDAP servers.',
    ConfirmEnableSAML: 'Confirm SAML as the default authentication setting?',
    ConfirmLastLDAPConfigRemoval:
      "Deleting the last LDAP server configuration disables LDAP authentication. Only the 'Local' setting will be available. Click OK to proceed.",
    CountOfMax: '({count} of {max})',
    DisableAuthMethod: 'Disable {method}',
    EmptyLDAPConfigs: 'No LDAP servers configured',
    EnableAuthMethod: 'Enable {method}',
    EnableLDAP: 'LDAP authentication is not active. Click Turn On to apply on all the LDAP servers.',
    EnableLDAPSupercluster:
      'LDAP authentication is not active. Click Turn On to apply on all the LDAP servers on all PCEs.',
    EnableSAML: 'SAML authentication is not active. Click Turn On to enable SAML.',
    EnableSAMLSupercluster: 'SAML authentication is not active. Click Turn On to enable SAML on all PCEs.',
    FullNameAttribute: 'Full Name Attribute',
    FullNameAttributeExample: 'Example: cn,commonName,displayName',
    FullNameAttributeHint: 'Attribute on a user object which contains the full name',
    GroupMembershipAttribute: 'Group Membership Attribute',
    GroupMembershipAttributeExample: 'Example: memberOf',
    GroupMembershipAttributeHint: 'Attribute on a user object containing group membership information',
    HostnameOrIPAddressHint: 'Enter an IP address or Hostname',
    LDAP: {
      Config: {
        AnonymousBindTooltip: "It is not recommended to use 'Allow' Anonymous Bind.",
        BindDNRequired: 'Bind DN is required',
        BindPasswordRequired: 'Bind Password is required',
        ConfigError: 'LDAP server configuration error',
        ConfirmAnonymousBind:
          "It is not recommended to use 'Allow' Anonymous Bind. Click confirm to 'Allow' or click Cancel and select 'Do Not Allow'.",
        ConfirmBindPasswordRequired: 'Confirm Bind Password is required',
        IPAddressOrHostnameRequired: 'IP Address or Hostname is required',
        NameRequired: 'Configuration Name is required',
        PasswordsDoNotMatch: 'Passwords do not match',
        PCERequired: 'PCE is required',
        PortRequired: 'Port is required',
        PortRequiredHint: 'Port number must be greater than or equal to 1',
        ProtocolRequired: 'Protocol is required',
        RequestTimeoutPeriodRequired: 'Request Timeout Period is required',
        RequestTimeoutPeriodRequiredHint: 'Request Timeout Period must be less than or equal to 60 seconds',
        UserBaseDNRequired: 'User Base DN is required',
        UserDNPatternError: 'User DN pattern must have exactly one * character in the pattern',
        UserNameAttributeRequired: 'UserName Attribute is required',
      },
    },
    LDAPEnabled: 'LDAP authentication is active.',
    LDAPServerConfiguration: 'LDAP server configuration',
    LearnMore: 'Learn about supported SSO providers',
    MaxServersAllowed: 'Cannot add more than 3 servers.',
    MaxServersAllowedSupercluster: 'Cannot add more than 3 servers per PCE.',
    NameHint: 'Enter a unique name for this setting',
    OIDC: {
      ClientSecret: {
        Info: 'In OIDC authorization, the client secret authenticates your application with the Identity Provider during token exchange, ensuring only authorized clients can obtain tokens securely.\n\nOnce saved, the client secret will no longer be visible in the UI.',
        Placeholder: 'Visible only when setting or updating.',
        Secondary:
          "When using a client secret, your OIDC provider app must be configured as a private (confidential) client. Please review your provider's documentation for information on how to configure it.",
        Title: 'Client Secret',
      },
      ClientTokenExchange: {
        Label: 'From user browser',
        SubLabel:
          'The ID Token is obtained directly by the user’s browser in exchange for an Authorization Code from the Identity Provider (IdP)',
      },
      Name: 'OpenID Connect (OIDC)',
      ServerTokenExchange: {
        Label: 'From Illumio Console server',
        SubLabel:
          'The ID Token is requested by the Illumio Server in exchange for the Authorization Code received from the user’s browser, which was issued by the Identity Provider (IdP)',
      },
      TokenRequest: 'Token Request',
    },
    Options: {
      LDAP: 'LDAP users can also authenticate to the PCE using local credentials.',
      Local:
        "User will sign in to the PCE only with a local credential provided by the user's organization password policy.",
      OIDC: 'Configure users to authenticate into Illumio Console using an OIDC application.',
      SAML: 'SAML users can also authenticate to the PCE using local credentials.',
    },
    PasswordConfirmed: 'Password is confirmed',
    PortExample: 'Defaults: 389 for LDAP or LDAP with STARTTLS, 636 for LDAPS. Valid values are 1 to 65,535',
    PortHint: 'Enter a Port',
    RequestTimeoutPeriod: 'Request Timeout Period (seconds)',
    RequestTimeoutPeriodExample: 'Time to wait when opening new server connection.',
    SAML: {
      Config: {
        ConfigError: 'SAML configuration error',
      },
    },
    SAMLEnabled: 'SAML authentication is active.',
    SignInOnlySAMLOptionsTip: 'Sign in to the PCE using SAML along with local credentials.',
    SignInOptionsTip: 'Sign in to the PCE using either SAML or LDAP along with local credentials.',
    TestConnection: 'Test Connection',
    TypeHint: 'Select a Type',
    UserBaseDN: 'User Base DN',
    UserBaseDNExample: 'Example: CN=Users,DC=MyDomain,DC=com',
    UserBaseDNHint: 'The location of your LDAP users, specified by the DN of your user subtree',
    UserDirectoryConfiguration: 'User Directory Configuration',
    UserDNPattern: 'User DN Pattern',
    UserDNPatternHint:
      'Pattern used to create a DN string for a user during login,\n e.g. uid=*,ou=people, where * will be replaced with the username',
    UserNameAttribute: 'User Name Attribute',
    UserNameAttributeExample: 'Default directory Active Directory (AD): sAMAccountName',
    UserNameAttributeHint: 'The user attribute that contains the username',
    UserSearchFilter: 'User Search Filter',
    UserSearchFilterExample: 'Example: department=IT',
    UserSearchFilterHint: 'The LDAP search filter used to filter users',
    WellKnownIdentityProviders: 'Well-known Identity Providers',
  },
  BlockedTraffic: {
    List: {
      ByTheConsumer: 'By the Source',
      ByTheProvider: 'By the Destination',
      LastDetected: 'Last Detected',
    },
    Name: 'Blocked Traffic',
  },
  Certificate: {
    CertExpired: 'Your Illumio PCE certificate has expired.',
    CertExpiredMessage: 'Your VENs can no longer communicate with the PCE.',
    Days: '{count, plural, =1 {DAY} other {DAYS}}',
    MessageRealCert: 'VENs will be unable to communicate with the PCE when the certificate expires.',
    MessageSelfSigned: 'You are seeing this message because the PCE is using a temporary self-signed certificate.',
    TrialEnded: 'Your Illumio trial has ended.',
    TrialEndedMessage:
      'To continue using Illumio, update the PCE with a valid certificate issued by a trusted certificate authority.',
    ValidCert: 'Your Illumio PCE certificate expires on <span class="{className}">{when, date, LL}</span>.',
    ValidTrial: 'Your Illumio trial is valid until <span class="{className}">{when, date, LL}</span>.',
  },
  CLASBetaTermsDialog: {
    AcceptedMessage: 'Accepted Beta Terms',
    Content:
      'Welcome to Illumio for Kubernetes Beta. Please review beta terms and conditions in this <a>link</a>. Then accept the terms to proceed to the beta.',
    Title: 'Beta Terms and Conditions',
  },
  Cloud: {
    ApplicationDefinition: {
      AddResources: 'Add Resources Using Cloud Metadata',
      AddToSelection: 'Add to Selection',
      ApplicationDescriptionPlaceholder: 'Type a description for the new Application',
      ApplicationNamePlaceholder: 'Type a name for the Application',
      ApplicationStatus: 'Application Status',
      ConfirmSelection: 'Confirm Selection',
      DefineWithCloudAccounts: 'Define with Cloud Accounts',
      DefineWithCloudTags: 'Define with Cloud Tags',
      DefineWithVirtualNetworksSubnets: 'Define with Virtual Networks and Subnets',
      DeploymentsResources: 'Deployments & Resources',
      DiscoveredDeploymentsResources: 'Discovered Deployments & Resources',
      Discovery: 'Resources for your application can be discovered by Cloud Metadata',
      DiscoveryStatus: 'Discovery Status',
      FilterByCA: 'Filter By Cloud Accounts',
      FilterByCSP: 'Filter By CSP',
      ResourcesWarning: 'Newly added resources will receive the existing policy for this deployment',
      SelectCA: 'Select Cloud Accounts',
      SelectCT: 'Select Cloud Tags',
      SelectCT4T2LDetails: 'Select Cloud Tags for your Tag to Label Mapping',
      Selection: 'Selection',
      SelectionsInfo: 'Selections you make will show up here',
      SelectSN: 'Select Subnets',
      SelectVN: 'Select Virtual Networks',
      Title: 'Application Definition',
    },
    ApplicationDefinitions: {
      Add: 'Add an Application Definition',
      AddDescription: 'Select how you would like to add your application definition',
      ApplicationNameAlreadyInUse: 'Application name is already in use.',
      ApprovalButtonMultipleSelectionsTooltip: 'Select exactly one unapproved item to use the Approve function.',
      ApprovalButtonNoSelectionTooltip: 'Select a single unapproved item to enable the Approve button.',
      ApprovalError: 'An error occurred approving the following application definitions: {deployments}',
      ApprovalStatus: 'Approval Status',
      BulkRemovalError: 'Unable to remove {appName} because: {errorMessage}',
      ConfirmApproveSubTitle: 'Review and approve pending deployments and resources for {application}',
      ConfirmApproveTitle: 'Approve Pending Deployments & Resources',
      ConfirmDenyDeploymentTitle: 'Confirm Application Deployment to Deny',
      ConfirmRemoveDeploymentTitle: 'Confirm Application Deployment to Remove',
      ConfirmRemoveRuleset: 'Application policies will be removed',
      ConfirmRemoveTitle: 'Remove {count, plural, =1 {Application Definition} other {# Application Definitions}}',
      ConfirmRemoveWarning:
        'This action will remove all associated policies and disassociate all resources from their respective application.',
      DeleteConfirm: 'Are you sure you want to remove the selected Application Definitions?',
      DeletedApprovedApplicationDeployment:
        'Related pending approval application deployments will be removed for this application deployment',
      Discovering: 'Discovering...',
      EditSelection: 'Edit Selection',
      ErrorApproving: 'An error occurred approving the following application deployments:',
      FilterDefinitions: 'Filter Application Definitions',
      FilterDefinitionsBy: 'Filter Application Definitions by...',
      GenericError: 'There was an error saving your changes. Please retry, if the issue persists reach out to support.',
      ListError: 'An error occurred',
      Loading: 'Loading Application Definitions...',
      NoApplicationDefinitionsDataYet: 'No application definitions data yet',
      PendingApproval: 'Pending Approval',
      UnableToCreate: 'Unable to create Application Definition',
      UnableToEdit: 'Unable to edit Application Definition',
    },
    ApplicationDeployments: {
      AssociatedLabels: 'Associated Labels',
      ConfirmRemoveTitle: 'Confirm Deployments to Remove',
      EnvironmentNameAlreadyInUse: 'Environment name is already in use. Type to create a new one.',
      EnvironmentRequired: 'Environment is required',
      FilterDeploymentsBy: 'Filter Deployments by...',
      RemoveDeployment: 'Remove Deployment',
    },
    ApplicationDiscovery: {
      Add: 'Add a Discovery Rule',
      ApplicationDefinitionsMessage: 'Application Definitions shown below are discovered by this rule.',
      ApplicationDefinitionsTitle: 'Discovered Application Definitions',
      ConfirmRemove: 'Confirm Discovery Rule to Remove',
      Create: 'Create an Application Discovery Rule',
      Created: 'Application Discovery Rule is created',
      CreateDescription: 'Try creating a discovery rule, it will automatically discover application definitions',
      DeleteConfirm: 'Are you sure you want to remove the selected Discovery Rule?',
      Edited: 'Application Discovery Rule is updated',
      FilterDiscoveryRule: 'Filter Application Discovery Rule',
      NoDiscoveryRulesFound: 'No Discovery Rules Found',
      Placeholder: {
        Name: 'Give your application discovery rule a catchy name',
        Prefix: 'Anything to help you identify the application',
        RuleType: 'Select a rule type',
        SearchOrSelect: 'Search or select',
      },
      ResourceDiscoveryCreate:
        'Application Definitions will be discovered automatically once the discovery rule is created',
      ResourceDiscoveryEdit:
        'Application Definitions will be discovered automatically once the discovery rule is edited',
      Rules: 'Application Discovery Rules',
      RuleWasCreated: '{ruleName} was successfully created and applications are being discovered.',
      RuleWasEdited: '{ruleName} was successfully updated and applications are being discovered.',
      UnableToCreate: 'Unable to create Application Discovery Rule',
      UnableToEdit: 'Unable to edit Application Discovery Rule',
    },
    Applications: {
      Approval: 'Application Approval',
      AutoApprovalSetting: 'Auto Approval Setting',
      FilterApplicationsBy: 'Filter Applications by...',
      Loading: 'Loading Applications...',
      NoApplicationsDataYet: 'No applications data yet',
      ResourcesByDeployment: 'Resources by Deployment',
      ServiceRoles: 'Service Roles',
      UnableToFetchPolicy: 'An error occurred fetching policy',
      ViewApplicationDefinitionDetails: 'View Application Definition Details',
      ViewDiscoveryRuleDetails: 'View Discovery Rule Details',
    },
    AppsIntegrations: {
      Automation: {
        AddAction: 'Add Action',
        AddARuleName: 'Add a Rule Name',
        AddTrigger: 'Add Trigger',
        AndIs: 'and is',
        AuditEventOccurs: 'audit event occurs',
        Delete: 'Remove {count, plural, =1 {Rule} other {# Rules}}',
        Frequency: 'Frequency',
        MonthlyRecurrence: 'Monthly Recurrence',
        RemoveSuccess: '{count, plural, =1 {Rule has} other {# Rules have}} been successfully removed',
        SendAMessage: 'send a message...',
        SendASlackMessage: 'send a Slack message',
        SendASlackTo: 'Send a slack to',
        SendImmediately: 'Send immediately',
        SendLater: 'Send later',
        SendTest: 'Send Test',
        SlackChannel: 'Slack Channel',
        SystemEventOccurs: 'system event occurs',
        Trigger: 'Trigger',
        Triggers: 'Triggers',
        WeeklyRecurrence: 'Weekly Recurrence',
        When: 'When',
        WhenRuleShouldTrigger: 'When rule should trigger',
        YourPolicyHasBeenUpdated: 'Your policy has been updated...',
      },
      LoadingConnectorData: 'Loading connector data',
      S3Bucket: {
        AWSAccount: 'AWS Account',
        ConnectedBy: 'Connected By',
        ConnectionFailed: 'Connection Failed',
        ConnectionSuccessful: 'Connection Successful',
        ConnectModal: {
          ConfirmAndConnect: 'Confirm and Connect',
          ConnectToAWS: 'Connect to AWS',
        },
        ConnectS3Bucket: 'Connect S3 Bucket',
        DataTransferred24H: 'Data Transferred Last 24 Hours',
        DestinationPath: 'Destination Path',
        ExportType: 'Export Type',
        FailedToConnect:
          '{destination} failed to connect due to {message}. No exports will be executed until the S3 Bucket is connected.',
        FilterPlaceholder: 'Filter S3 Buckets by...',
        GoToTheTrafficPageToInitiateExports: 'Go to the traffic page to initiate exports',
        IsSuccessfullyConnected: '{destination} is successfully connected',
        LastTransferred: 'Last Transferred',
        LoadingExportsData: 'Loading exports data...',
        NoData: 'No Connected S3 Buckets',
        NoS3BucketExports: 'No exports to this S3 Bucket',
        Remove: 'Remove S3 Bucket',
        RemoveConfirm: 'Are you sure you want to remove the S3 Bucket?',
        RemovedSuccessfully: 'The following S3 Bucket has been successfully deleted',
        RemoveResultTitle: 'Result of S3 Bucket removal',
        S3BucketARN: 'S3 Bucket ARN',
      },
      Slack: {
        ChannelName: 'Channel Name',
        Channels: 'Channels',
        ConnectJiraSoftwareToAutomateTicketCreation: 'Connect Jira Software to automate ticket creation.',
        ConnectSlackChannel: 'Connect Slack Channel',
        ConnectSlackChannelsToSendNotifications: 'Connect Slack Channels to Send Notifications',
        ConnectYourSlackChannelsToStartSendingSlackNotifications:
          'Connect your slack channels to start sending slack notifications.',
        DeletedSuccessfully: 'Slack channel successfully deleted',
        DocumentationToCreateWebhookUrlForYourChannel: 'Documentation to create webhook URL for your channel',
        Error: 'Something went wrong',
        Webhooks: 'Webhooks',
        WebhookUrl: 'Webhook Url',
      },
    },
    Common: {
      ApplicationsAndDeployments: 'Applications & Deployments',
      ChooseYourIllumioServiceAccountDescAWS:
        'Choose your Illumio service account to grant permission for AWS API calls to access flow logs. Safeguard the service account credentials given by Illumio, as they are essential for configuring the CloudFormation Template (CFT) in AWS.',
      ChooseYourIllumioServiceAccountDescAzure:
        'Choose your Illumio service account to grant permission for Azure API calls to access flow logs. Safeguard the service account credentials given by Illumio, as they are essential for configuring PowerShell Script for Azure.',
      ChooseYourServiceAccount: 'Choose Your Service Account',
      CloudAccounts: 'Cloud Accounts',
      CloudInfrastructure: 'Cloud Infrastructure',
      CloudMetadata: 'Cloud Metadata',
      CloudTag: 'Cloud Tag',
      CloudTagKey: 'Cloud Tag Key',
      CloudTags: 'Cloud Tags',
      DailyTotalsDataProcessed: 'Daily totals of Data Processed',
      DataProcessed: 'Data Processed',
      Deployment: 'Deployment',
      DeploymentAndResources: 'Deployment & Resources',
      Deployments: 'Deployments',
      DiscoveryRule: 'Discovery Rule',
      DiscoveryRules: 'Discovery Rules',
      EventType: 'Event Type',
      IllumioLabelType: 'Illumio Label Type',
      IncidentResponseRoleSublabel: 'Grants permissions to view CloudMap, Inventory, and Traffic',
      Includes: 'Includes',
      Jira: 'Jira',
      KubernetesClusters: 'Kubernetes Clusters',
      KubernetesMap: 'Kubernetes Map',
      LabelAdminRoleSublabel: 'Manages labels and applications. This role grants access to Tag-Label Mapping.',

      LabelTypes: 'Label Types',
      OnboardingSublabel: 'Manages the onboarding process for accounts to the Illumio platform',
      PolicyAuthorSublabel:
        'Manages policies for your organization and all applications. Read-only access to Applications, Labels, Maps, Inventory, and Traffic.',
      PolicyOptimization: 'Policy Optimization',
      Prefix: 'Application Prefix',
      Regions: 'Regions',
      RootAccount: 'Root Account',
      Routes: 'Routes',
      RouteType: 'Route Type',
      RuleName: 'Rule Name',
      RuleTags: 'Cloud Tag Keys',
      RuleType: 'Rule Type',
      SecurityAuditRoleSublabel:
        'Grants permissions to view CloudMap, Inventory, Account Onboarding, Events, Traffic, and Policies',
      SecurityControls: 'Security Controls',
      SecurityControlsAWSBothNACLsandSGs:
        'Cloud Secure programs both NACLs and Security Groups to ensure proper flow of traffic.',
      SecurityControlsAWSEnforcementPoint: 'Both NACLs and SGs',
      SecurityControlsAWSOnlyNACLs:
        'Cloud Secure will not program Security Groups. These configurations will need to be managed elsewhere to ensure proper traffic flow.',
      SecurityControlsAWSOnlySGs:
        'Cloud Secure will not program Network Access Control List (NACLs). These configurations will need to be managed elsewhere to ensure proper traffic flow.',
      SecurityControlsAWSRadio2: 'Only Network Access Control Lists (NACLs)',
      SecurityControlsAWSRadio3: 'Only Security Groups',
      SecurityControlsAzureBothSubnetandNICNSGs:
        'Cloud Secure programs both Subnet and NIC level Network Security Groups to ensure proper flow of traffic.',
      SecurityControlsAzureDescription:
        'Cloud Secure programs only Subnet NSGs. NIC level NSGs will need to be managed elsewhere.',
      SecurityControlsAzureOnlyNICNSGs:
        'Cloud Secure will not program Subnet NSGs. These configurations will need to be managed elsewhere to ensure proper traffic flow.',
      SecurityControlsAzureOnlySubnetNSGs:
        'Cloud Secure will not program NIC NSGs. These configurations will need to be managed elsewhere to ensure proper traffic flow.',
      SecurityControlsAzureRadio1: 'Both Subnet and NIC NSGs',
      SecurityControlsAzureRadio2: 'Only Subnet NSGs',
      SecurityControlsAzureRadio3: 'Only NIC NSGs',
      // SecurityControlsOCIBothNACLsandNICNSGs:
      // 'Cloud Secure programs both NACLs and NIC NSGs to ensure proper flow of traffic.',
      SecurityControlsDefault: 'DEFAULT',
      SecurityControlsEditSecurityControls: 'Edit Security Controls',
      SecurityControlsEnforcementPoint: 'Enforcement Point:',
      SecurityControlsEnforcementPoints: 'Enforcement Points',
      SecurityControlsLoading: 'Loading...',
      SecurityControlsName: 'Name:',
      SecurityControlsNetworkSecurityGroupsTab: 'Network Security Groups',
      SecurityControlsNIC: 'nic',
      SecurityControlsNICSubnet: 'nic-subnet',
      SecurityControlsSubnet: 'subnet',
      SecurityControlsViewSecurityControls: 'View Security Controls',
      SecurityControlsWarning:
        'Cloud Secure recommends maintaining default settings for Subnet and NIC level NSGs in Azure and Network ACLs in AWS for optimal security',
      SecurityControlsWarningError:
        'Modifying the security control options may potentially have an impact on traffic flows until the changes take effect',
      Slack: 'Slack',
      TagsAndLabels: 'Labels And Tags',
      UsageMetricsNotAvailable: 'Usage Metrics not available',
      VNetsSubnets: 'Virtual Networks and Subnets',
    },
    Dashboard: {
      AWSResourcesTile: {
        Modal: {
          Compute: 'Compute',
          Database: 'Database',
          Gateways: 'Gateways',
          Instructions: 'Select resource categories to display in the dashboard. Maximum 5 categories.',
        },
        ResourceCategory: 'Resource Category',
      },
      FlowSummaryTile: {
        Header: 'Traffic Flow Summary',
      },
      LoadError: 'There was an unexpected error fetching information for the dashboard. Please try again later.',
      OnboardingSummaryTile: {
        Header: 'Onboarding Overview',
        ReadAndWriteAccessHeader: 'Read and Write Access',
      },
      SecurityReviewTile: {
        Title: 'Illumio Zero Trust Segmentation Review',
      },
      Top5byNumberOfDaysSinceTrafficWasLastSeen:
        'Top 5 by number of days since traffic was last seen. (30 days maximum)',
      ViewFullList: 'View full list',
      WelcomeBannerText: 'Add organizations or accounts to manage <a></a>your cloud resources',
    },
    EventLogs: {
      Message: 'Message',
    },
    Events: {
      AdditionalData: 'Additional Data',
      Audit: 'Audit Events',
      AuditDetailMessage: '{category} event {eventType} occurred at {occurredAt} by {user}',
      AuditEventIs: 'Audit event is',
      FilterAuditEvents: 'Filter Audit Events',
      FilterSystemEvents: 'Filter System Events',
      LoadingAuditEvents: 'Loading Audit Events...',
      NoAuditEvents: 'No Audit Events found',
      SystemEventIs: 'System event is',
    },
    ExternalResources: {
      EntityId: 'Entity ID',
      ExternalResource: 'External Resource',
      ExternalResources: 'External Resources',
      ExternalTenantId: 'External Tenant ID',
      FilterExternalResourcesBy: 'Filter External Resources by...',
      ResourceId: 'Resource ID',
      TypeAnResourceID: 'Type an External Resource ID',
    },
    FlowLog: {
      AccessHasBeenSuccessfullyGranted:
        'Access has been successfully granted for the {csp} Account ID: {accountCspId}.',
      AccessIsGrantedFor: 'Access is Granted for Destination "{destination}"',
      ByLogDestinationAccount: 'By Log Destination Account',
      ByLogSourceAccount: 'By Log Source Account',
      CheckingAccess: 'Checking access ...',
      ClickRunCFTTooltip:
        "Click 'Run CFT' to allow Illumio access to the S3 buckets, where your flow logs are stored. You'll be directed to AWS to create a CFT stack. Ensure you have the necessary AWS permissions.",
      ClickTheCopyIconToCopyTheScript:
        'Click the copy icon to copy the script. Then, execute it in Azure to grant access.',
      CloudFormationStackExpiryMessage:
        'The cloud formation template is available to download/run for next 15 minutes. After 15 minutes, you will have to re-trigger the grant flow access to generate the template again.',
      CopyDestination: 'Copy Destination',
      CopyPowerShellScript: 'Copy PowerShell Script',
      DestinationAccountID: 'Destination Account ID',
      DownloadCFT: 'Download CFT',
      DownloadCFTFileAndManuallyRunItInAWS: 'Download CFT file & manually run it in AWS',
      DownloadTheCFTTooltip:
        'Download the CFT file to manually enter your S3 bucket details before running it in AWS. This option is for advanced users familiar with AWS configurations.',
      ErrorForAccount: '{error}. For Destination "{destination}"',
      FlowLogAccess: 'Flow Log Access',
      FlowLogID: 'Flow Log ID',
      FlowLogSourcesThatBelongToADifferentAccount: 'Flow Log sources that belong to a different account',
      FlowLogSourceThatBelongToThisAccount: 'Flow Log sources that belong to this account',
      FlowLogsThatAreGoingToADifferentAccount: 'Flow Logs that are going to a different account',
      FlowLogsThatAreGoingToThisAccount: 'Flow Logs that are going to this account',
      GoToAzure: 'Go to Azure',
      GoToAzureTooltip:
        "Click 'Go to Azure' to be taken to your Azure environment, where you can paste and run the script. Ensure you have the necessary permissions in Azure to execute this action.",
      GrantAccessToCSPFlowLogs: 'Grant Access to {csp} Flow Logs',
      LogDestination: 'Log Destination',
      LogSource: 'Log Source',
      NoFlowLogDataYet: 'No Flow Log data yet',
      OptionsToEnablePermissions: 'Options to enable permissions',
      PleaseSelectAtLeastOneItemToGrantAccess: 'Please select at least one item to grant access',
      RunCFT: 'Run CFT',
      RunCFTNowInAWS: 'Run CFT Now in AWS (Recommended)',
      SearchFlowLogsBy: 'Search Flow Logs by...',
      SelectedS3Buckets: 'Selected S3 Buckets',
      SelectedStorageAccounts: 'Selected Storage Accounts',
      SourceAccountID: 'Source Account ID',
      StatusInCsp: 'Status in CSP',
      TestAccess: 'Test Access',
      ThisButtonIsDisabledBecauseYouHaveFullAccess: 'This button is disabled because you have full access',
      ThisButtonIsDisabledBecauseYouHaveToGrantAccessFirst:
        'This button is disabled because you have to grant access first',
      ThisCheckboxIsDisabledBecauseAccessIsGrantedForThisItem:
        'This checkbox is disabled because access is granted for this item',
    },
    Insights: {
      AllTraffic: 'All Traffic',
      CloudConfigurationInsights: {
        CrossTalkingPeeringConnections: 'Cross Talking Peering Connections',
        CrossTalkingPeeringConnectionsDescription:
          'This provides a list of all the peering connections in AWS and Azure cloud that allow cross-connectivity across cloud regions and accounts. It helps in understanding the peering connections in environment that allows cross region, account, or tenant traffic.',
        InternetExposedEC2Instances: 'Internet Exposed EC2 Instances',
        InternetExposedEC2InstancesDescription:
          'This reports all EC2 instances in AWS with external connectivity through internet-facing load balancers, identifying potentially exposed resources and offering critical insights for securing these connections.',
        TrafficBlindspots: 'Traffic Blindspots',
        TrafficBlindspotsDescription:
          'This provides an overview of areas where you may not have network visibility. The report lists all VPCs and NSGs where flow logs are not enabled from the onboarded accounts (AWS) and/or subscriptions (Azure).',
        UnprotectedResources: 'Unprotected Resources',
        UnprotectedResourcesDescription:
          'This provides a list of resources that do not have security controls attached to it. Specifically, resources in Azure that do not have NSG attachments at any level- NIC/Subnet, and resources in AWS that aren’t deployed in a VPC boundary.',
      },
      CombinedTooltip: 'All Traffic includes both allowed and denied traffic',
      ConfigurationPanel: {
        AlignToCalendarDays: 'Align to calendar days',
        AlignToCalendarDaysTooltip:
          'When checked, this option will align the selected date ranges to full calendar days (midnight to midnight)',
        ComparedTo: 'Compared to',
        CompareTo: 'Compare to',
        ShowMeTraffic: 'Show me traffic {suffix}',
        Timeframe: 'Timeframe',
        TimeframeComparison: 'Timeframe Comparison',
        TimeframeSubtitle: 'Select the traffic timeframe(s) that you would like to see and compare.',
        Title: 'Insight Configuration',
        YourSelection: 'Your Selection',
      },
      LoadingInsightData: 'Loading insights data',
      NoData: 'No data available',
      RiskyPortsOnly: 'Risky Ports Only',
      ShowMore: 'Show more...',
      TrafficType: 'Traffic Type',
    },
    Integration: {
      AccountIdValidation: 'Account ID must be 12 characters long and numeric',
      Accounts: {
        Disable: 'Disable {count, plural, =1 {{accountType}} other {{accountType}s}}',
        DisableConfirm:
          'Are you sure you want to disable the {count, plural, =1 {{accountType}} other {{accountType}s}}?',
        DisabledSuccessfully:
          'The following {count, plural, =1 {{accountType} has} other {# {accountType}s have}} been successfully disabled',
        DisableResultTitle: 'Result of {count, plural, =1 {{accountType}} other {{accountType}s}} disablement',
        DisableWarning: 'All associated resources and traffic data will remain stored but syncing will stop',
        Enable: 'Enable {count, plural, =1 {{accountType}} other {{accountType}s}}',
        EnableConfirm:
          'Are you sure you want to enable the {count, plural, =1 {{accountType}} other {{accountType}s}}?',
        EnabledSuccessfully:
          'The following {count, plural, =1 {{accountType} has} other {# {accountType}s have}} been successfully enabled',
        EnableResultTitle: 'Result of {count, plural, =1 {{accountType}} other {{accountType}s}} enablement',
        Remove: 'Remove {count, plural, =1 {{accountType}} other {{accountType}s}}',
        RemoveConfirm:
          'Are you sure you want to remove the {count, plural, =1 {{accountType}} other {{accountType}s}}?',
        RemoveResultTitle: 'Result of {count, plural, =1 {{accountType}} other {{accountType}s}} removal',
      },
      AccountTypeDeployment: '{type} Deployment',
      AddAWS: 'Add AWS',
      AddAzure: 'Add Azure',
      AddOCI: 'Add OCI',
      AzureClientID: 'Azure Client ID',
      CFStack: 'Cloud Formation Stack',
      CICDEnabled: 'CI/CD',
      CICDIntegration: 'CI/CD Integration',
      CICDSettingInfo:
        'When enabled, Illumio CloudSecure will auto-discover the deployments and applications that have been created in the associated AWS account. Using Application Discovery, CloudSecure will sync with AWS CodeDeploy to ensure these definitions are always up to date.',
      CICDSettingsTooltip: 'CI/CD Settings are {ciCDEnabled} for the Organization',
      CodeDeploy: 'CodeDeploy',
      CompletedNoErrors: 'Completed, No Errors',
      ConnectCSPAccount: 'Add {csp} Cloud {label}',
      Connector: 'Connector',
      Connectors: 'Connectors',
      ConnectToCSP: 'Connect to {csp}',
      DeploymentScriptCompleted: 'PowerShell Script Uploaded',
      DeploymentSnippetCompleted: 'Azure Deployment Completed',
      DeployScriptAzure: 'Download the PowerShell script and upload it in Azure Cloud Shell',
      DisableCICD: 'Disable CI/CD',
      DownloadPermissions: 'Download Permissions',
      DownloadPowerShellScript: 'Download PowerShell script',
      EnableCICD: 'Enable CI/CD',
      EnableIllumioQuestion: 'Illumio has Read and Write access to ensure compliance',
      EnableSyncedSubscriptions: 'Onboard all subscriptions in the tenant',
      EnableSyncedSubscriptionsHelpText:
        'No resources from the account/subscription are synced automatically if the setting is set to Off',
      ExcludedRegions: 'Excluded Regions',
      ExcludedSubnets: 'Excluded Subnet IDs',
      ExcludedVNETs: 'Excluded VNET IDs',
      ExcludedVPCs: 'Excluded VPC IDs',
      IAMRolesOnAWS: 'IAM Roles on AWS',
      IllumioCloudTenantID: 'Illumio Cloud Tenant ID',
      LoadError: 'There was an unexpected error fetching information for the onboarding. Please try again later.',
      LoadingAccounts: 'Loading accounts',
      Monitor: 'Monitor',
      MonitorProtect: 'Monitor and Protect',
      NoAccountsFound: 'No accounts found',
      OCI: {
        APIFingerprint: 'API Fingerprint',
        Compartment: 'Compartment',
        DeploymentFileCompleted: 'Terraform script was successfully run',
        DestinationPorts: 'Destination Ports',
        DownloadTerraformFile: 'Download Terraform File',
        GroupName: 'Group Name',
        HomeRegion: 'Home Region',
        InvalidOCID: 'Must be a valid {prefix} OCID',
        OCID: 'OCID',
        RootTenancyCompartmentOCID: 'Root Tenancy/Compartment OCID',
        SelectedBuckets: 'Selected Buckets',
        SourcePorts: 'Source Ports',
        Subcompartments: 'Subcompartments',
        UserOCID: 'User OCID',
      },
      ReadWriteAccess: 'Read Write Access',
      Remove: 'Remove {count, plural, =1 {Integration} other {Integrations}}',
      RemoveConfirm: 'Are you sure you want to remove the {count, plural, =1 {Integration} other {Integrations}}?',
      RemovedSuccessfully:
        'The following {count, plural, =1 {Integration has} other {# Integrations have}} been successfully deleted',
      RemoveIndividual: 'Remove {name}?',
      RemoveIndividualConfirm: 'Are you sure you want to remove the {csp} {type}?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Integration} other {Integrations}} removal',
      RemoveWarning: 'All associated resources and traffic data will be removed',
      RunCommandAzure: 'Run the following command in Azure',
      SaveAndConfirm: 'Save and Confirm',
      SaveAndConfirmDisclaimer: 'Save and Confirm will begin the resource ingestion for the Cloud',
      SearchAppPolicyBy: 'Search Application Policies by...',
      SearchIntegrationsBy: 'Search Integration by...',
      SearchIPListBy: 'Search IP Lists by...',
      SearchOrgPolicyListBy: 'Search Organization Policies by...',
      SearchServicesListBy: 'Search Services List by...',
      SecureAWSAccount: 'Secure a specific AWS account',
      SecureAWSAccounts: 'Secure an AWS {type} including all its member accounts',
      SecureAzureSubscription: 'Secure an Azure Subscription',
      SecureAzureTenant: 'Secure an Azure Tenant',
      SelectServiceAccount: 'Select Service Account to use',
      ServiceProvider: 'Service Provider',
      SetUpAccess: 'Set up Access',
      SubscriptionId: 'Subscription ID',
      TenantId: 'Tenant ID',
      TypeANameForTheIntegration: 'Type a name for the {type}',
      TypeFieldName: 'Type in the {fieldName}',
      TypeIntegrationID: 'Type in the {type} ID',
      TypeIntegrationName: 'Type in the Integration Name',
      TypeOfAccount: 'Type of Account',
      TypeOfIntegration: 'Type of Integration',
      TypeServiceAccountToken: 'Type in the Service Account Token',
      UnableToGetFile: 'Unable to get correct template file. Please try again later.',
      UnableToGetTemplate: 'Unable to get correct template link',
      UpdatePermissionsAzure: 'Run the following command in Azure to update permissions',
    },
    Inventory: {
      AllInventory: 'All inventory',
      CloudDetail: 'Cloud Detail',
      CloudResources: 'Cloud Resources',
      CurrentVPCVNet: 'Current VPC/VNET',
      FilterInventoryBy: 'Filter Inventory by...',
      IsShared: 'Is Shared',
      KubernetesResources: 'Kubernetes Resources',
      LabelsAndCloudTags: 'Labels and Cloud Tags',
      LoadingResources: 'Loading Inventory Resources...',
      NoInventoryDataYet: 'No inventory data',
      NoRulesAvailable: 'No rules available',
      PolicyRules: {
        EmptyPolicyRules: 'No Policy Rules',
        LoadingPolicyRules: 'Loading Policy Rules...',
        NatRules: 'NAT Rules',
        NetworkRules: 'Network Rules',
        PolicyRules: 'Policy Rules',
        Protocols: 'Protocols',
        RuleCollection: 'Rule Collection',
        RuleCollectionGroup: 'Rule Collection Group',
        RuleCollectionPriority: 'Rule Collection Priority',
        TlsInspection: 'TLS Inspection',
        TranslatedEntity: 'Translated Entity',
        TranslatedPort: 'Translated Port',
      },

      ResourceGraph: 'Resource Graph',
      ResourceGroup: 'Resource Group',
    },
    IpLists: {
      Delete: 'Remove {count, plural, =1 {Cloud IP List} other {# Cloud IP Lists}}',
      DeleteConfirm: 'Are you sure you want to remove this Cloud IP List?',
      EndpointError: 'There was an unexpected error fetching IP Lists. Please try again later.',
      Remove: 'Remove Cloud IP List',
      RemoveConfirm: 'Are you sure you want to remove the selected Cloud IP {count, plural, =1 {List} other {Lists}}?',
      RemovedSuccessfully:
        '{count, plural, =1 {Cloud IP List has} other {# Cloud IP Lists have}} been successfully removed',
      RemoveResultTitle: 'Result of {count, plural, =1 {Cloud IP List} other {Cloud IP Lists}} removal',
    },
    KnownNetworks: {
      AddAttributeType: 'Add an attribute type',
      AddEndpointError: 'There was an unexpected error adding the Known Network. Please try again later.',
      AddManually: 'Add Manually',
      AddManuallyTitle: 'Add Known Network',
      BulkImport: 'Bulk Import',
      BulkImportPages: {
        AddToKnownNetworks: 'Add to Known Networks',
        BackToKnownNetworkList: 'Back to Known Network List',
        DefaultError: 'An unexpected error occurred during the file upload. Please check your file and try again.',
        ErrorFileType: 'The file you uploaded is not a valid file type. Please upload a valid csv file and try again.',
        ErrorIncorrectIpAddresses: 'Incorrect IP Addresses',
        ErrorIncorrectIpAddressesExtended:
          'The CSV file contains incorrect IP addresses. Please ensure all IP addresses are in correct and re-upload the file.',
        ErrorMissingFields: 'Missing Fields',
        ErrorMissingFieldsExtended:
          'The uploaded file is missing required fields. Please check your file and ensure all necessary fields are included before uploading again.',
        FileUploadError: 'File upload error',
        ImportedListReady: 'Your imported list is ready. Review and add it to Known Networks.',
        StartImportingKnownNetworkLists: 'Start importing your Known Network lists in bulk by uploading a csv file.',
        TemplateCsv: 'template csv',
        UserProvidedTemplate:
          'Use the provided {csvTemplate} to format your file prior to upload, and upload one file for a successful import.',
      },
      Delete: 'Remove {count, plural, =1 {Known Network} other {# Known Networks}}',
      EndpointError: 'There was an unexpected error fetching Known Networks. Please try again later.',
      FilterPlaceholder: 'Filter Known Networks by...',
      KnownNetwork: 'Known Network',
      NoData: 'No known networks to display',
      Remove: 'Remove Known Network',
      RemoveConfirm:
        'Are you sure you want to remove the selected {count, plural, =1 {Known Network} other {Known Networks}}?',
      RemovedSuccessfully:
        '{count, plural, =1 {Known Network has} other {# Known Networks have}} been successfully removed',
      RemoveIndividualConfirm: 'Are you sure you want to remove the Known Network?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Known Network} other {Known Networks}} removal',
    },
    KubernetesClusters: {
      Clusters: {
        HealthStatus: 'Health Status',
        KubernetesVersion: 'Kubernetes Version',
        LastConnection: 'Last Connection',
        ManagedBy: 'Managed by',
        Nodes: 'Nodes',
        Offboard: 'Off-board',
        OffboardClusters: {
          Offboard: 'Off-board {count, plural, =1 {Kubernetes Cluster} other {Kubernetes Clusters}}',
          OffboardConfirm:
            'Are you sure you want to off-board the {count, plural, =1 {Kubernetes Cluster} other {Kubernetes Clusters}}?',
          OffboardedSuccessfully:
            'The following {count, plural, =1 {Kubernetes Cluster has} other {# Kubernetes Clusters have}} been successfully off-boarded',
          OffboardResultTitle: 'Result of Kubernetes Clusters off-boarding',
        },
        OnboardingCredentialPlaceholder: 'Select an onboarding credential',
        OnboardingInstruction:
          'Please use the downloaded Helm values file when you create the onboarding credential. Otherwise, you have to create a new onboarding credential.',
        Pods: 'Pods',
        SectionSubtitle: 'Here are all managed Kubernetes clusters in the {illumioRegion} region.',
        SectionTitle: '{illumioRegion} Kubernetes Clusters',
      },
      ManagedKubernetesClusters: 'Managed Kubernetes Clusters',
      NoClustersOnboarded: 'No Kubernetes clusters to display',
      OnboardCluster: 'Onboard Cluster',
      OnboardClusterLandingMessage:
        'To onboard Kubernetes cluster, an onboarding credential is required. There is no available onboarding credential yet, please create one first.',
      OnboardClustersOtherIllumioRegions: 'Onboard clusters in other Illumio regions here.',
      OnboardClusterSubtitle: 'Please follow instructions below to onboard cluster',
      OnboardingCredentials: {
        AddOnboardingCredential: 'Add onboarding credential',
        AddOnboardingCredentialModal: {
          ConfigureHelmConnectCluster: 'Configure Helm to connect to the cluster',
          ConfirmDeploymentPairing: 'Confirm deployment and pairing by running the command',
          DeployIllumioCloudSecureOperator:
            'Deploy Illumio CloudSecure Operator into the cluster by running the command',
          DescriptionPlaceholder: '(Optional) Type in credential description',
          DownloadHelmValuesFile: 'Download the Helm values file',
          FollowOnboardingInstructions: 'Please follow instructions step-by-step to pair cluster.',
          ModalTitle: 'Create New Onboarding Credential',
          NamePlaceholder: 'Give your onboarding credential a name',
          OnboardClusterInstructions: 'Onboard Cluster Instructions',
          OnboardingCreatedMessage:
            "Onboarding credential is created. Please follow instructions step-by-step to onboard cluster. Please download Helm values file now, you wouldn't be able to download it after your close this page.",
          OnboardingCredential: 'Onboarding Credential',
          OnboardingCredentialName: 'Onboarding Credential Name',
        },
        ConfirmOnboardingCommand: 'Confirm Onboarding Command',
        CopyOnboardingCommand: 'Copy Onboarding Command',
        LastTimeUsed: 'Last Time Used',
        NoDataMessage: 'No onboarding credential to display',
        OnboardingClusterCommand: 'Onboarding Cluster Command',
        OnboardingCredentialRequiredMessage:
          'To onboard Kubernetes cluster, an onboarding credential is required. There is no available onboarding credential in {illumioRegion} region yet, please add one first.',
        OnboardingCredentialRequiredTitle: 'Onboarding credential required',
        RemoveMultipleOnboardingCredentials: {
          Remove: 'Remove {count, plural, =1 {Onboarding Credential} other {Onboarding Credentials}}',
          RemoveConfirm:
            'Are you sure you want to remove the {count, plural, =1 {Onboarding Credential} other {Onboarding Credentials}}?',
          RemovedSuccessfully:
            'The following {count, plural, =1 {Onboarding Credential has} other {# Onboarding Credentials have}} been successfully deleted',
          RemoveResultTitle: 'Result of Onboarding Credentials removal',
        },
        RemoveSingleOnboardingCredential: {
          Remove: 'Remove Onboarding Credential',
          RemoveConfirm: 'Are you sure you want to remove the Onboarding Credential?',
          RemovedSuccessfully: 'The following Onboarding Credential has been successfully deleted',
          RemoveResultTitle: 'Result of Onboarding Credential removal',
        },
        SectionSubtitle:
          'Onboarding credential is used to authenticate Illumio Cloud Operators when onboarding Kubernetes clusters.',
        SectionTitle: 'Onboarding Credentials',
      },
      OnboardOtherIllumioRegions: 'Onboard other Illumio regions',
      PageDescription:
        'Here are all your managed clusters grouped in Illumio Regions. An Illumio Region is a designated cloud region where onboarded Kubernetes clusters connect for enhanced visibility and control. Select the nearest Illumio Region to each cluster to optimize performance and security. You can use a single onboarding credential to onboard multiple clusters within the selected Illumio Region.',
      TotalKubernetesClusters: 'Total Kubernetes Clusters',
    },
    LabelMapping: {
      AddMapping: 'Add Mapping',
      AddMappingSubtitle:
        'Apply filters on the left and map selected cloud tag keys to an Illumio label type on the right.',
      AILabel: 'AI Recommended Label',
      AILabelsNote: 'AI labels are currently supported for AWS resources in US only regions.',
      AIWarningTooltip: 'The AI Recommended Label will override an existing role label for this resource',
      ApplicationResources: 'Application Resources',
      ApprovalTime: 'Approval Time',
      ApprovedBy: 'Approved By',
      ConfirmAndAdd: 'Confirm & Add',
      ConfirmAndSave: 'Confirm & Save',
      ConfirmApproval: 'Confirm Approval',
      ConfirmRemoveTitle: 'Confirm Tags to Label Mappings to Remove',
      CurrentLabel: 'Current Label',
      DeniedBy: 'Denied By',
      DeniedTime: 'Denied Time',
      EditMapping: 'Edit Mapping',
      Error: 'There was an error adding label mapping. Please try again.',
      ErrorEncounteredMessage: 'An error was encountered while applying your decision. Please try again.',
      FilterAppResources: 'Filter Application Resources',
      FilterLabelMappingsBy: 'Filter Label Mappings by...',
      FilterNonAppResources: 'Filter Non-Application Resources',
      LabelKeyAlreadyInUse: 'Label key is already in use.',
      LabelNameAlreadyInUse: 'Label name is already in use.',
      LabelsApprovedCount: '{count} Labels Approved',
      LabelsApprovedMessage:
        'Labels have been successfully applied to all selected resources. Update your filters to view the latest changes.',
      LabelsDeniedCount: '{count} Labels Denied',
      LabelsDeniedMessage:
        'Labels have been successfully denied for all selected resources. Update your filters to view the latest changes.',
      LabelWarning:
        'The “Role” label is currently set through “Tag to Label Mapping,” a feature that automatically assigns labels based on existing tags. Please review and choose whether to keep the current label or apply the AI-recommended label.',
      LoadingRecommendations: 'Loading recommendations...',
      Mapping: 'Mapping',
      MapsTo: 'Maps to',
      MapsToIllumioLabelType: 'Maps to Illumio Label Type',
      NoLabelMapsFound: 'No label maps found',
      NonApplicationResources: 'Non Application Resources',
      NoRecommendationsFound: 'No recommendations found',
      OverrideLabel: 'Override any existing labels with AI Recommended Labels',
      PendingApprovalCount: '{count} Pending Approval',
      RecommendationReason: 'Recommendation Reason',
      RecommendationReasonMessage:
        'This label is recommended based on the resource’s attributes, usage patterns, and its similarity to other labeled resources in your environment.',
      RecommendedTime: 'Recommended time',
      ReviewApplicationResource: 'Review and approve labels for your application resources',
      ReviewNonApplicationResources: 'Review and approve labels for non-application cloud resources.',
      SelectCTK: 'Select Cloud Tag Keys',
      SelectedCTK: 'Selected Cloud Tag Keys ({count})',
      SelectedCTKMessage: 'Tag Keys you add will show up here',
      TagKeys: 'Tag Keys',
    },
    Labels: {
      AddCloudAccounts: 'Add Cloud Accounts',
      AddCloudTags: 'Add Cloud Tags',
      AddDeploymentStacks: 'Add Deployment Stacks',
      AddFirstDeployment: 'Add First Deployment',
      AddRegions: 'Add Regions',
      AddSubnets: 'Add Subnets',
      AddVirtualNetworks: 'Add Virtual Networks',
      ApplicationDefinitions: 'Application Definitions',
      ApplicationDefinitionsLandingPageInstructions:
        'To get started, Illumio needs to understand your Cloud Deployments and Applications. Begin by adding your first application definition',
      ApplicationDiscovery: 'Application Discovery',
      AWSServiceRoleLabels: 'Service Role Labels (AWS)',
      AzureServiceRoleLabels: 'Service Role Labels (Azure)',
      CategoryLabel: 'Service Category Label',
      DeploymentLandingPageInstructions:
        'To get started, Illumio needs to understand your Cloud Deployments and applications. Begin by adding your first deployment',
      DeploymentStacks: 'Deployment Stacks',
      DeploymentStacksInstructions:
        'Deployment Stacks can include any of these- One or more Cloud Accounts, Regions, Availability Zones, Virtual Networks, Subnets or Cloud Tags (environment cloud tags or otherwise). A Deployment stack is used to discover application topologies and apply policies',
      EditWarning:
        'If the deployment is in use, any edits may change the deployments discovered for applications (where applicable).',
      FilterLabelsBy: 'Filter Labels by...',
      Labeling: 'Labeling',
      NoDeploymentsFound: 'No deployments found',
      NoDeploymentStacksToDisplay: 'No Deployment Stacks to display',
      NoLabelsDataYet: 'No Labels data yet',
      NoSystemGeneratedLabelsFound: 'No System Generated Labels Found',
      SelectEnvironmentLabel: 'Select or type in an environment label to create one',
      SystemGeneratedLabels: 'System Generated Labels',
      SystemRolesMessage:
        'This list maps system generated Illumio Labels that are applied to Inventory Resources based on Cloud Service Roles and Cloud Service Categories',
      SystemRules: 'System Rules',
      TagToLabelMapping: 'Tag to Label Mapping',
      VirtualNetworks: 'Virtual Networks',
    },
    Map: {
      All: 'all',
      Annotate: 'Annotate',
      Any: 'any',
      ChooseToAnnotate: 'Choose labels or tags to annotate on the map',
      CollapseGroup: 'Collapse Group',
      Contain: 'contain',
      DoNotContain: 'do not contain',
      ExpandAllGroups: 'Expand All Groups',
      ExpandGroup: 'Expand Group',
      FilterCloudResourcesBy: 'Filter Cloud Resources by...',
      GoBackToGeoMap: 'Go Back to Geo Map',
      GoToInfrastructureMap: 'Go to Infrastructure Map',
      LoadingCloudMap: 'Loading Cloud Map resources and traffic...',
      MapConfigurations: 'Map Configurations',
      NoCrossRegionTrafficAvailable: 'No cross region traffic was detected in the last 24 hours',
      NoResourceAvailable: 'No resource available to display on the map',
      NoResourceFound: 'No Resource found.',
      NoTrafficDataAvailable: 'No traffic data available for the resources within the selected time range.',
      PleaseAddFilters: 'Please add filters to view the map.',
      PleaseRefineFilters: 'Please refine the filters.',
      RegionPanelDataSetTooLarge: 'The data set is too large to display fully. Please refine your filters.',
      ResourcesAndRelationships: 'Resources and Relationships',
      ResourcesAnnotated: 'Resources Annotated',
      ResultsArePartial: 'The {type} results are partial; the map currently displays only {limit} {type}s.',
      SelectAccountToViewSummary: 'Select an account to view its summary',
      ShowPeeredVPCorVNet: 'Show Peered VPC/VNET',
      TotalLabelsInApplication: 'Total Labels in Application',
      TotalTagsInApplication: 'Total Tags in Application',
      TrafficNotAvailable: 'Traffic Not Available',
      UnknownIPs: 'Unknown IPs',
    },
    Policy: {
      AddPolicy: '{isCreate, select, true {Add} false {Edit}} Organization Policy',
      ApplicationPolicies: 'Application Policies',
      ApplicationPolicy: 'Application Policy',
      AzureNsgs: 'Azure NSGs',
      BackToPolicy: 'Back to Policy',
      EmptyMessage:
        'There are no inherited rules from {isAppPolicy, select, true {Application} false {Organization}} Policies',
      InheritedRules: 'Inherited Rules',
      OrganizationPolicies: 'Organization Policies',
      OverlappingRulesHint: 'This rule is not in compliance.<br/>Click to view relevant rules',
      OverlappingRulesTitle: '{name} - Out of Compliance',
      PolicyNotAppliedRO: 'Policy not applied. Read-only account',
      RemoveConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Policy} other {Policies}}?',
      RemoveSuccess: '{count, plural, =1 {Policy has} other {# Policies have}} been successfully removed',
      TypeNameForNewPolicy: 'Type a name for the new policy',
      ViewInheritedRules: 'View Inherited Rules',
      ViewInheritedRulesHint:
        'This policy has inherited rules from</br>Organizational policies and/or other</br>Application policies',
    },
    PolicyAttributes: 'Policy Attributes',
    PolicyImpact: {
      AllSecurityControls: 'All Security Controls',
      AwsSecurityGroups: 'AWS Security Groups',
      FilterImpactResourcesBy: 'Filter Impact Resources by...',
      GoToPolicy: 'Go to Policy',
      IllumioAuthored: 'Illumio Authored',
      ImpactOfPolicy: 'Impact of Policy',
      LoadingImpactResources: 'Loading Impact Resources...',
      NumberOfProtectedResources: 'Number of Protected Resources',
      ReadOnlyText: 'This security control belongs to a read only Cloud Accounts. No policy changes will be applied.',
      RuleNumber: 'Rule number',
    },
    Provision: {
      ConfirmAndRevert: 'Confirm & Revert',
      NoPendingDataYet: 'No pending items yet',
      ProvisionInfo: 'The recalculated policy will be sent to impacted security controls when you provision',
    },
    Reports: {
      AllAppDefinitions: 'All application definitions',
      AllAuditEvents: 'All audit events',
      AllSystemEvents: 'All system events',
      AppDefinitionsConfiguration: 'Application Definitions Configuration',
      AppDefinitionsReport: 'Application Definitions Report',
      ApplicationsReport: 'Applications Report',
      AuditEventConfiguration: 'Audit Event Configuration',
      AuditReport: 'Audit Report',
      BackToSchedules: 'Back to Schedules',
      BulkRemovalError: 'Unable to remove {reportName} report',
      Error: 'An error occurred generating a report',
      ErrorDisablingSchedule: 'An error occurred disabling a schedule',
      ErrorEnablingSchedule: 'An error occurred enabling a schedule',
      FilterReportsBy: 'Filter reports by',
      GeneratedReports: 'Generated Reports',
      GenerateReportNow: 'Generate report now',
      IncludeTopConversations: 'Include Top Conversations',
      IncludeTopSourcesTopDestinations: 'Include Top Sources & Top Destinations',
      InsightsConfiguration: 'Insights Configuration',
      InsightsConfigurationDisclaimer:
        'Exported reports only apply filters listed in the configuration section; other filters like Source and Destination are excluded.',
      InsightsReport: 'Insights Report',
      InventoryConfiguration: 'Inventory Configuration',
      InventoryConfigurationCount:
        'Currently, {count, plural, =1 {# resource} other {# resources}} match your selected filters.',
      InventoryReport: 'Inventory Report',
      LastGenerated: 'Last Generated',
      LoadingReports: 'Loading Reports...',
      LoadingReportSchedules: 'Loading Report Schedules...',
      NextRun: 'Next run: {time}',
      NoReportSchedulesFound: 'No Report Schedules Found',
      NoReportsFound: 'No Reports Found',
      RecurrenceDaily: '{includesRecurrence, select, true {Recurrence: } other {}}Daily at {time}',
      RecurrenceMonthly: '{includesRecurrence, select, true {Recurrence: } other {}}Monthly on {day} at {time}',
      RecurrenceMonthlyDayOfMonth:
        '{includesRecurrence, select, true {Recurrence: } other {}}Monthly on {dayOfMonth} day at {time}',
      RecurrenceQuarterly: '{includesRecurrence, select, true {Recurrence: } other {}}Quarterly at {time}',
      RecurrenceWeekdays: '{weekdayList} and {weekdayName}',
      RecurrenceWeekly: '{includesRecurrence, select, true {Recurrence: } other {}}Weekly on {days} at {time}',
      RemoveReports: 'Remove {count, plural, =1 {Report} other {Reports}}',
      RemoveReportSchedule: 'Remove {count, plural, =1 {Report Schedule} other {Report Schedules}}',
      RemoveReportScheduleConfirmation:
        'Are you sure you want to remove the selected {count, plural, =1 {Report Schedule} other {Report Schedules}}?',
      RemoveReportsConfirmation:
        'Are you sure you want to remove the selected {count, plural, =1 {Report} other {Reports}}?',
      RiskConfiguration: 'Risk Configuration',
      ScheduleEditMessage: 'Changes to this schedule will impact all future reports generated from it.',
      ScheduleGenerating: '{filename} is generating...',
      ScheduleName: 'Schedule Name',
      ScheduleSuccessfullyGenerated: '{filename} has been successfully generated',
      Scheduling: 'Scheduling',
      SystemEventConfiguration: 'System Event Configuration',
      SystemReport: 'System Report',
      TrafficConfiguration: 'Traffic Configuration',
      TrafficReport: 'Traffic Report',
      UsageConfiguration: 'Usage Configuration',
      UsageReport: 'Usage Report',
    },
    Resource: {
      AWS: {
        AwsNetworkAcls: 'AWS Network ACLs',
        CarrierGateway: 'Carrier Gateway',
        ConnectAttachment: 'Connect Attachment',
        CoreNetwork: 'Core Network',
        CustomerGateway: 'Customer Gateway',
        DirectConnectConnection: 'DirectConnect Connection',
        DirectConnectGateway: 'DirectConnect Gateway',
        DirectConnectLag: 'DirectConnect Lag',
        DirectConnectLocation: 'DirectConnect Location',
        DirectConnectVirtualInterface: 'DirectConnect Virtual Interface',
        DOCDBCluster: 'DOC DB Cluster',
        DocDBElasticCluster: 'DOC DB Elastic Cluster',
        DOCDBInstance: 'DOC DB Instance',
        DynamoDBTable: 'DynamoDB Table',
        EBSVolume: 'EBS Volume',
        EC2Instance: 'EC2 Instance',
        EC2InstanceConnectEndpoint: 'EC2 Instance Connect Endpoint',
        ECSCluster: 'ECS Cluster',
        ECSContainerInstance: 'ECS Container Instance',
        EgressOnlyInternetGateway: 'Egress Only Internet Gateway',
        EIP: 'Elastic IP',
        EKSAddon: 'EKS Addon',
        EKSCluster: 'EKS Cluster',
        EKSFargateProfile: 'EKS Fargate Profile',
        EKSNodeGroup: 'EKS Node Group',
        ElastiCacheCacheCluster: 'ElastiCache Cache Cluster',
        ELBV2LoadBalancer: 'ELB v2 Load Balancer',
        GlacierVault: 'Glacier Vault',
        GlobalNetwork: 'Global Network',
        IAMAccount: 'IAM Account',
        IAMUser: 'IAM User',
        InternetGateway: 'Internet Gateway',
        KMSKey: 'KMS Key',
        LambdaFunction: 'Lambda Function',
        MemoryDBCluster: 'MemoryDB Cluster',
        NetworkAcl: 'Network ACL',
        RDSDBCluster: 'RDS DB Cluster',
        RDSDBInstance: 'RDS DB Instance',
        RDSDBSecurityGroup: 'RDS DB Security Group',
        RedshiftCluster: 'Redshift Cluster',
        ResourceShare: 'Resource Share',
        S3Bucket: 'S3 Bucket',
        S3BucketPolicy: 'S3 Bucket Policy',
        SecurityGroup: 'Security Group',
        SecurityGroupRule: 'Security Group Rule',
        SiteToSiteVpnAttachment: 'Site-to-Site VPN Attachment',
        SpotFleetRequest: 'Spot Fleet Request',
        SpotInstanceRequest: 'Spot Instance Request',
        TargetGroup: 'Target Group',
        TransitGateway: 'Transit Gateway',
        TransitGatewayAttachment: 'Transit Gateway Attachment',
        TransitGatewayMulticastDomain: 'Transit Gateway Multicast Domain',
        TransitGatewayPeering: 'Transit Gateway Peering',
        TransitGatewayRegistration: 'Transit Gateway Registration',
        TransitGatewayRouteTable: 'Transit Gateway Route Table',
        TransitGatewayRouteTableAttachment: 'Transit Gateway Route Table Attachment',
        VPC: 'VPC',
        VpcAttachment: 'VPC Attachment',
        VPCEndpoint: 'VPC Endpoint',
        VPCEndpointService: 'VPC Endpoint Service',
        VPCPeering: 'VPC Peering',
        VPNConnection: 'VPN Connection',
      },
      Azure: {
        AppConfigurationConfigurationStores: 'App Configuration Stores',
        AppConfigurationConfigurationStoresReplicas: 'App Configuration Stores Replicas',
        ApplicationGateway: 'Application Gateway',
        ApplicationSecurityGroup: 'Application Security Group',
        AzureFirewall: 'Azure Firewall',
        BatchAccounts: 'Batch Accounts',
        BatchAccountsApplications: 'Batch Accounts Applications',
        BatchAccountsApplicationsVersions: 'Batch Accounts Applications Versions',
        BatchAccountsCertificates: 'Batch Accounts Certificates',
        BatchAccountsPools: 'Batch Accounts Pools',
        BatchAccountsPrivateEndpointConnections: 'Batch Accounts Private Endpoint Connections',
        BatchAccountsPrivateLinkResources: 'Batch Accounts Private Link Resources',
        BGPConnections: 'BGP Connections',
        CacheRedis: 'Redis Cache',
        ContainerGroup: 'Container Group',
        ContainerServiceManagedClustersAgentPools: 'Managed Clusters Agent Pools',
        ContainerServiceManagedClustersAgentPoolsMachines: 'Managed Clusters Agent Pools Machines',
        DBforPostgreSQLFlexibleServer: 'DBforPostgreSQL Flexible Server',
        DBforPostgreSQLFlexibleServersDatabase: 'DBforPostgreSQL Flexible Servers Database',
        DBforPostgreSQLServer: 'DBforPostgreSQL Server',
        DBforPostgreSQLServerGroupsv2: 'DBforPostgreSQL ServerGroups v2',
        DBforPostgreSQLServerGroupsv2Server: 'DBforPostgreSQL ServerGroups v2 Server',
        DBforPostgreSQLServersDatabase: 'DBforPostgreSQL Servers Database',
        DiagnosticSettings: 'Diagnostic Settings',
        DocumentDBCassandraCluster: 'DocumentDB Cassandra Cluster',
        DocumentDBDatabaseAccount: 'DocumentDB Database Account',
        DocumentDBDatabaseAccountsCassandraKeyspace: 'DocumentDB Database Accounts Cassandra Keyspace',
        DocumentDBDatabaseAccountsGremlinDatabase: 'DocumentDB Database Accounts Gremlin Database',
        DocumentDBDatabaseAccountsMongodbDatabase: 'DocumentDB Database Accounts MongoDB Database',
        DocumentDBDatabaseAccountsSqlDatabase: 'DocumentDB Database Accounts SQL Database',
        DocumentDBDatabaseAccountsTable: 'DocumentDB Database Accounts Table',
        DocumentDBMongoCluster: 'DocumentDB Mongo Cluster',
        FirewallPolicy: 'Firewall Policy',
        IPConfig: 'IP Config',
        LocalNetworkGateways: 'Local Network Gateways',
        ManagedCluster: 'Managed Cluster',
        NetworkSecurityGroupDefaultSecurityRule: 'Network Security Group Default Security Rule',
        NetworkWatcher: 'Network Watcher',
        NSGSecurityRule: 'NSG Security Rule',
        PrivateLinkServices: 'Private Link Services',
        PublicIPPrefix: 'Public IP Prefix',
        RuleCollectionGroups: 'Rule Collection Groups',
        SqlServer: 'SQL Server',
        SqlServersDatabase: 'SQL Servers Database',
        StorageAccount: 'Storage Account',
        VirtualHubs: 'Virtual Hubs',
        VirtualMachine: 'Virtual Machine',
        VirtualMachineScaleSet: 'Virtual Machine ScaleSet',
        VirtualMachineScaleSetVM: 'Virtual Machine ScaleSet VM',
        VirtualNetwork: 'Virtual Network',
        VirtualNetworkGateway: 'Virtual Network Gateway',
        VirtualNetworkPeering: 'Virtual Network Peering',
        WebSite: 'Web Site',
        WebSitesFunction: 'Web Sites Function',
      },
      ContainerHosts: 'Container Hosts',
      FlowLog: 'Flow Log',
      Kubernetes: {
        Cluster: 'K8s Cluster',
        CronJob: 'Cron Job',
        DaemonSet: 'Daemon Set',
        Job: 'Job',
        Map: 'K8s Map',
        ReplicaSet: 'Replica Set',
        ReplicationController: 'Replication Controller',
        StatefulSet: 'Stateful Set',
      },
      LoadBalancer: 'Load Balancer',
      NatGateway: 'NAT Gateway',
      NetworkInterface: 'Network Interface',
      NetworkSecurityGroup: 'Network Security Group',
      OCI: {
        BackendIPs: 'Backend IPs',
        BackendSets: 'Backend Sets',
        DatabaseToolsConnection: 'Database Tools Connection',
        DatabaseToolsEndpointService: 'Database Tools Endpoint Service',
        Instance: 'OCI Instance',
        LogGroup: 'Log Group',
        ObjectStoragebucket: 'Object Storage Bucket',
        OracleDBAutonomousDatabase: 'OracleDB Autonomous Database',
        OracleDBCloudExadataInfrastructure: 'OracleDB Cloud Exadata Infrastructure',
        OracleDBCloudVMCluster: 'OracleDB Cloud VM Cluster',
        SecurityList: 'Security List',
        ServiceConnector: 'Service Connector',
        VCN: 'VCN',
        VNIC: 'VNIC',
        VNICAttachment: 'VNIC Attachment',
      },
      Peering: {
        Accepter: 'Accepter',
        Connections: 'Peering Connections',
        CrossAccount: 'Cross Account',
        CrossRegion: 'Cross Region',
        Requester: 'Requester',
      },
      PrivateEndpoint: 'Private Endpoint',
      RouteTable: 'Route Table',
      Subnet: 'Subnet',
      VPNGateway: 'VPN Gateway',
    },
    Rulesets: {
      Rules: {
        ExtraScope: {
          Add: 'Add Deny Rule',
          AddDesc: 'Deny traffic from specified Source to Destination not explicitly allowed by an Allow Rule',
        },
        IntraScope: {
          Add: 'Add Allow Rule',
          AddDesc: 'Allow traffic from specified Source to Destination',
        },
        OverrideDeny: {
          AddDesc: 'Deny traffic from specified Source to Destination regardless of subsequent Allow Rules',
          AddRule: 'Add Override Deny Rule',
        },
      },
    },
    Search: {
      TypeToFilter: 'Type to filter results',
    },
    ServiceAccounts: {
      AddSecret: 'Add Secret',
      SecretCreatedNote: 'This is the only time this secret will be available to copy.',
      SecretCreatedSuccesfully: 'Secret Created Successfully',
      SecretDelete: 'Remove {count, plural, =1 {Service Account Secret} other {# Service Account Secrets}}',
      SecretDeleteConfirm:
        'Are you sure you want to remove {count, plural, =1 {this Service Account Secret} other {# selected Service Account Secrets}}?',
      SecretDeletedSuccessfully:
        '{count, plural, =1 {Service Account Secret has} other {# Service Account Secrets have}} been successfully removed',
      SecretDeleteTitle: 'Remove {count, plural, =1 {Service Account Secret} other {Service Account Secrets}}',
      YourSecretCreated: 'Your secret has been successfully created.',
    },
    Traffic: {
      AllowedFlow: 'Allowed Flow',
      DeniedFlow: 'Denied Flow',
      ExportToConnector: {
        ConnectAnS3Bucket: 'Connect an S3 Bucket',
        ConnectionSuccess: 'Traffic Export to S3 Bucket Connected',
        ConnectorSettings: 'Connector Settings',
        ExportToConnector: 'Export to Connector',
        FilePrefixRegex: 'File prefix must contain only letters, numbers, and underscores',
        FilePrefixTooLong: 'File prefix must not be more than 50 characters',
        GoToConnectorToViewDetails: 'Go to Connector to view details',
        PrefixNameIsTaken: 'Prefix name is taken. Please use a different name.',
        PrefixNamesMustBeUnique: 'Prefix names must be unique per S3 Bucket',
        S3BucketPrefixName: 'S3 Bucket Prefix Name',
        SaveFailed: 'Save Failed',
        SelectAnS3Bucket: 'Select an S3 Bucket',
        SendTrafficFlows: 'Send traffic flows to external data storage',
        Tooltip:
          'Sends all traffic flows to external data storage. Best suited for customers to integrate their existing SIEM solution to search through flow logs with Illumio enrichments.',
        TypeAPrefix: 'Type a prefix',
      },
      FilterTrafficBy: 'Filter Traffic by...',
      FlowStatus: 'Flow Status',
      LoadingFlows: 'Loading Traffic Flows...',
      MatchAll: 'Match All Conditions (AND)',
      MatchAny: 'Match Any Condition (OR)',
      NoTraffic: 'No traffic flow is available',
      RiskReport: {
        DetailsToInclude: 'Details to Include',
        DetailsToIncludeTooltip:
          'Details of top 10 S/D, and conversations are added to the report. Top 10 is determined on the type of sorting',
        ErrorMessage: 'An unexpected error occurred. Could not generate the report.',
        ExportToReport: 'Export to Report',
        Filters: {
          TopConversations: 'Top Conversations',
          TopSourcesTopDestinations: 'Top Sources & Top Destinations',
        },
        InstructionBanner:
          'The Risk Report lets you download a .PDF report summarizing the following at the account/subscription level: total count of ransomware-susceptible traffic flows and total count of resources in your cloud environment affected by such flows',
        LoadingMessage: 'Please stay on this page while the report is being generated.',
        ReportName: 'Report Name',
        ReportTitle: 'Illumio_CloudSecure_RiskReport',
        Title: 'Risk Report',
        Tooltip:
          'Downloads a file directly to your browser. Best for users who want to upload reports to Microsoft Excel, Google Sheets, etc.',
      },
      ShowAllTrafficFlows: 'Show All Traffic Flows',
      SourceDestination: 'Source / Destination',
      TrafficList: 'Traffic List',
      TypeAnIpAddress: 'Type an IP Address',
      TypeAPortProtocol: 'Type a Port/Protocol',
    },
  },
  ComponentForm: {
    AdvancedExamples: 'Advance Examples',
    CidrIpv4: 'Cidr and Ipv4',
    ComplexRadio: 'Complex Radio',
    DisableExample: 'Disable Example',
    DisplayDisabledExample: 'Display Disabled Example',
    DynamicFormRadio: 'Dynamic with FormRadio',
    EnableDisabledExample: 'Enabled Example',
    EnabledOptions: 'Enabled Options',
    EnterUniqueName: 'Enter a unique name no more than {count} characters',
    FormTextareaLimited: 'FormTextarea limited Formik props - no errors',
    HorizontalRadios: 'Horizontal Radios',
    IPListEditor: 'IP List Editor',
    MaxIpAllowed: 'Max Allowed IP Inputs',
    MinimumOneItem: 'Minimum one item',
    NonRestrictingFileUpload: 'Non-Restricting File Upload',
    OtherExamples: 'Other Examples',
    RestrictingFileUpload: 'Restricting File Upload',
    SelectExample: 'Select Example',
    TextareaRowsCols: 'FormTextarea with rows, cols',
    Themr: 'Themr',
    UncontrolledElements: 'Uncontrolled Elements',
    UploadFile: 'Upload File',
    VerticalRadios: 'Vertical Radios',
  },
  Components: {
    AttributeListTest: 'AttributeList Test',
    CodeSnippet:
      'When a code snippet is displayed, hovering over the copy button reveals a Copy to clipboard tooltip. Clicking the copy button initiates a progress check mark and updates the tooltip to Copied',
    CodeSnippetHeader: 'Code Snippet Header',
    ConfirmDelete: 'Do you really want to delete?',
    ConfirmDeleteIPLists: 'Are you sure you want to remove the selected IP list(s)?',
    ConfirmLeavePage: 'Are you sure you want to leave the page?',
    Dialog: 'Dialog',
    FieldsetTitle: 'Fieldset Title',
    InviteAnotherUser: 'Invite Another User',
    Modal: 'Modal',
    PleaseEnterFullName: 'Please enter your full name',
    PleaseEnterTimeZone: 'Please enter your time zone information',
    PleaseLogOut: 'Please Log out',
    PleaseLogOutBeforeCompleteInvite: 'Please log out before attempting to complete an invitation for another user.',
    PleaseSelectRuleset: 'Please select a Policy',
    ProvideRulesetName: 'Please provide a Policy name',
    RulesetNameExists: 'A Policy already exists with that name',
    TestEmailUnableToSend: 'The test email was unable to be sent.',
    TextareaLabel: 'Textarea Label',
    TextLabel: 'Text Label',
    ThreeSixtyWorkloads: '360 Workloads',
    Transmission: 'Transmission',
  },
  Connectivity: {
    BothEntitiesCantBeIP: 'Both entities cannot be IP Addresses',
    CheckRules: 'Check Rules',
    ConsumerError: 'Source error',
    ConsumerMissing: 'Missing Source',
    Example22TCP: 'Example: 22 TCP',
    NetworkError: 'Network error',
    NonCorporateMustUseIpListError:
      "'Non-Corporate Networks (Endpoints Only)' or 'Any' is only supported for Rules that contain IP Address as Sources or Destinations",
    PortAndProtocol: 'Destination Port and Protocol',
    ProviderError: 'Destination error',
    ProviderProvider: 'Missing Destination',
    RulesAllowConnection: 'The Rules below allow this connection',
    RulesAllowConnectionFail: 'No Rules exist to allow this connection',
    RulesAllowConnectionFailMessage: 'Change the criteria or add a Rule to allow this connection',
    SelectWorkloadContainerWorkloadOrIP: 'Select Workload, Container Workload, or IP Address',
    ServiceError: 'Service error',
    VerifyRulesExist:
      'Verify if Rules exist that allow connections between Workloads, Container Workloads, or IP addresses in IP Lists',
  },
  ContainerClusters: {
    AddNew: 'Add a new Container Cluster',
    AddNewContainerWorkloadProfile: 'Add a new Container Workload Profile',
    Allowed: 'allowed',
    AssignedLabels: 'Assigned Labels',
    AssignLabel: 'Assign Label',
    CannotDelete:
      'The following {count, plural, =1 {Container Cluster is} other {# Container Clusters are}} still online. Please stop Kubelink before deleting the cluster from the PCE.',
    CannotDeleteContainerWorkloadProfile:
      'The following {count, plural, =1 {Container Workload Profile is} other {# Container Workload Profiles are}} cannot be deleted.',
    ClasMode: 'Cluster Local Actor Store',
    ClusterId: 'Cluster ID',
    ClusterPairingToken: 'Cluster Pairing Token',
    ClusterToken: 'Cluster Token',
    ContainerAnnotationLabels: 'Container Annotation Labels',
    ContainerRuntime: 'Container Runtime',
    ContainerWorkloadProfiles: 'Container Workload Profiles',
    ContainerWorkloadProfilesAdd: 'Add Container Workload Profile',
    ContainerWorkloadProfilesEditError: 'Unable to Edit Container Workload Profiles',
    ContainerWorkloadProfilesUnmanaged:
      'Container Workload Profiles will be created automatically for discovered {isOpenshift, select, false {Namespaces} true {Projects}}',
    CopyKey: 'Copy Key',
    CopyPairingKeyMessage:
      'Please copy the following pairing key. This information will not be available after you leave the page. A new profile will need to be created.',
    CopyTokenMessage:
      'Please copy the following token and ID. This information will not be available after you leave the page. A new token will need to be generated.',
    CreatedByContainerCluster: 'Created by Container Cluster',
    Delete: 'Remove {count, plural, =1 {Container Cluster} other {# Container Clusters}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {Container Cluster} other {# Container Clusters}}?',
    DeleteContainerWorkloadProfile:
      'Remove {count, plural, =1 {Container Workload Profile} other {# Container Workload Profiles}}',
    DeleteContainerWorkloadProfileConfirm:
      'Are you sure you want to remove the {count, plural, =1 {Container Workload Profile} other {# Container Workload Profiles}}?',
    DeletedContainerWorkloadProfileSuccessfully:
      'The following {count, plural, =1 {Container Workload Profile has} other {#  Container Workload Profiles have}} been successfully deleted',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Container Cluster has} other {# Container Clusters have}} been successfully deleted',
    DuplicatedIdList: 'The duplicate IDs are: {list}',
    DuplicatedIdNodeList: 'The nodes with duplicate IDs are: {list}',
    DuplicateMachineIDsError:
      'There are duplicate machine IDs among your cluster nodes. Container cluster functionality will be limited until this issue is resolved.',
    EditDefaultSettings: 'Edit default settings',
    KubelinkVersion: 'Kubelink Version',
    LabelAssignType: 'Label Assign Type',
    LabelsAllowedAssign: 'Labels Allowed / Label Assign',
    LabelsAllowedNumberAndType: '{count} {labelTypeName} Labels allowed',
    NoLabelAllowed: 'No label allowed',
    NoLabelAllowedHeader: 'No Label Allowed',
    NoProfilesToEditSpecified: 'Container Workload Profiles to edit was not specified',
    ProfileLink: 'Profile Link',
    UndeletedVirtualServices: 'Created by Deleted Container Cluster',
    UseContainerAnnotations: 'Use Container Annotations',
  },
  Containers: {
    PlatformVersion: 'Platform Version',
  },
  ContainerWorkloads: {
    All: 'All Container Workloads',
    ByCount: '{count, plural, =1 {Container Workload} other {Container Workloads}}',
    ContainerID: 'Container ID',
    HostIpAddress: 'Host IP Address',
    StartedAt: 'Started At',
    StoppedAt: 'Stopped At',
    Total: 'Total Container Workloads',
    View: 'View Container Workloads',
    WithCount: '{count} {count, plural, =1 {Container Workload} other {Container Workloads}}',
  },
  CoreServices: {
    AcceptDescription: 'Describe why this recommendation is accepted',
    Accepted: 'Accepted',
    AcceptedCoreService: '{name} is accepted as a provider of the {service} core service',
    AcceptedCoreServices: 'Accepted Core Service',
    AcceptedCount: 'Accepted ({count})',
    AcceptedError: 'An unexpected error occurred. The server could not be accepted as a provider of the Core Service',
    AcceptedNotification:
      'Edit Labels to distinguish core service providers from other workloads. Click "Reject" if a server no longer provides the core service.',
    AcceptInfoMsg: 'An unmanaged workload is created automatically if the accepted server is an unknown IP address.',
    Confidence: '{confidence}% confidence',
    ConfirmRejectAccepted: 'Are you sure you want to reject this Recommendation?',
    CoreService: 'Core Service',
    CoreServices: 'Core Services',
    CoreServicesDetector: 'Core Services Detector',
    CoreServiceSettings: 'Core Services Settings',
    Current: 'Current',
    Default: '(Default)',
    DefaultSettings: 'Default Settings',
    Detection: 'Core Services Detection',
    DetectionModel: 'Detection Model',
    DisabledDetails: 'Do not analyze traffic data for Core Service Detection',
    EditDefaultSettingsBanner:
      'These are default label assignments for workloads providing the {name} Service. Editing the default setting does not affect previously edited workload Labels.',
    EditDefaultSettingsLabel: 'Edit Default Settings',
    EditLabelsCheckBoxLabel:
      'Save edited Role and Application Labels as the default label assignments for workloads providing the {name} Service',
    EditLabelsError: 'An unexpected error occurred. Could not edit the labels for this Core Service',
    EmptyServices: 'No Services to Display',
    EnabledDetails: 'Analyze traffic data every two weeks to identify servers that provide Core Services',
    ErrorFetchingWorkload: 'Workload does not exist.',
    FollowUp: 'Follow Up',
    FollowUpDescription: 'Describe why a decision cannot be made, what further steps need to be taken, and by whom.',
    LastRunAtNotification: 'Core Service Detection algorithm was last run at {timestamp}.',
    NoLastRunAtNotification: 'Core service detection algorithm has not run yet.',
    NoScannerNotification: 'Scanner detection algorithm has not run yet.',
    PeerCountInfo: 'Top Detected Processes',
    PeerCountNoTraffic: 'No Traffic to Display',
    PeerCountSubtitle: 'Top Detected Processes by Number of Ports',
    Peers: 'Peers',
    PortMatch: 'Port Matching',
    PortMatchInformation: 'Rule-based model based on connections to specific ports',
    ProcessNames: 'Process-based ML',
    ProcessNamesInformation: 'Machine learning model based on processes running on the server',
    RecommendedCoreService: 'Recommended Core Service',
    RecommendedCount: 'Recommended ({count})',
    RecommendedNotification:
      'The {name} core service was detected on the following servers on {date}. Click to Accept or Reject the recommendations.',
    RecommendedNotificationNoTimestamp:
      'The {name} core service was detected on the following servers. Click to Accept or Reject the recommendations.',
    RejectAcceptedBanner:
      'It is recommended that you edit or remove Role and Application Labels assigned to the rejected server.',
    RejectAcceptedError:
      'An unexpected error occurred. The server could not be rejected as a provider of the Core Service',
    RejectDescription: 'Describe why this recommendation is rejected.',
    Rejected: 'Rejected',
    RejectedCoreService: '{name} is no longer recommended as a provider of the {service} core service',
    RejectedCoreServices: 'Rejected Core Service',
    RejectedCount: 'Rejected ({count})',
    RejectedNotification: 'If a server is rejected in error, click "Accept" to move it to the Accepted tab.',
    ScannerDetection: 'Traffic Pattern',
    ScannerDetectionInformation: 'Algorithmic model based on traffic patterns associated with scanner activity.',
    ScannerNotification:
      '{scannerStatus, select, true {Scanner Detection is {scannerDetectionSettings}.} false {Scanner Detection algorithm was last run at {scannerTimestamp}.}}',
    SetDefaultSuggestedError:
      'An unexpected error occurred. Could not set default suggested labels for this Core Service',
    SettingsNotification:
      'The accuracy of Core Service Detection improves with the amount of traffic data collected. A minimum of 14 days is recommended for optimal results.',
    TrafficFlows: 'Port-based ML',
    TrafficFlowsInformation: 'Machine learning model based on connections to specific ports',
    UnknownIP: 'Unknown IP',
    UnknownProcess: 'Unknown Process',
    UseCurrentLabel: 'Use current {type} Label',
    UseCurrentLabels: 'Use current Labels',
  },
  Dashboard: {
    ActivePolicy: 'Active Policy',
    AddWidget: 'Add Widget',
    BlockedOrPotentiallyBlockedFlow: 'Blocked / Potentially Blocked Flow',
    DraftPolicyChanges: 'Draft Policy Changes',
    DragNewWidget: 'Drag a widget to add it into the dashboard',
    HostOsUndefined: 'Host OS not defined',
    LastFourteen: 'Last 14 Days',
    LastSeven: 'Last 7 Days',
    LastThirty: 'Last 30 Days',
    NoOsAttribute: 'No OS Attribute',
    PolicyStatistics: 'Policy Statistics',
    RefreshDashboard: 'Refresh Dashboard',
    Title: 'Title',
    VenCountByEnforcementMode: 'Total VENs by Enforcement Mode',
    VenCountByOS: 'Active VENs by OS',
    VenCountByStatusOrHealth: 'Total VENs by Status / Health',
    VenCountByVersion: 'Active VENs by Version',
    WarningVensToolTip: 'View workloads with warnings and errors',
  },
  DateTimeInput: {
    Anytime: 'Anytime',
    CustomTime: 'Custom Time',
    DateAndTime: 'Date and Time',
    From: 'From',
    InvalidTimeRange: 'Invalid time range selected',
    Last1Hour: 'Last 1 hour',
    Last2Hours: 'Last 2 hours',
    Last7days: 'Last 7 days',
    Last14days: 'Last 14 days',
    Last24Hours: 'Last 24 hours',
    Last30days: 'Last 30 days',
    LastMonth: 'Last month',
    LastPolicyProvisioned: 'Last Policy Provisioned - {when, date, L_hh_mm_ss}',
    LastWeek: 'Last week',
    Later: 'Later',
    LimitedTimeRange: 'Time range is limited to {count, plural, =1 {1 day} other {# days}}',
    ListenOnlyEnableTime: 'Listen Only Enabled - {when, date, L_hh_mm_ss}',
    Now: 'Now',
    PreviousPeriod: 'Previous period',
    SelectDateAndTime: 'Select Date and Time',
    SelectedTime: '{when, date, L_HH_mm}',
    SelectFromPlaceholder: 'Select or anytime (blank)',
    SelectToPlaceholder: 'Select or now (blank)',
    ShortDays: {
      Fri: 'F',
      Mon: 'M',
      SunSat: 'S',
      TuesThurs: 'T',
      Wed: 'W',
    },
    To: 'To',
    UTC: '(GMT+0:00) UTC',
    Weekdays: {
      Fridays: 'Fridays',
      Mondays: 'Mondays',
      Saturdays: 'Saturdays',
      Sundays: 'Sundays',
      Thursdays: 'Thursdays',
      Tuesdays: 'Tuesdays',
      Wednesdays: 'Wednesdays',
    },
  },
  DragAndDrop: {
    ChooseFile: 'Choose File',
    DragAndDropFileHere: 'Drag and drop file here or',
  },
  Endpoint: {
    Dashboard: {
      ActiveEndpoints: 'Active Endpoints',
      EndpointCountByEnforcementMode: 'Total Endpoints by Enforcement Mode',
      EndpointCountByOS: 'Active Endpoints by OS',
      EndpointCountByStatusOrHealth: 'Total Endpoints by Status/Health',
      EndpointCountByVersion: 'Active Endpoints by Version',
      EndpointStatistics: 'Endpoint Statistics',
    },
  },
  EnforcementBoundaries: {
    BlockedConnectionCount: '{count} {count, plural, =1 {connection} other {connections}}',
    BlockedConnectionDesc: 'These connections will be blocked when the Deny Rule is provisioned',
    BlockedConnections: 'Deny Rule Blocked Connections',
    DeleteConfirm: 'Are you sure you want to remove this Deny Rule?',
    DenyRulesNoData: 'No Deny Rules to display',
    DenyRulesNoMatchData: 'No matching Deny Rules to display',
    Description:
      'A Deny Rule is defined by a scope consisting of Source, Destination, and Service. When a Deny Rule is provisioned, connections that match the scope are blocked. A blocked connection can be allowed to cross the Deny Rule by writing a Rule.',
    EnfRulesCount: '{count} {count, plural, =1 {rule} other {rules}}',
    Errors: {
      ConsumerProviders: 'Cannot use IP Lists for both Sources and Destinations',
      Create: 'Unable to Create Deny Rule',
      Edit: 'Unable to Edit Deny Rule',
      MatchingWorkloadsFullEnforcement: 'No workloads are fully enforced',
      MatchingWorkloadsIdle: 'No workloads are idle',
      MatchingWorkloadsSelective: 'No workloads are selectively enforced',
      MatchingWorkloadsVisibility: 'No workloads are visible only',
    },
    List: {
      DeleteConfirm: 'Are you sure you want to remove the selected Deny {count, plural, =1 {Rule} other {Rules}}?',
    },
    MixedEnforcement: 'Mixed Enforcement',
    NoBlockedConnectionDesc: 'No connections will be blocked by this boundary',
    Remove: 'Remove Deny Rules',
    RulesCount: '{count, plural, =1 {# Deny Rule} other {# Deny Rules}}',
    RulesDesc: 'No rules exist to allow connections across the Boundary',
    RulesProvision:
      '{count, plural, =1 {# Deny Rule} other {# Deny Rules}} added to the Deny Rule Policy allow connections to cross the boundary. Be sure to provision these rules before provisioning the boundary.',
    SelectiveEnforcement: 'Selective Enforcement',
    UpdateResultTitle: 'Result of {count, plural, =1 {Deny Rule} other {Deny Rules}} status update',
    UpdateSuccess: '{count, plural, =1 {Deny Rule has} other {# Deny Rules have}} been successfully updated',
    Warning: {
      FQDN: 'FQDNs are not supported in Deny Rules. Illumio recommends creating IP lists without FQDNs for use in Deny Rules. IP lists containing FQDNs will be dropped from Deny Rules when the policy is delivered to the VEN.',
      FQDNsTooltip: 'Click to see information about FQDNs support',
      IPList:
        'This IP list containing FQDNs is used in a Deny Rule.\nFQDNs are not supported in Deny Rules and Override Deny Rules. Illumio recommends creating IP lists without FQDNs for use in Deny Rules and Override Deny Rules. IP lists containing FQDNs will be dropped from Deny Rules when the policy is delivered to the VEN.',
    },
    WorkloadEnforcementDesc: 'This workload is within the scope of {count} <a>Deny Rules</a>.',
    WorkloadEnforcementFollowingServices: 'The following services are selectively enforced.',
    WorkloadsInScope: 'Workloads in Scope',
    WorkloadsInScopeCount:
      '<span class="{className}">{count} {count, plural, =1 {workload} other {workloads}}</span> in {scopeType} {mode}',
    WorkloadsInScopeDesc: {
      Full: 'All traffic to and from these workloads is blocked unless allowed by Rule.',
      Idle: 'VEN does not control workload firewalls.',
      Mode: 'mode',
      Selective: 'Traffic that matches the Deny Rule is blocked unless allowed by a Rule. Unmatching is not blocked.',
      SupportEnforcement:
        '{context} <span class="{className}">These workloads must be set to Selective Enforcement to support Deny Rule</span>',
      Total:
        '<span class="{className}">{count} {count, plural, =1 {workload} other {workloads}}</span> are within the scope of the Deny Rule',
      Visibility: 'No traffic is blocked by policy.',
    },
  },
  EnforcementBoundary: {
    AddNew: 'Add a new Deny Rule',
  },
  Error: {
    AnErrorHasOccured:
      'An error has occurred loading the header. Please retry, view the error details, or contact support for assistance.',
    ApplicationTimeout: 'The Application configuration is calculating. Wait a few minutes, then refresh the page.',
    ComponentStack: 'Component Stack',
    DataStoreUnavailable: 'Traffic Datastore is in maintenance mode',
    ErrorDetails: 'Error Details',
    NetworkTimeout: 'The selected Illumination map is generating. Wait a few minutes, then refresh the page.',
    ProvideErrorId: 'Provide this Error-ID when contacting your support team',
    RetryToReload: 'Retry to reload',
    Unknown: 'Unknown Error',
    WeApologizeForTheInconvenience:
      'We apologize for the inconvenience. Please contact our support team for assistance. You can provide the error details to help expedite the troubleshooting process.',
  },
  ErrorsAPI,
  EssentialServiceRules: {
    Egress: {
      DHCPv4: 'Required for outbound DHCPv4',
      DHCPv6: 'Required for outbound DHCPv6',
      DNS: 'Required for outbound DNS',
      EventService: 'Required for real-time communication between the Workload and the PCE',
      ICMPv6: 'Required for outbound ICMPv6',
      PCE: 'Required for HTTPS communication from the Workload to the PCE',
      Traceroute: 'Required for outbound traceroute',
    },
    Inbound: 'Essential Service Rules - Inbound',
    Ingress: {
      DHCPv4: 'Required for DHCPv4 replies',
      DHCPv6: 'Required for DHCPv6 replies',
      ICMPv6: 'Required for inbound ICMPv6',
      Traceroute: 'Required for inbound traceroute',
    },
    IPSec: 'Enabled for SecureConnect and Machine Authentication connections',
    Modal: {
      ConfirmationMessage:
        'Essential Service Rules are programmed on the VEN to allow normal network functionality and communication with the PCE. Improperly editing a rule could cause a disruption of service. Are you sure you want to continue?',
      ConfirmationTitle: 'Editing Essential Service Rule',
      IPListPlaceholder: 'Select an IP list',
      Notification: {
        DHCP: 'Use extreme caution when editing this rule. Misconfiguring this rule, or not maintaining this rule could impact the ability of a workload to receive an IP address.',
        DNS: 'Use extreme caution when editing this rule. Misconfiguring, or not maintaining, this rule could impact the ability of the VEN to communicate with the PCE and to DNS - undoing this rule may require manual intervention to re-establish connectivity to the PCE and to DNS. Use this function with extreme caution!',
        ICMPv6:
          'Use extreme caution when editing this rule. If this rule is misconfigured, or if it is not maintained, Workloads will be unable to use IPv6.',
        Traceroute:
          'Use extreme caution when editing this rule. If this rule is configured incorrectly, traceroute may not be available.',
      },
      Purpose: 'Purpose',
      SourcePort: 'Source Port',
      Title: 'Edit Essential Service Rule',
    },
    Outbound: 'Essential Service Rules - Outbound',
  },
  Events: {
    AddRepository: 'Add Repository',
    Address: 'Address',
    AddressPlaceHolder: 'Type IP address or FQDN',
    AllowedTraffic: 'Allowed Traffic',
    APIEndpoint: 'API Endpoint',
    APIMethod: 'API Method',
    AuditableEvents: 'Auditable Events',
    ByEvent: 'by Event',
    ByGenerated: 'by Generated',
    BySeverity: 'by Severity',
    ByTimestamp: 'by Timestamp',
    CEF: 'CEF',
    Changes: 'Changes',
    ChangeType: 'Change Type',
    Config: {
      Errors: {
        Format: 'Needs to be one of {format}',
        PCE: 'Needs to be one of these {pce}',
        Severity: 'Needs to match one of these {severity}',
        TLS: 'Needs to be {tls}',
      },
    },
    Configuration: 'Event Configuration',
    DaysCount: '{count, plural, =1 {# day} other {# days}}',
    DaysSetting: '{count, plural, =1 {day} other {days}}',
    Deactivated: 'Deactivated',
    DuplicateError: 'Repository update error',
    DuplicateMessage: 'A repository with the same address, port and protocol already exists',
    EditEventInformation: 'Changes to settings may take up to 5 minutes to take effect',
    EditRepository: 'Edit Repository',
    EmptyMessage: 'No matching Events to display',
    EncryptedDesc: 'Forwarded event data is encrypted using TLS',
    EventForwarding: 'Event Forwarding',
    Format: 'Event Format',
    Forwarding: 'Forwarding',
    Hostname: 'Hostname: {hostname}',
    HTTPStatusCode: 'HTTP Status Code',
    InvalidAddress: 'Invalid address',
    LandingMessage:
      'Select filter parameters then click "Run" to display filtered events. <br />Click "Run" without filter parameters to display most recent events. <br />Click one of preset filters below to display filtered events.',
    LastPairedAt: 'Last paired at',
    LEEF: 'LEEF',
    LocalRepositoryDesc: 'Forward events to local syslog service',
    ManagedSince: 'Managed Since',
    NotEncryptedDesc: 'Forwarded event data is not encrypted',
    OrganizationalEvents: 'Organizational Events',
    PotentiallyBlockedTraffic: 'No Rule / Unenforced Blocked Traffic',
    Process: 'Process: {process}',
    QuickFilter: 'Quick Filter',
    QuickFilterClear: 'Clear filter',
    QuickFilterInfo: 'Quick filter is applied to the most recent 1000 events',
    RemoveRepository: 'Remove Repository',
    Repository: 'Repository',
    RepositoryDeleteConfirm:
      'Are you sure you want to delete the selected {count, plural, =1 {repository} other {# repositories}}?',
    ResourceChange: 'Resource Change',
    RetentionPeriod: 'Retention Period',
    RetentionPeriodDesc: 'Audit events older than this are purged',
    RulesetHref: 'Policy Href',
    SecurityPrincipalHref: 'Security Principal Href',
    SecurityPrincipalType: 'Security Principal Type',
    SecuritySettings: {
      ContainersPolicyScopes: 'Containers Policy Scopes:',
      IPForwardingScopes: 'IP Forwarding Scopes:',
      LoopbackScopes: 'Loopback Scopes:',
      RejectedScopes: 'Rejected Scopes:',
      ScopeHref: 'Scope Href',
      StaticScopes: 'Static Scopes:',
    },
    ServiceAccount: 'Service Account',
    ServiceAccountHref: 'Service Account Href',
    ServiceAccountToken: 'Service Account Token',
    Severity: 'Event Severity',
    SeverityDesc: 'Only audit events of this severity or higher are saved',
    StatusLogs: 'Status Logs',
    SyslogDestUpdateError: 'Syslog destination settings update error',
    SystemHealthMessages: 'System Health Messages',
    TLS: 'TLS',
    TlSConfigureMessage: 'Once TLS is configured, TLS setting cannot be modified',
    TLSHelp: 'Please create a new repository, if TLS needs to be disabled',
    Types: {
      Organizational: 'Organizational',
    },
    UpdateError: 'Event setting update error',
    ValidTLS: "Ensure that TLS peer's server certificate is valid",
    Yesterday: 'Yesterday',
  },
  EventUtils: {
    AccessRestrictionCreate: 'Create access restriction',
    AccessRestrictionDelete: 'Delete access restriction',
    AccessRestrictionUpdate: 'Update access restriction',
    AdminRecalc: 'Admin forced recalculation of policy',
    APIKeyCreated: 'API key created',
    APIKeyDeleted: 'API key deleted',
    APIKeyUpdated: 'API key updated',
    AuthenticationSettingsUpdated: 'Authentication settings updated',
    ClusterCreated: 'Cluster created',
    ClusterDeleted: 'Cluster deleted',
    ClusterUpdated: 'Cluster updated',
    ComputePolicyForUnmanagedWorkloads: 'Compute policy for unmanaged workloads',
    ContainerClusterChangeRate: 'Container Cluster Change Rate',
    ContainerClusterCreated: 'Container cluster created',
    ContainerClusterDeleted: 'Container cluster deleted',
    ContainerClusterDuplicateMachineID: 'Container cluster duplicate machine ID',
    ContainerClusterLabelMapUpdated: 'Label Map Updated',
    ContainerClusterRegionMismatch: 'Container cluster region mismatch',
    ContainerClusterSecurityPolicyAcksUpdated: 'Container Cluster Security Policy Acks Updated',
    ContainerClusterServicesProvisioned: 'Container cluster services provisioned',
    ContainerClusterUpdated: 'Container cluster updated',
    ContainerWorkloadInvalidPairingConfig: 'Container workload invalid pairing config',
    ContainerWorkloadNotCreated: 'Container workload not created',
    ContainerWorkloadProfileCreated: 'Container workload profile created',
    ContainerWorkloadProfileDeleted: 'Container workload profile deleted',
    ContainerWorkloadProfilesUpdated: 'Container workload profiles updated in bulk',
    ContainerWorkloadProfileUpdated: 'Container workload profile updated',
    ContainerWorkloadUpdateServices: 'Container Workload Update Services',
    CorporateIpsSettingsCreate: 'Create new corporate IPs for the org',
    CorporateIpsSettingsDelete: 'Delete the corporate IPs instance',
    CorporateIpsSettingsUpdate: 'Update the corporate IPs instance',
    CreateGroup: 'Create a group',
    DatabaseCleanupCompleted: 'Database cleanup completed',
    DatabaseCleanupStarted: 'Database cleanup started',
    DeleteExpireServiceAccountApiKeys: 'Delete expired service account API keys',
    DeleteGroup: 'Group Deleted',
    DeleteOldCachedPerspectives: 'Delete old cached perspectives',
    DeletePairingProfileKeys: 'Delete all pairing keys of a pairing profile by ID',
    DenyRuleCreated: 'Deny Rule created',
    DenyRuleDeleted: 'Deny Rule deleted',
    DenyRuleUpdated: 'Deny Rule updated',
    DestinationCreated: 'Syslog destination created',
    DestinationDeleted: 'Syslog destination deleted',
    DestinationUpdated: 'Syslog destination updated',
    DomainCreated: 'Domain created',
    DomainDeleted: 'Domain deleted',
    DomainUpdated: 'Domain updated',
    EndpointOfflineCheck: 'Endpoint marked offline',
    EnforcementPointCreate: 'Enforcement point created',
    EnforcementPointDelete: 'Enforcement point deleted',
    EnforcementPointsSet: 'Enforcement point collection updated',
    EnforcementPointUpdate: 'Enforcement point updated',
    EssentialServiceRulesUpdated: 'Essential Service Rules updated',
    EventConfigUpdated: 'Event configuration updated',
    EventPruningCompleted: 'Event pruning completed',
    ExpireServiceAccountApiKeys: 'Service account API keys expiration successful',
    FirewallTampered: 'Firewall tampered',
    GlobalPolicySettingsUpdated: 'Global policy settings updated',
    HardLimitExceeded: 'Object creation hard limit exceeded',
    HardLimitRecoveryCompleted: 'Hard limit recovery completed',
    IPListCreated: 'IP list created',
    IPListDeleted: 'IP list deleted',
    IPListsDeleted: 'IP lists deleted',
    IPListUpdated: 'IP list updated',
    IPTablesRuleCreated: 'IP tables rules created',
    IPTablesRuleDeleted: 'IP tables rule deleted',
    IPTablesRuleUpdated: 'IP tables rule updated',
    JobDeleted: 'Job Deleted',
    KubernetesWorkloadsBulkCreated: 'Kubernetes Workloads Bulk Created',
    KubernetesWorkloadsBulkDeleted: 'Kubernetes Workloads Bulk Deleted',
    KubernetesWorkloadsBulkUpdated: 'Kubernetes Workloads Bulk Updated',
    LabelCreated: 'Label created',
    LabelDeleted: 'Label deleted',
    LabelDimensionCreated: 'Label dimension created',
    LabelDimensionDeleted: 'Label dimension deleted',
    LabelDimensionUpdated: 'Label dimension updated',
    LabelGroupCreated: 'Label group created',
    LabelGroupDeleted: 'Label group deleted',
    LabelGroupUpdated: 'Label group updated',
    LabelRuleCreated: 'Label rule created',
    LabelRuleDeleted: 'Label rule deleted',
    LabelRulesAsyncJobAssignedLabels: 'Label rules assigned',
    LabelRulesAsyncJobCreated: 'Label rules async job created',
    LabelRulesDeleted: 'Label rules deleted',
    LabelRulesReordered: 'Label rules reordered',
    LabelRulesUpdated: 'Label rules updated',
    LabelRuleUpdated: 'Label rule updated',
    LabelsDeleted: 'Labels deleted',
    LabelUpdated: 'Label updated',
    LDAPConfigCreated: 'LDAP config created',
    LDAPConfigDeleted: 'LDAP config deleted',
    LDAPConfigUpdated: 'LDAP config updated',
    LDAPConfigVerifyConnection: 'LDAP config verify connection',
    LicenseDeleted: 'License deleted',
    LicenseUpdated: 'License updated',
    LocalUserAuthenticated: 'Local user authenticated',
    LocalUserDeletedForInactivity: 'Local user account deleted for inactivity',
    LocalUserPasswordChanged: 'Local user password changed',
    LocalUserProfileCreated: 'Local user profile created',
    LocalUserProfileDeleted: 'Local user profile deleted',
    LocalUserReinvited: 'Local user reinvited',
    LocalUserUnableToDeleteForInactivity: 'Unable to Delete Inactive Local User',
    LoginMSPTenantCreated: 'Login MSP tenant created',
    LoginMSPTenantDeleted: 'Login MSP tenant deleted',
    LoginMSPTenantUpdated: 'Login MSP tenant updated',
    LoginUserSessionCreated: 'Login user session created',
    LoginUserSessionTerminated: 'Login user session terminated',
    // The agent that has been lost for extended period of time sent another request
    LostAgentFound: 'Lost agent found',
    ModifyGroup: 'Modify a group by ID',
    NetworkCreated: 'Network created',
    NetworkDeleted: 'Network deleted',
    NetworkDeviceAckEnforcementInstructionsApplied: 'Network device Ack enforcement instructions Applied',
    NetworkDeviceAssignWorkload: 'Network device assign workload',
    NetworkDeviceCreate: 'Network device create',
    NetworkDeviceDelete: 'Network device delete',
    NetworkDeviceDeleteWorkload: 'Network device delete workload',
    NetworkDevicesAckMultiEnforcementInstructionsApplied: 'Network devices Ack multi-enforcement instructions applied',
    NetworkDeviceUpdate: 'Network device update',
    NetworkDeviceUpdateWorkload: 'Network device update workload',
    NetworkDeviceWorkloadsBulkCreate: 'Integration workload bulk create',
    NetworkDeviceWorkloadsBulkUpdate: 'Integration workload bulk update',
    NetworkEndpointCreate: 'Network endpoint create',
    NetworkEndpointDeleted: 'Network endpoint delete',
    NetworkEndpointUpdate: 'Network endpoint update',
    NetworkEnforcementNodeActivate: 'Network Enforcement Node activate',
    NetworkEnforcementNodeClearConditions: 'Clear Network Enforcement Node conditions',
    NetworkEnforcementNodeDeactivated: 'Network Enforcement Node deactivated',
    NetworkEnforcementNodeDegraded: 'Network Enforcement Node Degraded',
    NetworkEnforcementNodeMissedHeartBeats: 'Network Enforcement Node missed heartbeats',
    NetworkEnforcementNodeMissedHeartBeatsCheck: 'Network Enforcement Node missed heartbeats check',
    NetworkEnforcementNodePolicyAck: 'Network Enforcement Node Policy Ack',
    NetworkEnforcementNodeRequestPolicy: 'Network Enforcement Node request policy',
    NetworkEnforcementNodeUpdate: 'Network Enforcement Node update',
    NetworkFuncCntlrActivated: 'Network function controller activated',
    NetworkFuncCntlrDeactivated: 'Network function controller deactivated',
    NetworkFuncCntlrDiscoveryComp: 'Network function controller discovery completed',
    NetworkFuncCntlrPolicyStatusUpdated: 'Network function controller policy status updated',
    NetworkFuncCntlrSLBStateUpdated: 'Network functional controller load balancer state updated',
    NetworkInternetCreate: 'Find or create internet networks',
    NetworkUpdated: 'Network updated',
    OrgApiKeyDelete: 'API key deletion',
    OrgExtractCreate: 'Org extract created for Migration',
    OrgExtractDelete: 'Org extract deleted for Migration',
    OrgExtractUpdate: 'Org extract updated for Migration',
    OrgInfoCreated: 'Organization information created',
    OrgInfoUnmanaged: 'Organization changed to unmanaged',
    OrgInfoUpdated: 'Organization information updated',
    OrgLoadCreate: 'Org load created for Migration',
    OrgLoadDelete: 'Org load deleted for Migration',
    OrgLoadUpdate: 'Org load updated for Migration',
    OrgsVENCount: 'Get active VEN count for a list of orgs',
    OrgUnpairVENs: 'Unpair all VENs from org',
    PairingProfileCreated: 'Pairing profile created',
    PairingProfileDeleted: 'Pairing profile deleted',
    PairingProfileKeyGenerated: 'Pairing profile pairing key generated',
    PairingProfilesDeleted: 'Pairing profiles deleted',
    PairingProfileUpdated: 'Pairing profile updated',
    PasswordPolicyCreated: 'Password policy created',
    PasswordPolicyDeleted: 'Password policy deleted',
    PasswordPolicyUpdated: 'Password policy updated',
    PCESessionCreated: 'PCE user session created',
    PCESessionTerminated: 'PCE user session terminated',
    PCESupportReportRequestCreate: 'PCE support report request created',
    PCESupportReportRequestDelete: 'PCE support report request deleted',
    ProtectionSchemaCreated: 'Protection Schema Created',
    ProtectionSchemaDeleted: 'Protection Schema Deleted',
    ProtectionSchemaGroupingsCreated: 'Protection Schema Groupings Created',
    ProtectionSchemaUpdated: 'Protection Schema Updated',
    RADIUSAuthChallenge: 'RADIUS auth challenge issued',
    RADIUSConfigDeleted: 'RADIUS configuration deleted',
    RADIUSConfigsCreated: 'RADIUS configurations created',
    RADIUSConfigSecretVerified: 'RADIUS config shared secret verified',
    RADIUSConfigUpdated: 'RADIUS configuration updated',
    RBACAuthSecPrincipalCreated: 'RBAC auth security principal created',
    RBACAuthSecPrincipalDeleted: 'RBAC auth security principal deleted',
    RBACAuthSecPrincipalUpdated: 'RBAC auth security principal updated',
    RBACPermissionCreated: 'RBAC permission created',
    RBACPermissionDeleted: 'RBAC permission deleted',
    RBACPermissionUpdated: 'RBAC permission updated',
    RemoteSyslogReachable: 'PCE external audit server is reachable',
    RemoteSyslogUnreachable: 'PCE external audit server is not reachable',
    RemoveStaleZoneSubnets: 'Stale zone subnets removed',
    RequestAuthenticationFailed: 'Request authentication failed',
    RequestAuthorizationFailed: 'Request authorization failed',
    RequestInternalServer: 'API request failed due to internal server error',
    RequestInvalid: 'Request Invalid',
    RequestServiceUnavailable: 'API request failed due to unavailable service',
    RequestUnknownServer: 'API request failed due to unknown server error',
    ResourceCreated: 'Login resource created',
    ResourceDeleted: 'Login resource deleted',
    ResourceUnmanaged: 'Login resource changed to unmanaged',
    ResourceUpdated: 'Login resource updated',
    RulesetCreated: 'Policy created',
    RulesetDeleted: 'Policy deleted',
    RulesetsDeleted: 'Policies deleted',
    RulesetUpdated: 'Policy updated',
    SAMLACSUpdated: 'SAML assertion consumer service  updated',
    SAMLConfigCreated: 'SAML configuration created',
    SAMLConfigDeleted: 'SAML configuration deleted',
    SAMLConfigPceSigningCert: 'Saml config pce signing cert has been generated/rotated',
    SAMLConfigUpdated: 'SAML configuration updated',
    SAMLSPCreated: 'SAML Service Destination created',
    SAMLSPDeleted: 'SAML Service Destination deleted',
    SAMLSPUpdated: 'SAML Service Destination updated',
    SecPoliciesCreated: 'Security policies created',
    SecPolicyPendingDeleted: 'Pending security policy deleted',
    SecPolicyRestored: 'Security policy restored',
    SecPolicyRestoredNotification: 'Restored security policy version',
    SecPolicyRuleCreated: 'Security policy rules created',
    SecPolicyRuleDeleted: 'Security policy rule deleted',
    SecPolicyRulesDeleted: 'Security policy rules deleted',
    SecPolicyRuleUpdated: 'Security policy rule updated',
    SecPrincipalDeleted: 'RBAC security principal deleted',
    SecPrincipalsBulkCreated: 'RBAC security principals bulk created',
    SecPrincipalsCreated: 'RBAC security principals created',
    SecPrincipalUpdated: 'RBAC security principal updated',
    SecureConnectGatewayCreated: 'Secure connect gateways created',
    SecureConnectGatewayDeleted: 'Secure connect gateway deleted',
    SecureConnectGatewayUpdated: 'Secure connect gateway updated',
    ServiceAccountCreated: 'Service Account created',
    ServiceAccountDeleted: 'Service Account deleted',
    ServiceAccountUpdated: 'Service Account updated',
    ServiceBindingCreated: 'Service binding created',
    ServiceBindingDeleted: 'Service binding deleted',
    ServiceBindingsDeleted: 'Service bindings deleted',
    ServiceCreated: 'Service created',
    ServiceDeleted: 'Service deleted',
    ServicesDeleted: 'Services deleted',
    ServiceUpdated: 'Service updated',
    SetServerSyncCheck: 'Set server synced',
    SettingsUpdate: 'Settings Update',
    SetupActivePceCreate: 'Active PCE created',
    SetupStandbyPceCreate: 'Standby PCE created',
    SLBCreated: 'Server load balancers created',
    SLBDeleted: 'Server load balancer deleted',
    SLBUpdated: 'Server load balancer updated',
    SoftLimitExceeded: 'Object creation soft limit exceeded',
    SourceIP: 'Source IP',
    SupportReportRequestCreate: 'Support report request created',
    SupportReportRequestDelete: 'Support report request deleted',
    SupportReportUpload: 'Support report',
    TempTableAutocleanupCompleted: 'Temp Table Auto Cleanup Completed',
    TempTableAutocleanupStartup: 'Temp Table Auto Cleanup Started',
    TLSChannelEstablished: 'TLS channel established',
    TLSChannelTerminated: 'TLS channel terminated',
    TrafficCollectorSettingCreate: 'Traffic Collector Setting Created',
    TrafficCollectorSettingDelete: 'Traffic Collector Setting Deleted',
    TrafficCollectorSettingUpdate: 'Traffic Collector Setting Updated',
    TrustedProxyIpsUpdated: 'Trusted Proxy IPs Updated',
    UserAcceptInvitation: 'User accepted invitation',
    UserAuthTokenCreated: 'User auth token created',
    UserCreated: 'User created',
    UserCSRFValidationFailed: 'User CSRF validation failed',
    UserDeleted: 'User deleted',
    UserInactiveLocalUsersPruneCheck: 'User inactive local users prune check',
    UserInvited: 'User invited',
    UserLogin: 'User login',
    UserLoginFailed: 'User login failed',
    UserLogout: 'User logout',
    UserPasswordChanged: 'User password changed',
    UserPasswordChangedAt: 'User password changed at',
    UserPasswordChangeFailure: 'User password change failed',
    UserPasswordComplexityNotMet: 'User password complexity not met',
    UserPasswordExpired: 'User expired password used',
    UserPasswordResetCompleted: 'User password reset completed',
    UserPasswordResetRequested: 'User password reset requested',
    UserPasswordUpdated: 'User password updated',
    UserResetPassword: 'User reset password',
    UserSignIn: 'User session created',
    UserSignOut: 'User session terminated',
    UserSynced: 'User synced',
    UserUpdated: 'User updated',
    UserVerifyMFA: 'Verify two-factor authentication code',
    VacuumDeactivatedAndDeletedWorkloads: 'Deactivated and deleted workloads have been vacuumed',
    VENAuthenticationMode: 'VEN in authentication recovery mode',
    VENClasAuthTokenRequested: 'VEN CLAS Authentication JWT Token requested',
    VENClearAuthRecoveryCondition: 'Clear VEN authentication recovery condition',
    VENClearConditions: 'Clear VEN conditions',
    VENCloneActivated: 'VEN clone activated',
    VENCloneDetected: 'VEN clone detected',
    VENCompatibilityCheckReportUpdate: 'VEN compatibility check report updated',
    VENFetchedPolicy: 'VEN fetched policy',
    VENFirewallStateTableThresholdExceeded: "VEN's firewall statetable's threshold has been exceeded",
    VENGoodbye: 'Workload shutdown',
    VENHasTooManyInterfacesReported: 'VEN reported more interfaces than the PCE supports',
    VENHostUnknown: 'VEN host: unknown',
    VENMachineIdentifiers: 'VEN machine identifiers updated',
    VENMaintenanceTokenGenerated: 'VEN tampering protection token has been generated',
    VENMaintenanceTokenInvalidTimeError: 'Please type a number',
    VENMaintenanceTokenOutOfRangeTimeError: 'Please type a number between 1 and 65,535',
    VENMaintenanceTokenRequested: 'VEN tampering protection token requested',
    VENMissedHeartbeatsCheck: 'VEN missed a few heartbeats',
    VENMissingHeartbeatAfterUpgrade: 'VEN missing heartbeat after upgrade',
    VENOfflineCheck: 'VEN marked offline',
    VENPaired: 'VEN paired',
    VENPolicyDeployedSuccessfully: 'VEN policy deployed successfully',
    VENPolicyDeployFailed: 'VEN policy deploy failed',
    VENProcessFailure: 'VEN process failure',
    VENPropertiesUpdated: 'VEN properties updated',
    VENRefreshToken: 'VEN refreshed token',
    VENReleaseDeleted: 'VEN release deleted',
    VENReleaseDeployed: 'VEN release deployed',
    VENRequestUpgrade: 'VEN request upgrade',
    VENRotateAuthSecret: 'VEN rotate authentication secret',
    VENsAuthenticationMode: 'VENs in authentication recovery mode',
    VENSelfSignedCertsCheck: 'VEN self-signed certificate housekeeping check',
    VENSelfSignedExpired: 'VEN self-signed certificate expired',
    VENSelfSignedGracePeriod: 'VEN self signed certificate in grace period',
    VENServiceUnavailable: 'VEN reported a service not running',
    VENSetDefaultVersion: 'Set a default VEN version',
    VENSettingsInvalidationErrorStateCheck: 'VEN settings invalidation error state check',
    VENSettingsUpdate: 'VEN settings updated',
    VENsUnpaired: 'VENs unpaired',
    VENSuspended: 'VEN suspended',
    VENTampering: 'VEN firewall tampered',
    VENUnauthenticatedClone: 'VEN unauthenticated clone detecte',
    VENUninstallTimeout: 'VEN uninstall timeout',
    VENUnpaired: 'VEN unpaired',
    VENUnsuspended: 'VEN Unsuspended',
    VENUpdateInteractiveUsers: 'VEN interactive users updated',
    VENUpdateIpTables: 'VEN updated existing iptables href',
    VENUpdateRunningContainers: 'VEN updated running containers',
    VENUpgradeRequested: 'VEN upgrade requested.',
    VENUpgradeSuccessful: 'VEN Upgrade Successful',
    VENUpgradeTimeOut: 'VEN Upgrade timed out.',
    VENUploadedIptablessRules: 'VEN uploaded iptables rules',
    VENWeakTLSCipherDetected:
      'This VEN’s TLS cipher suites are out of compliance. Connectivity to the PCE will be impacted after July 15th 2024. Refer to Illumio KB article #4066 for additional details',
    VirtualServerDeleted: 'Virtual server deleted',
    VirtualServersCreated: 'Virtual servers created',
    VirtualServerUpdated: 'Virtual server updated',
    VirtualServiceCreated: 'Virtual Service created',
    VirtualServiceDeleted: 'Virtual Service deleted',
    VirtualServiceNotCreated: 'Virtual Service not created',
    VirtualServicesCreated: 'Virtual services bulk created',
    VirtualServicesUpdated: 'Virtual services bulk updated',
    VirtualServiceUpdated: 'Virtual Service updated',
    VulnerabilityCreated: 'Vulnerability created',
    VulnerabilityDeleted: 'Vulnerability deleted',
    VulnerabilityReportDeleted: 'Vulnerability report deleted',
    VulnerabilityReportUpdated: 'Vulnerability report updated',
    VulnerabilityUpdated: 'Vulnerability updated',
    WorkloadApplyPolicy: 'Workload apply pending policy',
    WorkloadDuplicateInterfaceReported: 'Workload duplicate interface reported',
    WorkloadFlowReportingFreq: 'Workload flow reporting frequency changed',
    WorkloadFlowReportingFrequencyUpdated: 'Workload flow reporting frequency updated',
    WorkloadInterfaceDeleted: 'Workload interface deleted',
    WorkloadInterfacesCreated: 'Workload interfaces created',
    WorkloadInterfacesDeleted: 'Workload interfaces deleted',
    WorkloadInterfacesUpdated: 'Workload interfaces updated',
    WorkloadLabelsApplied: 'Workload labels applied',
    WorkloadMismatchedInterfaces: 'Workload updated its mismatched interfaces',
    WorkloadNatRulesPresent: 'Workload has NAT rules present',
    WorkloadNetworkRedetect: 'Workload network redetected',
    WorkloadOfflineVenGoodbye: 'Workload offline after ven goodbye',
    WorkloadOnline: 'Workload came back online, after being offline',
    WorkloadPartialPolicyDelivered: 'Workload partial policy delivered',
    WorkloadPolicyRecalc: 'Workload policy recalculated',
    WorkloadsBulkCreated: 'Workloads bulk created',
    WorkloadsBulkDeleted: 'Workloads bulk deleted',
    WorkloadsBulkImported: 'Workloads bulk imported',
    WorkloadsBulkRead: 'Workloads bulk read',
    WorkloadsBulkUpdated: 'Workloads bulk updated',
    WorkloadServiceReportsUpdated: 'Workload service reports updated',
    WorkloadSettingsUpdated: 'Workload settings updated',
    WorkloadsLabelsRemoved: 'Workloads labels removed',
    WorkloadsUnpaired: 'Workloads unpaired',
    WorkloadsUpdated: 'Workloads updated',
    WorkloadUpdated: 'Workload updated',
  },
  Explorer: {
    AcrossDenyRules: 'across Deny',
    AddressesExisting:
      'The following {count, plural, =0 {No addresses} =1 {# address} other {# addresses}} were not saved, because they are used by existing Workloads:',
    AddressHelp: 'Enter an IP Address or CIDR',
    AggregateExplorerResults: 'Aggregate Explorer Results',
    AllowAllConnections: 'Allow All Connections...',
    AllowAllConnectionsMessage:
      '{count, plural, =0 {No connections} =1 {# connection} other {# connections}} will be blocked by the Deny Rule when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow All Connections</span>.}}',
    AllowFilteredConnections: 'Allow Filtered Connections',
    AllowFilteredConnectionsMessage:
      '{count, plural, =0 {No filtered connections} =1 {# filtered connection} other {# filtered connections}} will be blocked by the Deny Rule when it is provisioned. {count, plural, =0 {} other {To allow all of these connections, click <span class="{className}">Allow Filtered Connections</span>.}}',
    AllowSelectedConnections: 'Allow Selected Connections...',
    AllPolicyDecisions: 'All Policy Decisions',
    ApplicationDisabled: 'Application Type must be set before the Explorer can be used',
    BlockedFlows: 'Blocked Flows',
    ByDenyRules: 'by Deny Rules',
    ByOverrideDenyRules: 'by Override Deny Rules',
    ByRule: 'by Rule',
    CalculateBasedOnLabelsDescription: 'Aggregate connections based on Source/Destination Labels.',
    CalculateBasedOnLabelsDescriptionDraft: 'Determine Policy Decisions using Label-set rules only.',
    CalculateBasedOnLabelsDraft: 'Quick Draft Rules',
    CalculateBasedOnUnmanagedIPDescription: 'Show Unmanaged IPs from the connections.',
    CalculateWorkloadBasedRulesDescription: 'Show connections between individual Source/Destination pairs.',
    CalculateWorkloadBasedRulesDescriptionDraft: 'Perform deep rule analysis to determine Policy Decisions.',
    CalculateWorkloadBasedRulesDraft: 'Deep rule analysis',
    CannotGetTrafficData: 'Cannot get traffic data',
    CheckboxLabelToRemoveItems: 'Check to select items to remove from Map and Traffic',
    ClearFilters: 'Clear Filters',
    ClearQuery: 'Clear Query',
    ClickGo: 'Then click "Go" to display traffic.',
    ClickRun: 'Then click "Run" to display traffic.',
    ClickToStartWritingRules: 'Click to start writing Rules',
    Closed: 'Closed',
    CommonSetOfLabels: 'Configure Common Set Of Labels',
    ConnectionsDisplayed: '({connections, number} displayed)',
    ConsumerApp: 'Source App',
    ConsumerApplications: 'Source Applications',
    ConsumerCount: '{count, plural, =0 {no Sources} =1 {# Source IP} other {# Source IPs}}',
    ConsumerEnforcement: 'Source Enforcement',
    ConsumerEnv: 'Source Env',
    ConsumerEnvironments: 'Source Environments',
    ConsumerHostname: 'Source Hostname',
    ConsumerIPList: 'Source IPList',
    ConsumerIs: 'Source is',
    ConsumerIsNot: 'Source is not',
    ConsumerLabels: 'Source Labels',
    ConsumerLoc: 'Source Loc',
    ConsumerLocations: 'Source Locations',
    ConsumerOrProviderIs: 'Source OR Destination is',
    ConsumerOrProviderIsNot: 'Source OR Destination is not',
    ConsumerProcessService: 'Source Process/Service',
    ConsumerRole: 'Source Role',
    CreateNewService: 'Create a new Service',
    CreateVirtualService: 'Create Virtual Service',
    CreateVSComplete: 'Creating Virtual Services Complete',
    CreateWorkloads: 'Create Unmanaged Workloads...',
    CreateWorkloadsComplete: 'Creating Workloads Complete',
    CustomRange: 'Custom Range',
    DashboardTitle: 'Inbound Traffic for Group:',
    DaysAgo: '{count, plural, =0 {no Days} =1 {# Day} other {# Days}} Ago',
    DeepDraft: 'Individual Connections',
    DeletePolicyMessage: 'Are you sure you want to delete this Policy?',
    DeletePolicyTitle: 'Delete Policy',
    DeprecationNoticePart1: 'We plan to deprecate Explorer and encourage users to access the new',
    DeprecationNoticePart2:
      'that incorporates the latest improvements which includes support for new label types, table, map and graph view',
    DestinationHostname: 'Destination Hostname',
    DestinationIPAddresses: 'Destination IP Addresses',
    DestinationName: 'Destination Name',
    DestinationNames: 'Destination Names',
    DestinationProcessService: 'Destination Process/Service',
    DestinationRoles: 'Destination Roles',
    DestinationSelections: 'Destination Selections:',
    Disabled: 'Explorer is Disabled',
    DisplayedInExplorer: 'Displayed in Explorer',
    DisplayedInTraffic: 'Displayed in Traffic',
    DownloadError: 'Unable to retrieve traffic data due to a system error.\nPlease try again later.',
    EditInParenthesis: '(Edit)',
    EmptyFilter: 'Empty Filter',
    EnableRuleWritingWithDraftAndLabels: 'Choose Draft View and Connections to enable Rule writing',
    EnableRuleWritingWithDraftView: 'Choose Draft View to enable Rule writing',
    EnableRuleWritingWithLabelBased: 'Choose Connections to enable Rule writing',
    Encap: 'Encap',
    EnterName: 'Enter a name for the saved filter',
    ExcludeConsumers: 'Exclude in Sources',
    ExcludeConsumersOrProviders: 'Exclude Sources Or Destinations',
    ExcludeProviders: 'Exclude in Destinations',
    ExcludeServices: 'Exclude in Services',
    ExcludeWorkloadsFromIpList: 'Exclude Workloads from IP List Query',
    ExportConnectionsDraft:
      'Export {flowsCount, number} connections in CSV format.\nInclude Reported and Draft policy decisions.',
    ExportConnectionsReported:
      'Export {flowsCount, number} connections in CSV format.\nInclude Reported policy decision.',
    FilterAlreadyExists: 'This filter property already exists in the filter. Try adding a different one',
    FirstDetected: 'First Detected',
    FlowCount: '{count, plural, =0 {No flows} =1 {1 Flow} other {# Flows}}',
    Format: 'Format',
    FQDNNotSupported: 'The IP List select contains an FQDN which is not supported as a Source Filter',
    HoursAgo: '{count, plural, =0 {no Hours} =1 {# Hour} other {# Hours}} Ago',
    ICMPCode: 'ICMP Code: {code}',
    ICMPType: 'ICMP Type: {type}',
    IncludeConsumers: 'Include in Sources',
    IncludeConsumersOrProviders: 'Include Sources Or Destinations',
    IncludeProviders: 'Include in Destinations',
    IncludeServices: 'Include in Services',
    IncludeWorkloads: 'Include Workloads',
    IncompleteResults: 'Incomplete Results',
    IncompleteTrafficFlows: 'Incomplete Traffic Flows',
    LabelGroupWarning:
      'The list of blocked connections may be incomplete due to the size or complexity of the Label Groups.',
    LastDay: 'Last Day',
    LastDays: 'Last {count, plural, =0 {no Days} =1 {24 Hours} other {# Days}}',
    LastHours: 'Last {count, plural, =0 {no Hours} =1 {Hour} other {# Hours}}',
    LastMonths: 'Last {count, plural, =0 {no Months} =1 {Month} other {# Months}}',
    LastWeeks: 'Last {count, plural, =0 {no Weeks} =1 {Week} other {# Weeks}}',
    LoadFilter: 'Load Filter',
    LoadingDraftPolicy: 'Loading draft policy',
    LoadResults: 'Load Results',
    MaxNumberOfConnections: 'Maximum Number of Connections',
    MemberShowsPartialPCEData:
      'Results based on data for this PCE only. To see results from all members, please run the query on the Leader.',
    MonthsAgo: '{count, plural, =0 {no Months} =1 {# Month} other {# Months}} Ago',
    MoreItems: '+ {numLists, number} More',
    NoBlockedConnections: 'No Denied Connections',
    NoInboundTraffic: 'No Inbound Traffic for this Group',
    NoRule: 'No Rule',
    NoTraffic: 'No Traffic available for selected filters.',
    NoTrafficForTimeInterval: 'No Traffic for selected time interval',
    NumFlows: 'Num Flows',
    NumOfMatchingFlowsExceedsConfMax: '* Number of matching connections exceeds the configured maximum',
    OrExcludeCP: 'Excluded Sources Or Destinations',
    OrExcludePC: 'Excluded Destinations Or Sources',
    OrIncludeCP: 'Included Sources Or Destinations',
    OrIncludePC: 'Included Destinations Or Sources',
    ParallelCoordinates: 'Parallel Coordinates',
    Policy: 'Policy',
    PolicyDecisions: 'Policy Decisions',
    PortsExclude: 'Excluded Services',
    PortsInclude: 'Included Services',
    ProcessService: 'Process/Service',
    ProviderApp: 'Destination App',
    ProviderApplications: 'Destination Applications',
    ProviderCount: '{count, plural, =0 {no Destinations} =1 {# Destination IP} other {# Destination IPs}}',
    ProviderEnforcement: 'Destination Enforcement',
    ProviderEnv: 'Destination Env',
    ProviderEnvironments: 'Destination Environments',
    ProviderIPList: 'Destination IPList',
    ProviderIs: 'Destination is',
    ProviderIsNot: 'Destination is not',
    ProviderLabels: 'Destination Labels',
    ProviderLoc: 'Destination Loc',
    ProviderLocations: 'Destination Locations',
    ProviderRole: 'Destination Role',
    QuickDraft: 'Label-Set Connections',
    Recent: 'Recent',
    RefreshDraft: 'Refresh Draft Policy',
    ReportedBy: 'Reported by',
    Reporter: 'Reporter',
    ResolveUnknownFqdns: 'Resolve Unknown FQDNs',
    Results: 'Results',
    ResultsAvailable: 'Results Available',
    ResultsSettings: 'Results Settings',
    ResultsSettingsInfoMessage:
      'For queries returning a large number of matches, increasing the maximum number of connections provides more complete results at the expense of slower performance. If the number of connections returned from the database exceeds the maximum displayed in the traffic view, all connections can be viewed by stepping through the results.',
    ResultsSettingsInfoMessageExplorer:
      'For queries spanning a long time period, increasing the maximum number of connections provides more complete results at the expense of slower performance. If the number of connections returned from the database exceeds the maximum displayed in Explorer, all connections can be viewed in the exported CSV file.',
    ReturnedFromDB: 'Returned from Database{numOfClusters, plural, =0 {} =1 {} other { per Region}}',
    RunAt: 'Run At',
    Saved: 'Saved',
    SaveFilter: 'Save Filter',
    SaveQuery: 'Save Query',
    SaveQueryAs: 'Save Query As',
    ScopedUserWarning: 'The data has been filtered to show only traffic applicable to your scope.',
    ScopedUserWarningExplorer: 'The Explorer data has been filtered to show only traffic applicable to your scope.',
    SelectConnectionsToStartWritingRules: 'Select Connections to start writing Rules',
    SelectFilters: 'Select filter parameters.',
    SelectIpsToStartCreatingWorkloads: 'Select Unmanaged IP Addresses to start creating workloads',
    SelectNewFilters: 'Select new filter parameters.',
    ServiceDoesNotExist: 'If a Service does not exist for a port/protocol',
    SortByNumberOfFlows: 'Sort By Number of Flows',
    SortByPortNumber: 'Sort By Port Number',
    SortByProcessName: 'Sort By Process Name',
    SourceExclude: 'Excluded Sources',
    SourceInclude: 'Included Sources',
    SourceIPAddress: 'Source IP Address',
    SourceIPAddresses: 'Source IP Addresses',
    SourceName: 'Source Name',
    SourceNames: 'Source Names',
    SourceProcessService: 'Source Process / Service',
    SourceRegion: 'Source Region',
    SourceRoles: 'Source Roles',
    SourceSelections: 'Source Selections:',
    SuperClusterWarning: 'Incomplete results were received from member PCE "{region}"',
    SwapConsumersProviders: 'Swap Destinations and Sources',
    Table: 'Table',
    TargetExclude: 'Excluded Destinations',
    TargetInclude: 'Included Destinations',
    Time: 'Time',
    TimedOut: 'Timed Out',
    TooManyFlowsDoYouWantToContinue:
      'Calculating the complete deep draft view may take a long time, do you want to continue?',
    TopPorts: 'Top Ports',
    TopSources: 'Top Sources',
    TotalConnections: 'Total Connections',
    TrafficDatabaseStorage: 'Traffic Database Storage',
    TrafficDatabaseStorageDaysAvailable: '{days, number} days of data available for queries',
    TrafficDatabaseStorageWarningAlertMessage: 'Storage is at {percent}% with less than {number} days of data.',
    UnmanagedIPConnections: 'Connections with unknown IPs',
    ValidRange: 'Valid range: {min, number} - {max, number}',
    ViewAllTraffic: 'View All Traffic',
    ViewPolicy: 'View Policy',
    VSCreated: '{count, plural, =0 {No Virtual Services} =1 {# Virtual Service} other {# Virtual Services}} Created',
    WeeksAgo: '{count, plural, =0 {no Weeks} =1 {# Week} other {# Weeks}} Ago',
    WorkloadsCreated:
      '{count, plural, =0 {No Workloads} =1 {# Unmanaged Workload} other {# Unmanaged Workloads}} Created',
    WrongValidRange: 'Number is not in valid range',
  },
  Exports: {
    ContainingAll: 'Containing All',
    Delete: 'Remove {count, plural, =1 {Export} other {Exports}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {selected Export} other {# selected Exports}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Export has} other {# Exports have}} been successfully deleted',
    FileName: 'File name',
    Formatted: 'Formatted as',
    NewExport: 'New Export',
    NoData: 'No Exports to display',
    PageName: 'Exports',
    ViewAllExports: 'View All Exports',
  },
  Firewall: {
    Coexistence: {
      AddScopeForFirewallCoexistence: 'Add Scope for Firewall Coexistence',
      AllConfirmation: "Adding 'All Workloads' will remove all other coexistence Scopes.",
      AllOnlyWarning: "'All Workloads' cannot coexist with other Scopes and Labels",
      BuildTest: 'Build / Test',
      BuildTestEnforced: 'Build / Test / Enforced',
      DefaultMode: 'Illumio is the exclusive firewall',
      EditScopeForFirewallCoexistence: 'Edit Scope for Firewall Coexistence',
      IllumioAsPrimaryFirewall: 'Illumio is Primary Firewall',
      ModeDesc:
        'Illumio is the exclusive firewall by default.\nConfigure firewall coexistence as needed for all workloads and/or specific labels.',
      NoData: 'Illumio is the exclusive firewall. There are no label settings for firewall coexistence',
      SetIllumioAsNotPrimaryFirewall: 'Do not set Illumio as the Primary Firewall',
      SetIllumioAsPrimaryFirewall: 'Set Illumio as the Primary Firewall',
      Title: 'Firewall Coexistence',
    },
    CoexistenceAllError: "Scope cannot be 'All Workloads' when selecting Enforced or Illuminated policy state.",
    DuplicatedScopesAndLabelsForFirewallCoexistence:
      'Cannot create another Firewall Coexistence Mode with the overlap Scope, irrespective of the Primary Firewall option selected',
    DuplicatedScopesAndLabelsForPolicyState: 'Static Policy with the same Scope has already been configured',
    Mode: 'Firewall Coexistence Mode',
    NoMatch: 'No matching Scopes to display',
    PolicyStateAllError: 'At least one named label must be selected to make Workload Static',
    ScopeRequired: 'Scope is required',
    StaticPolicyAllConfirmation: "Adding 'All Workloads' will remove all other Static Policy Scopes.",
  },
  FlowCollectionFilters: {
    Aggregate: 'Aggregate',
    Delete: 'Remove {count, plural, =1 {Flow Collection Filter} other {Flow Collection}}',
    DeleteConfirm: 'Are you sure you want to remove {count, plural, =1 {flow collection} other {# flow collections}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Flow Collection Filter has} other {# Flow Collection have}} been successfully deleted',
    DestinationIP: 'Destination IP Address',
    Drop: 'Drop',
    Errors: {
      Create: 'Unable to Create Flow Collection',
      Edit: 'Unable to Edit Flow Collection',
    },
    InvalidPortNo: 'Invalid Port Number',
    IPAddressHint:
      'Select an IP Address in the format 255.255.255.255<br>Class E IP addresses (240.0.0.0-255.255.255.254) are not permitted<br>For Any IP Address type 0.0.0.0/0',
    MatchCondition: 'When traffic matches the following conditions:',
    MatchingAggregate:
      'If a broadcast or multicast traffic flow is received by multiple workloads, aggregate all reported instances of that flow into a single record in the traffic database, and discard the destination workload information',
    MatchingDrop: 'Do not store matching flow information',
    NoFilters: 'No Flow Collection',
    PortHint: 'Select a port between 0 and 65535. Enter -1 for Any Port',
    ProtocolHint:
      'Protocol can be either TCP, UDP, ICMP or ICMPv6, TCP protocol is not compatible with multicast and broadcast transmissions',
    ProtocolRequired: 'Protocol is required for drop action',
    RemoveResultTitle: 'Result of {count, plural, =1 {flow collection} other {# flow collections}} removal',
    TakeAction: 'Take the following Action:',
    TypeIPOrCIDR: 'Type IP address or CIDR block',
    TypePort: 'Type Port',
  },
  Forms: {
    SelectOption: 'Select an option',
  },
  GlobalNetwork: {
    CorporateNatPublicIpsDesc:
      "When using Network Address Translation, this set of IPs appear for communication between Workloads of the corporate network and services on the Internet. This information is required to identify the interfaces on Endpoint Workloads that are connected to the corporate network vs. interfaces that connect to the Internet via an external network (e.g. home or public network) when the information cannot be obtained directly from the Endpoint Workload's operating system.",
    Delete: 'Remove Corporate Public IPs',
    GlobalIPs: 'Corporate Public IPs',
    RemoveConfirm: 'Are you sure you want to remove the selected Corporate Public IPs',
    RemoveResultTitle: 'Result of Corporate Public IPs removal',
    RemoveSingleConfirm: 'Are you sure you want to remove the Corporate Public IPs',
    RemoveSuccess: '{count, {Corporate Public IPs}} been successfully removed',
  },
  Groups: {
    GroupDashboardHelpTextSubtitle: 'Displays the Groups created and the inbound traffic for the selected Group.',
    GroupHelpTextSubtitle: 'Displays the Groups, their provision status, and the number of VENs.',
    GroupWizardHelpTextSubtitle: 'Helps create group and define security rules.',
    PairingProfiles: {
      TimeAndUseLimited: 'Time and Use Limited',
      TimeLimited: 'Time Limited',
      UseLimited: 'Use Limited',
    },
  },
  Health: {
    Ago: '{time} ago',
    Complete: 'Complete',
    CompletedCount: '{count} Completed',
    CoordinatorNode: 'Coordinator Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    CoordinatorNodeHealth: 'Coordinator Node Health',
    CoordinatorNodes: 'Coordinator Nodes',
    CoreNode: 'Core Node',
    CoreNodeHealth: 'Core Node Health',
    CPUUsage: 'CPU Usage',
    DatabaseReplication: 'Database Replication',
    DatabaseReplicationLag: 'Database Replication Lag',
    DataNodeHealth: 'Data Node Health',
    Days: '{days, plural, =0 {} =1 {DAY} other {DAYS}}',
    DaysHrsMinSec:
      '{days, plural, =0 {} =1 {# day} other {# days}} {hours, plural, =0 {} =1 {# hour} other {# hours}} {minutes, plural, =0 {} =1 {# minute} other {# minutes}} {seconds, plural, =0 {} =1 {# second} other {# seconds}}',
    DegradedServices: 'Degraded Services',
    DiskUsage: 'Disk Usage',
    DiskUsageLocation: '{val, number, percent} {location}',
    ErrorUnableToGetToNodeStatus: 'Error: Unable to get node status',
    FailedOptionalServices: 'Failed Optional Services',
    FailedServices: 'Failed Services',
    HideDetails: 'Hide Details',
    Hours: '{hours, plural, =0 {} =1 {HOUR} other {HOURS}}',
    LastSuccessfulSync: 'Last Successful Sync',
    LastSyncSuccessful: 'Last sync successful',
    LastSyncUnsuccessful: 'Last sync unsuccessful',
    LastUnsuccessfulSync: 'Last Unsuccessful Sync',
    Leader: 'Leader',
    ListenOnlyMode: 'Listen Only Mode',
    Member: 'Member',
    MemoryUsage: 'Memory Usage',
    Metrics: {
      TrafficDBMigrationBody: 'Some traffic flows may be missing from Explorer.',
      TrafficDBMigrationDetails: 'See PCE Health for more details.',
      TrafficDBMigrationTitle: 'Traffic database migration in progress',
    },
    Minutes: '{minutes, plural, =0 {} =1 {MIN} other {MINS}}',
    Node: 'Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    NodeRunlevel: 'Node Runlevel',
    NodeStatus: 'Node Status',
    NodeUptime: 'Node Uptime',
    Normal: 'Normal',
    Notifications: {
      NodeCpuStatus: '{node} CPU is in {status} status',
      NodeDiskStatus: '{node} disk is in {status} status',
      NodeMemoryStatus: '{node} memory is in {status} status',
      NodeNetworkReplicationStatus: 'Network Replication is in {status} status',
      NodeRunlevelNotFive: '{node} not running at runlevel 5',
      NodeServicesStatus: '{node} services are in {status} status',
    },
    NotRunning: 'Not running',
    OptionalServicesNotRunning: '{count, plural, =1 {# Optional service} other {# Optional services}} not running',
    Partial: 'Partial',
    PCEFunction: 'PCE Function',
    PCEHealth: 'PCE Health',
    PceInListenOnlyMode: 'PCE has been in Listen Only mode since {time}',
    PceInRollingUpgrade: 'Supercluster upgrade in progress',
    PCENodeHealth: 'PCE Node Health',
    PCEService: 'PCE Service',
    PCEServiceAlerts: 'PCE Service Alerts',
    PendingCount: '{count} Pending',
    Percent: '{val, number, percent}',
    PolicyDatabase: 'Policy Database',
    Primary: 'Primary',
    Replica: 'Replica',
    ReplicationLag: 'Replication Lag',
    ReportingDatabase: 'Reporting Database',
    RequiredServicesNotRunning: '{count, plural, =1 {# Required service} other {# Required services}} not running',
    RollingUpgrade: 'Health unavailable until upgraded',
    Seconds: '{seconds, plural, =0 {# seconds} =1 {# second} other {# seconds}}',
    SecondsBehindCluster:
      '{seconds, plural, =0 {# seconds} =1 {# second} other {# seconds}} behind <strong>{cluster}</strong> {unreachable, select, true {(Unreachable)} false {}}',
    Secs: '{seconds, plural, =0 {} =1 {SEC} other {SECS}}',
    ServicesDegraded: '{count, plural, =1 {# Service} other {# Services}} Degraded',
    ShowDetails: 'Show Details',
    SNC: 'SNC',
    Supercluster: 'Supercluster',
    SuperclusterHealth: 'Supercluster Health',
    SuperclusterIlluminationSync: 'Supercluster Illumination Sync',
    SuperclusterReplication: 'Supercluster Replication',
    SyncStatus: 'Sync Status',
    TrafficDatabase: 'Traffic Database',
    UnableToGetLastGeneratedTS: 'Unable to get last health check timestamp',
    UpgradeStatus: 'Upgrade Status',
    UpSinceDate: 'Up since {when, date, L_hh_mm_ss}',
    ViewPCEHealth: 'View PCE Health',
    VirtualAppliance: 'Virtual Appliance',
    Weeks: '{weeks, plural, =0 {} =1 {WEEK} other {WEEKS}}',
    WorkerNode: 'Worker Node {role, select, primary {(Primary)} replica {(Replica)} other {}}',
    WorkerNodeHealth: 'Worker Node Health',
    WorkerNodes: 'Worker Nodes',
  },
  Help: {
    Desc: {
      AcceptedCoreService:
        'Displays the list of core service recommendations that were accepted. Accepted recommendations are labeled. If the accepted service is an unknown IP address, an unmanaged workload is created automatically.',
      AccessConfiguration: 'PCE supports LDAP authentication with OpenLDAP and Active Directory.',
      AccessRestrictions:
        'As an admin, you can restrict access to API Keys that are used to automate the PCE management tasks. Restrictions are based on IP addresses and are used to block API requests coming in from non-allowed IP addresses.',
      ActiveVersion: 'Displays the policy currently enforced by the PCE.',
      AllowSelectedConnections:
        'Connections that you designate as being allowed to cross a Deny Rule. After creating a Deny Rule, you can select some or all connections that cross the boundary. The PCE web console then displays proposed rules for these connections that you can accept as is or modify.',
      APIKeys: 'Allow authentication with Illumio for making API calls.',
      APIKeySettings:
        'Set the expiration time for service account API keys, from 1 day to Never Expires. Specify how long expired service account API keys are retained in the PCE database, from 0 to 365 days.',
      Application: 'Traffic organized as a collection of Labels.',
      ApplicationDetailsRules:
        'Allow communication between multiple applications or entities in different Scopes or the same Scope.',
      ApplicationExplorer:
        "Queries the PCE's traffic database to analyze traffic flows for auditing, reporting, and troubleshooting purposes.",
      ApplicationLabel: 'Identifies the application instance of the Workload.',
      ApplicationList: 'View and edit Applications.',
      ApplicationMapSeverity:
        'You can select which severity level to consider when showing which traffic is going to vulnerable ports.',
      ApplicationPolicyGenerator:
        'Simplifies the policy creation process by recommending the optimal security policy for your Applications.',
      ApplicationSummaryReport:
        'Reports designed for application owners (for example, members of your business applications group like your Oracle or ServiceNow app admins) or other people in your organization who need to understand the security of you applications, such as IT security auditors (for example, auditors of PCI or HIPA systems).',
      ApplicationType: 'Configure Applications by selecting two or three matching Labels.',
      Attributes: 'Details about the Workload, such as VEN version, hostname, and uptime.',
      Blocked: 'No Rules in the active policy allow the traffic and Workload is in Enforced policy state.',
      CCServices: 'Kubelink creates a virtual service in the PCE for services in the container cluster.',
      ConnectedGroup: 'A collection of Workloads that communicates with another collection of Workloads.',
      Connection: 'Traffic summaries grouped by Role for traffic on different destination ports & protocols.',
      ConsumingApplications: 'Applications that use Services and can initiate connections.',
      ContainerClusters: 'An object used to store all the information about a container cluster in the PCE.',
      ContainerInheritHostPolicy:
        'Enforces host policy for all the docker containers running on the host when enabled.',
      ContainerWorkloadInIllumination:
        'After deploying containers, you can view container workloads and traffic associated with them in Illumination, Application Map, Vulnerability Map, and Explorer.',
      ContainerWorkloads: 'Represents a Pod in the PCE that is being secured by Illumio.',
      CoreServices:
        'Identify core services (such as DNS, Domain Controllers, NTP, and LDP) and use the suggested labels for them.',
      CoreServicesDetector:
        'A feature to detect and label workloads running core services based on the PCE analysis of traffic and process data.',
      CustomiptablesRules: 'Preserve any iptables configured on Linux hosts and add them to your policy as a Rule.',
      CustomLabelType: 'Any user-defined type created by editing the Label Settings.',
      CVEIds:
        'Unique public identifiers (designated by the NCF organization) for Common Vulnerabilities and Exposures (CVEs)',
      Destination: 'Workloads, services, or IP ranges that can initiate a connection to a destination.',
      Destinations:
        'Resource (such as a Workload, Virtual Service, or IP list) that processes requests from other resources.',
      DetectionModel:
        'The method that the PCE uses to detect core services: port matching, port-based machine learning (ML), or process-based ML. The methods are not configurable and the algorithms run continuously. Click the detection method to view more information.',
      DraftVersion: 'Displays the proposed changes that must be provisioned before they are applied to the policy.',
      DraftView: 'Previews expected policy coverage for unprovisioned changes.',
      DuplicateRuleset: 'Copy an existing Policy.',
      Enforcement:
        'Three states (Idle, Visibility Only, or Enforced) that determine whether the policy is applied and at what level.',
      EnforcementBoundaries:
        'Sets of rules that determine where rules apply. Deny Rules can block traffic from communicating with workloads you specify, while still allowing you to progress toward a Zero Trust environment. Unlike firewall policies, boundaries provide a simple policy model that does not depend on rule order.',
      EnforcementBoundaryBlockedConnections:
        'Page that displays the traffic flows blocked between workloads due to a Deny Rule. Review the blocked connections and add rules for connections that must traverse the boundary.',
      EnforcementBoundaryWorkflow:
        'After creating a Deny Rule, the PCE web console guides you through the process to review blocked connections and add any required rules (allowlist policy) to traverse the boundary.',
      EnvironmentLabel: 'Identifies the network environment of the Workload (such as Staging or Production).',
      EssentialServiceRules:
        'Essential Service Rules are programmed on the VEN to allow normal network functionality and communication with the PCE.',
      EventConfiguration: 'Configure the Severity, Retention Period, and Format for the events to be logged.',
      Events:
        'Provides an ongoing log of all Organization events that occur in the PCE, which you can filter by severity, status, event type, timestamp and also export the list.',
      EWExposure:
        '"East-West Exposure": A count of workloads that can use a vulnerable port with the currently provisioned rules',
      ExecutiveSummaryReport:
        'High-level reports designed to provide information to decision makers, such as an organization’s CISCO or VP if IT, about the overall deployment of Illumio within the organization’s computing environment.',
      Explorer:
        'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, consumers, providers, and services. Explorer is not an interactive tool, so you cannot write rules using it.',
      ExplorerSearchFormats:
        'View search results in the form of Parallel Coordinates, Table, or Unmanaged IP Addresses.',
      ExposedVulnerabilities: 'A list of vulnerabilities which will have exposure based on this rule',
      ExposureScore: 'The E/W Exposure Score combined with the Northern Exposure',
      ExtraScope: 'Allow communication between different Applications.',
      ExtraScopeBlocked: 'These vulnerabilities will have no Extra-Scope E/W Exposure after creating this policy',
      ExtraScopeConstrained:
        'These vulnerabilities will have some limited Extra-Scope E/W Exposure after creating this policy',
      ExtraScopeRules: 'Allow communication between applications.',
      ExtraScopeVulnerabilityExposure:
        'The number of vulnerabilities which have either some extra-scope E/W Exposure or no intra-scope E-W Exposure.',
      FirewallCoexistence:
        "Supplement Illumio's firewall with your organization's firewalls using Firewall Coexistence.",
      GlobalRoles:
        'Uses the ‘All Applications, All Environments, and All Locations’ scope. The scope for the Global roles cannot be changed.',
      HighestVEScore: 'The highest individual Vulnerability-Exposure score for this Workload',
      IKECertificates:
        "The IKE Certificates may display either the full or partial Distinguished Name that appears in the Issuer's field in an end-entity certificate. This filter is used to identify the proper end-entity certificate in a list of certificates. To configure Admin Access, you must indicate a valid IKE Certificate issuer.",
      IlluminationMap: 'Visualizes traffic flows between Workloads.',
      IlluminationPlus:
        'Analyze traffic flows for auditing, reporting, and troubleshooting purposes. You can view traffic by time, port, consumers, providers, and services. Illumination Plus is an interactive tool. You can write rules from the Table View and connections tab in the Map View.',
      IlluminationPlusSearchFormats: 'View search results in the form of Table, Map or Mesh.',
      InternetBlocked: 'These vulnerabilities will have no Northern Exposure after creating this policy',
      InternetConstrained: 'These vulnerabilities will have some Northern Exposure after creating this policy',
      InternetExposure:
        'Indicates whether a vulnerable port is exposed to traffic from an IP List. Northern Exposure is enabled by rules allowing inbound traffic on that port.',
      InternetVulnerabilityExposure:
        'The number of vulnerabilities which have either some Northern Exposure or no Northern Exposure.',
      IntraScope: 'Allow communication within an Application.',
      IntraScopeBlocked: 'These vulnerabilities will have no Intra-Scope E/W Exposure after creating this policy',
      IntraScopeConstrained:
        'These vulnerabilities will have some limited Intra-Scope E/W Exposure after creating this policy',
      IntraScopeRules: 'Allow communication within a specific Scope.',
      IntraScopeVulnerabilityExposure:
        'The number of vulnerabilities which have either some intra-scope E/W Exposure or no intra-scope E-W Exposure.',
      IPList: 'Set of IP addresses, an IP range, or CIDR blocks used to define an allowlist.',
      Kubelink:
        'A software component provided by Illumio for making integration between the PCE and the orchestration platform easier.',
      LabelAndLabelGroupsExcept:
        'In Labels and Label Groups Except, select labels that define roles, applications, environments, and locations you want to exclude from the policy.',
      LabelGroup:
        'A collection of Labels that can be used in a Rule or Scope to apply the policy to all the Workloads using those Labels.',
      LoadBalancers: 'Distributes network or application traffic across servers.',
      LocationLabel: 'Identifies the physical location of the Workload (such as AWS or US).',
      Managed: 'Resources that are paired with the PCE and have a VEN.',
      Microsegmentation:
        'Allows all Workloads within an App group to communicate with other Workloads within the same App group across all ports.',
      MyManagedTenants:
        'My Managed Tenants allows Managed Services Providers (MSPs) to onboard their customers into Illumio Edge (SaaS) and then manage and administer Edge on their behalf.',
      MyProfile: 'Set your profile and change your account password.',
      Nanosegmentation:
        'Allows Workloads with a specific Role Label to communicate with Workloads that have other Role Labels on ports where there is observed traffic between the Roles.',
      Networks:
        'A PCE provides visibility and policy enforcement for workloads that live on isolated networks with overlapping IP address space.',
      NoHelp: 'There is no help for this page. Click the link below to visit the support portal.',
      OfflineTimers: 'Set VEN Disconnect and Goodbye timers.',
      OpenShift:
        'Enterprise container-based PaaS built on top of Docker and Kubernetes, which adds additional capabilities such as, software defined networking.',
      OrganizationalOutboundPolicy:
        'Allow and deny rules at the organizational level (apply to all groups) for outbound traffic.',
      OrgEvents: 'A log of all user actions that occur in the PCE, such as login/logout.',
      PairingProfile:
        'Configuration that allows you to apply properties such as Labels or policy state to Workloads paired with the PCE.',
      PasswordPolicy:
        'Set password policies such as password length, composition (required number and types of characters), and password expiration, re-use, and history.',
      PCEHealth:
        'Monitor the PCE Health status by clicking the Health icon at the top of the page. The PCE Health page indicates whether the PCE is in a Normal, Warning, or Critical state of health. Tabs categorize the health information by Node, Application, Database Replication, and Supercluster.',
      PCESupportBundles:
        'Generate diagnostic information for this PCE. Displays individual node status until complete, then makes an aggregate support bundle available for download.',
      PolicyCheck:
        'Determines if Rules that allow communication between Workloads or a Workload and an IP address exist.',
      PolicyGeneratorSeverity:
        'You can select which severity level to consider when mitigating vulnerabilities by selecting Auto Level in the Rule Configuration.',
      PolicySettings:
        'When Display Scopes in Policies is set to No, each policy applies to all workloads. When it is set to Yes, policies apply only in specified environments, locations, or applications. Provisioning Setting describes context before provisioning rules. By default, it is set to ‘No’. On changing it to ‘Yes’, makes providing a ‘Provision Note’ mandatory before provisioning rules. Also allows the order of Destination and Source columns to be changed across the UI.',
      PolicySync: 'Applies recent active policy configurations to the Workload.',
      PortAndProtocol:
        'A port is an endpoint for TCP or UDP communications. A protocol is the set of rules used to send or receive communications.',
      PortBased: 'Machine learning detection method based on connections to specific ports.',
      PortMatch: 'Rule-based detection method based on connections to specific ports.',
      PotentiallyBlocked: 'No Rules in the active policy allow the traffic and Workload is in Test policy state.',
      ProcessBased: 'Machine learning detection method based on processes running on the servers.',
      ProvidedTraffic:
        'Traffic that has been observed into a vulnerable port, and if it was allowed, blocked or potentially blocked',
      ProvidingApplications: 'Applications that provide Services and cannot initiate connections.',
      ProvidingService:
        'Resource defined by a Rule that allows specific types of communication (such MySQL) between providers and consumers.',
      Provision:
        'Enacting any changes made to policy objects (Policies, IP Lists, Services, Label Groups, and security settings) by recalculating policy on the PCE and transmitting the changes to all VENs.',
      Provisioning:
        'If you have made any changes to provisionable objects, such as policies, IP lists, services, label groups, and security settings, you need to provision those changes before they can take effect. The orange badge on the Provision button indicates the number of changes that need to be provisioned. When you provision updates, the PCE recalculates any changes made to policies, IP lists, services, label groups, and security settings, and then transmits those changes to all VENs installed on your workloads.',
      ProvisionStatus: 'Displays if changes have been made but not provisioned.',
      RansomwareProtectionDashboard:
        'Provides detailed protection information for your managed server workloads regarding ransomware protection readiness, risk exposure, and protection coverage statistics. Endpoints and container workloads are not included in this dashboard.',
      RecommendedCoreService:
        'PCE displays detected core services as recommendations for creating policy along with suggested labels. You can modify the policy and suggested labels.',
      RecurringReports: 'Reports that are repeatedly generated on the following schedule: daily, weekly, or monthly',
      RefreshDashboard:
        'The PCE updates the information in this dashboard at regular intervals. To force an update, click <strong>Refresh</strong>.',
      RejectConnections: 'Workload instructed by the PCE to reject the blocked connection.',
      RejectedCoreService:
        'Displays the list of core service recommendations that were rejected. When you reject a core service recommendation, that IP address is no longer recommended as a provider of the requested core service.',
      ReportedView: 'Shows current policy coverage as reported by Workloads.',
      Reporting:
        'Generate, download, and manage two types of recurring reports: Executive Summary reports and Application Summary reports.',
      ReportRecurrence:
        'Removing a report from the Recurrence tab stops the report from running again. Select the report you want to stop generating and click Remove.',
      ReportRetentionPeriod:
        'Configure globally how long the PCE retains the PDF files generated for each report you add.',
      Reports:
        'Useful when you want to share data with team members who do not have access to the PCE. You can download PCE objects in JSON and CSV formats.',
      ReportType: 'Either an Executive Summary or Application Summary report.',
      Revert: 'Undoing any provisionable changes before they are provisioned.',
      RoleLabel: 'Defines the function of the Workload (for example, Web or Database).',
      Rule: 'Allows communication between multiple applications or entities providing Services and the applications or entities using the Services.',
      RuleCoverage: 'Percentage of total connections that are allowed by Rules.',
      RuleSearch: 'Search, analyze, and download Rules.',
      Rulesets: 'A collection of Rules that define allowed communications between Workloads based on their Scope.',
      Scope:
        'Applies Rules to a collection of Workloads that are identified by Label type (Application, Environment, & Location).',
      ScopedRoles: 'Assign users to roles with specific scopes.',
      SecureConnect: 'Dynamically encrypts Workload communications using IPsec.',
      SecureGateways:
        'Encrypts communications between managed workloads and the unmanaged workloads on the private subnet protected by the VPN gateway at the edge of the data center.',
      SecuritySettings: 'Organization-wide configuration for features such as DHCP.',
      SegmentationTemplate:
        'Allows you to quickly write a security policy to allow traffic for commonly used applications using a pre-defined set of Services, Policies, Labels, Label Groups, and IP Lists.',
      SelectiveEnforcementState:
        'Rules are enforced directionally for selected services when a workload is within the scope of a Deny Rule. To apply a Deny Rule to workloads, you must move the required workloads into the Selective Enforcement state.',
      ServiceAccounts:
        'Service Accounts include API keys by default so that you can use them to perform programmatic operations. A user account is not required to use a Service Account. Any permissions assigned to a Service Account apply to its API key.',
      Services:
        'Ports, protocols, or processes discovered by the VEN or defined by the user that specify the Service type and communication methods used by Workloads.',
      Severity: 'Represents a range of Vulnerability Score values.',
      SeverityCritical: '9.1 - 10 = Critical',
      SeverityHigh: '7.1 to 9.0 = High',
      SeverityInfo: '0 = Info',
      SeverityLow: '0.1 to 4.0 = Low',
      SeverityMedium: '4.1 - 7.0 = Medium',
      SID: 'Security identifier, a unique identification associated with each user or user group in the Active Directory.',
      Source: 'Workloads, services, or IP ranges that can initiate a connection to a source.',
      Sources:
        'Resource (such as a Workload, Virtual Service, or IP list) that can initiate connections to other resources or access a specified Service.',
      SSOConfig:
        'Configure a single sign-on (SSO) identity provider in the PCE to validate usernames and passwords against your own user management system.',
      StaticPolicy:
        'Control when updates to OS-level firewall rules are applied to your Workloads by adding static policy.',
      Status: 'Displays whether the Rule is enabled or disabled.',
      SupportReports:
        'Generate diagnostic information for the selected Workload. The report can be downloaded from the Support Reports page.',
      TotalApplicationVEScore: 'The sum of all Vulnerability-Exposure scores for this Application',
      TotalWorkloadVEScore: 'The sum of all Vulnerability-Exposure scores for this Workload',
      TrafficLines: 'Represents traffic that is blocked (red) or allowed (green) or unknown (gray).',
      Unmanaged: 'Allow you to write policy for resources that are not paired with the PCE and that do not have a VEN.',
      UserActivity: 'View the audit trail of user’s activity along with the permission and authentication details.',
      UserGroup:
        'Collection of users with access to the PCE that can be mapped to an Active Directory or used to control outbound access for specific Workloads.',
      UserProfile:
        'Contains the stored settings for your Illumio user account. A user can update information about their account, such as their preferred timezone, how to view policy in the UI, generate REST API keys, and view the permissions they have for managing security policy in Illumio.',
      UsersAndGroups: 'Add or remove local users, external users, and external groups.',
      UseWorkloadSubnets:
        'Select Use Workload Subnets if you want the peers to get the workloads’ subnets instead of the individual IPs. The subnets will be determined by the IP address and netmask reported by the VEN.',
      VenActions: 'Upgrade, unpair, refresh, and generate support bundles.',
      VENConnectivity: 'Whether VEN is online or offline.',
      VENDashboard:
        'Provides an operational dashboard to give customers broad, visual information about VEN statistics. The VEN Dashboard aggregates various system data and helps customers focus on important insights into their managed workloads.',
      VenInfo: 'Installed on each workload and provides information about the Workload and enforces policy rules.',
      VenPairingProfiles: 'Allows you to apply certain properties to Workloads as they pair with the PCE.',
      VenPairingScripts:
        'Contains a unique pairing key at the end of the script that identifies the VEN securely, so it can authenticate with the PCE.',
      Vens: 'PCE can be a repository for distributing, installing and upgrading the VEN software. PCE-based deployment of VENs replaces the external VEN repository.',
      Versions: 'Restore to any previous version of the Policy.',
      VEScore: 'A calculated value based on the Vulnerability Score and the Exposure Score.',
      VEScoreApplicationAggregation: 'For an Application it is a summation of all V-E Scores in the Application',
      VEScoreMultipleAggregation:
        'It can be shown for an individual vulnerability on a port for a single workload or as a summation of all the V-E Scores for a Workload, Role, or Application.',
      VEScoreWorkloadAggregation: 'For a Workload it is a summation of all V-E Scores on the Workload',
      VirtualServers: 'A server shared by multiple owners.',
      VirtualService:
        'Allow you to label processes or services on workloads. Illumination, Policy Generator, and Explorer support virtual services.',
      VirtualServices:
        'Allow you to associate a Label or set of Labels with processes or Services on Workloads to dynamically calculate Rules in your policy.',
      VulnerabilityName: 'The name of the vulnerability',
      VulnerabilityPortProtocol: 'The port and protocol associated with the vulnerability',
      VulnerabilityScore:
        'The summation of severities of the vulnerabilities for an Application, Role, or Workload where the individual vulnerability scores range between 0 and 10',
      Workload:
        'A discrete operating system instance running on a physical device, virtualization platform, or a cloud instance.',
      WorkloadCount: 'Number of workloads in the Application with this vulnerability',
    },
    Menu: {
      HelpMenu: 'Help Menu',
      ShowMore: 'Show More',
      VisitSupportPortal: 'Visit Support Portal',
    },
    Title: {
      ActiveVersion: 'Active Version',
      AllowSelectedConnections: 'Allow All or Allow Selected Connections',
      Connection: 'Connection',
      CustomiptablesRules: 'Custom iptables Rules',
      DraftVersion: 'Draft Version',
      EnforcementBoundaryWorkflow: 'Deny Rule Workflow',
      Microsegmentation: 'Microsegmentation',
      Nanosegmentation: 'Nanosegmentation',
      NoHelp: 'No Help Found',
      OrganizationalOutboundPolicy: 'Organizational Outbound Policy',
      Rule: 'Rule',
      RuleCoverage: 'Rule Coverage',
      SelectiveEnforcement: 'Selective Enforcement State',
      TrafficLines: 'Traffic lines',
    },
    TooltipInfo: {
      DenyRules:
        'Deny Rules allow setting up base network hygiene across your organization e.g. blocking risky ports, separating Production vs Non-Production. Deny Rules only apply to workloads in Selective Enforcement and you can create Policies to explicitly override Deny Rules.',
      PairingProfile:
        'Configuration that controls the pairing process of a Workload. A Pairing Profile contains a pairing script with a unique pairing key, Label and policy state assignment, command line restrictions, as well as limits on how many times the pairing script can be used and for how long.',
      SecureConnect:
        'Enables users to encrypt communication between Workload services dynamically using IPsec protocol.',
      VEN: 'Virtual Enforcement Node (VEN) is a local control point from the Illumio installed on each workload. VEN provides information about the workload and enforces policy rules by controlling the Linux iptables or Windows Filtering Platform (WFP) tables on a workload.',
      VirtualServer:
        'A PCE configuration that allows you to write a Policy for Virtual Servers whose traffic is managed by Load Balancers in your environment.',
      VirtualService:
        'A Service that originates from a Workload but is labeled separately and can be used in a Rule. Virtual services allow you to label processes or services on Workloads.',
    },
  },
  IlluminationMap: {
    AddRingFenceRuleTooltip: 'Allow all workloads in this group to communicate',
    AllGroupContainers: 'All Group Container Workloads',
    AllGroupVirtualServers: 'All Group Virtual Servers',
    AllGroupVirtualServices: 'All Group Virtual Services',
    AllGroupWorkloads: 'All Group Workloads',
    AssignedForUnmanagedWorkloads:
      'Assign Labels for {count} {count, plural, =1 {Unmanaged Workload} other {Unmanaged Workloads}}',
    AxisValue: 'Axis Value',
    BasicModeSearch: 'type to find anything',
    ChangeLinkStyle: 'Change Link Style',
    ChangeSort: 'Change Sorting Order',
    CheckFiltersExclude: 'Check to select items to exclude',
    CircularLayout: 'Make good use of available space.',
    ClearBrush: 'Clear Brush',
    ClickToViewDetailPanel: 'Click to view details',
    CloseTable: 'Close Table',
    CommonLabels: 'Common Set of Labels',
    ConsumerAndOrProvider:
      'Source {or, select, true {OR} false {AND}} Destination {not, select, true {is not} false {}}',
    ConsumerOrProvider: 'Source or Destination{not, select, true {is not} false {}}',
    ContainersWithTraffic: 'Container Workloads with Traffic',
    CreatedForUnmanagedWorkloads:
      '{count} {count, plural, =1 {Unmanaged Workload} other {Unmanaged Workloads}} Created. Do you want to recalculate the table without the newly created workloads?',
    CreateUnmanagedWorkloadInstruction: 'To create unmanaged workloads, first select an IP List.',
    CustomizeAxes: 'Customize Axis: Change order, remove axis.',
    DataCenterType: 'Data Center Type',
    DefaultFilters: 'Default Filters',
    DefaultGraph: 'Default Graph',
    DefaultGroupByRole: 'Default Group by:',
    DefaultQuery: 'Default Query',
    DefaultQueryCompletedNotification:
      'This graph is automatically prepared from the Default Query. To remove this query, click "Remove".',
    DefaultQueryWorkingNotification: 'This graph is automatically prepared from the Default Query.',
    DeniedByNoRule: 'Denied by No Rule',
    DestinationIPs: 'Destination IPs',
    DoNotRecalculate: "Don't Recalculate",
    ExportDraft: 'Export Draft View',
    ExportReported: 'Export Reported View',
    ExportResults: 'Export Results',
    ExportResultsCloudFlowsNotSupported: 'Export Cloud Traffic Flows not supported',
    ExportResultsDescription:
      'Export the Server and Endpoint Traffic Flows ({count} {count, plural, =1 {flow} other {flows}})\n (Cloud flows are not supported)',
    FilteringTips: 'Filtering Tips',
    FQDNExample: 'e.g. www.example.com',
    GlobalFilters: 'Global Filters',
    GoToApplicationMap: 'Go To Application map',
    GoToCloudMap: 'Go To Cloud Map',
    GoToDataCenterMap: 'Go To Data Center Application Map',
    HowToRead: 'How To Read',
    IlluminationClassic: 'Illumination Classic',
    IPAddressOrCIDRExample: 'e.g. {ipAddress} or {cidrBlock}',
    IPAddressOrCIDRExampleCaveat:
      'e.g. {ipAddress} or {cidrBlock} *CIDR block only available for Servers and Endpoints',
    IPListExample: 'Input the name of an IP list',
    IPListExampleCaveat: 'Input the name of an IP list *Only available for Servers and Endpoints',
    LabelSet: 'Label Set',
    LandingPageHeading: 'Traffic Exploration',
    LandingPageInstructions:
      'Choose query search parameters and press "Run", or load the results for a previous query by pressing "Load Results"',
    MapWarning:
      'User-defined Label Types are not supported in the Illumination Map. Use Illumination Plus to see a graph with user-defined Label Types',
    Mesh: 'Mesh',
    NodeTypes: 'Node Types',
    NoRuleUnenforcedDeny: 'No Rule / Unenforced Deny',
    NotVulnerable: 'Not Vulnerable',
    NumberOfFlows: 'Number of Flows',
    NumberOfWorkloads: 'Number of Workloads',
    OrganicLayout: 'Reduces overlaps in Label Sets, groups and traffic lines.',
    OverrideDeny: 'Override Deny',
    PaginationNextPrevInfo: 'Load {direction, select, next {older} previous {more recent}} connections',
    PaginationPageInfo: '{offsetStart, number} - {offsetEnd, number} of {flowsCount, number} connections',
    PaginationPageInfoShort: '{offsetStart, number} - {offsetEnd, number} of {flowsCount, number}',
    ParallelCoordinatesInfo1: 'Click on an axis value to drill down.',
    ParallelCoordinatesInfo2: 'Drag along an axis (brush) to filter on that dimension.',
    ParallelCoordinatesInfo3: 'Drag an axis title to reorder the axes.',
    PolicyDecisionsByDenyRules: 'Policy Decisions by Deny Rules',
    PolicyGeneratorWarning:
      'User-defined Label Types are not supported in the Policy Generator. Use Illumination Plus to create policy with user-defined Label Types',
    RemoveDefaultQuery: 'Remove Default Query',
    SaveFilterNameExists: 'A filter with that name already exists. This will overwrite the existing filter.',
    SaveFilterNamePlaceholder: 'Input a name',
    SaveFilterNameWithBlankSpaces: 'No white spaces are allowed and please enter a name.',
    SearchAppGroupsByName: 'search app groups by name',
    SelectedGroupTrafficFilters: 'Selected Group Traffic Filter',
    ServersAndEndpoints: 'Servers And Endpoints',
    SetAsDefault: 'Save as default Group By',
    ShowAllApplications: 'Show all Applications',
    ShowExclusionFilters: 'Show Exclusion Filters',
    ShowReportedPolicyDecisionFilter: 'Show Reported Policy Decision Filter',
    SortBy: 'Sort By',
    SourceIPs: 'Source IPs',
    StartBrush: 'Activate Brush: Select data ranges on the axis.',
    StopBrush: 'Deactivate Brush',
    TieredLayout: 'Highlights the source/destination relationship between Label Sets and groups.',
    Top: 'Top',
    TransmissionExample: 'Broadcast, Multicast, Unicast',
    UnenforcedDeny: 'Unenforced Deny',
    UnenforcedOverrideDeny: 'Unenforced Override Deny',
    UnmanagedConsumerIP: 'Unmanaged Source IP',
    UnmanagedProviderIP: 'Unmanaged Destination IP',
    UnmanagedWorkloadError:
      'An unexpected error occurred. Could not create {count, plural, =1 {unmanaged workload} other {unmanaged workloads}}.',
    ViewDetailPanel: 'View Details',
    ViewDetailPanelHint: 'View Details (left-click again)',
    ViewTable: 'View Table',
    ViewUnmanagedIPAddresses: 'View Unmanaged IP Addresses',
    VirtualServersWithTraffic: 'Virtual Servers with Traffic',
    VirtualServicesWithTraffic: 'Virtual Services with Traffic',
    Vulnerable: 'Vulnerable',
    WorkloadsWithTraffic: 'Workloads with Traffic',
    ZeroDayAddCloudOrgs: 'Add organizations or accounts to manage your cloud resources',
    ZeroDayPairOrInvite: 'Get started by pairing workloads and inviting users',
  },
  Illumio: {
    Copyright: 'Copyright © 2013-2024 Illumio, Inc. All Rights Reserved.',
    Name: 'Illumio',
    Welcome: 'Welcome to Illumio',
    Welcometitle: 'Welcome',
  },
  Insights: {
    InsightsHub: 'Insights Hub',
  },
  InstantSearch: {
    ClearAll: 'Clear All',
    RecentSearches: 'Recent Searches',
    SearchByFilter: 'Search {filter} by name',
    TabToFilter: 'Tab to filter',
  },
  IPLists: {
    AddNew: 'Add a new IP List',
    Any: 'Any (0.0.0.0/0 and ::/0)',
    AutomaticDisableValidation:
      'Disable validation of IP addresses and FQDNs is automatically enabled when a total count of {count} IP addresses and FQDNs is reached.',
    CIDRBlock: 'CIDR block',
    Delete: 'Remove {count, plural, =1 {IP List} other {# IP Lists}}',
    DeleteConfirm: 'Are you sure you want to remove this IP List?',
    EnableValidation: 'Disable validation of IP addresses and FQDNs',
    EnableValidationTooltip:
      'For performance reasons, it is recommended to disable real-time IP Address and FQDN validation when working with large sets of IP Addresses and FQDNs.',
    Errors: {
      Create: 'Unable to Create IP List',
      Edit: 'Unable to Edit IP List',
      Experimental: 'Experimental Class E not allowed',
      FQDN: 'Invalid FQDN Entry',
      Ip4: 'Invalid IPv4',
      Ip4Fqdn: 'Invalid IPv4 or DNS Entry',
      NoLeadingZero: 'The first octet cannot be 0',
      RequestFacetFail: 'Request to check against IP allowed names is failing. Please try again',
    },
    Exists: 'IP List <a>{name}</a> already exists',
    InvalidMessage: 'Please add a valid Name and IP Range.',
    IPAddresses: 'IP Addresses',
    IpName: 'New IP Name',
    List: {
      DuplicateErrorLineMessage: '{duplicateMessage} duplicate at line {duplicateAt}',
      Error:
        '{count, plural, =1 {<span class="{className}">#</span> Error} other {<span class="{className}">#</span> Errors}} (Hover over an Error to see details)',
      Exclusion:
        '{count, plural, =1 {<span class="{className}">#</span> Exclusion} other {<span class="{className}">#</span> Exclusions}}',
      Label: 'IP Address/Domain',
      Message: {
        CannotDeleteAny: 'This is a default IP List and cannot be edited or removed.',
        CannotDeleteBoundToEnforcementBoundary:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing Deny Rule',
        CannotDeleteBoundToRules:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing Rule',
        CannotDeleteBoundToSCG:
          'Cannot remove {count, plural, =1 {IP List that is} other {# IP Lists that are}} referenced by an existing SecureConnect Gateway',
        CannotEditLargeLists: 'This IP List is too large to be edited',
        RemoveConfirm: 'Are you sure you want to remove the selected IP {count, plural, =1 {List} other {Lists}}?',
        RemoveResultTitle: 'Result of {count, plural, =1 {IP List} other {IP Lists}} removal',
        RemoveSuccess: '{count, plural, =1 {IP List has} other {# IP Lists have}} been successfully removed',
      },
      OverlappingAddresses: 'Overlapping Addresses',
      OverlappingFQDN: 'Overlapping FQDN',
      OverlappingInterfaces: 'Overlapping Interfaces',
      Range: 'Must add a containing IP range',
      Warning:
        '{count, plural, =1 {<span class="{className}">#</span> Warning} other {<span class="{className}">#</span> Warnings}}',
      ZeroValidity: "0.0.0.0 is valid address, but it's not used as Any (0.0.0.0/0) and ::/0)",
    },
    Mixin: {
      CIDRBlock: 'CIDR Block',
      Comments: 'Comments',
      Excludes: 'Excludes',
      ExclusionInclusionText: 'The excluded IP address(es) must be within the included IP range.',
      ExclusionTip:
        'Use an exclamation point to <span class="{className}">exclude</span> an IP address, CIDR block or IP range.',
      FQDN: 'Fully Qualified Domain Name',
      HashTip: 'Use a hash symbol to indicate a line comment',
      HyphenTip: 'Use a hyphen to indicate an IP range',
      InstructionsExample: {
        CIDR: '192.168.100.0/24',
        CIDRV6: '2620:0:860:2::/64',
        Comment: '23.4.55.6 #Comment Text',
        Exclusion: '!192.168.100.0/30',
        ExclusionV6: '!3ffe:1900:4545:3:200:f8ff:fe21:67cf',
        IllumioFQDN: 'www.illumio.com',
        Range: '10.0.0.0-10.255.255.255',
        Single: '127.0.0.1',
        SingleV6: '2001:0db8:0a0b:12f0:0000:0000:0000:0001',
        Wildcard: '*.illumio.com',
      },
      IPListDescription: 'Type a description for the new IP List',
      IPListName: 'Type a name for the new IP List',
      IPVersions: 'IPv4 or IPv6',
      Ranges: 'IP Ranges',
      SingleIP: 'Single IP',
      SlashTip: 'Use a slash to indicate a CIDR Block',
      WildcardMatches: 'Wildcard Matches',
    },
    NoData: 'No IP Lists to display',
    NoMatchData: 'Not matching IP Lists to display',
    Remove: 'Remove IP List',
    TypeOrPasteAndEnter: 'Type or paste in one or more IP Addresses or wildcard FQDNs and then press enter',
    TypeOrPasteIPs: 'Type or paste IP addresses',
    TypeOrPasteQualifiedName: 'Type or paste fully qualified names',
  },
  KubernetesWorkloads: {
    K8sKind: 'k8s Kind',
    Kind: 'Kind',
  },
  LabelGroups: {
    AddMembers: 'Add new members',
    AddNew: 'Add a new Label Group',
    Create: {
      Placeholder: {
        LabelGroupDescription: 'Type a description for the new Label Group',
        LabelGroupName: 'Type a name for the new Label Group',
        LabelGroupType: 'Choose a type for the new Label Group',
      },
    },
    CreateError: 'Cannot Create Label Group',
    Delete: 'Remove {count, plural, =1 {Label Group} other {# Label Groups}}',
    DeleteConfirm: 'Are you sure you want to remove this Label Group?',
    DeleteMembers: 'Remove Label Group Members',
    DeleteMembersConfirm: 'Are you sure you want to remove the selected members from the Label Group?',
    List: {
      DeleteSelectedLabelGroups: 'Are you sure you want to remove the selected Label Groups?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Label Group} other {Label Groups}} removal',
      RemoveSuccess: '{count, plural, =1 {Label Group has} other {# Label Groups have}} been successfully removed',
    },
    MemberExist: 'Label Group Members exists',
    Members: 'Label Group Members',
    New: 'New Label Group',
    NoMembers: 'No Members',
    Types: 'Label Group Types',
    UpdateError: 'Cannot Update Label Group',
  },
  LabelRules: {
    ApplyInIntervals: 'Apply rules at a specified time at specified intervals',
    ApplyRegularly: 'Apply Rules Regularly',
    ApplyRules: 'Apply Rules',
    ApplyRulesCompletedNotificationMessage:
      "Matching workloads to labeling rules is complete. Click 'View' for results.",
    ApplyRulesErrorNotificationMessage: 'Matching workloads to labeling rules failed. Try again or contact support.',
    ApplyRulesExportFilenamePrefix: 'Workload_Label_Review_',
    ApplyRulesInProgressNotificationMessage:
      'Matching workloads to labeling rules.{percentComplete, select, 0 { Calculating progress...} other { Current progress: {percentComplete}%{remainingTime, select, null {} other { ({remainingTime} remaining)}}.}}',
    ApplyRulesLoadingMessage:
      'Currently matching workloads to labeling rules. This could take a moment. You are welcome to continue with your other tasks and return to this page at your convenience to view the results.',
    ApplyRulesNoUpdates: 'No updates to apply',
    ApplyRulesSuccessClickHereMessage: 'Click here to view the results.',
    ApplyRulesSuccessToastMessage: 'Matching workloads to labeling rules is complete.',
    ApplyVenActivation: 'Apply rules whenever a VEN is activated',
    ApplyWhenTriggered: 'Apply Rules when Triggered',
    AssignLabel: 'Assign a label',
    AssignLabelsSuccess: 'Labels have been assigned to {count, plural, =1 {1 workload} other {# workloads}}',
    Attribute: 'Attribute',
    ChooseCondition: 'Choose a condition',
    Condition: 'Condition',
    LabelingRules: 'Labeling Rules',
    LogicOperatorAnd: 'AND',
    LogicOperatorNot: 'NOT',
    LogicOperatorOr: 'OR',
    MatchingComplete: 'Matching Complete',
    MatchingInProgress: 'Matching in Progress',
    NoLabelingRules: 'There are no labeling rules defined',
    OperatorContains: 'contains',
    OperatorEndsWith: 'ends with',
    OperatorIs: 'is',
    OperatorIsIn: 'is in',
    OperatorRegex: 'regex match',
    OperatorStartsWith: 'starts with',
    PlaceholderTextHostname: 'Type a hostname',
    PlaceholderTextIP: 'Type an IP range or CIDR block',
    PlaceholderTextOS: 'Type an OS release',
    PlaceholderTextPortIs: 'Type a listening port (e.g. 22, 514 UDP, ICMP)',
    PlaceholderTextPortIsIn: 'Type a listening port range (e.g. 71-74, 80-90 TCP)',
    PlaceholderTextProcess: 'Type a process path (e.g. /usr/sbin/sshd)',
    PlaceholderTextRegex: 'Type a regular expression',
    RecurringRuleApplication: 'Recurring Rule Application',
    ReviewAndAssignLabels: 'Review and Assign Labels',
    RuleNumberValidRange: 'Valid range is 1 to {maxRuleNumber}',
    ScheduleLabelAssignment: 'Schedule Label Assignment',
    SchedulerDayOfMonth: 'day of the month',
    SchedulerDescriptionDaily:
      'Rules will be applied every day at {time}{applyOnVenActivation, select, false {.} true {, and when a VEN is activated.}}',
    SchedulerDescriptionMonthly:
      'Rules will be applied on the {day} of every month at {time}{applyOnVenActivation, select, false {.} true {, and when a VEN is activated.}}',
    SchedulerDescriptionVenActivation: 'Rules will be applied when a VEN is activated.',
    SchedulerDescriptionWeekly:
      'Rules will be applied every {days} at {time}{applyOnVenActivation, select, false {.} true {, and when a VEN is activated.}}',
    SchedulerThe: 'The',
    SlideOutSubTitle:
      'Click "Assign" to assign new Labels to the {count, plural, =1 {Workload} other {Workloads}} shown below',
    SlideOutTitle: 'Workloads that match criteria',
    SomethingWentWrong: 'Something went wrong. Try again or reach out to Illumio support for assistance.',
    Values: 'Values',
  },
  Labels: {
    AddNew: 'Add a new Label',
    AddScopeForStaticPolicy: 'Add Scope for Static Policy',
    AlreadyDeleted: 'The label is already deleted',
    Application: 'Application Label',
    Applications: 'Application Labels',
    Assign: 'Assign Labels',
    AssignVS: 'Create Virtual Service - Assign Labels',
    ClearMessage:
      'All {labelNames} Labels for {count, plural, =1 {Workload} other {<strong>#</strong> Workloads}} will be cleared.',
    ConsumerEdit: 'Edit Source Labels',
    Create: {
      Placeholder: {
        LabelName: 'Type a name for the new Label',
        LabelType: 'Select a type for the new Label',
      },
    },
    CreateVSPreview: 'Create Virtual Service - Preview',
    CurrentLabelsInfo: 'The current labels are showed in gray',
    CustomLabelType: 'Custom Label Type',
    Delete: 'Remove Label',
    DeleteConfirm: 'Are you sure you want to remove this Label?',
    Deleted: 'The label is deleted',
    DeleteSeveral: 'Remove Labels',
    Detail: {
      CreatedAt: 'Created at',
    },
    Edit: 'Edit Labels',
    EditScopeForStaticPolicy: 'Edit Scope for Static Policy',
    Environment: 'Environment Label',
    Errors: {
      Create: 'Unable to Create Label',
      Edit: 'Unable to Edit Label',
      UnableToRemove: 'Unable to Remove Label',
    },
    Excluded: 'All {type} except',
    Exists: 'Label <a>{name}</a> already exists',
    Group: 'Label Group',
    GroupExists: 'Label Group <a>{name}</a> already exists',
    Groups: 'Label Groups',
    GroupsCount: '{count, plural, =1 {# Label Group} other {# Label Groups}}',
    InUse: 'Labels in Use',
    InvalidLabel: 'Invalid Label',
    InvalidLabelCantSave: 'Cannot save invalid Label',
    List: {
      DeleteConfirm: 'Are you sure you want to delete the selected {count, plural, =1 {Label} other {Labels}}?',
      RemoveSuccess: '{count, plural, =1 {Label has} other {# Labels have}} been successfully removed',
    },
    Location: 'Location Label',
    ModifyForUnmanagedWorkloads:
      'Assign Labels for {count, plural, =1 {Unmanaged Workload} other {<strong>#</strong> Unmanaged Workloads}}.',
    ModifyForWorkloads:
      'Modify Label assignments for {count, plural, =1 {Workload} other {<strong>#</strong> Workloads}}.',
    NamePlaceholder: 'Label Name',
    New: 'New Label',
    NoData: 'No Labels to display',
    NoDataGroup: 'No Label Groups to display',
    NoMatchData: 'No matching Labels to display',
    NoMatchGroupData: 'No matching Label Groups to display',
    ProviderEdit: 'Edit Destination Labels',
    RoleHint: 'Select a Role',
    Select: 'Select {multiple, select, false {Label} true {Labels}}',
    SelectByName: 'Type or select a Label by name',
    SelectLabelTypes: 'Select all Label Types',
    SelectToFilterViews: 'Labels to filter',
    SelectType: 'Select {type} Label',
    TooManySelected: 'Too Many Selected Workloads',
    Type: 'Label type',
    UsedInContainerCluster: 'In use by one or more Container Clusters',
  },
  LabelSettings: {
    BackgroundColor: 'Background Color',
    BlankSpaceError: 'This field can not be blank space',
    Color: 'Color',
    CreateNewType: 'Create New Label Type',
    DisplayNamePlural: 'Display Name (plural)',
    DisplayNameSingular: 'Display Name (singular)',
    DragAndDropTooltip:
      'Reordering of the label types causes the labels to appear in that order across the UI. Reordering is supported only when the table is not sorted by any other column.',
    EditType: 'Edit Label Type',
    ForegroundColor: 'Foreground Color',
    Icon: 'Icon',
    IconPrefix: 'Icon + Prefix',
    IconSuffix: 'Icon + Suffix',
    InitialDuplicated: 'Label Type Initial already exists',
    InitialLetter: 'Initial Letter',
    InitialLimitError: 'Valid range 1-2 characters and must be unique',
    InitialRequired: 'Please enter a valid Label Type Initial',
    LabelGroupInUse: 'Label Group In Use',
    LabelIdentifier: 'Label Identifier',
    LabelInitial: 'Label Type Initial',
    LabelInitialDescription:
      'The label type initial is used to create a unique icon for the labels.\n Maximum character limit (2 characters).',
    LabelInUse: 'Label In Use',
    LabelKeyDescription: 'Enter a unique key for the new Label Type. (used internally)',
    LabelKeyDuplicated: 'Key already exists',
    LabelKeyRequired: 'Please enter a valid Key',
    LabelKeyTooLong: 'Key must not be more than 64 characters',
    LabelLabelGroupTypes: 'Label and Label Group Types',
    LabelNameDuplicated: 'Name already exists',
    LabelNameRequired: 'Please enter a valid Name',
    ReachLimitNumberNotification: 'You have reached the limit of {count} label types',
    RemoveLabelType: 'Remove Label Type',
    RemoveResultTitle: 'Result of {count, plural, =1 {Label Type} other {# Label Types}} removal',
    RemoveSuccessfully:
      'The following {count, plural, =1 {Label Type has} other {# Label Types have}} been successfully removed',
    RemoveWarningMessage:
      'Removing a label type decouples it from everywhere and not visible. Are you sure you want to remove the selected Label type?',
    SelectIcon: 'Select Icon',
  },
  Landing: {
    InviteUsers: 'Invite Users',
    ViewDocumentation: 'View Documentation',
  },
  LoadBalancers: {
    AddNew: 'Add a new Load Balancer',
    Create: {
      CreateManagement: 'Type the management IP or FQDN',
      CreateName: 'Type a name',
      CreatePassword: 'Type the Password',
      CreatePort: 'Type the Port Number',
      CreateUsername: 'Type the Username',
      HostTooLong: 'Hostname must contain fewer than {len, number} characters.',
      ManagementSame: 'Management IP/FQDN cannot be the same for both devices.',
      PasswordNotSame: 'Password is not the same',
      PasswordTooLong: 'Password must contain fewer than {len, number} characters.',
      UsernameTooLong: 'Username must contain fewer than {len, number} characters.',
    },
    Delete: 'Remove Load {count, plural, =1 {Balancer} other {Balancers}}',
    DeleteConfirm:
      'Are you sure you want to remove the selected Load {count, plural, =1 {Balancer} other {Balancers}}?',
    Detail: {
      BadCertificate: 'Certificate could not be verified',
      BadCredentials: 'Incorrect Username or Password',
      BadRequest: 'Bad Request',
      CannotConnect: 'Cannot Connect',
      CannotResolve: 'Cannot Resolve',
      DeviceManagement: 'Management IP or FQDN',
      DupDevice: 'Duplicate Device',
      Manage: 'Manage',
      NumberOfDevices: 'Number of Devices',
      Software: 'Software',
      TLSVerified: 'TLS Verified',
    },
    DeviceNumber: '{count, plural, =1 {# (Standalone)} other {# (HA Pair)}}',
    Errors: {
      Create: 'Unable to Create Load Balancer',
      Edit: 'Unable to Edit Load Balancer',
    },
    FirstDeviceProperties: 'First Device',
    List: {
      NotSupportedDesc:
        'Load balancer functionality is not enabled. Before you add a load balancer, pair a Network Enforcement Node with the load balancer functionality enabled with this PCE. For more information, see the <a>PCE Web Console Users Guide</a>',
      RemoveSuccess: '{count, plural, =1 {Load Balancer has} other {# Load Balancers have}} been successfully removed',
    },
    NoMatchData: 'No matching Load Balancer to display',
    Properties: 'Properties',
    SecondDeviceProperties: 'Second Device',
  },
  Map: {
    ApplicationConfigurationNotComplete: 'Application configuration is not complete',
    ApplicationDisabled: 'Application Type must be set before Applications can be generated and displayed',
    AssignLabelsCluster:
      'To write a security policy for this connection, first navigate to Group detail page and assign Scope Labels to all Workloads.',
    AssignLabelsTraffic:
      'To write a security policy for this connection, first navigate to Group detail page and assign all Labels to source and target Workloads.',
    AssignRoleOnLeveledMap: 'It may take a few minutes for this change to reflect correctly on the Map',
    CantChangeLabelsOnContainers: 'Cannot change the labels of Container Workloads.',
    CantPerformOperation: 'Cannot Perform Operation',
    CantPerformOperationMessage: 'Your user account does not have sufficient privileges to perform this operation.',
    CollapseConnectedGroupData: 'Collapse Connected Group',
    CollapseTrafficLinks: 'Collapse Traffic Link',
    ConnectedGroup: 'Connected Group',
    ConnectedGroups: 'Connected Groups',
    ConsumingApplications: 'Consuming Applications',
    Count: 'Count',
    CreateRuleset: 'Create Policy',
    CreateUnmanagedWorkload: 'Create Unmanaged Workload',
    CreateUnmanagedWorkloads: 'Create Unmanaged Workloads',
    Data: 'Illumination Map Data',
    DataAge: 'The Illumination map refreshed its data: {time}. To refresh the map now click Refresh.',
    DataGenerated: 'Map data refreshed: {time}',
    DataRefresh:
      'Refreshing the map can take several minutes. Reload your browser to see updates while the map refreshes its data.',
    DataTooLarge: 'Map data is too large and has been truncated.',
    Discovered: 'Discovered',
    DiscoveredGroup: 'Discovered Group',
    DoNotShowAgain: 'Do not show this again',
    DraftView: 'Draft View',
    DraftViewAllowed: 'Draft View > Allowed',
    DraftViewBlocked: 'Draft View > Blocked',
    EditPolicyState: 'Edit Policy State',
    EditPolicyStateWorkloadsOnly:
      'Editing the Policy State affects only the Workloads in this group. The Policy State of Workloads bound to Virtual Services must be edited separately.',
    EnterApplication: 'Enter an Application in the Search bar to view a map of connections',
    ExpandTrafficLinks: 'Expand Traffic Link',
    ExtraScope: 'Extra Scope',
    ExtraScopeConsumers: 'Extra Scope Sources',
    FlowVisibility: {
      BlockAllowed: 'VEN logs connection information for traffic which is allowed, denied or has no rule',
      BlockPlusAllowed: 'Denied + Allowed',
      BlockTraffic: 'VEN logs connection information for denied or no rule traffic only',
      EnhancedDataCollection: 'Enhanced Data Collection',
      EnhancedDataCollectionDesc:
        'VEN logs byte counts in addition to connection details for traffic which is allowed, denied or has no rule',
      HighDetail: 'High Detail',
      HighDetailDesc:
        'VEN logs allowed and blocked connection details (source IP, destination IP, protocol and source port and destination port)',
      IllumioEnhancedDataContact: 'Contact your Illumio sales representative',
      IllumioEnhancedDataDesc:
        'Enhanced Data Collection is a premium feature that allows you to monitor the amount of data transferred between workloads',
      LowDetail: 'Low Detail',
      LowDetailDesc:
        'VEN logs only blocked connection details (source IP, destination IP, protocol and source port and destination port), including all dropped packets',
      MixedDetail: 'Mixed Detail',
      NoDetail: 'No Detail',
      NoDetailDesc: 'VEN does not log traffic connection information',
      NoneDetail: 'VEN does not collect connection details',
    },
    Fqdn: {
      AddPolicy: 'Add Policy FQDN',
      DescriptionPlaceholder: 'Type a description for this Wildcard FQDN',
      Examples: 'hostname.illumio.com, *.illumio.com',
      Hint: 'When used in a rule, allow connections to the following Wildcard FQDN definition',
      Manage: 'Manage FQDN',
      Placeholder: 'Type a Wildcard FQDN name',
    },
    Illumination: 'Illumination map',
    JustNow: 'Just Now',
    LegendPanel: {
      BlockedOrNotVulnerabile: 'Blocked or not Vulnerable',
      Discovered: 'Cannot write Rules for Discovered Groups',
      Legend: 'Show Legend',
      LoadingData: 'Loading rule data',
      PartiallyAllowed: 'Partially allowed by Rules',
      PotentiallyBlockedVulnerableTraffic: 'Potentially Blocked Vulnerable Traffic',
      UnknownRuleStatus: 'Rules not calculated',
      VulnerableTraffic: 'Vulnerable Traffic',
    },
    LoadConnectedGroupData: 'Expand Connected Group',
    LoadingData: 'Loading map data',
    Locations: {
      LocationGroupsContains: 'This location contains {count} groups.',
      LocationGroupsFindGroup: "Use 'Search Groups' to view the map for a specific group.",
      LocationGroupsLimits: 'Only 200 groups are shown here.',
      LocationsExceedsMaximum:
        'The number of locations exceeds the maximum that can be displayed in Illumination. Please use the Search bar to select a location for viewing.',
    },
    NewerDataIsAvailable: 'More up-to-date Map data is available.',
    NoApplicationsInScope: 'No Applications are fully within your scope',
    NoIPAddressesForAny: 'No addresses available for Any(0.0.0.0/0)',
    NoLabeledWorkloads: 'Applications will be generated as Workloads are Labeled',
    NoPermission: 'The applicable rule is outside your user scope.',
    NoTraffic: 'No traffic has been reported between workloads. Try again later.',
    OtherConnectedGroups: 'Other Connected Groups',
    OverwriteEndpoint: 'Merge Rule - Overwrite {endpoint}',
    OverwriteEndpointMessage:
      "{endpoint} in the existing Rule will be overwritten because you have selected 'All Workloads'.",
    Policy: 'Policy Map',
    PolicyVersionPanel: {
      DraftViewDetails: 'Visualize draft policy to see what will happen when you provision changes',
      PolicyViewDetails: 'Show traffic based on Rules',
      ReportedViewDetails: 'Visualize policy coverage as reported by your Workloads',
      VulnerabilityViewDetails:
        'Show severity and exposure of workload vulnerabilities and when traffic is inbound to a vulnerable port.',
    },
    PortVulnerabilities: '{count} Port {count, plural, =1 {Vulnerability} other {Vulnerabilities}}',
    ProvidingApplications: 'Providing Applications',
    RecalculatingData: 'Calculating map data',
    RecentlyViewedApplications: 'Recently Viewed Applications',
    ReportedView: 'Reported View',
    RequestLimit: 'Already Using Increased Update Rate',
    RequestLimitMessage:
      'It has been less than {minutes, plural, =1 {one minute} other {# minutes}} since the Workload update rate was increased for this Group. Increased update rate is still in effect.',
    Search: {
      ContainerWorkload: 'Container Workload: {workload}',
      Group: 'Group: {group}',
      Location: 'Location: {location}',
      VirtualServer: 'Virtual Server: {virtualServer}',
      VirtualService: 'Virtual Service: {virtualService}',
      Workload: 'Workload: {workload}',
      WorkloadWithRole: 'Workload: {workload} ({role})',
    },
    SearchApplications: 'Choose an Application',
    SearchConnectedGroups: 'Search Connected Groups',
    SearchConsumingApplications: 'Search Consuming Applications',
    SearchGroups: 'Search Groups',
    SearchIllumination: 'Search Illumination',
    SearchIpAddresses: 'Search IP Addresses',
    SearchProvidingApplications: 'Search Providing Applications',
    SuperClusterDataRefresh: 'This will also sync data from all Supercluster members.',
    TooManyConnectedGroups:
      'Exceeded limit of the number of Connected Groups that can be displayed.\n Open the Application Map to view the Connected Groups.',
    Traffic: {
      Broadcast: 'Broadcast',
      ClearCounters: 'Clear Traffic Counters',
      ClearForApp: 'For all endpoints in this Group',
      ClearForLocation: 'For all endpoints in this Location',
      ClearForMissingRole: 'For all endpoints without a Role label in this Group',
      ClearForOrg: 'For all endpoints in this Organization',
      ClearForRole: 'For all endpoints with matching Role labels in this Group',
      ClearTrafficMessage: 'Permanently delete traffic data for all links',
      CoreServices: 'DNS/DHCP/IPsec',
      Filter: {
        AllowedByRule: 'Allowed by Rule',
        AllowExtraGroup: 'Extra-Group',
        AllowIntraGroup: 'Intra-Group',
        BlockedNoRule: 'Blocked (No Rule)',
        FilterByConnections: 'Filter by number of connections',
        IgnoreServices: 'Ignore Selected Services',
        NoResult: 'No results. Try different filters.',
        PotentiallyBlockedNoRule: 'Potentially Blocked (No Rule)',
        SinceLastProvision: 'Since Last Provision',
      },
      Multicast: 'Multicast',
      ServiceCount: '{count, plural, =1 {Service} other {Services}}',
      TopServices: 'Top Services',
      Unicast: 'Unicast',
    },
    TrafficLinks: 'Traffic Links',
    TryAgain: 'Try again after viewing the Detail Map.',
    ViewBoundContainerWorkloads: 'View Bound Container Workloads',
    ViewBoundWorkloads: 'View Bound Workloads',
    ViewConnectedGroups: 'View Connected Groups',
    Vulnerability: 'Vulnerability Map',
    Workloads: {
      AddToGroup: 'Add Workload to Group',
      AddToGroupMessage:
        "When a Workload is added to a Group, the Group's Application, Environment, and Location Labels are applied to the Workload.",
      AddToOtherGroup: 'Move Workloads to Other Group',
      AddToOtherGroupMessage:
        'This will impact the Rules associated with the Workloads. And, all the Workloads will acquire the Application, Environment, and Location Labels of the new Group.',
      AddVirtualServerToGroup: 'Add Virtual Server to Group',
      AddVirtualServerToGroupMessage:
        "When a Virtual Server is added to a Group, the Group's Application, Environment, and Location Labels are applied to the Virtual Server.",
      AddVirtualServiceToGroup: 'Add Virtual Service to Group',
      AffectedCount: '{count, plural, =0 {No Workloads} =1 {1 Workload} other {# Workloads}} affected',
      ApplicationTooManyToDisplay: 'Too many Workloads in this Application to display',
      CantDiscovery: 'Cannot Add or Remove Workloads',
      CantDiscoveryMessage: 'You cannot add or remove Workloads from Groups in the Discovered state.',
      GroupTooManyToDisplay: 'Too many Workloads in this group to display',
      Limit: 'Too Many Workloads',
      LimitMessage:
        'The Workload update rate cannot be increased for this Group because it contains too many Workloads.',
      MustHaveLocationLabels: 'Workloads must have Location Labels to be seen in this view',
      RemoveFromGroup: 'Remove Workloads from Group?',
      RemoveFromGroupMessage:
        'This will impact the Rules associated with the Workloads. And, all the Workloads’ Application, Environment, and Location Labels will be removed',
      RemoveFromGroupNewRole: 'You can optionally select a new Role Label for the Workloads.',
      RemoveOneFromGroup: 'Remove Workload from Group',
      RemoveOneFromGroupMessage:
        'When a Workload is removed from a Group, the Application, Environment, and Location Labels are removed from the Workload.',
      RemoveVirtualServerFromGroup: 'Remove Virtual Server from Group',
      RemoveVirtualServerFromGroupMessage:
        'When a Virtual Server is removed from a Group, the Application, Environment, and Location Labels are removed from the Virtual Server.',
      RemoveVirtualServiceFromGroup: 'Remove Virtual Service from Group',
      RemoveVirtualServiceFromGroupMessage:
        'When a Virtual Service is removed from a Group, the Application, Environment, and Location Labels are removed from the Virtual Service.',
      Role: '{count, plural, =0 {}  =1 {1 Role} other {# Roles}}',
      RoleContainerWorkloads: '{count, plural, =0 {}  =1 {1 Container Workload} other {# Container Workloads}}',
      RoleTotalWorkloads: '{count, plural, =0 {}  =1 {1 Workload} other {# Total Workloads}}',
      RoleTotalWorkloadsOfTotal: '{count, plural, =0 {}  =1 {1 Workload} other {# of {total} Total Workloads}}',
      RoleVirtualServices: '{count, plural, =0 {}  =1 {1 Virtual Service} other {# Virtual Services}}',
      RoleWorkloads: '{count, plural, =0 {}  =1 {1 Workload} other {# Workloads}}',
      RoleWorkloadsOfTotal: '{count, plural, =0 {}  =1 {1 Workload} other {# of {total} Workloads}}',
      TooManyToDisplay: 'Too many Workloads to display',
    },
  },
  Menu: {
    AlarmsAndEvents: 'Alarms and Events',
    ContainerClusters: '{multiple, select, false {Container Cluster} true {Container Clusters}}',
    Infrastructure: 'Infrastructure',
    LoadBalancers: 'Load Balancers',
    Navigation: 'Navigation menu',
    Networks: '{multiple, select, false {Network} true {Networks}}',
    Switch: 'Switch',
    Switches: 'Switches',
  },
  MSP: {
    ActiveEdgeVENs: 'Active Edge VENs',
    ActiveTenants: 'Active Tenants',
    ActiveVENs: 'Active VENs',
    AddNewTenant: 'Add New Tenant',
    AddProduct: 'Add {product}',
    CountMSP: '{count, number}',
    CustomerDomain: 'Customer Domain',
    DeleteConfirm:
      'Removing a tenant deletes all associated resources and unpairs any VENs that are paired.<br/>Are you sure you want to remove selected {count, plural, =1 {Tenant} other {# Tenants}}?',
    DeletedSuccessfully: '{count, plural, =1 {tenant has} other {# tenants have}} been successfully removed',
    DeleteTitle: 'Remove {count, plural, =1 {Tenant} other {Tenants}}',
    EndpointVENs: 'Endpoint VENs',
    Errors: {
      Create: 'Unable to Create tenant',
      Edit: 'Unable to Edit tenant',
    },
    Hints: {
      CompanyURL: "The customer's company website URL",
      CustomerDomain: 'Must be a globally unique name in the form of a domain (example.com)',
    },
    // ManagedTenants: 'Managed Tenants',
    ManageTenant: 'Manage Tenant',
    MyManagedTenant: 'My Managed Tenant',
    MyManagedTenants: 'My Managed Tenants',
    MySubscription: 'My Subscription',
    NoData: 'No tenants to display',
    Placeholder: {
      Address: 'Type an address for the new tenant',
      City: 'Type a city for the new tenant',
      CompanyURL: "Type a company's URL for the new tenant",
      Country: 'Select a country',
      Domain: 'Type a domain for the new tenant',
      Name: 'Type a name for the new tenant',
      PostalCode: 'Type a postal code for the new tenant',
      Province: 'Type a state, province, or territory for the new tenant',
      State: 'Select a state',
      ZIPCode: 'Type a ZIP code for the new tenant',
    },
    ProductTenant: '{product} Tenant',
    ServerVENs: 'Server VENs',
    TenantManagement: 'Tenant Management',
    WelcomeMessage: 'You can create, view and manange tenants in this portal',
    WelcomeMessageLearnMore:
      "If you want to learn more functionality of the Illumio's Multi-tenant portal, please visit the help portal.",
    WelcomeTitle: 'Welcome to Illumio Multi-tenant Portal',
  },
  MultilineInput: {
    ShiftDeleteToDeleteRow: 'Use "shift-delete" to delete a row',
    TypeOrPaste: 'Type or Paste Input and press enter',
  },
  NavigationAlert: {
    Details: 'Navigation error details',
    Error: 'Navigation has been cancelled due to an error',
    ErrorPage: 'The page failed to load due to an error',
    NoConnection: 'Navigation has been cancelled due to inability to establish connection to the PCE Server',
    NoConnectionPage: 'The page failed to load due to inability to establish connection to the PCE Server',
    ProvideRequestId: 'Provide this Request-ID when contacting your support team',
    Timeout:
      'The PCE server seems too busy to be reached, you could try again later or use another page in the meantime',
    Warning:
      'The next page seems to be taking longer than usual to load, continue waiting or press stop to cancel navigation',
    WarningInitial: 'This page seems to be taking longer than usual to load, please continue waiting',
  },
  Network: {
    Delete: 'Remove {count, plural, =1 {Network} other {# Networks}}',
    Errors: {
      Create: 'Unable to Create Network',
      Edit: 'Unable to Edit Network',
      NoScope: 'User defined network should have at least one scope with label(s)',
    },
    IPType: 'IP Type',
    LinkLocal: 'Link Local',
    Message: {
      CannotDeleteBoundToVirtualService: 'Cannot delete Network while referenced by an active or draft Virtual Service',
      CannotDeleteBoundToWorkflowInterface: 'Cannot delete Network while referenced by a Workload Interface',
      RemoveConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Networks} other {Networks}}?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Network} other {# Network}} removal',
      RemoveSuccess: '{count, plural, =1 {Network has} other {# Networks have}} been successfully removed',
      ScopeCannotBeEmpty: 'Network scope cannot be empty. This scope will be removed after saving.',
      SystemDefaultIsReadOnly: 'This is a default Network and cannot be edited or removed.',
    },
    NetworkSummary: 'Network Summary',
    RemoveConfirm: 'Are you sure you want to remove the Network?',
    ReservedNetworkNames: 'The names "Private" and "Internet" are reserved for System use.',
    SelectIPVersionFirst: 'First, Select an IP Version.',
    Subnets: 'Subnets',
    SystemDefined: 'System Defined',
    UserDefined: 'User Defined',
  },
  ObjectLimit: {
    ContactIllumioSupport: 'Contact Illumio support.',
    HardLimitReached: '{object} hard limit reached',
    IllumioHardLimitReached: 'Illumio {object} hard limit reached',
    RequestId: 'Request-ID: {id}',
  },
  ObjectSelector: {
    AdvancedOptions: 'Advanced Options(cmd+a)',
    FilteringTipsContent: "Use 'in' to search in a category",
    FilteringTipsContentLabels:
      'Use prefix to search by label type. For example,\na: Application\ne: Environment\nLabel prefixes can be found in the Label Settings',
    FilteringTipsLabel: 'Filtering Tips({os, select, mac {cmd} windows {ctrl}}+i)',
    LoadingValues: 'Loading Values...',
    ManyMatchCount: '{name} – {count, number} of {total, number}+ Total',
    MatchCount: '{name} – {count, number} of {total, number} Total',
    MatchedCount: '{name} – {matchedDisplayed, number} of {matchedTotal, number} Matched',
    MatchingResult: 'Matching Result',
    MatchingResults: 'Matching Results',
    MatchingResultsDisabled: 'Cannot match *',
    NoMatchingResults: 'No matching results',
    RecentlyUsed: 'Recently Used. Type to find more...',
    SearchAllCategories: 'Search All Categories',
    ShowAll: 'Show all...',
    ShowAllCategories: 'Show All Categories',
    ShowAllMatches: 'show all matches',
    TypeToSearchObject: 'Type to search {object}',
    TypeToShowMore: 'Type to show more',
    TypeToShowMoreObject: 'Type to show more {object}',
    TypeToShowObject: 'Type to show {object}',
  },
  OfflineTimers: {
    CleanUpQuarantineWorkloads1Hour:
      'After waiting 1 hour without receiving a heartbeat, consider the server disconnected, and push an updated policy to remove the IP addresses of the disconnected servers from their peer workloads',
    CleanUpQuarantineWorkloadsEndpoint:
      'After waiting 1 day without receiving a heartbeat, consider the endpoint disconnected, and push an updated policy to remove the IP addresses of the disconnected endpoints from their peer workloads',
    CleanUpQuarantineWorkloadsEndpointSpecificTime:
      'After waiting for the specified time without receiving a heartbeat, consider the endpoint disconnected, and push an updated policy to remove the IP addresses of the disconnected endpoints from their peer workloads',
    CleanUpQuarantineWorkloadsSpecificTime:
      'After waiting for the specified time without receiving a heartbeat, consider the server disconnected, and push an updated policy to remove the IP addresses of the disconnected servers from their peer workloads',
    ConfirmChanges: 'Confirm Timer Setting Changes',
    CustomDisconnectError: 'This setting must be higher than the value of the Disconnect and Quarantine Warning',
    CustomNotificationError: 'This setting must be less than the value of the Disconnect and Quarantine Timer',
    CustomNotificationRequirement:
      'This setting must be greater than 5 minutes (300 seconds) and less than the value of the Disconnect and Quarantine Timer',
    CustomTimeout: 'Custom timeout',
    CustomUninstallWaitTimeMaxError: 'Valid range {min} hour - {count} {unit}',
    CustomWaitTimeError: 'Wait time must be a minimum of {count, plural, =1 {# second} other {# seconds}}',
    CustomWaitTimeErrorHours: 'Wait time must be a minimum of {count, plural, =1 {# hour} other {# hours}}',
    CustomWaitTimeMaxError: 'Valid range {min} seconds - {count} {unit}',
    DaysNumber: 'Custom timeout - {count, plural, =1 {# day} other {# days}}',
    DecommissionIpCleanupEndpointNote: 'Note: The default option of 1 day (86400 seconds) offers higher security.',
    DecommissionIpCleanupNote: 'Note: The default option of 15 minutes (900 seconds) offers higher security.',
    DecommissionSideNote:
      'Time period to wait (default = 15 minutes) after a managed workload sends a goodbye message to mark it offline.',
    DecommissionSideNoteEdge:
      'Time period to wait (default = 1 day) after a managed workload sends a goodbye message to mark it offline.',
    DecommissionTimer: 'Decommission and IP Cleanup Timer',
    DescTitle: 'With this setting the PCE will:',
    DisableEndpoint: 'Disabled - {default}',
    Disconnect: 'Disconnect and Quarantine Timer',
    DisconnectNotification: 'Disconnect and Quarantine Warning',
    DisconnectNotificationSideEndpointNote:
      'Time period to wait (default = Disabled) with no heartbeat before a warning is emitted',
    DisconnectNotificationSideNote:
      'Time period to wait (default = one-quarter of the Disconnect and Quarantine Timer) with no heartbeat before a warning is emitted',
    DisconnectQuarantineNote: 'Note: The default option of 1 hour (3600 seconds) offers higher security.',
    DisconnectSideNote:
      'Time period to wait (default = 1 hour) with no heartbeat before a managed workload is marked offline.',
    DisconnectSideNoteEdge:
      'Time period to wait (default = 1 day) with no heartbeat before a managed workload is marked offline.',
    DisconnectTimer20minOrLess:
      'If the Disconnect and Quarantine Timer is set to 20 minutes or less, the warning will be emitted 5 minutes after the previous VEN heartbeat. In no case will a warning be emitted fewer than 5 minutes since the previous VEN heartbeatbefore emitting a warning event indicating that the endpoint VEN has missed heartbeats. The endpoint VEN will appear in a warning state on the VEN pages',
    EndpointMessage: 'The offline timer settings apply to all workloads that are endpoints',
    FifteenMinutes: 'Wait 15 minutes - High Security {default}',
    FifteenMinutesCleanup:
      'After waiting for 15 minutes, push an updated policy to remove the IP addresses of the servers that have sent a goodbye message from their peer workloads',
    HoursNumber: 'Custom timeout - {count, plural, =1 {# hour} other {# hours}}',
    IgnoreGoodbyeMessages: 'Ignore goodbye messages from the server VEN',
    IgnoreGoodbyeMessagesEndpoint: 'Ignore goodbye messages from the endpoint VEN',
    KeepAllIPsAndNeverAutoCleanup:
      'After the server VEN is stopped or the host is gracefully shut down, continue to keep the previously reported IP addresses in policy',
    KeepAllIPsAndNeverAutoCleanupEndpoint:
      'After the endpoint VEN is stopped or the host is gracefully shut down, continue to keep the previously reported IP addresses in policy',
    KeepAllIPsAndNeverAutoRemove: 'IP addresses of disconnected VENs can be removed by unpairing the VENs',
    ListenGoodbye: 'Listen for goodbye messages from the server VEN',
    ListenGoodbyeEndpoint: 'Listen for goodbye messages from the endpoint VEN',
    MinDisconnect: 'Minimum is {count, plural, =1 {# second} other {# seconds}}',
    MinGoodbye: 'Minimum is 0 seconds',
    MinUninstall: 'Minimum is 1 hour and Maximum is 185 days.',
    MinutesNumber: 'Custom timeout - {count, plural, =1 {# minute} other {# minutes}}',
    Months: 'Months',
    MonthsNumber: 'Custom timeout - {count, plural, =1 {# month} other {# months}}',
    MustManuallyUninstallVenAfterSevenDays:
      'After 7 days, the VEN record may be completely removed from the PCE. All API requests from this VEN will fail, and the VEN must be manually uninstalled',
    NeverCleanUp: 'Never remove IP addresses - Highest Availability',
    NeverCleanUpOption: 'Never Clean up',
    NeverDisconnectOrQuarantine:
      'Keep the previously reported IP addresses in policy, even if the server VEN has not heartbeat for an extended time',
    NeverDisconnectOrQuarantineEndpoint:
      'Keep the previously reported IP addresses in policy, even if the endpoint VEN has not heartbeat for an extended time',
    NeverTimeoutOption: 'Never Timeout or Quarantine',
    NeverTimeoutOrQuarantine:
      'If the Disconnect and Quarantine Timer is set to "Never Timeout or Quarantine", a warning event will be emitted if 15 minutes have passed since the previous VEN heartbeat',
    NotificationBodyHint:
      '- selecting a longer warning timer may reduce the time between when the warning is emitted and when the workload is removed from policy, leading to potential connectivity issues.\nIllumio recommends that the warning is set to at least 10 minutes (2 VEN heartbeat intervals) so that transient connectivity issues between the PCE and VEN do not result in a large number of warning events.',
    OfflineTimers: 'Offline Timers',
    OneDayCleanup:
      'After waiting for 1 day, push an updated policy to remove the IP addresses of the endpoints that have sent a goodbye message from their peer workloads',
    OneDayWait: 'Wait 1 day - High Security {default}',
    OneHourWait: 'Wait 1 hour - High Security {default}',
    OneQuarterWait: 'Wait one-quarter of the Disconnect and Quarantine Timer - {default}',
    RequiresManualRemoval: 'IP addresses of decommissioned VENs can be removed by unpairing the VENs',
    SecondsNumber: 'Custom timeout - {count, plural, =1 {# second} other {# seconds}}',
    ServerMessage: 'The offline timer settings apply to all workloads that are servers',
    SevenDayUninstallWait: 'Wait 7 days {default}',
    SpecifiedTimeVenRecordRemovedSideNote:
      'After the specified time period, the VEN record may be completely removed from the PCE. All API requests from this VEN will fail, and the VEN must be manually uninstalled',
    SpecifiedVenHeartbeatIntervalSideNote:
      'If the VEN heartbeats anytime within the specified interval, the VEN will be instructed to uninstall itself. Other API requests from this VEN in this time interval will fail',
    Title: 'PCE to Workloads Global Offline Timers',
    UninstallBodyHintWarning:
      '- after the specified time period, API requests from VENs unpaired from the PCE will fail, leading to frequent “authentication failed” errors. The VEN must be manually uninstalled from the host. Use a longer timeout if VENs are often unpaired from the PCE but hosts are offline for an extended time before coming back online.',
    UninstallIfHeartBeatInSevenDays:
      'If the VEN heartbeats anytime within the 7-day interval, the VEN will be instructed to uninstall itself. Other API requests from this VEN in this time interval will fail',
    UninstallSideNote: 'Time period to wait (default = 7 days) with no heartbeat before a VEN Uninstalls itself',
    UninstallTimeout: 'VEN Uninstall Timeout',
    UninstallTimer: 'VEN Uninstall Timers',
    Wait1Day: 'Wait for 1 day for the endpoint VEN to heartbeat',
    Wait1Hour: 'Wait for 1 hour for the server VEN to heartbeat',
    WaitForSpecifiedAfterUnpairedNotification:
      'Wait for the specified time period after a VEN is unpaired from the PCE before timing out the VEN unpair request',
    WaitForSpecifiedNotification:
      'Wait for the specified time period for the server VEN to heartbeat before emitting a warning event indicating that the server VEN has missed heartbeats. The server VEN will appear in a warning state on the VEN pages',
    WaitForSpecifiedNotificationEndpoint:
      'Wait for the specified time period for the endpoint VEN to heartbeat before emitting a warning event indicating that the endpoint VEN has missed heartbeats. The endpoint VEN will appear in a warning state on the VEN pages',
    WaitForSpecifiedTime: 'Wait for the specified time period for the server VEN to heartbeat',
    WaitForSpecifiedTimeEndpoint: 'Wait for the specified time period for the endpoint VEN to heartbeat',
    WaitForSpecifiedTimeGoodbye:
      'After waiting for the specified time period, push an updated policy to remove the IP addresses of the servers that have sent a goodbye message from their peer workloads',
    WaitForSpecifiedTimeGoodbyeEndpoint:
      'After waiting for the specified time period, push an updated policy to remove the IP addresses of the endpoints that have sent a goodbye message from their peer workloads',
    WaitOneQuarter:
      'Wait one-quarter of the Disconnect and Quarantine Timer for the server VEN to heartbeat before emitting a warning event indicating that the server VEN has missed heartbeats. The server VEN will appear in a warning state on the VEN pages',
    WaitTime: 'Wait Time',
    WaitTimeRequired: 'Wait time field is required',
    Warning:
      '- Selecting the {option} option or choosing a very high custom timeout degrades the security posture because the PCE will not remove IP addresses that belonged to workloads that have been disconnected from those that were allowed to communicate with the disconnected workloads. You will need to remove the disconnected workloads from the PCE to ensure that their IPs are removed from the policy.',
    WillWaitSevenDaysUninstall:
      'Wait 7 days after a VEN is unpaired from the PCE before timing out the VEN unpair request',
  },
  PairingProfiles: {
    AddNew: 'Add a new Pairing Profile',
    CommandLineOverrides: 'Command Line Overrides',
    CreateWithVEN:
      'Install the selected VEN version. If not selected, installs the default VEN version. When using the default VEN version, VENs paired using the pairing line may have different versions depending on the default VEN version at pairing time. If no default VEN version is specified, VENs paired using the pairing line will use the latest compatible VEN version.',
    CustomTimeRange: 'Custom Time Range',
    Delete: 'Remove Pairing {count, plural, =1 {Profile} other {Profiles}}',
    DeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {the selected Pairing Profile} other {# Pairing Profiles}}?',
    Detail: {
      AllowRequests: 'Allow pairing requests from all valid keys generated by this Pairing Profile',
      DenyRequests: 'Deny pairing requests from all keys generated by this Pairing Profile',
      URI: 'URI',
      VENTypeWarning:
        'VEN version {version} is supported only on server VENs. This pairing profile cannot be used to pair endpoint VENs',
    },
    Errors: {
      Create: 'Unable to Create Pairing Profile',
      Edit: 'Unable to Edit Pairing Profile',
      Start: 'Unable to Start Pair',
      Stop: 'Unable to Stop Pair',
    },
    InitialVenVersion: 'Initial VEN Version',
    Install: 'Install the selected VEN version',
    Key: 'Key',
    KeyGen: 'Generate key',
    KeyMaxAge: 'Maximum Key Age',
    KeyUsage: 'Key Usage & Lifespan',
    LabelAssignment: 'Label Assignment',
    LastUse: 'Last Used On',
    List: {
      Message: {
        DeleteConfirm:
          'Are you sure you want to remove the selected Pairing {count, plural, =1 {Profile} other {Profiles}}?',
      },
    },
    Mixin: {
      CanSetLabel: '{labelTypeName} Label can be set',
      KeyLifespan: 'Key Lifespan',
      Placeholder: {
        PairingProfileDescription: 'Type a description for the new Pairing Profile',
        PairingProfileName: 'Type a name for the new Pairing Profile',
      },
      UsesPerKey: 'Uses Per Key',
    },
    NoData: 'No Pairing Profiles to display',
    NoMatchData: 'No matching Pairing Profiles to display',
    OneUse: 'One Use',
    OneWorkloadCanPaired: 'Only one Workload can be paired using this Pairing Profile',
    Pair: 'Pair',
    Pairing: 'Pairing',
    PairingKeys: 'Pairing Keys (active)',
    Pause: 'Pause Pairing',
    Profile: 'Pairing Profile',
    Profiles: 'Pairing Profiles',
    Resume: 'Resume Pairing',
    SinglePairing: 'Single pairing',
    Start: 'Start Pairing',
    Status: 'Pairing Status',
    Stop: 'Stop Pairing',
    TLSWarning:
      'If you are pairing an older OS that does not support TLS 1.2, then the pairing script may fail to communicate with the PCE. In case of failure, you may upgrade your OS to support TLS 1.2 or use alternate means of pairing',
    Unlimited: 'unlimited',
    UnlimitedTime: 'No time limit set for pairing Workloads with this Pairing Profile',
    UnlimitedUses: 'Unlimited Uses',
    UnlimitedWorkloadCanPaired: 'Unlimited Workloads can be paired using this Pairing Profile',
    VenRelease: 'Current Default - {venVersion} ({venType})',
    VenReleaseDefault: 'Use the default {venType} version ({venVersion} is the current default)',
    VenReleaseNoDefault: 'Use the latest {venType} version ({venVersion})',
    VENType: {
      EndpointSub:
        'Activate endpoint VENs with pairing keys generated via this pairing profile. VENs that do not support endpoint mode cannot be activated using this pairing profile.',
      Legacy: 'Specified during VEN activation',
      LegacyCreatePage: 'Specified during VEN activation (deprecated legacy option)',
      LegacySub: 'Specify that a VEN should run in endpoint mode via command-line option.',
      ServerSub:
        'Activate server VENs with pairing keys generated via this pairing profile. VENs that do not support server mode cannot be activated using this pairing profile. VENs cannot be activated using a command-line option to specify endpoint mode with this pairing profile.',
    },
    VENVersionWarningMessage: 'The selected VEN version supports only server VENs and may not be used on endpoints.',
    VersionErrorMessage: 'VEN release {version} is supported only on endpoints',
    WhenKeyGenerated: 'Starting from when the key is generated',
    WindowsVersionErrorMessage:
      'VEN release {version} is supported only on endpoints. The “-endpoint true” option must be specified in the pairing script prior to installation',
    Workloads: {
      AIX: 'AIX OS Pairing Script',
      AllowCustomLabels: 'Allow custom Labels',
      AllowCustomLabelsDesc: 'Allow the following Workload Label assignment during script execution',
      AllowPolicyAssignmentDesc: 'Allow Enforcement to be set during script execution',
      Dependencies: 'Dependencies',
      GeneratedOn: 'Generated On',
      InitialState: 'Initial Workload State',
      Lifespan: 'Lifespan',
      Linux: 'Linux OS Pairing Script',
      LockLabelAssignment: 'Lock Label assignment',
      LockLabelAssignmentDesc: 'Deny Workload Label assignment during script execution',
      LockPolicyAssignmentDesc: 'Deny Enforcement changes during script execution',
      LockSomeLabels: 'Lock some of the Labels',
      LockSomeLabelsDesc: 'Deny Label assignment changes to the following Labels: {labels}',
      MacOS: 'macOS Pairing Script',
      OperatingSystems: 'Operating Systems',
      PairingKeySettings: 'Pairing Key Settings',
      PairingScripts: 'Pairing Scripts',
      PickProfile: 'Pick a Pairing Profile',
      RemainingUses: 'Remaining Uses',
      RequiredPackages: 'Required OS Packages',
      SourcePairingProfile: 'Source Pairing Profile',
      SupportedVersions: 'Supported Versions',
      Windows: 'Windows OS Pairing Script',
    },
  },
  PasswordPolicy: {
    CharactersAndPositions: 'characters and positions from current password',
    CharactersLower: 'characters',
    ComplexityChars: {
      Lowercase: 'a-z',
      Number: '0-9',
      Symbol: '!@#$%^&*<>?',
      Uppercase: 'A-Z',
    },
    ContinueSession: 'Continue Session',
    DaysLower: 'days',
    Errors: {
      Integer: 'Value must be an integer',
      Number: 'Value must be a number',
      Required: 'Value cannot be empty',
    },
    ExpirationDescription: 'The password will expire...',
    Logout: 'Log Out',
    LogoutWarningDescription:
      'You will be logged out automatically{seconds, plural, =0 {} =1 { in <s>#</s> second} other { in <s>#</s> seconds}}',
    MinutesLower: 'minutes',
    PasswordChanges: 'password changes',
    PasswordExpirationRetention: 'Password expiration and reuse',
    PasswordPolicy: 'Password Policy',
    PasswordPolicyEdit: 'Password Policy Edit',
    PasswordRequirements: 'Password requirements',
    PolicyKeys: {
      CharacterCategories: 'Character categories',
      Expiration: 'Expiration',
      MinimumCharacters: 'Minimum characters per category',
      MinimumLength: 'Minimum length',
      ReuseHistory: 'Reuse History',
      Similarity: 'Similarity',
      Timeout: 'Timeout',
    },
    RequirementIncreasedWarning:
      'Password requirements increased. All Users will need to reset their password at next login.',
    ReuseHistoryDescription: 'Do not allow password to be reused until after...',
    SessionTimeout: 'Session Timeout',
    SessionTimeoutDescription: 'Session will timeout...',
    SessionTimeoutWarning: 'Session Timeout Warning',
    SimilarityDescription: 'Do not allow a password unless it changes...',
    ValidHint: {
      RangeXtoY: 'Valid range {rangeStart, number} - {rangeEnd, number}',
      RequiredXorMore: 'Required {count, number} or more',
    },
  },
  PCE: {
    FQDN: 'FQDN',
    ItemUnderDifferentPCE: 'This is managed by a different PCE.',
    Started: 'PCE started',
    Stopped: 'PCE stopped',
  },
  PCESupportBundles: {
    BasicContent: 'Basic Content',
    DeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this PCE support bundle} other {# selected PCE support bundles}}?',
    DeletedSuccessfully:
      '{count, plural, =1 {PCE support bundle has} other {# PCE support bundles have}} been successfully removed',
    DeleteTitle: 'Remove {count, plural, =1 {PCE Support Bundle} other {PCE Support Bundles}}',
    Errors: {
      GenerateSupportReport: 'Unable to generate PCE support bundle',
    },
    FilterEventsBy: 'Filter events by:',
    GeneratePCESupportBundle: 'Generate PCE Support Bundle',
    LogCollection: 'Log Collection',
    LogCollectionDesc: 'Collect PCE software logs for analysis by Illumio',
    OptionalContent: 'Optional Content',
    PageName: 'PCE Support Bundles',
    PCEHealthCheck: 'PCE Health Check',
    PCEHealthCheckDesc: 'Includes information on system health and environment health',
    PCEHostSystemInventory: 'PCE Host and System Inventory',
    PCEHostSystemInventoryDesc: 'Includes basic hardware and software information',
    Status: {
      Blocked: 'Unable to transfer report. Check connectivity to fileserver.',
      Busy: 'Unmanaged support report in progress.',
      Cancelled: 'This request has been cancelled.',
      Corrupt: 'Archive damaged. Check disk space.',
      Error: 'Unable to run support report command. Check for installation or resource issues.',
      Failed: 'Unable to complete bundle. Check disk space against expected bundle size.',
      Missing: 'Unable to find report. Check disk space.',
      Partial: 'Incomplete support bundle ready for download.',
      Pending: 'Waiting for all operations to complete.',
      Purged: 'This file has been removed.',
      Rejected: 'Unable to transfer report. Check report size.',
      Retry: 'Issue creating file UUID. Please try again.',
      Unknown: 'Unable to decode status.',
    },
  },
  Pill: {
    Tooltip: {
      UpdateTypeTooltip:
        '{object, select, service {Service} iplist {IP List} labelGroup {Label Group} virtualService {Virtual Service} virtualServer {Virtual Server}} is in {updateType, select, create {Addition} update {Modification} delete {Deletion}} pending status',
      UserGroupIsDeleted: 'User Group is deleted',
      WorkloadIsDeleted: 'Workload is deleted or unpaired',
    },
  },
  Policy: {
    ActiveVersion: 'Active version',
    // AdvancedPolicy: 'Advanced Policy',
    BindingsWithoutProvision: 'Bindings can be changed without having to provision the changes',
    BuildDesc: 'Build Rules without events',
    ChangeFailed: 'Failed to apply policy changes',
    Check: 'Policy Check',
    Configuration: 'Policy Configuration',
    DestinationFirst: 'Display Destination Column First',
    Drafts: 'Drafts',
    DraftsAndPolicyVersions: 'Drafts & Versions',
    EditDraftVersion: 'Edit the draft version.',
    IdleDesc: "The VEN is installed but no changes have been made to Workload's firewall or iptables settings",
    IPListHasUnsavedChangesPending: 'The IP list has unsaved changes pending',
    ManagedStateChangeErrorWorkloadProfile:
      'Unable to Edit {count, plural, =1 {# Container Workload Profile} other {# Container Workload Profiles}}',
    ManagedStateChangeMessageWorkloadProfile:
      '<c>{count, plural, =1 {# Container Workload Profile} other {# Container Workload Profiles}}</c> will be changed to <t>{title}</t> mode',
    ManagedStateChangeMessageWorkloads:
      '<c>{count, plural, =1 {# Workload} other {# Workloads}}</c> will be changed to <t>{title}</t> mode',
    Mixed: 'Mixed',
    Objects: 'Policy Objects',
    OldVersion: 'Old version',
    OldVersionDesc: 'You are viewing version {version}',
    Organization: 'Organization Policy',
    Organizational: 'Organizational Policy',
    PolicyUpdatesExist: 'There are changes to the policy or referenced policy objects',
    ProviderConsumerOrder: 'Destination & Source Order',
    ReadOnlyVersion: 'Read Only version',
    RequiredProcessesNotRunning: 'Required processes not running',
    RequireProvisionNote: 'Require Provision Note',
    SecureConnectionFailed: 'Failed to establish one or more SecureConnect connections',
    SecurityPolicyStageFailed: 'Security policy stage failed',
    Settings: 'Policy Settings',
    SourceFirst: 'Display Source Column First',
    State: 'Policy State',
    StateChangeCant: 'Cannot Change Policy State',
    StateChangeConfirmWorkload: 'Confirm Workload Enforcement Change',
    StateChangeConfirmWorkloadProfile: 'Container Workload Profile Enforcement Change',
    StateChangeMessage: '{count} of {total} will change to <b>{title} {visibilityLevel}</b>',
    SuspendedMessage: 'VEN Suspended',
    TamperingRevertFailed: 'Failed to revert policy tampering',
    TestDesc: 'Test Rules and log events',
    UIColumnOrder: 'UI Column Order',
    UnknownDataCenterAndLocation: 'Unnamed Datacenter, Unknown Location',
    UnknownLocation: 'Unknown Location',
    UnmanagedDesc: 'Unmanaged Workloads do not have a VEN installed. Workload state does not apply.',
    UnmanagedWorkloadsNotChangeCount:
      '{unmanagedCount, plural, =1 {<b>#</b> Unmanaged Workload} other {<b>#</b> Unmanaged Workloads}} will not change.',
    UnnamedDataCenter: 'Unnamed Datacenter',
    UpdateMode: 'Policy Update Mode',
    Versions: 'Versions',
    ViewActiveVersion: 'View the active version.',
    ViewDraftVersion: 'View the draft version.',
    ViewingActiveVersion: 'This list shows only active Rules',
    ViewingDraftVersion: 'Viewing draft version',
    VisibilityChangeConfirm: 'Confirm Workload Visibility Change',
    YouViewEditVService: 'You are editing Virtual Service',
    YouViewingActiveVersion: 'You are viewing the active version',
    YouViewingActiveVersionVServer: 'You are viewing the active version of Virtual Server',
    YouViewingActiveVersionVService: 'You are viewing the active version of Virtual Service',
    YouViewingDraftVersion: 'You are viewing the draft version',
    YouViewingDraftVersionVServer: 'You are viewing the draft version of Virtual Server',
    YouViewingDraftVersionVService: 'You are viewing the draft version of Virtual Service',
  },
  PolicyGenerator: {
    Addresses: 'Addresses',
    AddScopes: 'Add Scopes',
    AllExcluded: 'If all connections are excluded, no Rules will be written.',
    AllTrafficNotAllowed: 'Not All Traffic Allowed',
    AllTrafficNotAllowedDescription:
      'Some traffic intended to be allowed by this rule will not be covered by this Service',
    ApplicationDisabled: 'Application Type must be set before the Policy Generator can be used',
    ApplicationLevel: 'Application Level',
    AutoDescription: 'Automatically generated using the Illumio Policy Generator',
    BroadServiceWarning: 'This could result in rules for traffic outside the included connections',
    CalculationInProgress: 'Calculation in Progress',
    ClickToViewAddresses: 'Click to view IP Addresses',
    ClickToViewVulnerabilites: 'Click to view exposed Vulnerabilities',
    ConfigureRules: 'Configure Rules',
    ConfirmMergeRules:
      'Editing this Service allows you to merge rules. Do you want to proceed? To reverse the merge, refresh your browser and edit the Services again.',
    Connections: '{count, plural, =0 {0 Connections} =1 {1 Connection} other {# Connections}}',
    Constrained: 'Constrained',
    DeletedRulesetsInstructions:
      'The following {count, plural, =1 {Policy is} other {Policies are}} <strong>deleted</strong>, <br/> and must be Provisioned or Reverted to add new Rules:',
    DisabledNotice: 'Policy Generator is Disabled',
    DisabledRulesets: 'Policies Deleted or Disabled',
    DisabledRulesetsInstructions:
      'The following {count, plural, =1 {Policy is} other {Policies are}} <strong>disabled</strong>, <br/> and must be enabled to add new Rules:',
    EditService: 'Edit Service',
    EditServiceUseAlways: 'Apply Changes to all matching ports',
    ExcludeAll: 'Exclude All',
    Excluded: 'Excluded',
    ExcludedConnections: 'Excluded  {count, plural, =1 {Connection} other {Connections}}',
    ExcludeSome: 'Exclude {count}',
    ExistingRules: 'Connections with Existing Rules',
    ExtraScope: {
      ChooseExtraScope: 'Choose Consuming',
      ConfigureExtraScope: 'Configure Extra-Scope',
      DeleteExtraScopeRules: '{count, plural, =1 {Delete 1 Extra-Scope Rule} other {Delete # Extra-Scope Rules}}',
      NewExtraScopeRules: '{count, plural, =1 {1 New Extra-Scope Rule} other {# New Extra-Scope Rules}}',
      NumConsumingApplication: '{count, plural, =1 {1 Consuming Application} other {# Consuming Applications}}',
      Options: {
        Title:
          '1. Choose Extra-Scope Rule Configuration for {count, plural, =1 {1 Application} other {# Applications}}',
      },
    },
    ExtraScopeConnections: 'Extra Scope Connections',
    ExtraScopeRules: 'Extra-Scope Rules',
    FilterIpPlaceholder: 'Type to Search for Labels, Ports, Protocols, IP Addresses, or Transmission Type',
    FilterPlaceholder: 'Type to Search for Labels, Ports, Protocols, or Transmission Type',
    Find: 'Find',
    Grid: {
      CompleteCoverage: '100% Covered',
      Connection: 'connections',
      Connections: '{count, plural, =1 {1 Connection} other {# Connections}}',
      ConnectionWithRules: '{count, plural, =1 {1 Connection} other {# Connections}} with Rules',
      Coverage: 'Coverage',
      CoveredByOtherRuleset:
        'All connections are allowed by the Rules in Policies that are not created using the Policy Generator.',
      ExposedVulnerabilityCount: '{count, plural, =1 {# Exposed Vulnerability} other {# Exposed Vulnerabilities}}',
      More: 'More',
      MorePortProtocol: '+ {numPortsProtocols, number} More',
      MoreRules: '{count, plural, =1 {1 More Rule} other {# More Rules}}',
      NoFilter: 'At least one transmission type must be selected',
      NumberSelected: '{subtitle} ({count, number} selected)',
      PortsProtocolsProcesses: 'Ports + Protocols + Processes',
      RulesetInclusion: 'Policy Inclusion',
      Sessions: '{count, plural, =1 {1 Flow} other {# Flows}}',
      Subtitle: 'Rules will be generated for',
      Title: '2. Review Connections',
      TitleDetails: {
        AppAll: '(Organized by Application)',
        AppSpec: '(Organized by Port + Protocol)',
        RoleAll: '(Organized by Role and Application)',
        RoleSpec: '(Organized by Role + Port and Protocol)',
      },
    },
    IllumioGenerated: 'Illumio Generated',
    IncludeAll: 'Include All',
    Included: 'Included',
    IncludedConnections: 'Included {count, plural, =1 {Connection} other {Connections}}',
    IncludeSome: 'Include {count}',
    IncompleteCoverage: 'Incomplete Coverage',
    IntraScope: {
      ConfigureIntraScope: 'Configure Intra-Scope',
      DeleteIntraScopeRules: '{count, plural, =1 {Delete 1 Intra-Scope Rule} other {Delete # Intra-Scope Rules}}',
      IntraScopeConnections: 'Intra-Scope Connections',
      NewIntraScopeRules: '{count, plural, =1 {1 New Intra-Scope Rule} other {# New Intra-Scope Rules}}',
      Options: {
        AutoLevel: {
          Body: 'Vulnerability Mitigation: Eliminate or reduce the exposure of vulnerable ports',
          Title: 'Auto Level',
        },
        Microsegmentation: {
          Body: 'Nanosegmentation: Divide Workloads by Role and specific Services',
          Title: 'Role Level - Specified Services',
        },
        Ringfencing: {
          Body: 'Microsegmentation: Allow all Workloads to talk across all Services',
        },
        TierToTier: {
          Body: 'Divide Workloads by Role and allow them to talk on all Services',
          Title: 'Role Level - All Services',
        },
        Title: '1. Choose Intra-Scope Rule Configuration',
      },
    },
    IntraScopeRules: 'Intra-Scope Rules',
    IpList: {
      ChooseIpList: 'Choose IP List',
      ConfigureIpList: 'Configure IP List',
      IpListChoose: 'IP List Selection',
      NumIpLists: '{count, plural, =1 {1 IP List} other {# IP Lists}}',
      Options: {
        Title: '1. Choose IP List Rule Configuration',
      },
    },
    IpListConnections: 'IPList Scope Connections',
    IpListCreationWarning: '{count, plural, =0 {No Ip Lists} =1 {1 Ip List} other {# Ip Lists}} will be saved',
    LargeServices: 'Large Number of Services',
    LargeServicesContinue: 'Are you sure you want to continue? Or would you like to write All Services Rules?',
    LargeServicesWarning: 'The large number of specified Services will result in a large number of Rules.',
    LastCalculated: 'Last Calculated',
    MakePolicyGeneratorRuleset: 'Make Default Policy Generator Policy',
    MergeConsumers: 'Merge rules with common Destination and Service',
    MergeExistingRules:
      'If two rules in the original policy meet the above merging criteria or are duplicates, merge the rules.',
    MergeNewAndExistingRules:
      'If possible, modify similar rules in the original policy to allow the traffic, rather than creating a new rule.',
    MergeOptions: 'Rule Merging Options',
    MergeServices: 'Merge rules with common Destination and Source',
    MissingLabels: 'Incomplete Labels',
    MustHaveRoleLabel: 'Workloads must have a Role Label to write Rules',
    NewIpLists:
      '{count, plural, =0 {<strong>0</strong> New Ip Lists} =1 {<strong>1</strong> New Ip List} other {<strong>#</strong> New Ip Lists}}',
    NewRules: '{count, plural, =1 {<strong>1</strong> New Rule} other {<strong>#</strong> New Rules}}',
    NewRulesets:
      '{count, plural, =0 {<strong>0</strong> Policies} =1 {<strong>1</strong> Policy} other {<strong>#</strong> Policies}}',
    NewServices:
      '{count, plural, =0 {<strong>0</strong> New Services} =1 {<strong>1</strong> New Service} other {<strong>#</strong> New Services}}',
    NoConnections: 'No Connections Available',
    Options: {
      AllServices: {
        Body: 'Write rules for all services by {level, select, auto {Auto} role {Role} application {Application}}',
      },
      SpecifiedServices: {
        Body: 'Write rules for individual services by {level, select, auto {Auto} role {Role} application {Application}}',
        Title: 'Specified Services',
      },
    },
    PolicyGenerator: 'Policy Generator',
    PolicyGeneratorRuleset: 'Default Policy Generator Policy',
    PolicyGeneratorTag: '(Policy Generator)',
    PortProtocolProcess: '{port} {protocol} {process, select, false {} unknown {} other {- {process}}}',
    PortServices: 'Use the port/protocol in a rule',
    PreviewRules: 'Preview Rules',
    ProvisionDeletedRulesets: 'Provision All Deleted Policies',
    RemoveDuplicateRules: 'Remove Duplicate Rules',
    ResetRules: 'Reset Rules',
    ResetRulesReason:
      'This service covers multiple flows, to edit this service you still need to reset your edits and start again.',
    RevertDeletedRulesets: 'Revert All Deleted Policies',
    RingFence: {
      ChooseApplications: 'Choose Applications',
      Contents: '1 Scope and 1 Intra-Scope Rule',
      CreatingRulesets: 'Creating Policies',
      Description: 'Allows all Workloads to talk across all Services within each Application',
      Multiple: 'Multiple',
      NewRulesets: '{count, plural, =1 {# Policy} other {# Policies}} each with',
      NumApplications: '{count, plural, =1 {1 Application} other {# Applications}}',
    },
    RuleConstruction: 'Rule Construction',
    RuleMerging: 'Rule Merging',
    Rules:
      '{count, plural, =0 {<strong>0</strong> Rules} =1 {<strong>1</strong> Rule} other {<strong>#</strong> Rules}}',
    RulesetScope: 'Policy Scope',
    RulesetsWillBeSaved: '{count, plural, =0 {No Policies} =1 {1 Policy} other {# Policies}} will be saved',
    RulesWillBeDeleted: '{count, plural, =0 {No Rules} =1 {1 Rule} other {# Rules}} will be deleted',
    RulesWillBeSaved: '{count, plural, =0 {No Rules} =1 {1 Rule} other {# Rules}} will be saved',
    SaveRules: 'Save Rules?',
    Scopes:
      '{count, plural, =0 {<strong>0</strong> Scopes} =1 {<strong>1</strong> Scope} other {<strong>#</strong> Scopes}}',
    SegmentMultipleApplications: 'Segment {count, plural, =0 {} =1 {Application} other {Multiple Applications}}',
    Select: {
      Intro: {
        Body: 'The <span class="{className}">Illumio Policy Generator</span> allows you to write <span class="{className}">Rules</span> for uncovered <span class="{className}">connections</span> of <span class="{className}">traffic</span> in your <span class="{className}">Applications</span>',
      },
      SearchApplication: 'Search Application',
      SelectApplications: 'Select an Application to build Rules',
      Steps: {
        Configure: 'Configure Intra-Scope Or/And Extra-Scope Rules',
        Secured: 'Applications are secured',
        Select: 'Select the Application',
      },
    },
    SelectApplications: 'Select Application',
    ServiceAutoDescription:
      'Automatically generated using the Illumio Policy Generator, based on traffic seen for "{processName}" process "{serviceName}" service.',
    ServiceCreationWarning: '{count, plural, =0 {No Services} =1 {1 Service} other {# Services}} will be saved',
    ServiceName: 'Service - {port} {protocol}',
    ServiceNameProtocol: 'Service - {protocol}',
    ServiceRuleConfig: 'Service Rule Configuration',
    Services:
      '{count, plural, =0 {<strong>0</strong> Services} =1 {<strong>1</strong> Service} other {<strong>#</strong> Services}}',
    ServiceSubstitution: 'If a Service cannot be substituted for a port/protocol',
    ServiceUse: 'If a Service exists for a port/protocol, the Service is used in a rule',
    ServiceWaitTime: 'It will take approximately {count, plural, =1 {1 minute} other {# minutes}} to complete',
    Spinner: {
      CalculatingFlowData: 'Calculating Flow Data',
      CalculatingRuleCoverage: 'Calculating Rule Coverage',
      CalculatingVulnerabilityData: 'Calculating Vulnerability Data',
      CreatingIpLists: 'Creating IpLists',
      CreatingRules: 'Creating Rules',
      CreatingServices: 'Creating Services',
      ExploringAsyncData:
        'Please wait to view results. You can start another query before this one is complete. Click Results to view or export the results of completed queries within 24 hours.',
      ExploringData: 'Loading Traffic',
      FindingConnections: 'Finding Connections',
      GeneratingRuleCoverage: 'Generating Rule Coverage',
      GeneratingRules: 'Generating Rules {percent, select, 0 {} other {{percent, number}%}}',
      LocatingApplications: 'Locating Applications',
      RecalculatingFlowData: 'Recalculating Flow Data',
    },
    StartPolicyGenerator: 'Start Policy Generator',
    TooManyConnections: 'Too many connections to generate rules.',
    TooManyConnectionsSelectFewer:
      'Too many connections to generate rules, please select fewer Extra-Scope Applications',
    TooManyRules: 'Too Many Rules',
    TooManyRulesMessage:
      'The number of Rules exceeds the number allowed for a Policy. We recommend using more broad options.',
    UseAllServices: 'Allow traffic on all Services',
    UseBroadServices: 'Allow the use of Services that include more than one port/protocol',
    WindowsServiceHelp: '"c:\\\\<ADD PATH HERE>\\\\{process}"',
    WindowsServiceInstructions: 'Cut and paste the following to use the process or service:',
    WindowsServiceName: 'Windows Service - {port} {protocol}',
    WindowsServiceNameProtocol: 'Windows Service - {protocol}',
    WithConnections: 'With Connections',
    WithoutConnections: 'Without Connections',
  },
  Port: {
    CIDRBlockPlaceHolder: 'E.g. 192.168.100.1/20',
    ConsumerProcess: 'Source Port/Process',
    EmptyProcessName: 'Empty process name',
    EmptyProcessorService: 'Empty process or service',
    Errors: {
      Invalid: 'Needs to be {port}',
    },
    InvalidCodeValue: 'Invalid code value',
    InvalidPortPortRange: 'Invalid Port/Port Range',
    InvalidPortValue: 'Invalid port value',
    InvalidProcess: 'Invalid process',
    InvalidRange: 'Invalid port range',
    InvalidServiceName: 'Invalid service name',
    InvalidTypeCodeValue: 'Invalid type/code value',
    InvalidTypeValue: 'Invalid type value',
    InvalidValue: 'Invalid value',
    IpAddressPlaceHolder: 'E.g. 192.168.100.1',
    Missing: 'Missing port',
    Overrides: 'Override ports',
    OverridesOptional: 'Override ports (Optional)',
    Port: 'Port',
    PortProcess: 'Destination Port/Process',
    PortProcessOnly: 'Port/Process',
    PortProtocolPlaceHolder: 'E.g. 22, 514 UDP, ICMP',
    PortProtocolProcessService: 'Services Name',
    PortProtocolProcessServiceIsNot: 'Services Name is not',
    PortRange: 'Port Range',
    PortRangePlaceHolder: 'E.g. 71-74, 80-90 TCP',
    PortRangePlaceHolderCaveat: 'E.g. 71-74, 80-90 TCP *Only available for Servers and Endpoints',
    Ports: 'Ports',
    PortStatus: 'Port Status',
    ProcessNamedSchedule: 'Schedule',
    ProcessNamePlaceHolder: 'E.g. c:\\windows\\myprocess.exe',
    ProcessPath: 'Process path',
    ProcessServiceRequiresQuotes: 'Process or service requires quotes',
    Protocol: 'Port/Protocol',
    ProtocolInvalid: 'Invalid port/protocol combination',
    ProtocolProcess: 'Destination Port/Protocol/Process',
    ProviderPortProcessService: 'Destination Port Process',
    ServiceNameRequiresQuotes: 'Service name requires quotes',
    ToAddPortProtocol: 'To add ports and protocols',
    ToAddPortProtocolProcess: 'To add ports, protocols, and processes/services:',
    ToAddProcessAndWindowsService:
      'To add process and Windows services, use quotation marks and the full path for processes',
    WindowsServiceExample: 'c:\\windows\\myprocess.exe',
    WindowsServiceExampleUNC: '\\\\windows\\process\\myprocess.exe',
    WindowsServiceExampleWithSystemEnvVariable: '%systemroot%\\system32\\myprocess.exe',
  },
  Protocol: {
    AH: 'AH',
    Any: 'Any',
    CFTP: 'CFTP',
    DCCP: 'DCCP',
    ESP: 'ESP',
    GRE: 'GRE',
    ICMP: 'ICMP',
    ICMPv6: 'ICMPv6',
    IGMP: 'IGMP',
    IPIP: 'IPIP',
    IPPC: 'IPPC',
    IPv4: 'IPv4',
    IPv6: 'IPv6',
    KRYPTOLAN: 'KRYPTOLAN',
    OSPF: 'OSPF',
    RDP: 'RDP',
    RVD: 'RVD',
    SATEXPAK: 'SAT-EXPAK',
    SCTP: 'SCTP',
    SMP: 'SMP',
    TCP: 'TCP',
    UDP: 'UDP',
  },
  Provision: {
    Added: 'Added',
    All: 'Provision All',
    AllChangesActiveCurrent: 'All policy changes are active and current.',
    Changes: 'Provision Changes',
    Confirm: 'Confirm & Provision',
    ContinuingInBackgroundMsg: 'Provisioning will continue in the background. Track progress on the <a>VENs</a> page.',
    Dependencies: 'Provision Dependencies',
    DraftChangeCount: 'Draft Changes ({count, number})',
    DraftChanges: 'Draft Changes',
    EntitiesNumber: '{count, plural, =1 {# Entity} other {# Entities}}',
    FollowingItemsAlso: 'The following items will also be provisioned',
    FollowingItemsNeed:
      'The selected items have dependencies that need to be provisioned before your items can be provisioned. Please contact an Admin to resolve this issue.',
    Item: 'Item',
    ListenOnlyMember: 'Members PCEs in listen only mode will not receive updated policy.',
    ListenOnlyModeMessage: 'PCE Listen Only mode is enabled.',
    ListenOnlyModePolicyChanges:
      'Policy changes for {count, plural, =1 {one Workload} other {# Workloads}} will be synchronized when Listen Only mode is disabled.',
    ListenOnlyModeSynchronizeCount: '{count, plural, =1 {1 Workload} other {# Workloads}} pending synchronization',
    Modified: 'Modified',
    Note: 'Provision Note',
    Now: 'Provision Now',
    Pending: 'Pending Provision',
    PendingAddition: 'Addition Pending',
    PendingDeletion: 'Deletion Pending',
    PendingModification: 'Modification Pending',
    PendingNote: 'This is the draft policy. Provision to make it the active version.',
    PendingProvisioning: 'Changes Pending Provisioning',
    ProvisioningComplete: 'Provisioning complete',
    ProvisioningCompleteSynced: 'All policy changes are active and current',
    ProvisioningDidNotAffect: 'Changes that were provisioned did not affect any Workloads',
    ProvisioningInfoMessage:
      'During this process, you can navigate to another page and policy synchronization will continue.',
    ProvisioningInProgress: '{ellipsis, select, false {Provisioning in progress} true {Provisioning in progress ...}}',
    ProvisioningNoProgress: 'No provisioning in progress',
    ProvisioningPushesToVEN: 'The PCE recalculates policy and sends it to impacted VENs when you provision.',
    ProvisioningPushesToVENWithoutDependencies:
      'You cannot partially provision resources with more than 500 dependencies. All changes must be provisioned at the same time.',
    ProvisioningStatus: 'Provisioning Status',
    RequiredBy: 'Required by',
    Revert: {
      All: 'Revert All',
      Dependencies: 'Revert Dependencies',
      DiscardChangesToItems: 'Discard changes to the selected items',
      FollowingItems: 'The following items will also be reverted',
      Now: 'Revert Now',
      RemoveOrSelectDependencies: 'Either remove the dependencies, or go back and select those dependencies to revert.',
      Review: 'Revert Review',
      SelectedItemsHaveDependencies: 'The selected items have dependencies that you did not select to revert.',
      Unable: 'Unable to Revert',
    },
    RevertingDiscardDraft: 'Reverting will discard all draft changes',
    Review: 'Provision Review',
    SelectedItems: 'Provision selected items',
    Settings: 'Provisioning',
    SynchronizingCount: '{count, plural, =1 {1 Workload} other {# Workloads}} synchronizing...',
    SynchronizingPolicyChanges:
      'Synchronizing policy changes for {count, plural, =1 {one Workload} other {# Workloads}}...',
    TallyLabel: {
      IPLists: '{count, plural, =1 {IP List} other {IP Lists}}',
      LabelGroups: '{count, plural, =1 {Label Group} other {Label Groups}}',
      Rulesets: '{count, plural, =1 {Policy} other {Policies}}',
      SecureConnect: '{count, plural, =1 {Secure Connect Gateway} other {Secure Connect Gateways}}',
      Settings: '{count, plural, =1 {Setting} other {Settings}}',
      VirtualServices: '{count, plural, =1 {Virtual Service} other {Virtual Services}}',
    },
    Unable: 'Unable to Provision',
    UpToDate: 'Up to date',
    Versions: '{multiple, select, false {Policy Version} true {Policy Versions}}',
    ViewLastCommit: 'View the last commit',
    ViewPolicyVersions: 'View Policy Versions',
  },
  RansomwareDashboard: {
    AddRiskSeverityWarning:
      'Adding Risk Severity will add this Service to Ransomware Protection assessment and recommendation.  This will be reflected in the Ransomware Protection Dashboard and Assessment after the next scheduled calculation.',
    AppGroupsRansomwareProtectionExcellentInfo:
      'Excellent. This App Group has excellent ransomware protection coverage.',
    AppGroupsRansomwareProtectionIncreaseInfo:
      'Increase your protection coverage by managing and enforcing Deny Rules for Risky Services below and writing Allowed Rules for legitimate traffic.',
    AppGroupsRansomwareProtectionOptimizeInfo:
      'Optimize your protection coverage by reducing the Allow Rules on Risky Services.',
    AppGroupsRansomwareProtectionPreviewTooltip:
      'This is a preview feature and may require an additional module purchase in a future release.',
    ChangeRiskSeverityWarning:
      'Changing Risk Severity will change Ransomware Protection assessment and recommendation.  This will be reflected in the Ransomware Protection Dashboard and Assessment after the next scheduled calculation.',
    ClickToEdit: 'Click to edit the asset count',
    DraftPolicy: 'Draft Policy',
    EnforcementChange: '{workload} will be changed to {enforcement} mode.',
    EnforcementChangeError: 'Errors changing {workload} to {enforcement} mode.',
    InstallVENs: 'Install VENs',
    LegacyPorts: 'Legacy',
    MoreRiskyServices: 'More Risky Services',
    NoMetaData: 'No services tagged with ransomware metadata',
    NonRealTime: 'Data was last updated at {time}, auto-refreshed every 4 hours',
    NoProtectionCoverageScore: 'NA. No workload matching the service',
    OSExposure: 'OS Exposure',
    PortCategory: '{port} Ports',
    PortCategoryCount: '{port} Count: {count}',
    PortsProtected: 'Ports Protected',
    PortsUnprotected: 'Ports Unprotected',
    PortType: 'Port Type',
    PrepareWorkloads: 'Prepare Workloads',
    PrepareWorkloadsAction: 'Install VENs to make them protection ready',
    ProtectedAllowed: 'Protected (Allowed by Policy)',
    ProtectedBlocked: 'Protected (Blocked)',
    ProtectedWorkloadsTooltip:
      'Protected workloads are server workloads that have policies enforced for all ransomware risky services.',
    ProtectionColumnTooltip:
      'Protected when the port is blocked or allowed (with policy) for only trusted sources. Unprotected otherwise.',
    ProtectionCoverage: 'Protection Coverage',
    ProtectionCoverageScore: 'Protection Coverage Score',
    ProtectionCoverageScoreTooltip:
      'Protection Coverage Score measures the network exposure to ransomware reduced by security policies compared to the total possible network exposure.',
    ProtectionReadyWorkloads: 'Protection Ready Workloads',
    ProtectionReadyWorkloadsTooltip:
      'Protection Ready Workloads are server workloads which have a VEN installed and can be configured to enforce Illumio security policies.',
    ProtectionReadyWorkloadTrendTooltip:
      'Protection Ready Workload trend shows the historical data of the Protection Readiness by day, week, month and quarter.',
    RansomwareExposure: 'Ransomware Exposure',
    RansomwareExposureTooltip:
      'Workload is classified by their risk exposure to ransomware - critical, high, medium, low and protected.',
    RansomwareProtection: 'Ransomware Protection',
    RansomwareProtectionCoverage: 'Ransomware Protection Coverage',
    RansomwareProtectionDashboard: 'Ransomware Protection Dashboard',
    RansomwareProtectionForServers: 'Ransomware Protection for Servers',
    RansomwareRisky: 'Ransomware Risky',
    RansomwareWorkloadsProtected: 'Protected Workloads',
    RansomwareWorkloadsUnProtected: 'Unprotected Workloads',
    RecommendedActions: 'Recommended Actions',
    RemoveRiskSeverityWarning:
      'Removing Risk Severity will remove this Service from Ransomware Protection assessment and recommendation.  This will be reflected in the Ransomware Protection Dashboard and Assessment after the next scheduled calculation.',
    ReviewTraffic: 'Review Traffic',
    ReviewTrafficAction: 'Add Allow Rules for legitimate traffic',
    RiskyPortsBySeverity: 'Risky Ports by Severity',
    RiskyPortsBySeverityTooltip:
      'This shows the number of ransomware risky ports, categorized by their severity - critical, high, medium and low in the system.',
    RiskyPortsByType: 'Risky Ports by Type',
    RiskyServices: 'Risky Services',
    RiskyServicesAction: 'Add Deny Rules and Provision',
    RiskyServicesCount: '{count} Risky Services',
    SeeAllApplications: 'See All Applications',
    SelectServiceFilter: 'Select a service name to filter view',
    ServiceColumnTooltip: 'These service ports are risky for ransomware exploits and should be protected.',
    SetEnforcement: 'Set Enforcement',
    ShowReadiness: 'Show Readiness',
    TopRiskyApplications: 'Top {count} Risky Applications',
    TopRiskyServices: 'Top {count} Risky Services',
    UnenforcedWorkloadsAction: 'Set Enforcement to Selective or Full',
    UnenforcedWorkloadsCount: '{count} Unenforced Workloads',
    Workload: 'workload',
    WorkloadExposureByGranularity: 'Workload Exposure',
    WorkloadsByRansomwareExposure: 'Workloads by Ransomware Exposure',
    WorkloadsRequiringProtection: 'Workloads Requiring Protection',
    WorkloadsRequiringProtectionInstruction:
      "To calculate Ransomware Protection Readiness, you must enter the number of server workloads requiring protection. If you don't know the exact number, an estimate will do. This number can be edited at anytime.",
  },
  RBAC: {
    AccessManagement: 'Access Management',
    AccessWizard: 'Access Wizard',
    AccessWizardRoleChangeError:
      'Unfortunately, the roles for some users could not be updated. Please review and try again',
    AccessWizardRoleChangeErrorTitle: 'Role Update Failed',
    AccessWizardRoleChangeSuccess: 'The roles for the selected users have been successfully updated',
    AddNewPrincipalAndRole: 'Add a new Principal and Role',
    CantHaveAllScope: 'Some or all of the selected roles cannot have All scope',
    ChooseScope: 'Choose a Scope',
    ConfirmGrantedAccess: 'Confirm Granted Access',
    ConfirmLeaveTitle: 'Confirm Leave',
    DefaultReadOnly: 'Default Viewer',
    EditScope: 'Edit Scope',
    ExternalGroup: {
      AddNew: 'Add a new External Group',
      NoData: 'No External Groups to display',
      NoMatchData: 'No Matching External Groups to display',
    },
    GlobalRoles: 'Global Roles',
    GrantAccess: 'Grant Access',
    GrantedAccess: 'Granted Access',
    ManageWarning: 'You will not be able to manage this workload after this action.',
    NoPermissionChange: 'All the permissions you selected already exist. No permission changes will be made.',
    NoPrincipalsAndRolesData: 'No Principals and Roles to display',
    NoRolesToDisplay: 'No Roles to display',
    NotValidScope: 'Not a valid scope',
    PartialApplicationScope:
      'Currently, the Application Type is set to “{type}”. Because this RBAC scope includes a label type that is outside the Application Type, principals assigned to this scope will be unable to view any Applications. To resolve this, remove the outside label types from the RBAC scope.\nIf you require RBAC scopes with additional label types, principals can still use Illumination Plus to view Applications.',
    PartialApplicationScopeLoc:
      'Currently the Application Type is set to “{type}”. Because this RBAC scope includes a specific location label, principals assigned to this scope will be unable to view any Applications. To resolve this, set the RBAC scope Location to “All Locations”.',
    PermissionsRemove: {
      NumPrincipals: '{count, plural, =1 {1 Principal} other {# Principals}}',
      NumUsers: '{count, plural, =1 {1 User} other {# Users}}',
    },
    PleaseChangePrincipal: 'Please select a new principal',
    PleaseChangeRole: 'Please make a role change',
    PleaseChangeUser: 'Please select a new user',
    PleaseRemoveLocationLabel:
      'If you require RBAC scopes with specific locations, go to the Applications List page and set the Application Type to “Application | Environment | Location”.',
    PleaseSelect: 'Please select',
    PleaseSelectPrincipal: 'Please select a principal',
    PleaseSelectRole: 'Please select a role',
    PleaseSelectScope: 'Please select a scope',
    PleaseSelectUser: 'Please select a user',
    Principals: 'Principals',
    PrincipalsAndRoles: 'Principals and Roles',
    PrincipalsTypeCount: {
      ExternalGroups: '{count, plural, =0 {}  =1 {1 External Group} other {# External Groups}}',
      ExternalUsers: '{count, plural, =0 {}  =1 {1 External User} other {# External Users}}',
      LocalUsers: '{count, plural, =0 {}  =1 {1 Local User} other {# Local Users}}',
    },
    RemoveAccess: 'Remove Access',
    RemoveAccessToScopes: 'Remove Access to Scopes',
    RemoveAccessToScopesWarning: 'All access to the scopes will be removed',
    RemovePermissions: {
      Success: '{count, plural, =1 {Permission has} other {# Permissions have}} been successfully removed',
    },
    ScopeAccessRemoved: 'The access to the scopes was removed',
    ScopeChangeWarning: 'The principals will no longer have access to the previous scope',
    ScopedRole: {
      AddNew: 'Add a new Scoped Role',
      NoData: 'No Scoped Roles to display',
    },
    ScopesAndRoles: 'Scopes and Roles',
    SelectAPrincipal: 'Select a principal',
    SelectRoles: 'Select Roles',
    ServiceAccounts: {
      AddNew: 'Add a new Service Account',
      AddServiceAccount: 'Add Service Account',
      APIKeysExpiresIn: 'API Key Expires in',
      CannotHaveAllScope: "{role} cannot have 'All' scope",
      CanOnlyHaveAllScope: "Global role can only have 'All' scope",
      Delete: 'Remove {count, plural, =1 {Service Account} other {# Service Accounts}}',
      DeleteConfirm:
        'Are you sure you want to remove {count, plural, =1 {this Service Account} other {# selected Service Accounts}}?',
      DeletedAPIKeysSuccessfully: '{count, plural, =1 {API Key has} other {# API Keys have}} been successfully removed',
      DeletedSuccessfully:
        '{count, plural, =1 {Service Account has} other {# Service Accounts have}} been successfully removed',
      DeleteTitle: 'Remove {count, plural, =1 {Service Account} other {Service Accounts}}',
      Errors: {
        APIKeyCreate: 'Unable to Create API Key',
      },
      ExpirationTimeText: 'Expiration cannot exceed the organization setting',
      LoadingServiceAccounts: 'Loading Service Accounts',
      LoadingServiceAccountSecrets: 'Loading Service Accounts Secrets',
      NoMatchingServiceAccountsData: 'No matching Service Accounts to display',
      NoServiceAccountsData: 'No Service Accounts to display',
      NoServiceAccountSecretsData: 'No Service Account Secrets to display',
      Placeholder: {
        ServiceAccountDescription: 'Type a description for the new Service Account',
        ServiceAccountName: 'Type a name for the new Service Account',
      },
      Remove: 'Remove Service Account',
      RemoveConfirm: 'Are you sure you want to remove this Service Account?',
      RolesAndScopes: 'Roles and Scopes',
    },
    Users: {
      AddNew: 'Add a new User',
      NoData: 'No Users to display',
      NoMatchData: 'No Matching Users to display',
      UsersCount: '{count, plural, =0 {}  =1 {1 User} other {# Users}}',
    },
    UsersActivity: {
      Duration: 'Duration',
      EffectiveGroups: 'Effective Groups',
      LastLogin: 'Last Login',
      LoginIP: 'Login IP',
      LoginTime: 'Login Time',
      LoginType: 'Login Type',
      UserActivity: 'User Activity',
    },
    WorkloadManager: 'Manage Workloads and Pairing Profiles that match scope.',
  },
  RBSuccessMessage: {
    AddedToDraftRules: 'Added to Draft Rules',
    ContinueWithGroup: 'Continue with Application',
    Desc: 'Rules have been successfully generated and added to your Draft Rules. You must provision these Rules to apply them to Workloads.',
    GoToApplicationMap: 'Go to Application Map',
    GoToDraftRules: 'Go to Draft Rules',
    PolicyGeneratorSuccessful: 'Policy Generator Successful',
    SelectAnApplication: 'Select An Application',
    SelectAnotherApplication: 'Select Another Application',
  },
  Reports: {
    AddReport: 'Add Report',
    ApplicationSummary: 'Application Summary',
    ApplicationSummaryReport: 'Application Summary Report',
    Daily: 'Daily',
    Downloads: 'Downloads',
    EmailCopyTo: 'Email Copy To',
    EveryWeekday: 'Every weekday',
    ExecutiveSummary: 'Executive Summary',
    ExecutiveSummaryReport: 'Executive Summary Report',
    ExplorerExportInstructionsPart1: 'You can run a previously saved',
    ExplorerExportInstructionsPart2:
      'filter and export the results to a CSV file on a recurring schedule. If you edit the filter, subsequent recurrences of the exported file will continue to use the original version of the filter.',
    Monthly: 'Monthly',
    NextScheduledTime: 'Next Scheduled Time',
    NoData: 'No reports to display',
    NoSavedFilters: 'No Saved Filters.',
    Quarterly: 'Quarterly',
    RansomwareRisk: 'Ransomware Risk',
    RansomwareRiskReport: 'Ransomware Risk Report',
    Recurrence: 'Recurrence',
    RecurringReports: 'Recurring Reports',
    RemoveSchedule: 'Remove Schedule',
    RemoveScheduleConfirm:
      'Are you sure you want to remove the selected schedule? The associated report will no longer be generated.',
    ReportEmailCheckbox: 'Email me a copy of the report',
    ReportEmailCheckboxCSV: 'Email me a copy of the CSV file',
    ReportNameAlreadyInUse: 'Report name is already in use.',
    ReportNameShouldContainLettersNumbersUnderscores:
      'Report name should contain only letters, numbers, and underscores.',
    ReportRecurrence: 'Report Recurrence',
    ReportRetentionPeriod: 'Report Retention Period',
    ReportType: 'Report Type',
    Retention: 'Retention',
    RetentionDays: 'Days (Maximum {days})',
    RuleHitCount: 'Rule Hit Count',
    RuleHitCountReport: 'Rule Hit Count Report',
    SavedFilter: 'Saved Filter',
    ScheduledTime: 'Scheduled Time',
    Schedules: 'Schedules',
    SchedulesNoData: 'No schedules to display',
    SelectFilter: 'Select a filter',
    SelectRuleSets: 'Select Policies',
    TimeRange: 'Time Range',
    TrafficExport: 'Traffic Export',
    ViewAllReports: 'View All Reports',
    Weekly: 'Weekly',
  },
  Role: {
    Admin: 'Admin',
    ClickToSelect: 'Click to Select Role',
    Collapse: 'Collapse Role',
    Expand: 'Expand Role',
    GlobalAdmin: 'Global Administrator',
    GlobalOrgOwner: 'Global Organization Owner',
    GlobalPolicyObjectProvisioner: 'Global Policy Object Provisioner',
    GlobalReadOnly: 'Global Viewer',
    GroupCollapse: 'Collapse Group Roles',
    GroupExpand: 'Expand Group Roles',
    Label: 'Role Label',
    LimitedRulesetManager: 'Limited Ruleset Manager',
    Owner: 'Owner',
    Provisioner: 'Provisioner',
    RulesetManager: 'Ruleset Manager',
    RulesetProvisioner: 'Ruleset Provisioner',
    RulesetViewer: 'Ruleset Viewer',
    Set: 'Set Role',
    Viewer: 'Viewer',
    WorkloadManager: 'Workload Manager',
  },
  Rule: {
    Add: 'Add Rule',
    Merge: 'Merge Rules',
    MergeMessage:
      'A Rule exists with the same Service but different {endpoint}. This Rule will be merged with the existing Rule.',
    Save: 'Save Rule',
    Validation: {
      BothVirtualServicesOnlyVirtualServicesWorkloads:
        "Cannot use 'Uses Virtual Services and Workloads' and 'Uses Virtual Services only' together for Sources.",
      CannotUseWorkloadSubnetsWithRuleOptions:
        "SecureConnect and Machine Auth cannot be used with 'Use Workload Subnets'",
      CantUseMachineAuthWithNonDefaultNetwork:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with 'Machine Authentication'",
      CantUseSecureConnectWithNonDefaultNetwork:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with 'SecureConnect'",
      ConsumerIpListVirtualServiceVirtualServerWorkload:
        "Cannot use IP Lists, Virtual Services, or Workloads along with 'Uses Virtual Services and Workloads' for Sources.",
      ConsumerIpListWindowsService: 'Cannot use Windows Egress Services as a Service when IP Lists is the Source.',
      ConsumerIpListWithMachineAuth: 'Machine Authentication is not supported for Rules that use IP Lists for Sources.',
      ConsumerIpListWorkload:
        "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Source.",
      ConsumerMustHaveOnlyIPLists:
        "Sources must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
      ConsumerWithVirtualService:
        "To use Virtual Services for Sources, you must first select 'Uses Virtual Services only'.",
      ConsumingServicesVirtualServices:
        "Cannot use a Service if 'Uses Virtual Services only' or 'Uses Virtual Services and Workloads' is a Source.",
      ContainerHostIntrascope: "Cannot use 'Container Host' as Source in an intra-scope rule.",
      ContainerHostItself: "Cannot use 'Container Host' along with any other Source.",
      ContainerHostNonTcpService: "Cannot use non TCP Port or Port Range with 'Container Host'.",
      ContainerHostProviders:
        "Only Labels, Workloads, or All Workloads can be used as Destinations if 'Container Host' is the Source.",
      ContainerHostSecureConnectMachineAuthStateless:
        "Cannot use SecureConnect, Stateless Rules, or Machine Authentication in a Rule if 'Container Host' is the Source.",
      ContainerHostWindowsService: "Cannot use Windows services with 'Container Host'.",
      CorrectAllErrors: 'Cannot save. Correct all errors in the Rules.',
      DuplicateScopesNotAllowed: 'Duplicate Scopes are not allowed',
      EgressSpecifiedWithNoIngress: 'An outbound service is specified with no inbound service',
      InvalidChainName: 'Ip table rules contains Invalid chain name',
      InvalidCharacter: 'Ip table rules contains Invalid character',
      InvalidFormat: 'Invalid format',
      InvalidParameter: 'Ip table rules contains Invalid parameter',
      InvalidTableName: 'Ip table rules contains Invalid table name',
      IPListsCantBeProviderAndConsumer: 'Cannot use IP Lists for both Destinations and Sources.',
      LabelWithWarning:
        'The highlighted labels or label groups types are used in the policy scope and in this rule. This may result in the rule being excluded from the policy.',
      MachineAuthWithAllServices: 'Machine Authentication is not supported for Rules that use All Services',
      MachineAuthWithNullService:
        'Machine Authentication is not supported for Rules that use From Destinations for Services',
      MachineAuthWithNullUbService:
        'Machine Authentication is not supported for Rules that use IP Lists, ‘Uses Virtual Services and Workloads’, or ‘Uses Virtual Services only’ for Destinations or Sources.',
      NamedAppLabelCantBeUsed: "Cannot use a named Application Label in a Rule unless the Scope uses ''{use}''.",
      NamedAppLabelIsUsed: "A named Application Label is used in a Rule. This Scope must use ''{use}''.",
      NamedEnvLabelCantBeUsed: "Cannot use a named Environment Label in a Rule unless the Scope uses ''{use}''.",
      NamedEnvLabelIsUsed: "A named Environment Label is used in a Rule. The Scope must use ''{use}''.",
      NamedLocLabelCantBeUsed: "Cannot use a named Location Label in a Rule unless the Scope uses ''{use}''.",
      NamedLocLabelIsUsed: "A named Location Label is used in a Rule. The Scope must use ''{use}''.",
      NetworkTypeMustBeDefaultCorporate:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' cannot be used with the 'Use Virtual Services only' option as Sources or Destinations",
      NonCorporateMustUseIpList:
        "'Non-Corporate Networks (Endpoints Only)' or 'All Networks' is only supported for Rules that contain IP List(s) as Sources or Destinations",
      OverlappingRules: 'Ip table rules contains Overlapping rules',
      ProcessServicesCantWithIPListProvider: 'Process-based Services cannot be used with IP List providers',
      ProviderIpListVirtualServiceVirtualServerWorkload:
        "Cannot use IP Lists, Virtual Services, Virtual Servers, or Workloads along with 'Uses Virtual Services and Workloads' for Destinations.",
      ProviderIpListWindowsService:
        'Cannot use Windows Services as a Providing Service when IP Lists is the Destination.',
      ProviderIpListWithMachineAuth:
        'Machine Authentication is not supported for Rules that use IP Lists for Destinations.',
      ProviderMustHaveOnlyIPLists:
        "Destinations must have only IP List(s) with 'Non-Corporate Networks (Endpoints Only)' or 'All Networks'",
      ProviderWithIpListScopedUser:
        'Users with Scoped Roles cannot create Extra-Scope Rules which have IP Lists as Destinations.',
      ProviderWithIpListWorkload:
        "Cannot use IP Lists or Workloads if you select 'Uses Virtual Services only' for a Destination.",
      ProviderWithVirtualService:
        "To use Virtual Services or Virtual Servers for Destinations, you must first select 'Uses Virtual Services only'.",
      ProvidingServicesVirtualServices:
        "Cannot use a Providing Service if 'Uses Virtual Services only' is a Destination.",
      RemoveUnpairedWorkloads: 'Cannot save. Remove unpaired Workloads in red.',
      RemoveUnpairedWorkloadsInRed: 'Remove unpaired Workloads in red.',
      RequiresLabel: 'Cannot save. A Custom iptables Rule requires a Label.',
      RequiresProviderConsumer:
        'Select {type, select, providers {Destinations} consumers {Sources}} except IP Lists or named Workloads',
      RequiresProviderServiceConsumer: 'Cannot save. A Rule requires a Destination, Service, and Source.',
      ScopeLabelInRulesetWithAllLabel:
        "Cannot create a Scope with a named Label when a Scope already exists with a corresponding 'All' Label.\nYou can edit the existing Scope to include a named Label in place of 'All'.",
      ScopeRequiresLabels: 'Cannot save. A Scope requires 3 Labels or Label Groups.',
      SecureConnectCantWithIPListBoundVirtual:
        "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Destinations or Sources.",
      SecureConnectWithNullUbService:
        "SecureConnect is not supported for Rules that use IP Lists, 'Uses Virtual Services and Workloads', or 'Uses Virtual Services only' for Destinations.",
      SecureConnectWithSecurityPrincipal: 'SecureConnect cannot be used with Security Principal.',
      StatelessInvalidConsumers:
        'A Stateless Rule can only have the following types of Source: Any IP List plus All Workloads, or a Label (maximum one of a specific type) , or an individual item',
      StatelessWithIPList: 'IP Lists in Destinations with a Stateless Rule is not supported',
      StatelessWithLabelGroup: 'Label Group in Sources with a Stateless Rule is not supported',
      StatelessWithMachineAuth: 'Machine Authentication is not supported for Stateless Rules.',
      StatelessWithSecureConnect: 'SecureConnect is not supported with Stateless Rules.',
      UserGroupsCantWithIPListConsumer: 'Cannot use User Groups if IP Lists is a Source.',
      UserGroupsWithLabelOrWorkload: 'You must use a Label, Label Group, or Workload with a User Group',
      UseWorkloadSubnetWithLabelsOrAllWorkloads:
        "You must use a Label, Label Group, or 'All Workloads' with 'Use Workload Subnets'",
    },
    View: 'View Rule',
  },
  RuleSearch: {
    Access: 'Access',
    ActiveRules: 'Active Rules',
    Advanced: 'Advanced',
    AdvancedDescription: 'Search by Destination, Source, or both',
    AllResults: 'All Results',
    AllResultsDescription: 'Any match of selected filters',
    Basic: 'Basic',
    BasicDescription: 'Search all attributes',
    Columns: 'Columns',
    CreatedAtUc: 'Created At',
    CreatedIn: 'Created in the',
    CustomCreatedAtRange: 'Custom Created at range',
    CustomUpdatedAtRange: 'Custom Updated at range',
    DraftRules: 'Draft Rules',
    Editable: 'Editable',
    ExactResults: 'Exact Results',
    ExactResultsDescription: 'Exact match of selected filters',
    Extra: 'Extra',
    LandingMessage: 'Select filter parameters then click "Run" to display filtered rules',
    MaxEntitiesError: 'Up to 8 filter attributes allowed per search.',
    Placeholders: {
      Consumers: 'Filter by Sources',
      Providers: 'Filter by Destinations and Rule attributes',
      ProvidersOrConsumers: 'Filter by Labels and Rule attributes',
    },
    ReadOnly: 'Read-only',
    ResetFilters: 'Reset Filters',
    RulesetName: 'Policy name',
    UpdatedAtUc: 'Updated At',
    UpdatedIn: 'Updated in the',
    WindowsProcessName: 'Windows process name',
    WindowsServiceName: 'Windows service name',
  },
  Rulesets: {
    ActionDiscardPendingChanges: 'Taking this action will discard pending changes.',
    Add: 'Add Policy',
    AddNew: 'Add a new Policy',
    AddRulesetFromTemplate: 'Add Policy from Template',
    AllowRule: 'Allow Rule',
    AllowRuleNoScope: 'Allow Rule No Scope',
    AllowRules: 'Allow Rules',
    AllowRulesOnlyInfo: 'Allow Rules Only',
    AndRules: 'Policies and Rules',
    CreateNew: 'Create New Policy',
    CreateOrSelect: 'Select or Create a new Policy',
    CreatePage: {
      CopyOf: 'Copy of {name}',
      DuplicateName: 'Duplicate Policy Name',
      Placeholder: {
        AppScope: 'Choose an Application Label for the new Policy Scope',
        EnvScope: 'Choose an Environment Label for the new Policy Scope',
        LocScope: 'Choose a Location Label for the new Policy Scope',
        RulesetDescription: 'Type a description for the new Policy',
        RulesetName: 'Type a name for the new Policy',
      },
    },
    CustomIPTableRule: 'Custom IP Table Rule',
    Delete: 'Remove {count, plural, =1 {Policy} other {# Policies}}',
    DeleteConfirm: 'Are you sure you want to remove the selected Policies?',
    DeleteRuleset: 'Are you sure you want to remove this Policy?',
    DeleteSeveral: 'Remove Policies',
    DenyRule: 'Deny Rule',
    DenyRules: 'Deny Rules',
    DenyRulesAuto: '{count, plural, =1 {Deny Rule} other {Deny Rules}}',
    Disabled: 'Disabled Policies',
    DuplicatePolicy: 'Duplicate Policy',
    EditDetails: 'Edit Details',
    EditPolicy: 'Edit Policy',
    EnablePolicy: 'Enable Policy',
    EnforcementBoundaryRuleset: 'Proposed Deny Rule Policy',
    FilterScopes: 'Filter Scopes',
    NewProposedBoundaryRuleset:
      'The Deny Rule Policy contains rules to allow blocked connections to cross the Boundary. Rules can be edited prior to saving the Policy.',
    NewProposedRuleset:
      'Rules allowing the connections will be added to the selected Policy. Rules can be edited prior to saving the Policy.',
    NoData: 'No Policies to display',
    NoMatchData: 'No matching Policies to display',
    OverrideDenyRule: 'Override Deny Rule',
    OverrideDenyRules: 'Override Deny Rules',
    PartOfApplication: 'One or more of the selected Policies is part of an application',
    PolicySettingsPage: {
      DisplayScopesInRulesets: 'Display Scopes in Policies',
      DisplayScopesSettingInfo:
        "Policies apply to all workloads unless a scope is specified. Scope will limit the Policy's rules to only apply to workloads that match a selected set of labels",
      ScopesInRulesets: 'Scopes in Policies',
      ScopesInRulesetsNo:
        'Policies apply to all workloads by default. Scope selection is still available in the Policy Action menu',
      ScopesInRulesetsYes: 'Optional scope selection in Policies is available by default',
    },
    ProposedRuleset: 'Proposed Policy',
    ProvisionRuleset: 'Provision Policy',
    RemoveResultTitle: 'Result of {count, plural, =1 {Policy} other {Policies}} removal',
    ReorderRules: 'Reorder Rules',
    RevertRuleset: 'Revert Policy',
    RoleLabelNotSupported:
      'The Role Label is not supported for Scopes. Use the Application, Location, and Environment Labels only.',
    Rules: {
      Added: 'ADDED',
      AddedScope: 'Added Scope',
      AdditionPending: 'ADDITION PENDING',
      AdditionProposed: 'Addition Proposed',
      AddNote: 'Add Note',
      AddServiceConfirmation: "Adding a new Service replaces 'All Services'. Are you sure?",
      AllNetworks: 'All Networks',
      ConfirmRemoveScopes: 'Are you sure you want to remove the selected Scopes?',
      ConfirmRuleDelete: 'Are you sure you want to remove the selected {count, plural, =1 {Rule} other {Rules}}?',
      ConsumerServices: 'The Source and Services are common',
      ConsumingProcess: 'Consuming Process',
      ConsumingService: 'Consuming Service',
      ConsumingUsername: 'Consuming Username',
      CorporateNetwork: 'Corporate Network',
      Deleted: 'DELETED',
      DeleteNote: 'Delete Note',
      DeleteProposed: 'Delete Proposed',
      DeleteRules: 'Remove {count, plural, =1 {Rule} other {Rules}}',
      DeleteScopes: 'Delete Scopes',
      DeletionPending: 'DELETION PENDING',
      DerivedFromProviderVirtualServices: 'Derived from Destination Virtual Services',
      DestinationService: 'Destination Service',
      DestinationServices: 'Destination Services',
      DuplicateRuleNotif: 'This will duplicate an existing Rule',
      EditNote: 'Edit Note',
      ExtraScope: {
        Add: 'Add Extra-Scope Rule',
        AddDesc: 'Allow traffic when the Source is global or extrascope',
        IPListWarning:
          'To allow outbound access to IP Lists, create an Intra-Scope Rule to prevent the Rule from being applied to a larger set of Workloads.',
        NoData: 'No Extra-Scope Rules to display',
        NoMatch: 'No matching Extra-Scope Rules',
        Rules: '{count, plural, =0 {No Extra-Scope Rules} =1 {1 Extra-Scope Rule} other {# Extra-Scope Rules}}',
        Title: 'Extra-Scope Rule',
      },
      ExtraToIntra: 'Rule changed from Extra-Scope to Intra-Scope.',
      FilterRules: 'Filter Rules',
      Header: {
        NoMatchingScopes: 'No matching Scopes',
      },
      Inbound: 'Inbound Rules',
      IndexColumnLabel: 'No.',
      IntraScope: {
        Add: 'Add Intra-Scope Rule',
        AddDesc: 'Allow tier-to-tier, role-to-role, or IP List traffic',
        NoData: 'No Intra-Scope Rules to display',
        NoMatch: 'No matching Intra-Scope Rules',
        Rules: '{count, plural, =0 {No Intra-Scope Rules} =1 {1 Intra-Scope Rule} other {# Intra-Scope Rules}}',
        Title: 'Intra-Scope Rule',
      },
      IntraToExtra: 'Rule changed from Intra-Scope to Extra-Scope.',
      IpTables: {
        Actors: 'Receivers',
        Add: 'Add Custom IPTable Rule',
        AddDesc: 'Integrate existing IP Tables into a policy',
        Form: {
          IpVersion: 'IP Version',
          StatementsPlaceholder: 'Type or paste a custom iptables Rule',
        },
        Grid: {
          Statements: 'iptables Rules applied to Scope',
        },
        NoData: 'No custom iptables Rules to display',
        NoMatch: 'No matching custom iptables Rules',
        Rules:
          '{count, plural, =0 {No Custom iptables Rules} =1 {1 Custom iptables Rule} other {# Custom iptables Rules}}',
      },
      LabelAndLabelGroups: 'Labels and Label Groups',
      LabelAndLabelGroupsExcept: 'Labels and Label Groups Except',
      MergeCommon: 'Automatically merge similar rules together when',
      Modified: 'MODIFIED',
      ModifiedPending: 'MODIFICATION PENDING',
      ModifiedProposed: 'MODIFICATION PROPOSED',
      NoCustomRulesWorkload: 'No Custom Iptables Rules apply to this workload',
      NoEntityUsed:
        'No {object, select, workloads {Workloads} services {Services} iplists {IP Lists} userGroups {User Groups} labels {Labels and Label Groups} virtualServices {Virtual Services} virtualServers {Virtual Servers} other {{object}}} in this Policy Rules',
      NoForwardRulesWorkload: 'No Forward Rules apply to this workload',
      NoInboundRules: 'No Inbound Rules to display',
      NoInboundRulesWorkload: 'No Inbound Rules apply to this workload',
      NoMatchingData: 'No Matching Rules',
      NoMatchingRules:
        'There are no {type, select, intrascope {Intra-Scope} extrascope {Extra-Scope} iptables {Custom iptables} overrideDeny {Override Deny} allow {Allow} deny {Deny}} Rules that match the filter criteria. Clear filter to see all the rules',
      NonCorporateNetworks: 'Non-Corporate Networks (Endpoints Only)',
      NoOutboundRules: 'No Outbound Rules to display',
      NoOutboundRulesWorkload: 'No Outbound Rules apply to this workload',
      NoRules: 'No Rules in the Policy',
      NoRulesDefined:
        'There are no {type, select, intrascope {Intra-Scope} extrascope {Extra-Scope} iptables {Custom iptables} overrideDeny {Override Deny} allow {Allow} deny {Deny}} Rules defined',
      NotePlaceholder: 'Type a note for the Rule',
      Outbound: 'Outbound Rules',
      OverrideDenyVenVersionWarning: 'Override Deny rules require VEN release 22.3.0 or later.',
      PortOrPortRange: 'Port/Port Range',
      Proposed: 'Proposed',
      ProviderConsumer: 'The Source and Destination are common',
      ProviderServices: 'The Destination and Services are common',
      ProvidingProcess: 'Providing Process',
      ProvidingService: 'Providing Service',
      ProvidingUsername: 'Providing Username',
      ReadonlyService: 'Rules cannot be edited in the UI for inline services without port or port-ranges defined.',
      RemoveAllScopes: 'Policies must contain at least one Scope. Add a second Scope and delete the first.',
      RemovedScope: 'Removed Scope',
      ResourceDeleted:
        'The rule is referring to a {object, select, labels {Label} label_groups {Label Group} services {Service} virtual_services {Virtual Service} virtual_servers {Virtual Server} ip_lists {IP List} other {{object}}} {resourceName} that is deleted. Edit the rule to remove references to {resourceName}',
      ReverseError:
        'Rule reversal is not supported for Rules with Container Host, Virtual Services, User Groups, or Virtual Servers.',
      RuleOptions: 'Rule Options',
      ScopeType: 'Scope Type',
      SelectApplication: 'Select Application Label',
      SelectEnvironment: 'Select Environment Label',
      SelectingContainerHostConfirmation: 'Selecting Container Host replaces any other Source. Are you sure?',
      SelectingLabelConfirmation:
        '{selectingInclude, select, true {Including} false {Excluding}} {typeName} Label replaces {selectingInclude, select, true {Excluded} false {Included}} {typeName} Labels. Are you sure?',
      SelectingReplacesConfirmation: 'Selecting {resourceName} replaces {selectedResourcesNames}. Are you sure?',
      SelectLocation: 'Select Location Label',
      SelectRuleOptions: 'Select Rule Options',
      SelectService: 'Select Service',
      TooManyRules:
        'For Policies with more than 500 Rules, changes to Rules are not highlighted inline. For best display results, we recommend splitting Policies with more than 500 Rules into multiple Policies.',
      UnchangedScope: 'Unchanged Scope',
      UpdateProposed: 'Update Proposed',
      UseAdvancedOptions:
        "To add Label exclusion (All Labels and Label Groups except), Virtual Services, Virtual Servers and related options use 'Advanced Options'.",
      UseWorkloadSubnets: 'Use Workload Subnets',
    },
    RulesetDeletedHeader: 'Policy is pending deletion',
    RulesetDeletedInstructions:
      'You have two options to continue with rule generation: (1) Provision the policy. The existing policy will be deleted and a new policy created. (2) Revert the policy to its last provisioned state.',
    RulesetDisabledHeader: 'Policy is disabled',
    RulesetDisabledInstructions: 'To continue with rule generation, you must enable the policy',
    RulesForTemplate: 'Rules for {template}',
    SaveOrder: 'Save Order',
    ScopedUserRuleset:
      'You can only view Policies that match the scopes assigned to you. Rules outside your scope that affect your applications can be viewed in the <a>Rule Search</a> page.',
    Scopes: {
      AddScope: 'Add Scope',
      AddScopeInfo: 'Each scope must include {labelNames} Labels',
      AddScopeWarning: 'Adding a scope to an Organization Policy changes it to a scoped policy.',
      ConfirmAddScope: 'Confirm Add Scope',
      DuplicateScope: 'Duplicate Scope',
      DuplicateScopeText: 'The scope is a duplicate. Select a different {labelNames}',
      EmptyScopePolicySubtitle:
        'Add a scope to limit the policy to apply to workloads that match a selected set of labels',
      RemoveLastScope: 'Remove Last Scope',
      RemoveLastScopeWarning:
        'Removing the last scope will result in the rules potentially applying across all managed wokloads. Are you sure?',
      ScopeModifiedDetails: 'Scope modified - Details',
      ScopesCannotBeAdded: 'Scopes cannot be added currently since the rules defined refer to all label types',
      ScopesCount:
        '{count, plural, =1 {# Scope} other {# Scopes}} {matched, select, true {Matched } false {}}- Each scope must include <span class="{className}">{labelNames}</span> Labels',
      ScopesCreatedByRuleBuilder:
        'The policy is generated by Illumination Map/Policy Generator. Scopes cannot be added',
    },
    SearchAllRulesets: 'Search All Policies',
    ServiceCreation: 'Service Creation',
    ServiceSubstitution: 'If no existing Service matches a port/protocol',
    SettingsNotification:
      'During rule generation, rules with common elements are merged to reduce the number of rules. Options selected below are used to perform these optimizations.',
    SomeSelectedFlowsAreNotCoveredByRules:
      'Some selected flows are not covered by Rules because they are not within the scope of the Policy.',
    Summary: 'Policy Summary',
    Tally: {
      Allow: '{count, plural, =1 {Allow Rule} other {Allow Rules}}',
      OverrideDeny: '{count, plural, =1 {Override Deny Rule} other {Override Deny Rules}}',
    },
    TemplatesWizard: {
      AddFromScratch: 'Add from Scratch',
      AddFromTemplate: 'Add from Template',
      Confirm: 'Confirm and Save',
      ErrorDetails:
        'Sorry, it seems something went wrong. Please try again, or reach out to support if the problem persists.',
      ErrorMessage: 'An unexpected error occurred while trying to add a Best Practice policy.',
    },
    TypeName: 'Type Name of New Policy',
    UpdateResultTitle: 'Result of {count, plural, =1 {Policy} other {Policies}} status update',
    UpdateSuccess: '{count, plural, =1 {Policy has} other {# Policies have}} been successfully updated',
    View: 'View Policies',
  },
  RulesetTemplatesWizard: {
    ErrorMessageToContactSupport: '{errorMessage}. If the issue persists, contact <a>{link}</a> for more information.',
    PreviewAndSave: 'Preview and Save',
    SelectTemplate: 'Select Template',
  },
  SecureConnect: {
    MustBeUnique: 'All rules must be unique.',
    NoData: 'No SecureConnect Gateways to display',
  },
  SecureGateway: {
    AccessibleBy: 'Accessible by',
    AddIdleTimeoutMin: 'Please enter a valid Idle Timeout Duration Value',
    AddNew: 'Add SecureConnect Gateway',
    CertificateAuthorityID: 'Certificate Authority ID',
    CertificateIssuerNameExample: 'Example: "C=US, O=Acme Inc., CN=Acme Inc. CA"',
    CertificateRsaSig: 'Certificate (rsa-sig)',
    EnterCertificateIssuerName: 'Enter the Certificate Issuer Name using the following format',
    EnterSubjectName:
      'Enter the Subject Name for the Gateway certificate using the following format for IKE peer authentication.',
    EnterWorkloadDN:
      'Enter the DN to identify the certificate used on the Workloads (when using multiple certificates for the Workloads).',
    GatewayIPAddress: 'Gateway IP Address',
    GatewaySubjectDN: 'Gateway Subject DN',
    IdleTimeout: 'Idle Timeout (Minutes)',
    IPsecConfiguration: 'IPsec Configuration',
    IssuerDistinguishedName: 'Issuer Distinguished Name (DN)',
    LinuxIKEv2DPD: 'Linux IKEv2 only DPD',
    LinuxIKEv2NAT: 'Linux IKEv2 only NAT-T',
    LinuxVersion: 'Linux Version',
    List: {
      Message: {
        RemoveResultTitle:
          'Result of {count, plural, =1 {SecureConnect Gateway} other {SecureConnect Gateways}} removal',
        RemoveSuccess:
          '{count, plural, =1 {SecureConnect Gateway has} other {# SecureConnect Gateways have}} been successfully removed',
      },
    },
    LocalID: 'Local ID',
    Name: '{name}',
    PreSharedKey: 'Pre-shared Key',
    ProtectedIPList: 'Protected IP List',
    PublicKey: 'public key',
    RemoteID: 'Remote ID',
    RulesEditInfo:
      'Rules below include Protected IP Lists. When not restricted to specific labels the scope expands to all workloads.',
    SecureConnect: 'SecureConnect Gateways',
    SecureConnectDelete: 'Remove {count, plural, =1 {SecureConnect Gateway} other {SecureConnect Gateways}}',
    SecureConnectDeleteConfirm:
      'Are you sure you want to remove {count, plural, =1 {this SecureConnect Gateway} other {# selected SecureConnect Gateways}}?',
    SubjectNameExample: 'Example: "C=US, CN=vpn.acmeinc.com"',
    TunnelMode: 'Tunnel mode',
    TypeGatewayDesc: 'Type the description of the Gateway',
    TypeGatewayName: 'Type the name of the Gateway (required)',
    TypeGatewaySubjectDN: 'Type the Gateway Subject DN',
    TypeIdleTimeout: 'Type the idle timeout duration (in minutes; must be a positive integer)',
    TypeIPAddress: 'Type the IP address for the Gateway (required)',
    TypeIssuerDN: 'Type the Issuer DN (Required)',
    TypePreSharedKey: 'Type the pre-shared key (PSK) for the Gateway (required)',
    TypeWorkloadSubjectDN: 'Type the Workload Subject DN',
    VPNConfiguration: 'VPN Configuration',
    WindowsVersion: 'Windows Version',
    WorkloadSubjectDN: 'Workload Subject DN',
    WorkloadSubjectDNExample: 'Example: "C=US, O=Acme Inc."',
  },
  SegmentationTemplates: {
    BadAttempt: 'Please select a file or choose "Cancel"',
    BadFileType: 'File type not supported. Please select a .json file or choose "Cancel"',
    CancelInstallation: 'Cancel Installation',
    Content: 'Content:',
    Dependencies: 'Dependencies and Prerequisites',
    Edited: 'edited',
    EULAAcceptance: 'EULA Acceptance',
    EULAAcceptanceCheck:
      'By checking this box, you acknowledge that you have read, understand, and agree to be bound by the',
    EULALink: 'Illumio, Inc. End User License Agreement.',
    EULAText1:
      'Illumio Segmentation Templates are designed to provide useful functionality for the deployment and operation of the Policy Compute Engine.',
    EULAText2: 'Illumio Segmentation Templates are provided as is, with limited support.',
    EULAText3:
      'Illumio is not responsible for, and expressly disclaims all liability for or damages of any kind arising out of use, reference to, or reliance on these tools.',
    FileError: 'The selected file is not a valid Segmentation Template. Please choose another file.',
    ForLinux: 'For Linux',
    ForWindows: 'For Windows',
    Install: 'Install',
    Installing: 'Installing',
    InvalidVersion: 'Because a newer version of this template is installed, this template is read-only.',
    IpListCount: '{ipListCount, plural, =0 {} =1 {# IP list} other {# IP lists}}',
    LabelCount: '{labelCount, plural, =0 {} =1 {# label} other {# labels}}',
    LabelGroupCount: '{labelGroupCount, plural, =0 {} =1 {# label group} other {# label groups}}',
    LastUpdated: 'Last Updated',
    Load: 'Load',
    LoadFile: 'Load File',
    LogIn: 'Log In to Support Portal',
    NamingConflict:
      'The selected template cannot be installed because objects with the following names already exist. Rename these objects and try again.',
    New: 'NEW',
    Overwrite: 'Overwrite',
    PreserveChanges: 'Preserve Changes',
    ReadOnlyError:
      'The following objects have been deleted and are read only. You must either revert or provision these changes before installing this template.',
    RulesetCount: '{rulesetCount, plural, =0 {} =1 {# policy} other {# policies}}',
    SegmentationTemplate: 'Segmentation Template',
    SegmentationTemplates: 'Segmentation Templates',
    ServiceCount: '{serviceCount, plural, =0 {} =1 {# Service} other {# Services}}',
    SupportLoginConfirmation:
      'To use Segmentation Templates, log in to the Illumio Support Portal or load a Segmentation Templates file.',
    UndeletableObjects:
      'Some policy objects were not deleted during Uninstall because they are in use or are dependencies of other objects. These objects can be deleted manually later.',
    Uninstall: 'Uninstall',
    UninstallTemplate: 'Uninstall Segmentation Template',
    UninstallWarning:
      'Uninstalling this Segmentation Template will permanently delete all associated Labels, Label Groups, Services, Policies, and IP Lists',
    Universal: 'Universal',
    Update: 'Update',
    UploadText1:
      'To use this feature, first download the Segmentation Templates file to your computer from the Illumio Support Portal. (An internet connection and login credentials are required.)',
    UploadText2: 'Then open the file in your browser. Click "Choose File" below to select the file and click "Load".',
    Version: 'Version:',
    WhatsNew: "What's New",
  },
  Server: {
    Dashboard: {
      ActiveServers: 'Active Servers',
      ServerCountByEnforcementMode: 'Total Servers by Enforcement Mode',
      ServerCountByOS: 'Active Servers by OS',
      ServerCountByStatusOrHealth: 'Total Servers by Status/Health',
      ServerCountByVersion: 'Active Servers by Version',
      ServerStatistics: 'Server Statistics',
    },
  },
  Services: {
    AddNew: 'Add a new Service',
    AddValidNameAndService: 'Please add a valid Name and Service.',
    Create: 'Create Service',
    Delete: 'Remove {count, plural, =1 {Service} other {# Services}}',
    DeleteConfirm: 'Are you sure you want to remove this Service?',
    DeleteSeveral: 'Remove Services',
    DeleteSeveralConfirm: 'Are you sure you want to remove the selected Service(s)?',
    DuplicateMessage:
      "A service of this name already exists. It's recommended to use a different name for a new service",
    Edit: {
      Info: {
        ICMPInstruction: 'To add ICMP with type and code, use <type> / <code>:',
        InstructionsExample: {
          ProcessName: 'myprocess\nMy Schedule',
        },
        PortInstruction: 'To add ports, use a hyphen to indicate the port range:',
        ProcessNameInstruction: 'To add process name, use the Windows process name without .exe or path:',
        ProcessPathInstruction: 'To add process path, use the full path or % for wildcard:',
        ProtocolInstruction: 'To add protocol, use one of the supported protocol names:',
      },
      Message: 'Please add a valid Name and Port Range before saving.',
      RansomwareRiskyWarning:
        'This service is used in ransomware protection reporting. Changes to the service definition may change the reported results.',
    },
    Errors: {
      Create: 'Unable to Create Service',
      Edit: 'Unable to Edit Service',
    },
    List: {
      CannotDeleteAny: 'This is a default Service and cannot be edited or removed.',
      CannotDeleteBoundToBPS:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Virtual Service',
      CannotDeleteBoundToEnforcementBoundary:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Deny Rule',
      CannotDeleteBoundToRules:
        'Cannot remove {count, plural, =1 {Service that is} other {# Services that are}} referenced by an existing Rule',
      RansomwareRiskyWarning:
        'One or more selected services are used in ransomware protection reporting. Removing them may change the reported results.',
      RemoveConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Service} other {Services}}?',
      RemoveSuccess: '{count, plural, =1 {Service has} other {# Services have}} been successfully removed',
    },
    Mixin: {
      AtLeastOnePort: 'At least one port is required.',
      AtLeastOneProcess: 'At least one port or process is required.',
      Instructions: 'When the Service is used in a Rule, allow the following:',
      Os: {
        All: {
          Subtitle: 'Specify ports and protocols',
          Title: 'All Operating Systems: Port-Based',
        },
        Instructions:
          'Specify the operating systems this Service must be running on in order for it to take effect when used in a Rule',
        Title: 'Operating System',
        Windows: {
          Subtitle: 'Specify ports and protocols, processes, or Windows services',
          Title: 'Windows Inbound: Process/Service-Based',
        },
        WindowsOutbound: {
          Subtitle: 'Specify process name or process path',
          Title: 'Windows Outbound: Process/Service-Based',
        },
      },
      Placeholder: {
        RansomwareOSExposure: 'Select one or more OSes',
        RansomwarePortType: 'Select a port type',
        RansomwareSeverity: 'Select a severity, or None if not ransomware risky',
        ServiceDescription: 'Type a description for the new Service',
        ServiceName: 'Type a name for the new Service',
        ServicePorts:
          'Type or paste a port and protocol, a process or service, or a port and protocol with a process or service',
      },
      PortOrProcessIsRequired: 'Port or Process is required',
      PortsAndProtocols: 'Ports & Protocols',
      PortsProtocolsAndProcesses: 'Ports, Protocols, Processes and Services',
      ProcessIsRequired: 'Process is required',
      RansomwareOSExposureIsRequired:
        'OS is required if Service is designated as ransomware risky service (Severity not equal to None)',
      RansomwarePortTypeIsRequired:
        'Port Type is required if Service is designated as ransomware risky service (Severity not equal to None)',
    },
    NoServices: 'No Services to display. Try a different filter.',
    OverlappingPorts: 'Overlapping Ports',
    OverlappingServiceDefinitions: 'Overlapping Service Definitions',
    OverlappingTypeCode: 'Overlapping Type/Code',
    PolicyServices: 'Policy Services',
    ServiceDefinitions: 'Service Definitions',
    SourceProcessServices: 'Source Process Services',
    WindowsService: 'Windows Service',
  },
  Settings: {
    AuthenticationType: 'Authentication Type',
    BlockAction: {
      AddScopeForRejectConnections: 'Add Scope for Reject Connections',
      AllConfirmation: "Adding 'All Workloads' will remove all other Reject Connections Scopes.",
      BlockedConnectionAction: 'Blocked Connection Action',
      BlockedConnectionActionDesc:
        'The default blocked connection action is drop. Workloads that match these labels will reject blocked inbound connections.',
      DuplicatedScopesAndLabels: 'Reject Connections with the same scope has already been configured',
      EditScopeForRejectConnections: 'Edit Scope for Reject Connections',
    },
    CertificateExample: 'C=US, O=Illumio, OU=UX, CN=issuer@foo.com',
    CertificateIssuer: 'Certificate Issuer',
    CertificateSecondaryText: 'Fields in the name of Certificate Issuer that will be matched',
    Containers: {
      AddScopeForContainerInheritHostPolicy: 'Add Scope for Container Inherit Host Policy',
      AllConfirmation: "Adding 'All Workloads' will remove all other Containers Policy Scopes.",
      AnnotationLabelHint:
        'Any container annotation label is accepted by default. You can choose to restrict container annotations to one or more labels if needed, or assign your own label',
      AnnotationLabelTooltip:
        '"Use Container Annotations" gives the ability to container platform administrators to set the Labels on pods and services using annotations in the manifest files, within the allowed list defined by the Illumio admin.\n\n"Assign Label" unidirectionally sets the Label selected on pods and services within this namespace/project. This label cannot be overwritten with container annotations.',
      ConfigAllError: 'At least one named label must be selected to make containers inherit',
      ContainerInheritHostPolicy: 'Container Inherit Host Policy',
      ContainerInheritHostPolicyDesc:
        'Illumio enforces the host policy for all the docker containers running on the host if enabled.',
      ContainerSettings: 'Container Settings',
      ContainersPolicy: 'Containers Policy',
      DuplicatedScopesAndLabels: 'A Container Inherit policy with the same Scope has already been configured',
      EditScopeForContainerInheritHostPolicy: 'Edit Scope for Container Inherit Host Policy',
      ManagedDesc: 'Container workloads in their namespace are managed by PCE',
      UnmanagedDesc: 'Container workloads in this namespace are ignored by PCE',
    },
    Edit: {
      AllowIPv6Traffic: 'Allow IPv6 traffic',
      BlockIPv6Traffic: 'Block IPv6 traffic',
      IPv6TrafficAllAllowed: 'All IPv6 traffic allowed',
      IPv6TrafficAllowedBasedOnPolicy: 'Allowed based on policy',
      IPv6TrafficBlockedInEnforced: 'Blocked only in Enforced state. Always allowed on AIX and Solaris Workloads',
      ModifyScope: {
        Errors: {
          PolicyStateOption: 'Needs at least one of these: all, enforced, illuminated',
          PrimaryFirewall: 'Needs at least one of these: yes, no',
        },
      },
      PolicyConfiguration: {
        PolicyUpdateModeDesc:
          'Adaptive applies policy changes to the Workload as soon as you Provision. Static stages policy changes on the Workload, but does not apply them until you decide to manually push them.',
      },
      SelectValuesBeforeSaving: 'Please select values for the settings before saving.',
      UpdateError: 'Check your Scopes configuration for overlapping Scopes and try again.',
      UpdateErrorTitle: 'Error updating Settings',
    },
    ESPEncriptionAndIntegrity: 'ESP Encryption and Integrity',
    EssentialServiceRule: 'Essential Service Rule',
    EssentialServiceRules: 'Essential Service Rules',
    EssentialServiceRulesDesc:
      'The following Essential Service Rules are programmed on every VEN to allow normal network functionality and communication with the PCE.',
    GenerateMaintenanceToken: 'Generate Maintenance Token',
    IKEAuth: 'IKE Auth',
    IKEAuthentication: 'IKE Authentication',
    IKECertificates: 'IKE Certificates',
    IKEv1L2TP: 'IKEv1/L2TP',
    IKEv2: 'IKEv1/IKEv2',
    IKEv12DHGroup: 'IKEv1/2 DH Group',
    IKEv12Integrity: 'IKEv1/2 Integrity',
    IKEv12PRF: 'IKEv1/2 PRF',
    IKEVersion: 'IKE version',
    IntervalSecondaryText: 'Minimum interval is 7 Days',
    IPForwarding: {
      AddScope: 'Add Scope for IP Forwarding',
      AllConfirmation: "Adding 'All Workloads' will remove all other IP Forwarding Scopes.",
      Description:
        'IP forwarding is blocked by default unless the host is part of a Container Cluster. Linux Workloads that match these scopes will allow all forwarded traffic. Illumio does not provide visibility of forwarded traffic.',
      DuplicatedScopesAndLabels: 'IP Forwarding with the same scope has already been configured',
      EditScope: 'Edit Scope for IP Forwarding',
      NoData: 'There are no scopes set up to enable IP forwarding',
      Title: 'IP Forwarding',
    },
    IPv6Traffic: 'IPv6 Traffic',
    LoopbackInterfaces: {
      AddScopeForLoopbackInterfaces: 'Add Scope for Loopback Interfaces Policy',
      AllConfirmation: "Adding 'All Workloads' will remove all other Loopback Interfaces Scopes.",
      ConfigAllError: 'At least one named label must be selected',
      DuplicatedScopesAndLabels: 'A Loopback Interface Policy with the same Scope has already been configured',
      EditScopeForLoopbackInterfaces: 'Edit Scope for Loopback Interfaces Policy',
      LoopbackInterfacesDescription:
        "Scopes where Loopback Interfaces participate in the policy Workloads that match the scope will apply policy on Loopback Interfaces. Loopback Interface's IP addresses will be distributed to peers.",
      NoData: 'There are no scopes set up to enable Loopback Interfaces',
      Title: 'Loopback Interfaces',
    },
    NATDetection: 'NAT Detection',
    NotNetworkDetectionMode:
      'You are using advanced cloud network detection settings. Contact Illumio Support for more information.',
    OrgDataCenterIsDisabled: 'Your Organization data center is <span class="{className}">disabled</span>.',
    PKI: 'PKI',
    PrivateDataCenter: 'Private Data Center or Public Cloud with 1:1 NAT (Default)',
    PrivateDataCenterDescription:
      'For Workloads in a known public cloud (e.g. AWS or Azure), the public IP address of the Workload as seen by the PCE will be distributed along with the IP addresses of interfaces on the Workload. Use this setting only if there are no shared SNAT IP address(es) for egress traffic from public cloud Workloads.',
    PSK: 'PSK',
    PublicCloudConfiguration: 'Public Cloud Configuration',
    PublicCloudWithSNAT:
      'Public Cloud with SNAT / NAT Gateway (recommended setting if using a NAT gateway in AWS or Azure or the default outbound access in Azure)',
    PublicCloudWithSNATDescription:
      'The PCE will ignore the public IP address of the Workload in policy computation. This setting should be used in environments where Workloads in a known public cloud (e.g. AWS or Azure) that connect to other Workloads or the PCE (outside the VPC or cloud) via an SNAT IP address or SNAT pool (one example is the NAT Gateway in AWS), as the public IP seen by the PCE is not specific to any Workload. Only the IP addresses of network interfaces on the Workload (which are usually private IP addresses) will be distributed in the policy.',
    RotationInterval: 'Self Signed Certificate Rotation Interval',
    SAIdleTimeout: 'SA Idle Timeout',
    SAIdleTimeoutDuration: '3 to 5 minutes',
    SALifetime: 'SA Lifetime',
    SecureConnect: 'Secure Connect',
    TamperProtectionInfo:
      'When set to Yes, the VEN will require a maintenance token to perform operations such as stop, suspend, and unpair. You can generate a VEN maintenance token for all VENs on the VEN List page or for any specific VEN on the VEN Detail page.',
    TamperProtectionMaintenanceToken: 'Require VEN Maintenance Token',
    TamperProtectionTitle: 'Tampering Protection',
    TransportMode: 'Transport Mode',
    UseCertificatesForAuthentication: 'Use Certificates for authentication:',
    UsePreShared: 'Use Pre-Shared Keys for authentication',
    ValidCertificateValues:
      '<span class="{className}">Can contain any of the following short name formats of object identifiers:</span></br>DC or domainComponent, C or countryName, O or organizationName, OU or organizationalUnitName, CN or commonName.',
    VenOperationSetting: 'Settings - VEN Operations',
    VensIKEAuthenticationInfo:
      'VENs will report and use the certificate whose Issuer Name matches the following for IKE Authentication',
    VensRecoveryHelpFirstLine:
      'VENs are protected from tampering by a maintenance token to perform operations like Stop, Suspend, Unpair. Tampering Protection can be disabled in the VEN Operations Settings',
    VensRecoveryHelpSecondLine: 'Clicking the button Generate Maintenance Token generates a token for all VENs',
    VensRecoveryInfo:
      "For a high level of security, it's recommended to set the Self Signed Certificate Rotation Interval to 30 Days or less. This setting applies only to VENs that were paired using VEN version 21.5.0 or higher",
    VensRecoveryTitle: 'Certificate Rotation',
    VensRotationIntervalError: 'The minimum Self Signed Certificate Rotation Interval is 7 Days',
    VensRotationIntervalIntegerError: 'Self Signed Certificate Rotation Interval should be an integer',
    VensVersionsHigher: 'VENs (versions 20.2.0 and higher)',
    VensVersionsLower: 'VENs (versions lower than 20.2.0)',
    WorkloadsHostedInCloud:
      'Your Workloads are hosted by a <span class="{className}">cloud service provider</span> that does not provide a private network.',
    WorkloadsHostedInCloudWithPrivate: 'Custom setting: <span class="{className}">Cloud Private Network</span>.',
    WorkloadsHostedInPrivate:
      'Your Workloads are hosted by a <span class="{className}">private data center</span>, or with a cloud service provider that provides a private network',
    WorkloadsHostedInPrivateOnly: 'Custom setting: <span class="{className}">Single Network</span>.',
  },
  States: {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AS: 'American Samoa',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District Of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    FM: 'Federated States Of Micronesia',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MP: 'Northern Mariana Islands',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    PW: 'Palau',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VI: 'Virgin Islands',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
  },
  Static: {
    FourOhFour: {
      CouldNotFindPage: 'We could not find the page you are looking for.',
      LetUsKnow: 'If you have reached this page, please let us know at <a>{email}</a> so we can fix it.',
      PageNotFound: '404 Page not found',
    },
    ResourceNotFound: {
      CodeResourceNotFound: '404 Resource not found',
      ResourceNotFound: 'Resource not found.',
      StartOver: 'Start over.',
    },
  },
  StatusCodes: {
    BadGateway: '502 Bad Gateway',
    GatewayTimeout: '504 Gateway Timeout',
    HttpVersionNotSupported: '505 HTTP Version Not Supported',
    InsufficientStorage: '507 Insufficient Storage',
    InternalServerError: '500 Internal Server Error',
    LoopDetected: '508 Loop Detected',
    NetworkAuthenticationRequired: '511 Network Authentication Required',
    NotExtended: '510 Not Extended',
    NotImplemented: '501 Not Implemented',
    ServiceUnavailable: '503 Service Unavailable',
    VariantAlsoNegotiates: '506 Variant Also Negotiates',
  },
  SupportBundles: {
    SupportBundles: 'Support Bundles',
  },
  SupportReports: {
    Failed: 'Support Bundle Failed',
    Generating: 'Generating ...',
    NewReportOverwritesExistingReport:
      'Generating a new bundle will overwrite the existing one. Do you want to continue?',
    NoData: 'Select a VEN to generate a VEN support bundle',
    PageName: 'VEN Support Bundles',
    RequestedByAt: 'Requested by {user} at {when, date}',
    SupportReport: 'VEN Support Bundle',
    SupportReportBeingGenerated: 'The support bundle is being generated.',
    SupportReports: "Bundle can't be Generated",
    SupportReportWasGenerated: 'The support bundle has been successfully generated.',
  },
  Switches: {
    AclGeneratedAt: 'ACL Generated at',
    AclGenerationPending: 'ACL Generation Pending',
    AddInterfaces: 'Add Interfaces',
    AddNew: 'Add a new Switch',
    AppliedAt: 'Applied At',
    CannotDelete: 'The following {count, plural, =1 {Switch is} other {# Switches are}} cannot be deleted.',
    Delete: 'Remove {count, plural, =1 {Switch} other {# Switches}}',
    DeleteConfirm: 'Are you sure you want to remove the {count, plural, =1 {Switch} other {# Switches}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Switch has} other {# Switches have}} been successfully deleted',
    DownloadACLs: 'Download ACLs',
    EnforcementNode: 'Enforcement Node',
    Form: {
      IfIndexPlaceholder: 'Type ifIndex',
      InterfaceTitle: 'Interface {count, number}',
      MonitorTraffic: 'Monitor Traffic',
      SelectWorkloads: 'Select Workloads',
      SwitchIpPlaceholder: '0.0.0.0',
      SwitchNamePlaceholder: 'Type a switch name',
      TotalInterfaces: 'Total Interfaces',
    },
    GenerateACLs: 'Generate ACLs',
    List: {
      Message: {
        DeleteConfirm: 'Are you sure you want to remove the selected {count, plural, =1 {Switch} other {Switches}}?',
        WorkloadsManagedDeleted: 'All workloads managed by the switch will be removed as well.',
      },
    },
    Manufacturer: 'Manufacturer',
    MarkApplied: 'Mark Applied',
    Modals: {
      CreateErrorTitle: 'Create switch error',
      EditInterfacesError: 'Edit interfaces error',
    },
    Model: 'Model',
    NENHostname: 'NEN hostname',
    NoData: 'No Switches to display',
    NoInterfaceData: 'No Interfaces to display',
    NoMatchData: 'No matching Switches to display',
    SwitchIp: 'Switch IP',
    SwitchIPMustBeUnique: 'Switch IP must be unique',
    SwitchName: 'Switch Name',
    SwitchNameMustBeUnique: 'Switch Name must be unique',
  },
  SystemSettings: {
    InterfaceName: 'Interface Name',
    KeyValue: 'Only key-value format is supported',
    LogForwarding: {
      Severity: {
        Info: 'Informational',
      },
    },
    Network: 'Network',
    NetworkEdit: {
      LogBackIn: 'You will be required to log back in.',
      NetworkSettingsApplied: 'Your network settings have been applied.',
    },
    TestEmailSentSuccess:
      'Test email was sent to  <span class="{className}">{email}</span>, check your email for validation.',
  },
  Tooltip: {
    ConnectedWorkloadCount:
      'Connected {count, plural, =1 {Workload} other {Workloads}}: <span class="{className}">{count}</span>',
    ContainerWorkload: 'Container Workload:',
    ContainerWorkloadCount:
      '{count, plural, =1 {Container Workload} other {Container Workloads}}: <span class="{className}">{count}</span>',
    EndpointCountHighlight:
      '{count, plural, =1 {<span class="{className}">{type}:</span>} other {<span class="{className}">{type}s:</span>}} {count}',
    Enforcement: 'Enforcement:',
    EnforcementCount: '{count, plural, =0 {} other {{count} {type}}}',
    Fqdns: 'FQDNs:',
    Group: 'Group:',
    GroupBy: 'Group by',
    GroupCount: '{count, plural, =1 {Group} other {Groups}}: <span class="{className}">{count}</span>',
    IPList: 'IP List:',
    Location: 'Location:',
    NoLabelData: 'No Label Data',
    OSWorkload: 'OS Workload:',
    ParentGroup: 'Parent Group',
    PolicyState: 'Policy State:',
    Role: 'Role:',
    RoleCount: '{count, plural, =1 {Role} other {Roles}}: <span class="{className}">{count}</span>',
    Services: 'Services :',
    VirtualServer: 'Virtual Server:',
    VirtualServerCount:
      '{count, plural, =1 {Virtual Servers} other {Virtual Servers}}: <span class="{className}">{count}</span>',
    VirtualService: 'Virtual Service:',
    VirtualServiceCount:
      '{count, plural, =1 {Virtual Service} other {Virtual Services}}: <span class="{className}">{count}</span>',
    WorkloadCount: '{count, plural, =1 {Workload} other {Workloads}}: <span class="{className}">{count}</span>',
  },
  TrafficEvents: {
    AllEvents: 'All Events',
    BlockedEvents: 'Blocked Events',
    Direction: 'Direction',
    FirstTime: 'First Time',
    FlowCount: 'Flow Count',
    LastTime: 'Last Time',
    List: 'Traffic Events',
    PotentiallyBlockedEvents: 'Potentially Blocked Events',
    Singular: 'Traffic Event',
    TrafficBlockedByEnforcement: 'Traffic that was blocked by policy enforcement.',
    TrafficBlockedWhenEnforced: 'Traffic that will be blocked when policy is enforced.',
    TrafficEventsCount: '{count, plural, =0 {no Traffic Events} =1 {# Traffic Event} other {# Traffic Events}}',
  },
  TrafficParameters: {
    AddTimeToTimestamp: 'Add Time to TS(Temp)',
    AlwaysRebuildWorkloads: 'Rebuild Role Expansion',
    AnyRuleCoverage: 'Any Rule coverage',
    Applications: '{count, plural, =0 {no Applications} =1 {# Application} other {# Applications}}',
    CollapsedThreshold: 'Collapsed Role Threshold',
    ConfigTraffic: 'Configurable Traffic Parameters',
    Days: '{count, plural, =0 {no Days} =1 {# Day} other {# Days}}',
    DefaultConnectedLinks: 'Default ({count, plural, =0 {no Links} =1 {# Link} other {# Links}})',
    DefaultDnsIplistMapping: 'Default (Map All IP List flows with FQDNs as IP Lists)',
    DefaultLinksForRules: 'Default  ({count, plural, =0 {no Links} =1 {# Link} other {# Links}})',
    DefaultMaxApplicationRuleCoverage: 'Autoload Rule Coverage < 200 Applications',
    DefaultNumberOfAddresses: 'Default ({count, plural, =0 {no Addresses} =1 {# Address} other {# Addresses}})',
    DefaultNumberOfClusters: 'Default ({count, plural, =0 {no Groups} =1 {# Group} other {# Groups}})',
    DefaultNumberOfIPLists: 'Default ({count, plural, =0 {no IP Lists} =1 {# IP List} other {# IP Lists}})',
    DefaultNumberOfLocations: 'Default ({count, plural, =0 {no Locations} =1 {# Location} other {# Locations}})',
    DefaultNumberOfPorts: 'Default ({count, plural, =0 {no Ports} =1 {# Port} other {# Ports}})',
    DefaultNumberOfRoles: 'Default ({count, plural, =0 {no Roles} =1 {# Role} other {# Roles}})',
    DefaultNumberOfWorkloads: 'Default ({count, plural, =0 {no Workloads} =1 {# Workload} other {# Workloads}})',
    DefaultPercentOfRuleCover: 'Default ({proportion, number, percent} Rule coverage)',
    DefaultRebuildStale: 'Rebuild Stale Data for < 50 workloads',
    DefaultServicesPerRule: 'Default ({count, plural, =1 {# Service} other {# Services}})',
    DefaultShowConnectionState: 'Default (Do not show Connection State)',
    DefaultShowExplorerServices: 'Default (Show Explorer PolicyServices)',
    DefaultTime: 'Default (No time added)',
    DefaultTruncationLimit: 'Default ({count, plural, =0 {no Nodes} =1 {# Node} other {# Node}})',
    DnsIplistMapping: 'FQDN Mapping',
    DoNotAlwaysRebuild: 'Do not Always Rebuild',
    DoNotRebuildStale: 'Rebuild Only On Demand',
    DoNotShowExplorerServices: 'Do Not Show Explorer Policy Services',
    EnableUnifiedPolicyAndOrMap: 'Enable Unified Policy and/or Map',
    ExplorerSearchFormats: 'Explorer Search Formats',
    ExplorerServices: 'Explorer Services',
    FreshData: 'Fresh Data',
    IlluminationPlusSearchFormats: 'Search Formats',
    IlluminationSettings: 'Illumination Settings',
    KiloNumber: '{count, number}K',
    LightGreenThreshold: 'Light Green Line Threshold',
    LocationLevel: 'Always Location Level',
    LocationThreshold: 'Location View Threshold',
    MapAllFqdnsToDomains: 'Map All IP List flows with FQDNs as FQDNs',
    MapOnly: 'Map Only',
    MaxAddressesPerLink: 'Max Addresses Per IP Link',
    MaxApplicationsForRuleCoverage: 'Application Rule Coverage',
    MaxClustersForLinks: 'Max Groups For Links',
    MaxClustersPerLocation: 'Max Groups Per Location',
    MaxExplorerLinksForDeepRules: 'Max Explorer Links for Deep Rules',
    MaximumRolesExpandable: 'Maximum Roles Expandable',
    MaxLinksForConnectedApplicationRuleCoverage: 'Draft Connected Application Links',
    MaxLocations: 'Max Locations',
    MaxNodesBeforeTruncation: 'Max Nodes before truncation',
    MaxOverlappingIP: 'Max Overlapping IP Lists',
    MaxPortsPerLink: 'Max Ports Per Link',
    MaxServicesPerRule: 'Max Services Per Rule',
    MaxWorkloads: 'Max Workloads Displayed',
    MaxWorkloadsPerGroup: 'Max Workloads Expanded Per Group',
    MaxWorkloadsPerRole: 'Max Workloads Expanded Per Role',
    Months: '{count, plural, =0 {no Months} =1 {# Month} other {# Months}}',
    NoLimit: 'No Limit',
    NumClusters: '{count, plural, =0 {no Groups} =1 {# Group} other {# Groups}}',
    NumLocations: '{count, plural, =0 {no Locations} =1 {# Location} other {# Locations}}',
    PolicyOnly: 'Policy Only',
    RebuildAlways: 'Always Rebuild (Up to 100 Workloads)',
    RebuildStale: 'Rebuild Stale Data',
    ResetPositions: 'Reset Positions',
    ShowConnectionState: 'Show Connection State',
    ShowExplorerConnectionState: 'Explorer Connection State',
    StaleData: 'Stale Data',
    TrafficParameters: 'Traffic Parameters',
    ViewLevel: 'View Level Threshold',
    Weeks: '{count, plural, =0 {no Weeks} =1 {# Week} other {# Weeks}}',
    Years: '{count, plural, =0 {no Years} =1 {# Year} other {# Years}}',
  },
  TrustedProxy: {
    Add: 'Add Trusted Proxy IPs',
    ConfigurationInfo: 'Proxy Configuration - cannot add more than {max} Trusted Proxy IPs',
    Edit: 'Edit Trusted Proxy IPs',
    Errors: {
      Edit: 'Unable to Edit',
    },
    List: {
      Delete: 'Remove {count, plural, =1 {Trusted Proxy IP} other {# Trusted Proxy IPs}}',
      Empty: 'No Proxy IPs',
      RemoveConfirm: 'Are you sure you want to remove the selected Trusted Proxy IPs?',
      RemoveResultTitle: 'Result of {count, plural, =1 {Trusted Proxy IP} other {# Trusted Proxy IPs}} removal',
    },
    RegionsAvailable: '{region} ({groupedRegions} of {maxTrustedListRegions}) Available',
    TrustedProxy: 'Trusted Proxy',
    TrustedProxyIp: 'Trusted Proxy IPs',
  },
  UnifiedPolicy: {
    ConflictedRules: 'Conflicted Rules',
    ConflictedRulesAllow:
      'Your allow rule is being overriden by {count, plural, =1 {this override deny rule} other {these override deny rules}}',
    ConflictedRulesDeny: 'Your deny rule is overriding {count, plural, =1 {this allow rule} other {these allow rules}}',
    ConflictedRulesOverrideDeny:
      'Your override deny rule is overriding {count, plural, =1 {this allow rule} other {these allow rules}}',
    InheritedRulesOfPolicy: 'Inherited Rules of {name}',
    OverlappingRulesHint: 'This rule has conflicted rules.<br/>Click to view relevant rules',
    ViewCloudApplication: 'View Cloud Application',
    ViewDataCenterApplication: 'View Data Center Application',
  },
  User: {
    AccessError: 'Access not authorized',
    AccessForbidden: 'Access denied or Access not authorized',
    Empty: 'PCE user/domain not yet configured',
    EmptyOrgs: 'User <b>{fullName}</b> (<b>{authName}</b>) does not have any permissions in this Organization',
  },
  UserGroups: {
    Add: 'Add User Group',
    AddNew: 'Add a new User Group',
    Create: {
      Placeholder: {
        UserGroupName: 'Type a name for the new User Group',
        UserGroupSID: 'Type a SID for the new User Group',
      },
      SIDExample: 'Use the format S-<unique number> to type in an SID',
      Validation: {
        InvalidName: 'User Group must have a name defined',
        InvalidSIDFormat: 'User Group SID format is invalid',
        InvalidSIDLength: 'User Group SID is too long',
      },
    },
    Errors: {
      Create: 'Unable to Create User Group',
      Edit: 'Unable to Edit User Group',
    },
    Instructions: 'User Groups allow user-based access to specific entities',
    NoData: 'No User Groups to display',
    NoMatchData: 'No matching User Groups to display',
    Remove: 'Remove {count, plural, =1 {User Group} other {User Groups}}',
    RemoveConfirm:
      'Are you sure you want to remove the selected {count, plural, =1 {User Group} other {# User Groups}}?',
    RemoveModal: {
      Confirm:
        'Are you sure you want to remove the {count, plural, =1 {selected User Group} other {# selected User Groups}}?',
      TitleConfirmation: 'Remove {count, plural, =1 {User Group} other {# User Groups}}',
    },
    SID: 'SID',
  },
  Users: {
    Accessibility: 'Accessibility',
    AccessRestrictionsNotSupported: 'Access restrictions is not supported with Cloud Only Roles',
    AccessRoles: 'Access Roles',
    AccessScopesRoles: 'Access Scopes and Roles',
    Add: 'Add User',
    AddExternalGroup: 'Add External Group',
    AddRole: 'Add Role',
    AddScopedRole: 'Add Scoped Role',
    AddUnscopedRole: 'Add Unscoped Role',
    AlreadyExists: 'User already exists',
    APIKeys: {
      AuthenticationUsername: 'Authentication Username',
      CanManageCredentials: 'You can manage and recreate these credentials at any time.',
      Create: 'Create API Key',
      Created: 'API Key Created',
      CredentialsDownload: 'Download Credentials',
      Delete: 'Remove {count, plural, =1 {API Key} other {API Keys}}',
      DeleteConfirm:
        'Are you sure you want to remove the {count, plural, =1 {selected API Key} other {# selected API Keys}}? If the API key is deleted, the API authentication cannot be used.',
      DeletedSuccessfully:
        'The following {count, plural, =1 {API Key has} other {# API Keys have}} been successfully deleted',
      Edit: 'Edit API Key',
      Explorer: 'API Explorer',
      HereCredentialsAvailableToDownload: 'This is the only time these credentials will be available to download.',
      KeyID: 'Key ID',
      MyAPIKeys: 'My API Keys',
      Name: 'API Keys',
      NoKeys: 'No API Keys',
      OrgId: 'Org ID',
      RemoveResultTitle: 'Result of {count, plural, =1 {API Key} other {# API Keys}} removal',
      UserID: 'User ID',
    },
    CannotEditExternalGroup: 'Cannot edit external group',
    CannotEditUser: 'Cannot Edit User',
    CannotRemoveUser: 'Cannot Remove User',
    ColorDeficient: {
      Message: 'Optimize the color palette for Deuteranopia, Protanopia, and Tritanopia vision',
      ProtanopiaAndDeuteranopia: 'Protanopia & Deuteranopia',
      Title: 'Color vision deficiency',
      Tritanopia: 'Tritanopia',
    },
    ColorMode: 'Color Mode',
    ColorNormal: {
      Message: 'Optimize the color palette for normal vision',
      Title: 'Normal vision',
    },
    Convert: 'Convert',
    ConvertExternalUserMessage:
      '{username} is currently an external user. Do you want to re-invite this user as a local user?',
    ConvertExternalUserToLocalWarning: 'The user will be converted to a local user',
    ConvertLocalUserToExternalWarning: 'The user will be converted to an external user',
    ConvertMultipleUsersType: '{count} users will be converted from {startType} to {endType}.',
    ConvertSuccessExternalUser:
      'User <span class="{className}">{username}</span> was successfully converted to an external user.',
    ConvertSuccessLocalUser:
      'User <span class="{className}">{username}</span> was successfully converted to a local user.',
    ConvertUsersResultTitle:
      'Result of converting {count, plural, =1 {{convertTypeInit} User} other {{count} {convertTypeInit} Users}} to {convertTypeFinal}',
    ConvertUsersSuccessfully: 'Successfully converted {count} Users',
    ConvertUserType: 'Convert to {type}',
    DefaultPolicyVersion: 'Default Policy Version',
    DisabledAddUserMessage: 'Adding users is temporarily disabled due to ongoing maintenance',
    EditExternalGroup: 'Edit External Group',
    EditGroup: 'Edit Group',
    EditUser: 'Edit User',
    EmailOrUsername: 'Email or Username',
    ExternalGroup: 'External Group',
    ExternalGroupAddFormHint:
      "Must match the group's memberOf attribute set in your IdP\nExamples: Sales or CN=Sales,OU=West,DC=MyDomain,DC=com",
    ExternalGroupRequired: 'External Group is required',
    ExternalGroups: 'External Groups',
    ExternalUsers: 'External Users',
    ExternalUserToLocal: '{count, plural, =1 {External User} other {External Users}} to Local',
    GlobalOwnerSublabel: 'Manage all resources, users and Security Settings.',
    GlobalPolicyObjectProvisionerSublabel:
      'Provision Services, IP Lists, Label Groups, and Security Settings. Read-only access to all other resources.',
    Invite: {
      AlreadyInOrg: 'This user already exists in the Organization',
      GroupAlreadyInOrg: 'This group already exists in the Organization',
      Link: 'Invitation Link',
      LinkDirectlyToUser: 'This link can be sent directly to the user',
      Reinvite: 'Re-Invite',
      Reinvited: 'User <span class="{className}">{username}</span> was successfully re-invited.',
      ReinvitedError: 'User <span class="{className}">{username}</span> is an active user.',
      ReinviteUser: 'Re-Invite User',
      ReinviteWarning: 'A new invitation will be sent to the user',
      UserAlreadyInOrg:
        "You've invited a user that is already part this organization. A new invitation will be sent to this user.",
      UserInDifferentOrg:
        "You've invited a user that is part of a different organization within Illumio. This is currently not supported. Try again with a different email address.",
    },
    LimitedRuleWritingSublabel:
      'Manage Policies that match the scope and Rules where the Destination and Source match the scope.',
    LocalUsers: 'Local Users',
    LocalUserToExternal: '{count, plural, =1 {Local User} other {Local Users}} to External',
    LockToggleSuccess: 'User <u>{username}</u> was successfully <s>{lock, select, true {locked} false {unlocked}}</s>.',
    LockUser: 'Lock User',
    LockUserWarning: 'Locking a user prevents them from logging in but does not change their granted access',
    LoginFailedCountExceeded: 'User login failure count exceeded',
    Logout: 'Logout',
    ManagingOrganization: 'Managing Organization',
    ManagingOrganizationId: 'Managing Organization ID',
    MyProfile: 'My Profile',
    MyRoles: 'My Roles',
    NameRequired: 'Name is required',
    NoAllLabels: 'The Scope is too broad. Scopes that have no Labels are not supported.',
    OrganizationId: 'Organization ID',
    PassClickToChange: 'Click to change your user account password',
    Personal: 'Personal',
    ReAddUserMessage: 'User {username} was removed. Do you want to re-invite this user as a local user?',
    ReAddUserTitle: 'Re-add removed user',
    ReadOnlySublabel: 'Global read-only access to all resources',
    Remove: 'Remove {multiple, select, false {User} true {Users}}',
    RemoveExternalGroupPermissions: 'Remove External Group Permissions',
    RemoveExternalGroupsWarning:
      "{count, number} External Group's permissions will be removed and revoked from this organization",
    RemoveUserPermissions: 'Remove User Permissions',
    RemoveUsersWarning: "{count, number} User's permissions will be removed and revoked from this organization",
    ResetPassword: 'Reset Password',
    RoleChangedSuccess:
      'The role for user <span class="{className}">{username}</span> was successfully changed to <span class="{className}">{rolename}</span>.',
    RulesetManagerSublabel: 'Manage Policies that match the scope and Rules where the Destination matches the scope.',
    RulesetProvisionerSublabel: 'Provision Policies that match the scope.',
    RulesetViewerSublabel:
      'Read-only access to Rules that match the scope. Does not permit editing of Policies and Rules.',
    SessionsExceeded: 'Maximum users reached',
    SessionTimeOut: 'Your session has timed out. Log in to continue.',
    SSOConfig: {
      ActiveDirectory: 'Active Directory Federation Services',
      AmazonCognito: 'Amazon Cognito',
      AssertionConsumerUrl: 'Assertion Consumer URL',
      Auth0: 'Auth0',
      AuthenticationMethod: 'Authentication Method',
      AzureAD: 'Azure AD',
      ClientID: 'Client ID',
      DownloadCertificate: 'Download Certificate',
      EnableIdpLogout: 'IDP Logout',
      EnableIdpLogoutInfo:
        'Enabling this will end the IdP session, but note that it may not be supported by all providers',
      ForceReauthentication: 'Force Re-authentication',
      GenerateCertificate: 'Generate Certificate',
      Google: 'Google',
      IdentityProvidersDesc:
        'You can use one of the following identity providers for authenticating users with the PCE',
      InfoForIdentityProvider: 'Information for Identity Provider',
      InfoFromIdentityProvider: 'Information from Identity Provider',
      IssuerUrl: 'Issuer URL',
      LogoutLandingURL: 'Logout Landing URL',
      LogoutUrl: 'Logout URL',
      MSEntraID: 'MS Entra ID',
      Name: 'SSO Config',
      NameIDFormat: 'NameID Format',
      Okta: 'Okta',
      OneLogin: 'OneLogin',
      PasswordProtected: 'Password Protected Transport',
      PCESAMLCertificate: 'PCE SAML Certificate',
      PCESAMLCertificateExpiresAt: 'PCE SAML Certificate Expires At',
      PingIdentity: 'Ping Identity',
      RemoteLoginURL: 'Remote Login URL',
      Rotate: 'Rotate',
      RotateCertificate: 'Rotate Certificate',
      RotateCertificateMessage:
        'When you rotate PCE SAML Certificate, it needs to be updated in your Identity Provider. Please log in to your Identity Provider and update your certificate',
      SAMLIdentityProviderCertificate: 'SAML Identity Provider Certificate',
      SAMLVersion: 'SAML Version',
      SignSAMLRequest: 'Sign SAML Request',
      SSOConfig: 'Single Sign-On Configuration',
      SSOMethod: 'SSO method',
      Unspecified: 'Unspecified',
    },
    UnlockUser: 'Unlock User',
    User: 'User',
    UserAdded: 'User <span class="{className}">{username}</span> was successfully added.',
    UserRemovedFromOrg: 'User <span class="{className}">{username}</span> was successfully removed from organization.',
    Users: 'Users',
    UsersAndGroups: 'Users and Groups',
  },
  VEN: {
    ActivationType: 'Activation Type',
    Architecture: 'Architecture',
    CaCertificate: 'CA Signed Certificate',
    Condition: 'Health Condition',
    ContainerCluster: 'Container Cluster',
    CVEN: 'Containerized VEN (C-VEN)',
    DisconnectedBefore: 'Disconnected Before',
    Distribution: 'Distribution',
    EditError: 'Unable to Edit VEN',
    EndpointOnly: 'Endpoint VEN only',
    EnforcementNodeType: 'Enforcement Node Type',
    EVEN: 'Endpoint VEN',
    Filename: 'VEN Filename',
    Filter: {
      InvalidVersionRange: 'Invalid version range selected',
      Max: 'Max',
      Min: 'Min',
      SelectAVersion: 'Select a Version',
    },
    Health: 'Health',
    Host: 'Host',
    IndividualEndpoints: 'Individual endpoints in your environment',
    Kerberos: 'Kerberos',
    LastHeartbeat: 'Last Heartbeat',
    Library: 'VEN Library',
    NoData: 'No VENs to display',
    NoMachingData: 'No matching VENs to display',
    OSVersion: 'OS Version',
    PairingScript: 'Pairing Script',
    PairWithPairingProfile: 'Add with Pairing Profile',
    RecoveryModeDescription:
      'Authentication Recovery Mode is typically used when restoring VENs from backup and poses an elevate security risk by allowing the VEN to provide old credentials.',
    RecoveryModeDuration: 'Recovery Mode Duration',
    RecoveryModeSecondaryPlaceholder: '1 Hour to 30 Days',
    RecoveryModeSecondaryText: 'Duration can be 1 Hour to 30 Days',
    RecoveryModeSecondDescription: 'Are you sure you want to Set Authentication Recovery Mode for the selected VEN(s)?',
    SelfCertificate: 'Self Signed Certificate',
    ServerEndpoint: 'Server + Endpoint VENs',
    ServerOnly: 'Server VEN only',
    SetRecoveryMode: 'Set Authentication Recovery Mode',
    SetRecoveryModeDayWarning: 'Recovery Mode duration needs to be in the range of  1 to 30 days.',
    SetRecoveryModeError: 'Unable to Set Authentication Recovery Mode',
    SetRecoveryModeHourWarning: 'Recovery Mode duration needs to be in the range of  1 to 720 hours.',
    SetRecoveryModeReqWarning:
      'Recovery Mode duration needs to be in the range of  1 hr to 30 Days. Enter a suitable duration',
    Supported: 'Supported OS Versions',
    Suspend: 'Suspend',
    Unpaired: 'Unpaired',
    Upgrade: {
      ConfirmUpgrade: 'Confirm Upgrade',
      Days: 'Day(s)',
      Hours: 'Hour(s)',
      Minutes: 'Minute(s)',
      Title: 'Upgrade VENs',
      UpgradeAll: 'Upgrade All',
      UpgradeFiltered: 'Upgrade Filtered',
      UpgradeInProgress: 'Upgrading',
      UpgradePending: 'Upgrade Pending',
      UpgradeSelected: 'Upgrade Selected',
      UpgradeStartedOnDate: 'VEN Upgrade of {count, plural, =1 {# VEN} other {# VENs}} started on {datetime}.',
      UpgradeWarningMessage:
        'VEN release {version} is supported only on endpoints and cannot be used to upgrade server VENs',
      VensMatchFilter: '{n} VENs match the selected filter criteria',
      VensSelected: '{n} VENs are selected',
      VensThatQualify: 'VENs that qualify for an upgrade to {version} release',
      VensWillBeUpgraded: '{n, plural, =0 {No VENs} =1 {# VEN} other {# VENs}} will be upgraded.',
      VENTypeError: "VENs are not supported by the selected VEN version's VEN type",
      VENUpgradeExpiration: 'VEN Upgrade Expiration',
      VENUpgradeExpirationWarning: 'Value must be between 15 minutes and 180 days.',
      VENUpgradeExpireAt: 'Expires at {expire}',
      VENUpgradeExpireDescription:
        'Once the expiration time passes, the PCE stops trying to upgrade the VEN. The expiration time must be between 15 minutes and 180 days. Illumio recommends 1 day for server VENs and 7 days for endpoint VENs.',
      VENUpgradeTo: 'Pending upgrade to {version}',
      ZeroVenReleaseBundles: 'No VEN release bundles',
    },
    VEN: 'Server VEN',
    VENLibraryWarningMessage:
      'VEN release {version} is supported only on endpoints and should not be installed on servers',
    VenMaintenanceTokenExpire: 'Token Expiration',
    VenMaintenanceTokenModalDescription: 'Maintenance Token for {name} has been generated at {time}',
    VenMaintenanceTokenModalExpire: 'The Token expires on {time}',
    VENOperation: 'VEN Operations',
  },
  Version: {
    Detail: {
      NoRulesInScope: 'None of the provisioned objects in this Policy Version are within your assigned scopes',
      TallyLabel: {
        ChangedEnforcementBoundaries: '{count, plural, =1 {Changed Deny Rule} other {Changed Deny Rules}}',
        ChangedIPLists: '{count, plural, =1 {Changed IP List} other {Changed IP Lists}}',
        ChangedLabelGroups: '{count, plural, =1 {Changed Label Group} other {Changed Label Groups}}',
        ChangedRulesets: '{count, plural, =1 {Changed Policy} other {Changed Policies}}',
        ChangedSecureConnect:
          '{count, plural, =1 {Changed Secure Connect Gateway} other {Changed Secure Connect Gateways}}',
        ChangedServices: '{count, plural, =1 {Changed Service} other {Changed Services}}',
        ChangedSettings: '{count, plural, =1 {Changed Setting} other {Changed Settings}}',
        ChangedVirtualServers: '{count, plural, =1 {Changed Virtual Server} other {Changed Virtual Servers}}',
        ChangedVirtualServices: '{count, plural, =1 {Changed Virtual Service} other {Changed Virtual Services}}',
      },
      Version: 'Version #{version}',
      VersionVsPrevious: 'Version #{currentVersion} vs. Version #{previousVersion}',
      WorkloadsAffected: '{count, plural, =1 {Workload Affected} other {Workloads Affected}}',
    },
    ProvisionedBy: 'Provisioned By',
    ProvisionedOn: 'Provisioned On',
    Restore: {
      ExistingDraftAction: 'Revert draft policies before restoring a prior version.',
      ExistingDraftMessage: 'Existing objects in the draft policy queue.',
      PastVersionsToBeRevertedTitle: 'Past versions that will be reverted by restoring version {version}',
      SaveAsDraft: 'Save as Draft',
      UnableToSaveToDraft: 'Unable to save to draft',
    },
    WorkloadsAffected: 'Workloads Affected',
    WorkloadsAffectedCount: '{count, plural, =1 {# Workload Affected} other {# Workloads Affected}}',
  },
  VersionMismatch: {
    Header: 'Incompatible PCE UI Version',
    However:
      'However, the PCE UI version must match the base version of the PCE software; for example, {uiVersion} must match PCE {pceVersion}',
    Incompatible: 'The PCE UI Version is incompatible with the PCE software',
    PCE: 'PCE Version',
    SeeGuide: 'See the PCE Operations Guide',
    UI: 'UI Version',
    Upgrade:
      'You can upgrade the PCE UI that appears in the PCE web console independently of the nodes in the PCE cluster.',
    UpgradeInfo: 'Upgrade the PCE UI or PCE software so that the versions are compatible',
  },
  VirtualServers: {
    All: 'All Virtual Servers',
    ByCount: '{count, plural, =1 {Virtual Server} other {Virtual Servers}}',
    ConfirmManageServer:
      'Are you sure you want to Manage {count, plural, =1 {this Virtual Server} other {# Virtual Servers}}?',
    ConfirmUnmanageServer:
      'Are you sure you want to stop managing {count, plural, =1 {this Virtual Server with this Load Balancer} other {# Virtual Servers with following Load Balancers}}?',
    Detail: {
      EditMembers: 'Edit Members',
      EnforcedDesc: 'All traffic flows to Virtual Server not allowed by Rules are blocked',
      LabelDefinedMembers: 'Label-defined Members',
      ManagementState: 'Management State',
      ManageVirtualServer: 'Manage Virtual {count, plural, =1 {Server} other {# Servers}}',
      NoMembersDefined: 'No Members Defined',
      NotEnforcedDesc: 'All traffic flows to Virtual Server are allowed',
      RoutingMethod: 'Routing Method',
      ServerLoadBalancer: 'Server Load Balancer',
      ServerLoadBalancers: 'Server Load Balancers',
      UnassociatedDesc: 'Connectivity to Server Load Balancer has been lost',
      UnmanageVirtualServer: 'Unmanage Virtual {count, plural, =1 {Server} other {# Servers}}',
    },
    Errors: {
      Edit: 'Unable to Edit Virtual Server',
      Remove: 'Unable to Unmanage Virtual Server',
    },
    List: {
      BoundToRules:
        'The following {count, plural, =1 {Virtual Server is} other {# Virtual Servers are}} bound to rules',
      SLB: 'SLB',
      UnmanagedSuccess:
        'The following {count, plural, =1 {Virtual Server has} other {# Virtual Servers have}} been unmanaged successfully',
    },
    ModeManagedEnforced: 'Managed: Enforced',
    ModeManagedNotEnforced: 'Managed: Not Enforced',
    NoData: 'No Virtual Servers to display',
    ProvidersEdit: {
      AllLabelsError:
        'At least one Label must be a resource other than "All." Selecting "All" for all four Label types is not supported.',
      SelectAppPlaceholder: 'Select an Application for the Virtual Server Members',
      SelectEnvPlaceholder: 'Select an Environment for the Virtual Server Members',
      SelectLocPlaceholder: 'Select a Location for the Virtual Server Members',
      SelectRolePlaceholder: 'Select a Role for the Virtual Server Members',
      SpecifyAllLabels: 'All four Labels must be specified',
    },
    SLBDevice: 'SLB Device',
    SummaryEdit: {
      PolicyEnforced: 'The virtual server policy is enforced',
      PolicyNotEnforced: 'The virtual server policy is not enforced',
    },
    Total: 'Total Virtual Servers',
    Unassociated: 'Unassociated',
    VIP: 'VIP',
    VIPPort: 'VIP Port Number',
    VIPProto: 'VIP Protocol',
    VirtualServerName: 'Type a name for the new Virtual Server',
    WorkloadsAllowedTraffic:
      'Workloads matching selected Labels are allowed to receive traffic as members of this Virtual Server. Note, Workloads columns showing these labels are hidden by default.',
  },
  VirtualServices: {
    Add: 'Add Virtual Service',
    AddFqdn: 'Add FQDN',
    AddFqdns: 'Add FQDNs',
    AddIPAddressesOrFqdn: 'Add IP Addresses or Wildcard FQDNs',
    AddIpv4: 'Add IPv4',
    AddIPv4Addresses: 'Add IPv4 Addresses',
    AddIpv6: 'Add IPv6',
    AddIPv6Addresses: 'Add IPv6 Addresses',
    Additional: 'Additional Virtual Services',
    AddNew: 'Add a new Virtual Service',
    AddressPool: 'Address Pool',
    AddWorkloads: {
      Description:
        'Select a Workload to bind to the Virtual Service. If the Service has a Port/Protocol, you can specify which ports to override for those Workloads when a connection is initiated by a Source.',
      InvalidPortError: 'Invalid port. Enter a valid port override or uncheck ‘Override ports’ checkbox.',
      InvalidPortRangeErrror:
        'Invalid port range. Enter a valid port range override or uncheck ‘Override ports’ checkbox.',
      PortsOverrideDesc: 'Cannot override entries that has all Ports/Protocols or no Port.',
      SubTitle: 'Bind Workloads',
      UniquenessError: 'Cannot save a Port Override for an existing Virtual Service’s binded Workload.',
    },
    BoundContainerWorkloads: 'Bound Container Workloads',
    BoundToRules:
      'The following {count, plural, =1 {Virtual Service is} other {# Virtual Services are}} bound to rules',
    BoundToWorkloads:
      'The following {count, plural, =1 {Virtual Service is} other {# Virtual Services are}} bound to workloads',
    BoundWorkload: 'Bound Workload',
    BoundWorkloads: 'Bound Workloads',
    ConnectionServicesOrPorts: 'Connection Service Or Ports',
    Containers: {
      Delete: 'Remove Virtual Service Container',
      DeleteConfirm: 'Are you sure you want to remove the selected Virtual Service Container?',
    },
    Delete: 'Remove Virtual {count, plural, =1 {Service} other {Services}}',
    DeleteConfirm:
      'Are you sure you want to remove the {count, plural, =1 {selected Virtual Service} other {# selected Virtual Services}}?',
    DeletedSuccessfully:
      'The following {count, plural, =1 {Virtual Service has} other {# Virtual Services have}} been successfully deleted',
    DeleteSingleVirtualService: 'Are you sure you want to remove the Virtual Service?',
    DomainNameDuplicateError: 'Duplicated domain names: {list}',
    DomainNameExistError: 'Domain name has been configured: {list}',
    DomainNameFormattingError: 'Domain name formatting error: {list}',
    DomainsAreRequired: 'Domains are required',
    EditAddIPAddressesOrFqdn: 'Edit/Add IP Addresses or Wildcard FQDNs',
    EditDomainNames: 'Edit FQDN',
    EditIPv4Addresses: 'Edit IP Address',
    EditIPv6Addresses: 'Edit IPv6 Address',
    Errors: {
      Create: 'Unable to Create Virtual Service',
      Edit: 'Unable to Edit Virtual Service',
    },
    HostOnly: 'Host Only (Default)',
    InternalBridgeNetwork: 'Internal Bridge Network',
    IpAddresses4FormattingError: 'IPv4 Addresses formatting error: {list}',
    IpAddresses6FormattingError: 'IPv6 Addresses formatting error: {list}',
    IPAddressesAndFqdns: 'IP Addresses and FQDNs',
    IpAddressesAreRequired: 'IP Addresses are required',
    IpAddressesDuplicateError: 'Duplicated IP Addresses: {list}',
    IpAddressesExistError: 'IP Address with current network has been configured: {list}',
    ManagedBy: {
      ContainerCluster: 'This Virtual Service is managed by a Container Cluster',
      ContainerClusterDeletedAndOnLeader:
        'This Virtual Service was managed by a Container Cluster that is now deleted. You may delete the Virtual Service.',
      ContainerClusterDeletedAndOnMember:
        'This Virtual Service was managed by a Container Cluster that is now deleted. You may delete the Virtual Service from the leader PCE.',
    },
    NetworkForIps: 'Network for IPs',
    NoData: 'No Virtual Services to display',
    NoMatchData: 'No matching Virtual Services to display',
    OptionalCommentAboutTheEntry: 'Optional Comment about the entry',
    PortOverride: 'Port Override',
    SeeAll: 'View All {count, plural, =1 {Workload} other {# Workloads}}',
    SelectNetworkHint: 'Select a Network',
    ServiceOrPorts: 'Service or Ports',
    ServiceOrPortsIsRequired: 'Service or Ports is required',
    ServicePorts: 'Service / Ports',
    ShowFewer: 'Show Fewer Workloads',
    SingleBoundToRules: 'The Virtual Service is bound to rules',
    SingleBoundToWorkloads: 'The Virtual Service is bound to workloads',
    Total: 'Total Virtual Services',
    TypeFqdnAddresses: 'Type or paste one or more FQDNs (wildcards allowed)',
    TypeIPAddresses: 'Type or paste one or more IP Addresses',
    TypeIPAddressOrFqdn: 'Type or paste IP Address or FQDN (wildcards allowed)',
    View: 'View Virtual Services',
    Workloads: {
      CannotBind: 'Unable to Bind Workloads',
      Delete: 'Remove Virtual Service Workload',
      DeleteConfirm: 'Are you sure you want to remove the selected Virtual Service Workload?',
      Label: {
        Bind: 'Bind',
      },
      RemoveRecommand: 'Cannot override "Unidentified" port - removing the port override is recommended.',
      WorkloadDeleted: 'Removing the non-existing Workload is recommended.',
    },
  },
  Vulnerability: {
    After: 'After',
    All: 'All Vulnerabilities',
    Before: 'Before',
    BlockedTraffic: 'Blocked Vulnerable Traffic',
    BoxInfo:
      '{state, select, open {Reduced} closed {Eliminated}} {type, select, intra {Intra-Scope} extra {Extra-Scope} ipList {Internet}} exposure from {count} "{severity, select, 0 {Info} 1 {Low} 2 {Medium} 3 {High} 4 {Critical}}" severity vulnerabilities',
    Calculating: 'Calculating...',
    CVEIds: 'CVE-IDs',
    Eliminated: 'Eliminated',
    EWExposure: 'E/W Exposure',
    Exposed: 'Exposed Vulnerability',
    ExposedVulnerabilities: 'Exposed Vulnerabilities',
    ExposureScore: 'Vulnerability Exposure Score',
    ExtraScopeExposure: 'Extra-Scope Exposure',
    ExtraScopeMitigation: 'Extra-Scope Vulnerability Mitigation',
    HighestVEScore: 'Highest V-E Score',
    HighestVulnerability: 'Highest Vulnerability',
    ImportTime: 'Import Time',
    InternetExposure: 'Northern Exposure',
    InternetMitigation: 'Northern Vulnerability Mitigation',
    IntraScopeExposure: 'Intra-Scope Exposure',
    IntraScopeMitigation: 'Intra-Scope Vulnerability Mitigation',
    MaxSeverity: 'Max Severity',
    MitigatedVulnerabilities:
      '{count, plural, =0 {Mitigated Vulnerability} =1 {1 Mitigated Vulnerability} other {# Mitigated Vulnerabilities}}',
    MitigateVulnerabilities: 'Mitigate Vulnerabilities',
    ProvidedTraffic: 'Provided Traffic (Reported)',
    Reduced: 'Reduced',
    Score: 'Vulnerability Score',
    TotalVEScore: 'Total V-E Score',
    Traffic: 'Vulnerability Traffic',
    VEScore: 'V-E Score',
    Vulnerabilities: '{count, plural, =0 {No Vulnerabilities} =1 {1 Vulnerability} other {# Vulnerabilities}}',
    VulnerabilityExposureScore: 'Vulnerability Exposure Score (V-E Score)',
    Workloads: 'Vulnerable Workloads',
  },
  WelcomeBanner: {
    GetStarted: 'Get started by pairing workloads and inviting users.',
  },
  WhatsNew: {
    IsReleased: '{product} {version} is released',
    NewAnnouncement: 'New Announcement!',
    RulesForNonDomainAdapters:
      'This PCE now supports policy enforcement on endpoints for non-corporate network traffic. <a>Policy</a> - Click to view new policy added to preserve compatibility with policies written on 22.2 and earlier PCEs.',
    SeeWhatsNew: "See What's New!",
    WhatsNewIn: "What's New in {product}",
  },
  Workloads: {
    ActiveSyncing: 'Active (Syncing)',
    AdaptiveWorkloads: 'Adaptive Workloads',
    AddUnmanaged: 'Add Unmanaged Workload',
    AKI: 'Authority Key Identifier',
    All: 'All Workloads',
    AllColumns: 'All Columns',
    AllColumnsMessage: 'Export all table columns (including hidden columns).',
    ApplyPolicy: 'Apply Policy',
    ByCount: '{count, plural, =1 {Workload} other {Workloads}}',
    Clear: 'Clear',
    ClearSuspension: 'Clear Suspension',
    ClearSuspensionForWorkload: 'Clear the VEN Suspension Error for this Workload:',
    Compatible: 'Compatible',
    Configuration: 'Workload Configuration',
    ConfirmVENSuspension: 'Confirm VEN Suspension',
    ConfirmVENSuspensionClear: 'Confirm VEN Suspension Clear',
    Create: {
      Placeholder: {
        MachineAuthenticationId: 'Type a Machine Authentication ID',
        PKICertificateId: 'Type a PKI Certificate ID to authenticate VEN using an X.509 certificate',
        SPN: 'Type a SPN to authenticate VEN using Kerberos',
        WorkloadHostName: 'Type a Hostname',
        WorkloadLocation: 'Type a Location',
        WorkloadName: 'Type a name for the new Unmanaged Workload',
        WorkloadOSFamily: 'Type an OS Family',
        WorkloadPublicIP: 'Type a Public IP. Can be a Load Balancer or Router connected to the host',
        WorkloadRelease: 'Type an OS Release',
      },
    },
    Created: 'Workload created',
    CSVEmpty: 'The CSV file is empty',
    CSVFile: 'CSV File',
    CSVImportTitle: 'Import a CSV to update workload labels',
    CSVMissing: 'A CSV file needs to be uploaded',
    CSVMissingHeader: 'The CSV file is missing a valid header row',
    CSVMissingLabelHeader: 'The CSV file is missing label header.',
    CSVMissingLabels: 'The CSV file is missing labels.',
    CSVNotificationPart1:
      'You can update workload labels by importing a CSV file containing label information. The first two column headers must be “href” and “hostname”. The remaining column headers must match the keys assigned to each label type in the',
    CSVNotificationPart2: 'page.',
    CSVParseError: 'Fail to parse the CSV file: {reason}',
    CSVRowsExceed: 'The CSV file exceeds {count} rows',
    CSVTooLarge: 'The CSV file is too large',
    DefaultGateway: 'Default Gateway',
    Deleted: 'Workload deleted',
    Edit: 'Edit Workloads',
    EditLabels: {
      MultipleLabels: 'Multiple {type} Labels',
      WorkloadsCannotModified: '{count, plural, =1 {# Workload} other {# Workloads}} cannot be modified: {message}',
      WorkloadsModified: '{count, plural, =1 {# Workload was} other {# Workloads were}} successfully modified',
    },
    EditLabelsWarning: {
      DisplayWarning: 'Display warning when editing labels',
      EditLabelsForEnforcementMode: 'Warning Setting',
      WarningDisabled:
        'Do not display a warning when editing labels of enforced workloads or when labels are in use by policies',
      WarningEnabled:
        'Display a warning when editing labels of enforced workloads or when labels are in use by policies',
    },
    Enforcement: 'Enforcement: {enforcement}',
    EnforcementBoundariesActiveVersion: 'This list shows only active Deny Rules',
    EnforcementBoundariesIdle:
      'Idle Workloads do not receive Deny Rules. Change the Enforcement Mode of the Workload to receive Deny Rules',
    EnforcementBoundariesInboundNoFound: 'No Inbound Deny Rules found',
    EnforcementBoundariesOutboundNoFound: 'No Outbound Deny Rules found',
    EnforcementBoundariesSelectedInbound:
      'Rules are enforced only for selected inbound services when workload is within scope of a Deny Rule',
    EnforcementBoundariesVersionLow:
      'The workload is not managed by a VEN that supports Deny Rules. Upgrade the VEN to version 21.2.0 or above for Deny Rules',
    EnforcementSettingFull: 'Workload enforcement is set to Full. Maximum protection is in place.',
    EnforcementSettingIdle:
      'Workload enforcement is set to Idle. Improve protection by setting enforcement to Selective or Full.',
    EnforcementSettingSelective:
      'Workload enforcement is set to Selective. Improve protection by setting enforcement to Full.',
    EnforcementSettingVisibilityOnly:
      'Workload enforcement is set to Visibility Only. Improve protection by setting enforcement to Selective or Full.',
    Errors: {
      Create: 'Unable to Create Workload',
      Edit: 'Unable to Edit Workload',
    },
    ErrorState:
      'VEN Suspension Mode places the Workload in the Error state and prevents the PCE from disabling communication with other Workloads',
    ErrorSuspensionTotal:
      '{error, plural, =1 {# Workload} other {# Workloads}} in Error, {suspended, plural, =1 {# Workload} other {# Workloads}} in Suspension.',
    ErrorTotal: '{count, plural, =1 {# Workload} other {# Workloads}} in Error',
    ExportWorkloads: 'Export Workload Data',
    FileFormat: 'File Format',
    FilteredWorkloads: 'Filtered Workloads',
    Full: 'Full',
    FullEnforcement: 'Full Enforcement',
    FullEnforcementInbound:
      'Rules are enforced for all inbound and outbound services. Traffic not allowed by a Rule is denied',
    HeartbeatLastReceived: 'Heartbeat Last Received',
    Ignored: 'Ignored',
    ImportedFileMessage: 'Imported file contains data for {count} workloads.',
    InboundEnforcement: 'Inbound Enforcement',
    IncompatibilityTypes: {
      GroupPolicy: 'Group policy',
      Ip6TablesEnabled: 'ip6tables enabled',
      Ip6TablesRuleCount: 'ip6tables rule count',
      IPSec: 'IPSec',
      IpTablesEnabled: 'iptables enabled',
      IpTablesRuleCount: 'iptables rule count',
      IPv4Enabled: 'IPv4 enabled',
      IPv4Forwarding: 'IPv4 forwarding enabled',
      IPv4ForwardingPacketCount: 'IPv4 forwarding packet count',
      IPv4GlobalScope: 'IPv4 global scope',
      IPv6ActiveConnectionCount: 'IPv6 active connections count',
      IPv6Enabled: 'IPv6 enabled',
      IPv6ForwardingEnabled: 'IPv6 forwarding enabled',
      IPv6GlobalScope: 'IPv6 global scope',
      MtuSize: 'MTU size',
      MulticastIncomingPacketCount: 'Multicast incoming packet count',
      MulticastOutgoingPacketCount: 'Multicast outgoing packet count',
      RoutingTableConflict: 'Routing table conflict',
      TeredoTunnelingEnabled: 'Teredo tunneling enabled',
      UnwantedNics: 'Unsupported NICs',
      UnwantedRoles: 'Unsupported roles',
      UnwantedSoftware: 'Non-Illumio security software',
      UnwantedWFProviders: 'Non-Illumio WFP providers',
    },
    InterfacePlaceholder: 'E.g. eth0.public',
    Interfaces: 'Interfaces',
    InterfacesNameError: 'The interface name format is invalid',
    InvalidStateToApplyPolicy:
      'Unable to update policy state for workloads in Idle. Move workload into a group to assign policy state.',
    IPCIDR: 'IP/CIDR',
    IPCIDRPlaceholder: 'E.g. 10.0.10.1/24',
    IssuerName: 'Issuer Name',
    LabelingColumns: 'Labeling Columns',
    LabelingColumnsMessage: 'Only export columns necessary for labelling workloads.',
    LastAppliedPolicy: 'Last Applied Policy',
    LastHeartbeatReceived: 'Last Heartbeat Received',
    List: {
      AddWorkloadWithoutVEN: 'Add Workload without installing VEN',
      AskAgainCheck: "Don't ask me again",
      ConfirmAffectMultipleWorkloads:
        'This operation will affect multiple Workloads. Are you sure you want to continue?',
      EditMultipleLabels: 'Edit Labels for Multiple Workloads',
      PairInstallVEN: 'Pair Workload and install VEN',
      PairWithPairingProfile: 'Pair Workload with Pairing Profile',
    },
    MachineAuthenticationExample: 'Machine Authentication ID Example:',
    MachineAuthenticationId: 'Machine Authentication ID',
    MajorIncompatibilities: 'Major incompatibilities found',
    ManagedIgnored:
      'Ignored interfaces will NOT be included in policy configuration provided by the PCE. Traffic will continue to flow through the interface uninterrupted.',
    ManagedInterfaces: 'Managed interfaces will be included in policy configuration provided by PCE',
    NewLabels: 'New Labels',
    NewLabelsMessage: '{count} new labels created.',
    NoChanges: 'No changes will be made to the VEN itself',
    NoDomainInterfaces: 'No Domain Interfaces',
    NoIncompatibilities: 'No incompatibilities',
    NoInterfaces: 'No Interfaces',
    NoMatchData: 'No matching Workloads to display',
    NotChangeExistingLabel: "Create and assign labels if they don't already exist in Label Settings",
    NotValidAfter: 'Not Valid After',
    NotValidBefore: 'Not Valid Before',
    OSRelease: 'OS Release',
    OutboundEnforcement: 'Outbound Enforcement',
    Pair: 'Pair Workloads',
    PCEAction: 'PCE Action',
    PolicyLastApplied: 'Policy Last Applied',
    PolicyLastReceived: 'Policy Last Received',
    PolicySync: 'Policy Sync',
    PossibleIncompatibilities: 'Possible incompatibilities found',
    ProcessesNotification: 'These are processes that are listening for incoming connections - Last updated at {time}',
    ProcessName: 'Process Name',
    ProtectedPorts: 'Protected {count, plural, =1 {Port} other {Ports}}',
    Protection: 'Protection',
    PublicIP: 'Public IP',
    RecalculatePolicy:
      'If the VEN remains in Suspension Mode, the PCE may mark this Workload as Offline and recalculate policy accordingly',
    RefreshThePageToSeeTheUpdatedPolicySyncStatus: 'Refresh the page to see the updated Policy Sync status.',
    Release: 'Release',
    Remove: {
      Confirm:
        'Are you sure you want to remove the {count, plural, =1 {selected Unmanaged Workload} other {# selected Unmanaged Workload}}? Policy will no longer be calculated for {count, plural, =1 {that Workload} other {these Workloads}}',
      Success: '{count, plural, =1 {Workload has} other {# Workloads have}} been successfully removed',
      TitleConfirmation: 'Remove {count, plural, =1 {Unmanaged Workload} other {# Unmanaged Workloads}}',
      TitleResult: 'Result of {count, plural, =1 {Unmanaged Workload} other {# Unmanaged Workloads}} removal',
    },
    RemoveDialog: {
      Content: {
        Actions: {
          Idle: 'Remove the VEN and make no other changes',
          KerberosPKI: 'Keep in PCE as unmanaged with labels after unpairing VEN',
          PairingKey: 'Remove entirely from PCE after unpairing and uninstalling VEN',
          Unmanaged: 'Remove entirely from PCE',
        },
        Options: {
          First: {
            Title: 'Remove Illumio policy (recommended)',
          },
        },
        SelectedCount: '{selected, plural, =1 {# workload} other {# workloads}} selected',
        SelectedUninstall:
          '{selected, plural, =1 {# VEN managed workload} other {# VEN managed workloads}} to be uninstalled.',
        SelectFirewallState: 'Select the uninstall firewall state:',
        Type: {
          Header: 'Workload Type',
        },
      },
      RemoveNow: 'Remove Now',
      Title: 'Remove and Unpair Workloads',
    },
    RemoveLabel:
      'Unassign existing labels from workloads in the PCE if the string you enter below matches a label you entered in the CSV file:',
    RemoveSuspensionOnPCE: 'This action only removes this Workload from VEN Suspension Mode on the PCE',
    RulesIdle:
      'Idle Workloads do not receive Segmentation Rules. Change the Enforcement Mode of the Workload to receive Segmentation Rules',
    SaveWarning: {
      ConfirmProceed: 'Do you still want to proceed?',
      LabelsRemovedAreInUseByRulesets:
        'One or more labels removed are in use by {count, plural, =1 {policy} other {policies}}: {policies}.',
      SelectiveOrFullEnforcementLabelRemovedMultiple:
        'One or more workloads are in selective or full enforcement mode. Changing the labels will change the policies enforced.',
      SelectiveOrFullEnforcementLabelRemovedSingle:
        'This workload is in {enforcementMode} enforcement mode. Changing the labels will change the policies enforced.',
    },
    Selective: 'Selective',
    SetEnforcement: 'Set enforcement',
    StagedPolicyAllStaticPolicyWorkloads: 'Staged Policy will be applied immediately on all static policy Workloads',
    StagedPolicyWillBeApplied:
      'Staged Policy will be applied immediately on {count, plural, =-1 {all Selected Workloads} =0 {0 Selected Workloads} =1 {1 Selected Workload} other {# Selected Workloads}}',
    StagedPolicyWillBeAppliedWithLabel:
      'Staged Policy will be applied immediately on all static policy Workloads with the following labels',
    StaticWorkloads: 'Static Workloads',
    Status: {
      ErrorAndSuspended: 'Error and Suspended',
      ErrorCount: '{count, plural, =1 {Error} other {Errors}}',
      NotInstalled: 'Not Installed',
      Offline: 'Offline',
      Suspended: 'Suspended',
      Syncing: 'Syncing',
      Uninstalling: 'Uninstalling',
      UnmanagedNotInstalled: 'Unmanaged (VEN not installed)',
      WarningAndSuspended: 'Warning and Suspended',
      WarningCount: '{count, plural, =1 {Warning} other {Warnings}}',
    },
    StringMessage: 'String must not be blank, contain spaces, or differ from CSV value to be removed.',
    SubjectName: 'Subject Name',
    Summary: {
      ErrorMessage: 'Error Message',
      ForwardRules: 'Forward Rules',
      KerberosServicePrincipalName: 'Kerberos Service Principal Name',
      Machine: 'Machine',
      RemotePeers: 'Remote Peers',
      TenMinuteWait: 'Your bundle has been requested. This may take up to 10 minutes.',
      Uptime: 'Uptime',
      VENVersion: 'VEN Version',
    },
    Suspend: 'Mark as Suspended',
    SuspensionTotal: '{count, plural, =1 {# Workload} other {# Workloads}} in Suspension',
    ThisPCE: '{fqdn} (this PCE)',
    ThisProcessCanTakeAFewMinutesToTakeEffect: 'This process can take a few minutes to take effect.',
    Total: 'Total Workloads',
    UnableToCommunicate: 'The VEN will be unable to communicate with the PCE until the VEN comes out of Suspension',
    UnavailableCheckHost: 'Unavailable. Check {workloadPce}.',
    UnavailableViewableOn: 'Unavailable. Viewable on {workloadPce}.',
    UnmanagedCantUnpair: 'Cannot Unpair Unmanaged Workloads',
    UnmanagedCantUnpairDesc: 'Unmanaged Workloads can only be managed using the Illumio REST API',
    Unpair: {
      Action: 'Uninstalls the selected VEN(s).',
      Actions: {
        FirewallChoice: 'Policies are configured into the host firewall based on the option selected below.',
        Header: 'Remove or Unpair Actions',
        Idle: 'No changes to the host firewall.',
        KerberosPKI: 'The associated workloads will become unmanaged and keep labels ana IP addresses.',
        PairingKey: 'Removes policy for the associated workloads.',
      },
      IdleNotApply: '-(does not apply to Idle workloads)',
      Mode: {
        Type: 'Visibility Only/Selective/Full',
      },
      Options: {
        First: {
          AIXSolarisDesc: 'Removes Illumio policy and reverts firewall rules to the pre-pairing state.',
          LinuxDesc: 'Removes Illumio policy and retains the coexistent firewall rules.',
          Title: 'Remove Illumio policy - Default',
          WindowsDesc: 'Removes Illumio WFP filters and activates Windows Firewall.',
        },
        OperatingSystems: {
          AIX: 'AIX',
          AIXSolaris: 'AIX / Solaris',
          All: 'All operating systems',
          Linux: 'Linux',
          LinuxAIXSolaris: 'Linux / AIX / Solaris',
          LinuxMacOS: 'Linux / MacOS',
          MacOS: 'MacOS',
          Solaris: 'Solaris',
          Windows: 'Windows',
        },
        Second: {
          Desc: 'Leaves all ports open.',
          Title: 'Open all ports',
        },
        Third: {
          LinuxDesc: 'Temporarily allows only SSH/22 until system is rebooted.',
          Title: 'Close all ports except remote management',
          WindowsDesc: 'Allows only RDP/3389 and WinRM/5985, 5986.',
        },
      },
      SelectFirewallState: 'Select final firewall status',
      Title: 'Unpair {count, plural, =1 {VEN} other {# VENs}}',
      Type: {
        Header: 'Pairing Method',
        KerberosPKI: 'Paired using Kerberos or PKI',
        PairingKey: 'Paired using pairing key',
      },
    },
    UnprotectedPorts: 'Unprotected {count, plural, =1 {Port} other {Ports}}',
    UpdateAllWorkloads: 'Update All Static Workloads',
    UpdateSelectedWorkloads: 'Update Selected Workloads',
    VENConnectivity: 'VEN Connectivity',
    VENStillSuspended: 'This does not remove the VEN itself from Suspension Mode',
    VenSuspensionNotice: 'This Workload will be marked as in VEN Suspension Mode on the PCE:',
    VenToPCEAuthentication: {
      PKICertificateId: 'PKI Certificate ID',
      PKICertificateIdExamples: 'PKI Certificate ID Examples:',
      PKICertificateIdExampleWithDN: 'Distinguished Name (DN) in the subject field of the certificate:',
      PKICertificateIdExampleWithSAN: 'DNS name in the Subject Alternative Name (SAN) field of the certificate:',
      Title: 'VEN to PCE Authentication',
    },
    View: 'View Workloads',
    ViewEnforcementBoundaries: 'View Deny Rules',
    ViewEnforcementBoundary: 'View Deny Rule',
    VisibilityOnlyReports: 'No traffic is denied by policy',
    WithCount: '{count} {count, plural, =1 {Workload} =0 {Workload} other {Workloads}}',
    WorkloadsLabelChangedMessage: '{count} workloads have at least one label added or changed.',
    WorkloadsNumber: '{count, plural, =0 {no Workloads} =1 {One Workload} other {# Workloads}}',
    WorkloadsUnaffectedMessage: '{count} workloads are unaffected by the imported file.',
    WorkloadToEnforcementNotification: 'Workload will be moved into <b>{enforcement}</b> and <b>{visibility}</b>',
  },
};
