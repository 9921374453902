/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import Constants from '../../constants';
import actionCreators from '../../actions/actionCreators';
import {RulesetIptableGrid, RulesetRuleGrid, RuleNote} from '.';
import {GeneralStore, RulesetStore} from '../../stores';
import {
  RestApiUtils,
  RuleWritingUtils,
  RulesetUtils,
  RuleValidationUtils,
  GridDataUtils,
  GeneralUtils,
  PortProtocolsUtils,
} from '../../utils';
import {
  Button,
  ButtonDropdown,
  RulesetAndScopesCollapseTitle,
  ConfirmationDialog,
  OSEntitySelect,
  AlertDialog,
  NotificationGroup,
  Icon,
} from '..';
import SessionStore from '../../stores/SessionStore';

// Tentative number, which will be changed with more data
const rulesLimit = 250;

export default React.createClass({
  propTypes: {
    anyHref: PropTypes.string.isRequired,
    comparedRules: PropTypes.array.isRequired,
    comparedIpTableRules: PropTypes.array.isRequired,
    onEditCancel: PropTypes.func.isRequired,
    onRuleChange: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
    ruleset: PropTypes.object.isRequired,
    rulesetId: PropTypes.string.isRequired,
    setIgnoreChanges: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    diffRuleset: PropTypes.object,
    reorderMode: PropTypes.string,
  },

  getDefaultProps() {
    return {
      ruleset: {},
      rulesetId: '',
      version: 'draft',
      readonly: false,
      pageLength: 50,
      hideRules: [],
    };
  },

  getInitialState() {
    const intraSorting = GeneralStore.getSorting('intraRuleList');
    const extraSorting = GeneralStore.getSorting('extraRuleList');
    const customSorting = GeneralStore.getSorting('customRuleList');
    let selection = GeneralStore.getSelection('rulesetRuleList');

    selection = selection && selection.id === this.props.rulesetId ? selection.selection : [];

    return {
      collapsed: false,
      selection: selection || [],
      filters: null,
      intraScopePage: 1,
      extraScopePage: 1,
      ipTablesPage: 1,
      editingHref: '',
      addRule: '',
      intraSorting: intraSorting || [],
      extraSorting: extraSorting || [],
      customSorting: customSorting || [],
      errors: [],
      rbacErrors: [],
      newRule: {},
      hideRules: this.props.hideRules,
      showNote: null,
      editingNote: null,
      showNoteTop: false,
    };
  },

  componentDidMount() {
    document.addEventListener('click', this.handleCompClick);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCompClick);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.hideRules !== nextProps.hideRules && this.state.hideRules === this.props.hideRules) {
      this.setState({hideRules: nextProps.hideRules});
    }
  },

  getActiveRule(isCustomIp, editingHref = this.state.editingHref) {
    if (this.state.addRule) {
      return {...this.state.newRule};
    }

    if (isCustomIp) {
      return {...this.props.ruleset.ip_tables_rules.find(rule => rule.href === editingHref)};
    }

    return {...this.props.ruleset.rules.find(rule => rule.href === editingHref)};
  },

  getCustomIpErrors(rule, justSubmitted) {
    const errors = RuleValidationUtils.validateIpTablesRule(
      this.props.ruleset.scopes,
      rule.actors,
      rule.ip_version,
      rule.statements,
      justSubmitted || (this.state && this.state.submitted),
    );

    if (
      this.state &&
      !justSubmitted &&
      this.state.submitted &&
      errors.length &&
      errors[0] === Constants.REQUIRED_RULEIPTABLES_FIELDS &&
      (!this.state.errors.length || this.state.errors[0].key !== Constants.REQUIRED_RULEIPTABLES_FIELDS)
    ) {
      errors.shift();
    }

    if (
      this.state &&
      !justSubmitted &&
      this.state.submitted &&
      errors.length &&
      errors[0] === Constants.STATEMENTS_INVALID &&
      (!this.state.errors.length || this.state.errors[0].key !== Constants.STATEMENTS_INVALID)
    ) {
      errors.shift();
    }

    return errors;
  },

  getErrors(rule, justSubmitted, isExtra = false) {
    const errors = RuleValidationUtils.validateScopeRule({
      scopes: this.props.ruleset.scopes,
      providers: rule.providers,
      ingressServices: rule.ingress_services,
      resolveLabelsAs: rule.resolve_labels_as,
      secureConnect: rule.sec_connect,
      machineAuth: rule.machine_auth,
      consumers: rule.consumers,
      consumingSecurityPrincipals: rule.consuming_security_principals,
      unscopedConsumers: rule.unscoped_consumers,
      isSubmitted: justSubmitted || (this.state && this.state.submitted),
      stateless: rule.stateless,
      isExtraScopeRules: isExtra,
      networkType: rule.network_type,
    });

    if (
      this.state &&
      !justSubmitted &&
      this.state.submitted &&
      errors.length &&
      errors[0] === Constants.REQUIRED_RULE_FIELDS &&
      (this.state.errors.length || this.state.errors[0].key !== Constants.REQUIRED_RULE_FIELDS)
    ) {
      errors.shift();
    }

    return errors;
  },

  getRuleNote(value, row, isCustomIp = false) {
    const {showNote, editingNote, showNoteTop} = this.state;
    const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';

    let version;
    let href;
    let readOnly;

    if (row.href && isNaN(row.href) && !row.href.includes('proposed')) {
      version = row.href.split('/')[4];
      href = GeneralUtils.isNumeric(version)
        ? row.href.replace(version, version - 1)
        : row.href.replace('draft', 'active');

      readOnly = this.props.readonly || this.props.version !== 'draft';

      if (!isCustomIp) {
        // Custom iptables rules don't have workloads
        readOnly ||=
          GeneralUtils.deepPluck(row.providers, 'workload').some(workload => workload.deleted) ||
          GeneralUtils.deepPluck(row.consumers, 'workload').some(workload => workload.deleted);
      }
    } else {
      version = 'draft';
      href = row.href;
      readOnly = false;
    }

    const active =
      this.props.diffRuleset &&
      this.props.diffRuleset[rulesStr] &&
      this.props.diffRuleset[rulesStr].find(rule => rule.href === href);

    return (
      <RuleNote
        href={row.href}
        onNoteIconClick={this.handleNoteIconClick}
        onNoteCloseClick={this.handleNoteCloseClick}
        onNodeEditClick={this.handleNoteEditClick}
        onNoteSaveClick={this.handleNoteSaveClick}
        onNoteValueChange={this.handleNoteValueChange}
        onSetNoteBox={this.handleSetNoteBox}
        isReadOnly={readOnly}
        isEdit={row.href === editingNote}
        value={value}
        activeValue={active && active.description}
        showNote={row.href === showNote}
        isCustomIp={isCustomIp}
        showNoteTop={showNoteTop}
      />
    );
  },

  getWritableStatements(statements) {
    return _.compact(
      _.map(statements, statement => {
        if (
          !statement.error &&
          !statement.removed &&
          !statement.duplicate &&
          statement.table_name &&
          statement.chain_name &&
          statement.parameters
        ) {
          return {
            table_name: statement.table_name,
            chain_name: statement.chain_name,
            parameters: statement.parameters,
          };
        }
      }),
    );
  },

  generateErrors(errors) {
    return _.map(errors.length > 1 ? errors.slice(0, 1) : errors, error => ({
      type: 'error',
      title: RuleValidationUtils.getValidationMessage(error),
      key: error,
    }));
  },

  async handleAdd({addRule, newRule = {unscoped_consumers: addRule === 'extra'}, errors} = {}) {
    let proceed = true;

    if (this.state.editingHref) {
      proceed = await this.handleEditCancel();
    }

    if (this.state.addRule) {
      proceed = await this.handleAddCancel();
    }

    if (!proceed) {
      return;
    }

    if (this.state.hideRules.includes(addRule)) {
      this.handleHideToggle(addRule);
    }

    this.props.setIgnoreChanges(false);

    if (errors) {
      this.setState({addRule, newRule, errors, rbacErrors: []});
    } else {
      this.setState({addRule, newRule, rbacErrors: []});
    }
  },

  async handleAddCancel() {
    let cancel = true;

    if (this.state.newRule && Object.keys(this.state.newRule).length > 1) {
      cancel = await this.handlePendingDialog();
    }

    if (cancel) {
      this.props.setIgnoreChanges(true);
      this.setState({errors: [], addRule: '', newRule: {}});
    }

    return cancel;
  },

  async handleAddSave() {
    if (this.state.addRule === 'custom') {
      await this.pushCustomIpAddSave(this.state.newRule);
    } else {
      await this.pushSave(this.state.newRule);
    }

    if (this.isMounted() && !this.state.errors.length) {
      this.props.setIgnoreChanges(true);
      this.setState({errors: [], addRule: '', newRule: {}});
    }
  },

  handleCollapse() {
    this.setState({collapsed: !this.state.collapsed});
  },

  handleCompClick(evt) {
    const ignoreOnClass =
      evt.target.classList.contains('Icon-RulesetRules-NoteIcon') ||
      evt.target.classList.contains('RulesetRules-Note-Box-Textarea--Active');
    const isAddNoteButton = evt.target.innerHTML === intl('Rulesets.Rules.AddNote');
    const isEditNoteButton = evt.target.innerHTML === intl('Rulesets.Rules.EditNote');

    if (!this.state.showNote || !this.noteBox || ignoreOnClass || isAddNoteButton || isEditNoteButton) {
      return;
    }

    if (!this.noteBox.contains(evt.target)) {
      this.handleNoteOutsideClick();
    }
  },

  handleDisable(hrefs = this.state.selection) {
    if (!hrefs.length) {
      return;
    }

    const rules = this.props.ruleset.rules.map(rule => {
      const strippedRule = {href: rule.href};

      if (hrefs.includes(rule.href)) {
        strippedRule.enabled = false;
      }

      return strippedRule;
    });
    const ipTablesRules = this.props.ruleset.ip_tables_rules.map(rule => {
      const strippedRule = {href: rule.href};

      if (hrefs.includes(rule.href)) {
        strippedRule.enabled = false;
      }

      return strippedRule;
    });

    if (!this.props.proposed) {
      RestApiUtils.ruleSet
        .update(this.props.rulesetId, {
          rules,
          ip_tables_rules: ipTablesRules,
        })
        .then(() => {
          RestApiUtils.secPolicies.dependencies();
        })
        .catch(err => {
          this.showRbacErrors(err);
        });
    }

    this.unSelect();
  },

  handleDuplicate(href) {
    if (href.includes('ip_tables_rules')) {
      const rule = this.props.ruleset.ip_tables_rules.find(rule => rule.href === href);
      const newRule = {
        actors: rule.actors,
        ip_version: rule.ip_version,
        statements: rule.statements,
      };
      const errors = this.generateErrors(this.getCustomIpErrors(newRule, false));

      this.handleAdd({addRule: 'custom', newRule, errors});
    } else {
      const rule = this.props.ruleset.rules.find(rule => rule.href === href);
      const newRule = {
        providers: rule.providers,
        ingress_services: rule.ingress_services,
        resolve_labels_as: rule.resolve_labels_as,
        consumers: rule.consumers,
        sec_connect: rule.sec_connect,
        stateless: rule.stateless,
        machine_auth: rule.machine_auth,
        consuming_security_principals: rule.consuming_security_principals,
        unscoped_consumers: rule.unscoped_consumers,
        duplicate: true,
      };
      const errors = this.generateErrors(this.getErrors(rule, false));

      this.handleAdd({addRule: rule.unscoped_consumers ? 'extra' : 'intra', newRule, errors});
    }
  },

  async handleEdit(editingHref, isReverse) {
    let proceed = true;

    if (this.state.editingHref) {
      proceed = await this.handleEditCancel();
    }

    if (this.state.addRule) {
      proceed = await this.handleAddCancel();
    }

    if (!proceed) {
      return;
    }

    const isCustomIp = Boolean(editingHref.includes('ip_tables'));
    const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';
    let rule = this.props.ruleset[rulesStr].find(rule => rule.href === editingHref);

    if (rule) {
      const ruleType = ''; // Rule type, e,g, intra, extra, custom is not applicable here. So, use empty string for dummy argument.

      if (isCustomIp && GeneralUtils.deepPluck(rule.actors, 'workload')) {
        this.handleEntityChange(ruleType, 'actors', RuleWritingUtils.getUnfriendlyEntities(rule.actors), editingHref);
      } else if (isReverse) {
        rule = {...rule};

        const temp = rule.providers;

        rule.providers = rule.consumers;
        rule.consumers = temp;
        this.handleRuleChange(rule, this.generateErrors(this.getErrors(rule, false)), false);
      } else {
        if (GeneralUtils.deepPluck(rule.providers, 'workload').some(workload => workload.deleted)) {
          this.handleEntityChange(
            ruleType,
            'providers',
            RuleWritingUtils.getUnfriendlyEntities(rule.providers),
            editingHref,
          );
        }

        if (GeneralUtils.deepPluck(rule.consumers, 'workload').some(workload => workload.deleted)) {
          this.handleEntityChange(
            ruleType,
            'consumers',
            RuleWritingUtils.getUnfriendlyEntities(rule.consumers),
            editingHref,
          );
        }
      }
    }

    this.props.setIgnoreChanges(false);
    this.setState({editingHref, rbacErrors: []});
  },

  async handleEditCancel() {
    const isCustomIp = Boolean(this.state.editingHref.includes('ip_tables'));
    const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';
    const hasChanged = this.props.proposed
      ? true
      : !_.isEqual(
          RulesetStore.getSpecified(this.props.ruleset.href)[rulesStr].find(
            rule => rule.href === this.state.editingHref,
          ),
          this.props.ruleset[rulesStr].find(rule => rule.href === this.state.editingHref),
        );
    let cancel = true;

    if (hasChanged) {
      cancel = await this.handlePendingDialog();
    }

    if (cancel) {
      this.props.onEditCancel(this.state.editingHref);
      this.props.setIgnoreChanges(true);
      this.setState({errors: [], editingHref: ''});
    }

    return cancel;
  },

  async handleEditSave() {
    const href = this.state.editingHref;
    const isCustomIp = Boolean(href.includes('ip_tables'));
    const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';
    const ruleId = href.split('/').pop();
    const rule = this.props.ruleset[rulesStr].find(rule => rule.href === href);
    const hasChanged = this.props.proposed
      ? true
      : !_.isEqual(
          RulesetStore.getSpecified(this.props.ruleset.href)[rulesStr].find(
            rule => rule.href === this.state.editingHref,
          ),
          rule,
        );
    const activeRule = this.props.diffRuleset && this.props.diffRuleset[rulesStr].find(rule => rule.href === href);

    if (rule.href.includes('proposed')) {
      await this.pushSave(rule, ruleId);
    } else if (hasChanged && isCustomIp) {
      await this.pushCustomIpEditSave(rule, ruleId);
      this.props.onRuleChange(RulesetUtils.getComparedIpTablesRule(rule, activeRule), true);
    } else if (hasChanged) {
      await this.pushSave(rule, ruleId);

      if (!this.props.proposed) {
        this.props.onRuleChange(RulesetUtils.getComparedRule(rule, activeRule), false);
      }
    }

    if (this.isMounted() && !this.state.errors.length) {
      this.props.setIgnoreChanges(true);
      this.setState({errors: [], editingHref: ''});
    }
  },

  highlightNoteTextarea() {
    setTimeout(() => {
      if (!this.noteBox) {
        return;
      }

      const textarea = this.noteBox.querySelector('.RulesetRules-Note-Box-Textarea');

      if (document.activeElement !== textarea) {
        textarea.focus();
      }
    }, 0);
  },

  handleEditSecondary(href, option, evt) {
    let showNoteTop;

    if (['addNote', 'editNote'].includes(option.action)) {
      const rect = evt.target.getBoundingClientRect();

      showNoteTop = rect.bottom + 96 > window.innerHeight - window.pageYOffset;
    }

    switch (option.action) {
      case 'enable':
        this.handleEnable([href]);
        break;
      case 'disable':
        this.handleDisable([href]);
        break;
      case 'duplicate':
        this.handleDuplicate(href);
        break;
      case 'delete':
        this.handleRemoveConfirm([href]);
        break;
      case 'reverse':
        if (this.isReversible(href)) {
          this.handleEdit(href, true);
        } else {
          this.showReverseError();
        }

        break;
      case 'addNote':
        this.handleNoteIconClick(href, showNoteTop);
        this.handleNoteEditClick(href, showNoteTop);
        break;
      case 'editNote':
        this.handleNoteEditClick(href, showNoteTop);
        this.highlightNoteTextarea();
        break;
      case 'deleteNote':
        this.handleNoteDelete(href);
        break;
    }
  },

  handleEnable(hrefs = this.state.selection) {
    if (!hrefs.length) {
      return;
    }

    const rules = this.props.ruleset.rules.map(rule => {
      const strippedRule = {href: rule.href};

      if (hrefs.includes(rule.href)) {
        strippedRule.enabled = true;
      }

      return strippedRule;
    });
    const ipTablesRules = this.props.ruleset.ip_tables_rules.map(rule => {
      const strippedRule = {href: rule.href};

      if (hrefs.includes(rule.href)) {
        strippedRule.enabled = true;
      }

      return strippedRule;
    });

    if (!this.props.proposed) {
      RestApiUtils.ruleSet
        .update(this.props.rulesetId, {
          rules,
          ip_tables_rules: ipTablesRules,
        })
        .then(() => {
          RestApiUtils.secPolicies.dependencies();
        })
        .catch(err => {
          this.showRbacErrors(err);
        });
    }

    this.unSelect();
  },

  handleEntityChange(ruleType = '', type, newEntities, editingHref) {
    const getFriendly = ['providers', 'consumers', 'actors'].includes(type);
    const isCustomIp = ['actors', 'ip_version', 'statements'].includes(type);
    const rule = this.getActiveRule(isCustomIp, editingHref);
    let response;

    if (getFriendly) {
      response = RuleWritingUtils.getFriendlyEntities(newEntities);
    }

    if (type === 'consumers') {
      rule.consuming_security_principals = response.userGroups;
    }

    if (type === 'providers' || type === 'consumers') {
      rule.resolve_labels_as ||= {
        providers: ['workloads'],
        consumers: ['workloads'],
      };

      if (newEntities[intl('Common.UsesVirtualServicesWorkloads')]) {
        rule.resolve_labels_as[type] = ['workloads', 'virtual_services'];
      }

      if (newEntities[intl('Common.UsesVirtualServices')]) {
        rule.resolve_labels_as[type] = ['virtual_services'];
      }

      if (
        !newEntities[intl('Common.UsesVirtualServices')] &&
        !newEntities[intl('Common.UsesVirtualServicesWorkloads')] &&
        rule.resolve_labels_as[type].includes('virtual_services')
      ) {
        rule.resolve_labels_as[type] = rule.resolve_labels_as[type].filter(value => value !== 'virtual_services');

        if (!rule.resolve_labels_as[type].includes('workloads')) {
          rule.resolve_labels_as[type].push('workloads');
        }
      }
    }

    if (type === 'service') {
      rule.ingress_services = [...(newEntities[intl('Services.PolicyServices')] || [])];

      if (newEntities[intl('Port.Port')]) {
        rule.ingress_services.push(...PortProtocolsUtils.getPortProtocol(newEntities[intl('Port.Port')]));
      }

      if (newEntities[intl('Port.PortRange')]) {
        rule.ingress_services.push(...PortProtocolsUtils.getPortRange(newEntities[intl('Port.PortRange')]));
      }

      Object.assign(rule, {
        sec_connect: Boolean(newEntities[intl('Common.SecureConnect')]),
        machine_auth: Boolean(newEntities[intl('Common.MachineAuthentication')]),
        stateless: Boolean(newEntities[intl('Common.Stateless')]),
      });

      if (newEntities[intl('Rulesets.Rules.NonCorporateNetworks')]) {
        rule.network_type = newEntities[intl('Rulesets.Rules.NonCorporateNetworks')].network_type;
      }

      if (newEntities[intl('Rulesets.Rules.AllNetworks')]) {
        rule.network_type = newEntities[intl('Rulesets.Rules.AllNetworks')].network_type;
      }

      if (
        !newEntities[intl('Rulesets.Rules.NonCorporateNetworks')] &&
        !newEntities[intl('Rulesets.Rules.AllNetworks')]
      ) {
        rule.network_type = 'brn';
      }
    } else if (type === 'ip_version') {
      rule.ip_version = newEntities.value;
    } else {
      rule[type] = getFriendly ? response.entities : newEntities;
    }

    if (type === 'actors' || type === 'statements') {
      this.handleRuleChange(rule, this.generateErrors(this.getCustomIpErrors(rule, false)), isCustomIp);
    } else {
      this.handleRuleChange(rule, this.generateErrors(this.getErrors(rule, false, ruleType === 'extra')), isCustomIp);
    }
  },

  handleErrorsChange(errors) {
    this.setState({errors});
  },

  handleFilter(filters) {
    this.setState({filters, intraScopePage: 1, ipTablesPage: 1, rbacErrors: []});
  },

  handleFilterOpen() {
    const stateObj = {filters: {}};

    if (this.state.collapsed) {
      stateObj.collapsed = false;
    }

    this.setState(stateObj);
  },

  handleHideToggle(type) {
    const hideRules = this.state.hideRules.slice();
    const typeIndex = hideRules.indexOf(type);

    if (typeIndex === -1) {
      hideRules.push(type);
    } else {
      hideRules.splice(typeIndex, 1);
    }

    this.setState({hideRules});
  },

  handleNoteCloseClick() {
    this.props.onEditCancel();
    this.setState({showNote: null, editingNote: null});
  },

  handleNoteDelete(href) {
    this.handleNoteSaveClick(href, href.includes('ip_tables'), true);
    this.setState({showNote: null, editingNote: null});
  },

  handleNoteEditClick(href, showNoteTop = this.state.showNoteTop) {
    this.setState({showNote: href, editingNote: href, showNoteTop});
    this.highlightNoteTextarea();
  },

  handleNoteIconClick(href, showNoteTop) {
    if (this.state.showNote === href) {
      this.setState({showNote: null, editingNote: null});

      return;
    }

    this.setState({showNote: href, editingNote: null, showNoteTop});
  },

  handleNoteOutsideClick() {
    if (this.state.editingNote) {
      const isCustomIp = this.state.editingNote.includes('ip_tables_rules');
      const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';
      const storeCopy = this.props.proposed
        ? {}
        : RulesetStore.getSpecified(this.props.ruleset.href)[rulesStr].find(
            rule => rule.href === this.state.editingNote,
          );
      const value = this.noteBox.querySelector('.RulesetRules-Note-Box-Textarea').value;

      if (value !== storeCopy.description) {
        this.handleNoteSaveClick(this.state.editingNote, isCustomIp);
      }
    }

    this.handleNoteCloseClick();
  },

  async handleNoteSaveClick(href, isCustomIp, deleteNote) {
    const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';
    const ruleId = href.split('/').pop();
    const rule = this.props.ruleset[rulesStr].find(rule => rule.href === href);

    if (deleteNote) {
      rule.description = '';
    }

    const activeRule = this.props.diffRuleset && this.props.diffRuleset[rulesStr].find(rule => rule.href === href);

    if (isCustomIp) {
      await this.pushCustomIpEditSave(rule, ruleId);
    } else {
      await this.pushSave(rule, ruleId);
    }

    this.handleNoteCloseClick();
    this.props.onRuleSave(true);
    this.props.onRuleChange(RulesetUtils.getComparedRule(rule, activeRule), false);
  },

  handleNoteValueChange(href, isCustomIp, value) {
    const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';
    const rule = {...this.props.ruleset[rulesStr].find(rule => rule.href === href), description: value};

    this.props.onRuleChange(rule, isCustomIp);
  },

  handlePageChange(type, page) {
    this.setState({[`${type}Page`]: page});
  },

  handlePendingDialog() {
    return new Promise(resolve => {
      actionCreators.openDialog(
        <ConfirmationDialog
          message={intl('Rulesets.ActionDiscardPendingChanges')}
          onCancel={() => resolve(false)}
          onConfirm={() => resolve(true)}
        />,
      );
    });
  },

  handleRbacErrorsChange(rbacErrors) {
    this.setState({rbacErrors});
  },

  handleRemove() {
    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('Rulesets.Rules.DeleteRules', {count: 2})}
        message={intl('Rulesets.Rules.ConfirmRuleDelete', {count: 2})}
        onConfirm={this.handleRemoveConfirm}
      />,
    );
  },

  handleRemoveConfirm(selection = this.state.selection) {
    // Get rules that are not selected
    const rules = this.props.ruleset.rules
      .filter(rule => !selection.includes(rule.href))
      .map(rule => ({href: rule.href}));
    const ipTablesRules = this.props.ruleset.ip_tables_rules
      .filter(rule => !selection.includes(rule.href))
      .map(rule => ({href: rule.href}));

    if (this.props.proposed) {
      this.props.onRemoveRules(selection);
    } else {
      // Remove the selected rules
      RestApiUtils.ruleSet
        .update(this.props.rulesetId, {
          rules,
          ip_tables_rules: ipTablesRules,
        })
        .then(() => {
          RestApiUtils.secPolicies.dependencies();
        })
        .catch(err => {
          this.showRbacErrors(err);
        });
    }

    this.unSelect();
  },

  handleRowSelect(selection, lastSelected = selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);

    actionCreators.updateGeneralSelection('rulesetRuleList', {id: this.props.rulesetId, selection: newSelection});
    this.setState({selection: newSelection, rbacErrors: [], lastSelected});
  },

  handleRuleChange(rule, errors, isCustomIp) {
    if (this.state.addRule) {
      this.setState({newRule: rule, errors});
    } else {
      this.props.onRuleChange(rule, isCustomIp);
      this.setState({errors});
    }
  },

  handleSetNoteBox(node) {
    this.noteBox = node;
  },

  handleSort(type, key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({
        key,
        direction,
      });
    }

    actionCreators.updateGeneralSorting(`${type}RuleList`, sorting);
    this.setState({[`${type}Sorting`]: sorting});
  },

  async isDuplicate(newRule, editingHref) {
    let rules = this.props.ruleset.rules;

    if (editingHref) {
      rules = rules.slice();
      rules.splice(
        rules.findIndex(rule => rule.href === editingHref),
        1,
      );
    }

    const isDuplicatedRule = rules.some(rule =>
      _.isEqual(newRule, {
        providers: RulesetUtils.fixRulesetArr(rule.providers),
        ingress_services: rule.ingress_services,
        sec_connect: Boolean(rule.sec_connect),
        machine_auth: Boolean(rule.machine_auth),
        stateless: Boolean(rule.stateless),
        consumers: RulesetUtils.fixRulesetArr(rule.consumers),
        consuming_security_principals:
          rule.consuming_security_principals && rule.consuming_security_principals.map(ug => ({href: ug.href})),
        unscoped_consumers: rule.unscoped_consumers,
      }),
    );

    if (isDuplicatedRule) {
      await new Promise(resolve => {
        actionCreators.openDialog(
          <AlertDialog
            title={intl('Common.Notification')}
            message={intl('Rulesets.Rules.DuplicateRuleNotif')}
            onClose={() => resolve()}
          />,
        );
      });
    }
  },

  isReversible(href) {
    const rule = this.props.ruleset.rules.find(rule => rule.href === href);

    if (rule.consuming_security_principals.length) {
      return;
    }

    return ![...rule.providers, ...rule.consumers].some(
      actor => actor.usesVirtualServices || actor.usesVirtualServicesWorkloads,
    );
  },

  async pushCustomIpAddSave() {
    await RestApiUtils.ipTablesRules
      .create(this.props.rulesetId, {
        actors: RulesetUtils.fixRulesetArr(this.state.newRule.actors),
        ip_version: this.state.newRule.ip_version || '4',
        statements: this.getWritableStatements(this.state.newRule.statements),
        enabled: true,
      })
      .catch(err => {
        const body = _.get(err, 'parsedBody[0]');

        if (err.status === 406 && body && body.token.includes('rbac')) {
          const errors = [
            {
              type: 'error',
              title: body.message,
            },
          ];

          this.setState({errors});
        }
      });
    RestApiUtils.secPolicies.dependencies();
  },

  async pushCustomIpEditSave(rule, ruleId) {
    await RestApiUtils.ipTablesRule
      .update(this.props.rulesetId, ruleId, {
        actors: RulesetUtils.fixRulesetArr(rule.actors),
        ip_version: rule.ip_version,
        statements: this.getWritableStatements(rule.statements),
        enabled: rule.enabled,
        description: rule.description || '',
      })
      .catch(err => {
        const body = _.get(err, 'parsedBody[0]');

        if (err.status === 406 && body && body.token.includes('rbac')) {
          const errors = [
            {
              type: 'error',
              title: body.message,
            },
          ];

          this.setState({errors});
        }
      });
    RestApiUtils.secPolicies.dependencies();
  },

  async pushSave(rule, ruleId) {
    const newRule = {
      providers: RulesetUtils.fixRulesetArr(rule.providers),
      consumers: RulesetUtils.fixRulesetArr(rule.consumers),
      consuming_security_principals:
        rule.consuming_security_principals && rule.consuming_security_principals.map(ug => ({href: ug.href})),
      sec_connect: Boolean(rule.sec_connect),
      machine_auth: Boolean(rule.machine_auth),
      stateless: Boolean(rule.stateless),
      unscoped_consumers: Boolean(rule.unscoped_consumers),
      description: rule.description || '',
      ingress_services: (rule.ingress_services || []).map(service => {
        if (service.href) {
          return {href: service.href};
        }

        // RestApiUtils and other code logic adds a "protocol" and rely on it,
        // however the API doesn't accept it and it should be removed before
        // making the API call.
        // The info which is within "protocol" is within "proto" as well.
        delete service.protocol;

        return service;
      }),
      resolve_labels_as: rule.resolve_labels_as || {
        providers: ['workloads'],
        consumers: ['workloads'],
      },
      network_type: rule.network_type || 'brn',
    };

    if (!this.props.proposed) {
      if (ruleId) {
        await this.isDuplicate(newRule, this.state.editingHref);
        await RestApiUtils.secRule
          .update(
            this.props.rulesetId,
            ruleId,
            Object.assign(newRule, {
              enabled: rule.enabled,
            }),
          )
          .catch(err => {
            const body = _.get(err, 'parsedBody[0]');

            if (err.status === 406 && body && body.token.includes('rbac')) {
              const errors = [
                {
                  type: 'error',
                  title: body.message,
                },
              ];

              this.setState({errors});
            }
          });
      } else {
        if (rule.duplicate) {
          delete rule.duplicate;
        } else {
          await this.isDuplicate(newRule);
        }

        await RestApiUtils.secRules
          .create(
            this.props.rulesetId,
            Object.assign(newRule, {
              enabled: true,
            }),
          )
          .catch(err => {
            const body = _.get(err, 'parsedBody[0]');

            if (err.status === 406 && body && body.token.includes('rbac')) {
              const errors = [
                {
                  type: 'error',
                  title: body.message,
                },
              ];

              this.setState({errors});
            }
          });
      }

      RestApiUtils.secPolicies.dependencies();
    }

    this.props.onRuleSave();
  },

  showRbacErrors(err) {
    const body = _.get(err, 'parsedBody[0]');

    if (err.status === 406 && body && body.token.includes('rbac')) {
      const rbacErrors = [
        {
          type: 'error',
          title: body.message,
        },
      ];

      this.setState({rbacErrors});
    }
  },

  showReverseError() {
    actionCreators.openDialog(
      <AlertDialog title={intl('Common.Error')} message={intl('Rulesets.Rules.ReverseError')} />,
    );
  },

  unSelect() {
    actionCreators.updateGeneralSelection('rulesetRuleList', {id: this.props.rulesetId, selection: []});
    this.setState({selection: []});
  },

  formatRowClass(row) {
    const className = GridDataUtils.formatStatusRowClass(row);
    const readOnly = row.ingress_services.some(service => service.port === -1);

    if (readOnly) {
      return `${className} Grid-row--readonly`;
    }

    return className;
  },

  render() {
    const readonly = !this.props.proposed && (this.props.readonly || this.props.version !== 'draft');

    const addOptions = [
      {
        value: 'intra',
        label: (
          <div data-tid="intra" className="ButtonDropdown-item">
            <div data-tid="button-dropdown-title" className="ButtonDropdown-title">
              {intl('Rulesets.Rules.IntraScope.Add')}
            </div>
            <div className="ButtonDropdown-subtitle">{intl('Rulesets.Rules.IntraScope.AddDesc')}</div>
          </div>
        ),
      },
      ...(SessionStore.canUserCUDExtraScopeRules()
        ? [
            {
              value: 'extra',
              label: (
                <div data-tid="extra" className="ButtonDropdown-item">
                  <div data-tid="button-dropdown-title" className="ButtonDropdown-title">
                    {intl('Rulesets.Rules.ExtraScope.Add')}
                  </div>
                  <div className="ButtonDropdown-subtitle">{intl('Rulesets.Rules.ExtraScope.AddDesc')}</div>
                </div>
              ),
            },
          ]
        : []),
      {
        value: 'custom',
        label: (
          <div data-tid="custom" className="ButtonDropdown-item">
            <div data-tid="button-dropdown-title" className="ButtonDropdown-title">
              {intl('Rulesets.Rules.IpTables.Add')}
            </div>
            <div className="ButtonDropdown-subtitle">{intl('Rulesets.Rules.IpTables.AddDesc')}</div>
          </div>
        ),
      },
    ];
    const modifyOptions = [
      {
        value: 'handleEnable',
        label: (
          <div data-tid="enable">
            <div data-tid="button-dropdown-title" className="ButtonDropdown-title">
              {intl('Common.Enable')}
            </div>
          </div>
        ),
      },
      {
        value: 'handleDisable',
        label: (
          <div data-tid="disable">
            <div data-tid="button-dropdown-title" className="ButtonDropdown-title">
              {intl('Common.Disable')}
            </div>
          </div>
        ),
      },
    ];
    const totalRules = this.props.comparedRules.length + this.props.ruleset.ip_tables_rules.length;
    const customRules = this.props.comparedIpTableRules || [];
    const intraRules = [];
    const extraRules = [];

    if (this.props.comparedRules) {
      this.props.comparedRules.forEach(rule => (rule.unscoped_consumers ? extraRules : intraRules).push(rule));
    }

    const statuses = new Set();

    this.props.ruleset.rules.concat(this.props.ruleset.ip_tables_rules).forEach(rule => {
      if (this.state.selection.includes(rule.href)) {
        statuses.add(rule.enabled);
      }
    });

    // If there's only one item and if it is either 'true' or 'false'
    if (statuses.size === 1) {
      if (statuses.has(true)) {
        // Remove 'Enable' option
        modifyOptions.splice(0, 1);
      } else {
        // Remove 'Disable' option
        modifyOptions.splice(1, 1);
      }
    }

    const addIntraRule =
      !this.props.proposed && !intraRules.length && !readonly ? (
        <div
          className="RulesetScopesAndRules-Section-AddLink"
          data-tid="rulesetrules-addrule rulesetrules-addrule-intra"
        >
          <span onClick={() => this.handleAdd({addRule: 'intra'})}>
            <Icon name="add" />
            <span data-tid="elem-text">{intl('Rulesets.Rules.IntraScope.Title')}</span>
          </span>
        </div>
      ) : null;
    const intraRuleGrid =
      intraRules.length || this.state.addRule === 'intra' ? (
        <RulesetRuleGrid
          lastType={this.props.lastType}
          addRule={this.state.addRule === 'intra'}
          anyHref={this.props.anyHref}
          comparedRules={intraRules}
          diffRuleset={this.props.diffRuleset}
          editingHref={this.state.editingHref}
          errors={this.state.errors}
          filter={this.state.filters}
          getRuleNote={this.getRuleNote}
          hideRules={this.state.hideRules.includes('intra')}
          onHideToggle={_.partial(this.handleHideToggle, 'intra')}
          newRule={this.state.newRule}
          onAdd={_.partial(this.handleAdd, {addRule: 'intra'})}
          onAddCancel={this.handleAddCancel}
          onAddSave={this.handleAddSave}
          onEdit={this.handleEdit}
          onEditCancel={this.handleEditCancel}
          onEditSave={this.handleEditSave}
          onEditSecondary={this.handleEditSecondary}
          onEntityChange={_.partial(this.handleEntityChange, 'intra')}
          onErrorsChange={this.handleErrorsChange}
          onStartReorder={this.props.onStartReorder}
          onReorderSave={this.props.onReorderSave}
          onReorderCancel={this.props.onReorderCancel}
          onPageChange={_.partial(this.handlePageChange, 'intraScope')}
          onRowSelect={this.handleRowSelect}
          onSort={_.partial(this.handleSort, 'intra')}
          page={this.state.intraScopePage}
          pageLength={rulesLimit}
          proposed={this.props.proposed}
          readonly={readonly}
          ruleset={this.props.ruleset}
          selection={this.state.selection}
          sorting={this.state.intraSorting}
          type="intra"
          allowShiftSelect={true}
          lastSelected={this.state.lastSelected}
          version={this.props.version}
          moveRow={this.props.moveRow}
          reorderMode={this.props.reorderMode}
          dragOrder={this.props.dragOrder}
          formatRowClass={this.formatRowClass}
          providerConsumerOrder={this.props.providerConsumerOrder}
        />
      ) : (
        addIntraRule
      );
    const addExtraRule =
      !this.props.proposed && !extraRules.length && SessionStore.canUserCUDExtraScopeRules() ? (
        <div
          className="RulesetScopesAndRules-Section-AddLink"
          data-tid="rulesetrules-addrule rulesetrules-addrule-intra"
        >
          <span onClick={() => this.handleAdd({addRule: 'extra'})}>
            <Icon name="add" />
            {intl('Rulesets.Rules.ExtraScope.Title')}
          </span>
        </div>
      ) : null;
    const extraRuleGrid =
      extraRules.length || this.state.addRule === 'extra' ? (
        <RulesetRuleGrid
          lastType={this.props.lastType}
          addRule={this.state.addRule === 'extra'}
          anyHref={this.props.anyHref}
          comparedRules={extraRules}
          diffRuleset={this.props.diffRuleset}
          editingHref={this.state.editingHref}
          errors={this.state.errors}
          filter={this.state.filters}
          getRuleNote={this.getRuleNote}
          hideRules={this.state.hideRules.includes('extra')}
          onHideToggle={_.partial(this.handleHideToggle, 'extra')}
          newRule={this.state.newRule}
          onAdd={_.partial(this.handleAdd, {addRule: 'extra'})}
          onAddCancel={this.handleAddCancel}
          onAddSave={this.handleAddSave}
          onEdit={this.handleEdit}
          onEditCancel={this.handleEditCancel}
          onEditSave={this.handleEditSave}
          onEditSecondary={this.handleEditSecondary}
          onEntityChange={_.partial(this.handleEntityChange, 'extra')}
          onStartReorder={this.props.onStartReorder}
          onReorderCancel={this.props.onReorderCancel}
          onReorderSave={this.props.onReorderSave}
          onErrorsChange={this.handleErrorsChange}
          onPageChange={_.partial(this.handlePageChange, 'extraScope')}
          onRowSelect={this.handleRowSelect}
          onSort={_.partial(this.handleSort, 'extra')}
          page={this.state.extraScopePage}
          pageLength={rulesLimit}
          proposed={this.props.proposed}
          readonly={readonly}
          ruleset={this.props.ruleset}
          selection={this.state.selection}
          sorting={this.state.extraSorting}
          allowShiftSelect={true}
          lastSelected={this.state.lastSelected}
          type="extra"
          version={this.props.version}
          moveRow={this.props.moveRow}
          reorderMode={this.props.reorderMode}
          dragOrder={this.props.dragOrder}
          formatRowClass={this.formatRowClass}
          providerConsumerOrder={this.props.providerConsumerOrder}
        />
      ) : (
        addExtraRule
      );
    const hideRules = !totalRules && this.state.filters === null && !this.state.addRule;
    const filterButton = (
      <Button
        content="icon-only"
        icon="filter"
        type="secondary"
        onClick={this.handleFilterOpen}
        tid="filter"
        disabled={
          this.state.filters !== null ||
          (!this.props.ruleset.rules.length && !this.props.ruleset.ip_tables_rules.length)
        }
        customClass={this.state.filters === null ? null : 'Button--active'}
      />
    );

    return (
      <div className="RulesetRules" data-tid="page-rulesetrules-rules">
        <RulesetAndScopesCollapseTitle
          title={intl('Common.Rules')}
          reorderMode={this.props.reorderMode}
          collapsed={this.state.collapsed}
          onClick={this.handleCollapse}
          totalRules={totalRules}
          readonly={readonly}
          type="rules"
          empty={
            hideRules ? (
              <div>
                <div className="RulesetScopesAndRules-Section-Empty" data-tid="RulesetRules-NoRules">
                  {intl('Rulesets.Rules.NoRules')}
                </div>
                {addIntraRule}
                {addExtraRule}
              </div>
            ) : null
          }
          actionButtons={
            !readonly && !this.props.proposed ? (
              <div className="RulesetScopes-CollapseTitle-Actions">
                <ButtonDropdown
                  icon="add"
                  options={addOptions}
                  type="secondary"
                  tid="add"
                  onSelect={({value}) => this.handleAdd({addRule: value})}
                />
                <Button
                  content="icon-only"
                  icon="remove"
                  onClick={this.handleRemove}
                  tid="remove"
                  type="secondary"
                  disabled={!this.state.selection.length}
                />
                <ButtonDropdown
                  icon="edit"
                  options={modifyOptions}
                  tid="modify"
                  type="secondary"
                  onSelect={option => this[option.value]()}
                  disabled={!this.state.selection.length}
                />
                {filterButton}
              </div>
            ) : (
              filterButton
            )
          }
        >
          {this.state.filters === null ? null : (
            <div className="RulesetRules-filter" data-tid="page-rulesetrules-filter">
              <OSEntitySelect
                ref="filterRules"
                selected={this.state.filters}
                onChange={this.handleFilter}
                version={this.props.version}
                anyHref={this.props.anyHref}
                placeholder={intl('Rulesets.Rules.FilterRules')}
                autoFocus={true}
                showServices={true}
              />
              <Button
                icon="cancel"
                type="secondary"
                content="icon-only"
                onClick={() => this.setState({filters: null})}
                tid="filter-cancel"
              />
            </div>
          )}
          {this.state.rbacErrors && (
            <NotificationGroup notifications={this.state.rbacErrors} onChange={this.props.onRbacErrorsChange} />
          )}
          {this.props.tooManyRules ? (
            <div className="RulesetRules-toomanyrules">
              <NotificationGroup notifications={[{type: 'warning', title: intl('Rulesets.Rules.TooManyRules')}]} />
            </div>
          ) : null}
          {(this.props.reorderMode === 'intra' || this.props.reorderMode === 'none') && intraRuleGrid}
          {(this.props.reorderMode === 'extra' || this.props.reorderMode === 'none') && extraRuleGrid}
          {(this.props.reorderMode === 'customip' || this.props.reorderMode === 'none') &&
            (customRules.length || this.state.addRule === 'custom') && (
              <RulesetIptableGrid
                addRule={this.state.addRule === 'custom'}
                comparedIpTableRules={customRules}
                reorderMode={this.props.reorderMode}
                type="customip"
                editingHref={this.state.editingHref}
                errors={this.state.errors}
                filter={this.state.filters}
                getRuleNote={this.getRuleNote}
                hideRules={this.state.hideRules.includes('custom')}
                onHideToggle={_.partial(this.handleHideToggle, 'custom')}
                newRule={this.state.newRule}
                onAdd={_.partial(this.handleAdd, {addRule: 'custom'})}
                onAddCancel={this.handleAddCancel}
                onAddSave={this.handleAddSave}
                onEdit={this.handleEdit}
                onEditCancel={this.handleEditCancel}
                onEditSave={this.handleEditSave}
                onEditSecondary={this.handleEditSecondary}
                onErrorsChange={this.handleErrorsChange}
                onEntityChange={_.partial(this.handleEntityChange, 'custom')}
                onPageChange={_.partial(this.handlePageChange, 'ipTables')}
                onRowSelect={this.handleRowSelect}
                onSort={_.partial(this.handleSort, 'custom')}
                page={this.state.ipTablesPage}
                pageLength={rulesLimit}
                readonly={readonly}
                ruleset={this.props.ruleset}
                selection={this.state.selection}
                allowShiftSelect={true}
                lastSelected={this.state.lastSelected}
                sorting={this.state.customSorting}
                version={this.props.version}
              />
            )}
        </RulesetAndScopesCollapseTitle>
      </div>
    );
  },
});
